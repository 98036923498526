import billing_theme from "style/theme_colors";
import { Heading5, Heading6 } from "components/text/heading/style";
import { Body4 } from "components/text/body/style";
import { ContratoInsideContent } from "../style";
import { NovoContratoInputLabel } from "../../../style";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "store/store";
import BillingSelectInput from "components/input/select";
import { setIndiceState } from "store/contrato/new_contrato_slice";

const TaxasEReajustes: React.FC<{}> = () => {
  const list_enum = useSelector((state: RootState) => state.list_enum);
  const novo_contrato = useSelector((state: RootState) => state.novo_contrato);
  const dispatch = useAppDispatch();
  return (
    <ContratoInsideContent>
      <NovoContratoInputLabel>
        <Heading5 fontWeight={600} color={billing_theme.pallete.tertiary.A600}>
          Taxas e reajustes
        </Heading5>
        <Body4 fontWeight={400} color={billing_theme.pallete.tertiary.A600}>
          Reajustes e descontos planejados
        </Body4>
      </NovoContratoInputLabel>
      <NovoContratoInputLabel>
        <Heading6 fontWeight={600} color={billing_theme.pallete.tertiary.A600}>
          Índice de reajuste
        </Heading6>
        <BillingSelectInput
          defaultInputValue={list_enum.IndiceReajusteOptions?.find(
            el => Number(el.value) === novo_contrato.indice
          )}
          onChange={val => {
            val !== null
              ? dispatch(setIndiceState(Number(val.value!)))
              : dispatch(setIndiceState());
          }}
          options={list_enum.IndiceReajusteOptions!}
          placeholder={"Índice de reajuste"}
        />
      </NovoContratoInputLabel>
    </ContratoInsideContent>
  );
};

export default TaxasEReajustes;
