import { BillingHttp } from "gateways/billing_http";
import {
  ContratadaListResponseModel,
  ContratadaRespondeModel,
  PostContratadaModel,
} from "shared/models/contratada_response.model";
import { OptionsResponseModel } from "shared/models/default_models.model";
export class ContratadaHttp {
  private http = new BillingHttp();

  getListContratada = async () => {
    const reponse = await this.http.get<ContratadaListResponseModel>("Contratada/List");

    return reponse.data;
  };

  getListContratadaOptions = async () => {
    const reponse = await this.http.get<OptionsResponseModel>("Contratada/ListOptions");
    return reponse.data;
  };
  getContratada = async (id: number) => {
    const response = await this.http.get<ContratadaRespondeModel>("Contratada/Get?id=" + id);
    return response.data;
  };
  adContratada = async (data: PostContratadaModel) => {
    const response = await this.http.post("Contratada/Insert", { data: data });
    return response.data;
  };
  updateContratada = async (data: PostContratadaModel) => {
    const response = await this.http.put("Contratada/Update", { data: data });
    return response.data;
  };
  deleteContratada = async (id: number | string) => {
    const response = await this.http.delete("Contratada/Delete?id=" + id);
    return response.data;
  };
}
