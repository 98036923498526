import { Body4 } from "components/text/body/style";
import { Heading5 } from "components/text/heading/style";
import billing_theme from "style/theme_colors";
import { NovoContratoInputLabel } from "../../style";
import { KitAdicionalContainer, SelectedKitContent } from "./style";
import { useSelector } from "react-redux";
import { OptionsModel } from "shared/models/contratada_response.model";
import { RootState, useAppDispatch } from "store/store";
import { addKitState, removeKitState } from "store/contrato/new_contrato_slice";
import { SingleValue } from "react-select";
import BillingSelectInput from "components/input/select";
import SelectedKit from "./components/selected_item_kit";
import { columnSelectionComplete } from "@syncfusion/ej2-react-grids";

const KitAdicional: React.FC<{}> = () => {
  const kit = useSelector((state: RootState) => state.kit);
  const state = useSelector((state: RootState) => state.novo_contrato);
  const dispatch = useAppDispatch();

  const getKitName = (id: string) => {
    return kit.options.find(el => el.value == id)?.label ?? "";
  };
  return (
    <KitAdicionalContainer>
      <NovoContratoInputLabel>
        <Heading5 fontWeight={600} color={billing_theme.pallete.tertiary.A600}>
          Kit adicional
        </Heading5>
        <Body4 fontWeight={400} color={billing_theme.pallete.tertiary.A600}>
          Informe um kit adicional
        </Body4>
      </NovoContratoInputLabel>
      <BillingSelectInput
        onChange={(val: SingleValue<OptionsModel>) => {
          if (val != null)
            dispatch(
              addKitState({
                ID: 0,
                Kit_ID: Number(val?.value),
              })
            );
        }}
        options={kit.options}
        placeholder={"Selecione um  Kit"}
      />
      <Heading5 fontWeight={600} color={billing_theme.pallete.tertiary.A600}>
        Adicionados
      </Heading5>
      <SelectedKitContent>
        {state.kitsComplementares?.map(el => (
          <SelectedKit
            key={el.Kit_ID}
            label={getKitName(el.Kit_ID!.toString())}
            onTap={() => {
              dispatch(removeKitState(el));
            }}
          />
        ))}
      </SelectedKitContent>
    </KitAdicionalContainer>
  );
};

export default KitAdicional;
