import React, { useEffect, useState } from "react";
import { EditInstalacaosForm } from "../style";
import billing_theme from "style/theme_colors";
import { Heading5, Heading6 } from "components/text/heading/style";
import BillingSelectInput from "components/input/select";
import { EditInstalacaoInputLabel } from "view/Instalacao/page/EditInstalacao/style";
import BillingDatePicker from "components/input/datePicker";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "store/store";
import LineSkeleton from "components/skeleton/line_skeleton";
import {
  setContratanteViewInstalacao,
  setDataInstalacaoViewInstalacao,
  setMotivoDesinstalacoViewInstalacao,
  setVeiculoViewInstalacao,
  setDataDesinsitalacaoViewInstalacao,
  setCodigoGoViewInstalacao,
} from "store/instalacao/view_instalacao_slice";
import PrimaryButton from "components/button/billing_button";
import BillingNumberInput from "components/input/number_input";
import { OptionsModel } from "shared/models/contratada_response.model";
import moment from "moment";
import useEditInstalacao from "hooks/instalacao/useEditIntalacao";

const NovaInstalacaoForm: React.FC = () => {
  const dispatch = useAppDispatch();
  const state = useSelector((state: RootState) => state.view_instalacao);
  const contratante = useSelector((state: RootState) => state.contratante);
  const veiculo = useSelector((state: RootState) => state.veiculo);
  const enum_list = useSelector((state: RootState) => state.list_enum);
  const { updateInstalacao } = useEditInstalacao();

  const selectedContratante = () =>
    contratante.options.find(el => Number(el.value) == state.Contratante_ID);

  const selectedVeiculo = () => veiculo.options.find(el => Number(el.value) == state.Veiculo_ID);
  const selectedMotivo = () =>
    enum_list.MotivoDesinstalacaoOptions?.find(el => Number(el.value) == state.MotivoDesinstalacao);

  console.log(state);
  return (
    <EditInstalacaosForm>
      <Heading5 fontWeight={600} color={billing_theme.pallete.tertiary.A600}>
        Dados da instalação
      </Heading5>
      <EditInstalacaoInputLabel>
        <Heading6 fontWeight={600} color={billing_theme.pallete.tertiary.A600}>
          Código GO
        </Heading6>
        {state.loading ? (
          <LineSkeleton width={"100%"} height={40} />
        ) : (
          <BillingNumberInput
            value={state.CodigoGO}
            onNumberFormat={val => {
              dispatch(setCodigoGoViewInstalacao(val.number));
            }}
            placeholder={"Selecione um equipamento principal"}
          />
        )}
      </EditInstalacaoInputLabel>
      <EditInstalacaoInputLabel>
        <Heading6 fontWeight={600} color={billing_theme.pallete.tertiary.A600}>
          Contratante
        </Heading6>
        {state.loading ? (
          <LineSkeleton width={"100%"} height={40} />
        ) : (
          <BillingSelectInput
            defaultInputValue={selectedContratante()}
            onChange={(val: any) => {
              val != null
                ? dispatch(setContratanteViewInstalacao(Number(val.value)))
                : dispatch(setContratanteViewInstalacao(undefined));
            }}
            options={contratante.options}
            placeholder={"Selecione o contratante"}
          />
        )}
      </EditInstalacaoInputLabel>
      <EditInstalacaoInputLabel>
        <Heading6 fontWeight={600} color={billing_theme.pallete.tertiary.A600}>
          Veiculo
        </Heading6>
        {state.loading ? (
          <LineSkeleton width={"100%"} height={40} />
        ) : (
          <BillingSelectInput
            defaultInputValue={selectedVeiculo()}
            onChange={(val: any) => {
              val != null
                ? dispatch(setVeiculoViewInstalacao(Number(val.value)))
                : dispatch(setVeiculoViewInstalacao(undefined));
            }}
            options={veiculo.options}
            placeholder={"Selecione o veiculo"}
          />
        )}
      </EditInstalacaoInputLabel>

      <EditInstalacaoInputLabel>
        <Heading6 fontWeight={600} color={billing_theme.pallete.tertiary.A600}>
          Data da instalação
        </Heading6>
        {state.loading ? (
          <LineSkeleton width={"100%"} height={40} />
        ) : (
          <BillingDatePicker
            defautValue={undefined}
            onChange={(val: Date) => {
              dispatch(setDataInstalacaoViewInstalacao(val.toISOString()));
            }}
            placeholder={"Selecione a data da instalação"}
            dateFormat={"dd/MM/yyyy"}
          />
        )}
      </EditInstalacaoInputLabel>
      <EditInstalacaoInputLabel>
        <Heading6 fontWeight={600} color={billing_theme.pallete.tertiary.A600}>
          Data da desinstalação
        </Heading6>
        {state.loading ? (
          <LineSkeleton width={"100%"} height={40} />
        ) : (
          <BillingDatePicker
            defautValue={undefined}
            onChange={(val: Date) => {
              dispatch(setDataDesinsitalacaoViewInstalacao(val.toISOString()));
            }}
            placeholder={"Selecione a data da desinstalação"}
            dateFormat={"dd/MM/yyyy"}
          />
        )}
      </EditInstalacaoInputLabel>
      <EditInstalacaoInputLabel>
        <Heading6 fontWeight={600} color={billing_theme.pallete.tertiary.A600}>
          Motivo da desinstalação
        </Heading6>
        {state.loading ? (
          <LineSkeleton width={"100%"} height={40} />
        ) : (
          <BillingSelectInput
            defaultInputValue={selectedMotivo()}
            onChange={(val: any) => {
              val != null
                ? dispatch(setMotivoDesinstalacoViewInstalacao(Number(val.value)))
                : dispatch(setMotivoDesinstalacoViewInstalacao(undefined));
            }}
            options={enum_list.MotivoDesinstalacaoOptions!}
            placeholder={"Qual o motivo da desinstalação"}
          />
        )}
      </EditInstalacaoInputLabel>
      <div className="button">
        <PrimaryButton label={"Editar instalação"} onTap={() => updateInstalacao({})} />
      </div>
    </EditInstalacaosForm>
  );
};

export default NovaInstalacaoForm;
