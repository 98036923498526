import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export interface NewVeiculo {
  loading?: boolean;
  isError?: boolean;
  isOpen: boolean;
}

const initialState: NewVeiculo = {
  loading: false,
  isError: false,
  isOpen: false,
};

const new_veiculo_slice = createSlice({
  name: "new_veiculo",
  initialState,
  reducers: {
    setLoadingNewVeiculo(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },

    setErrorNewVeiculo(state, action: PayloadAction<boolean>) {
      state.isError = action.payload;
    },
    setModalNewVeiculo(state, action: PayloadAction<boolean>) {
      state.isOpen = action.payload;
    },
  },
});

export const { setLoadingNewVeiculo, setErrorNewVeiculo, setModalNewVeiculo } =
  new_veiculo_slice.actions;
export default new_veiculo_slice.reducer;
