import { Heading4, Heading5 } from "components/text/heading/style";
import { LoginContainer, LoginBox, LoginDiv } from "./style";
import React, { useEffect, useState } from "react";
import billing_theme from "style/theme_colors";
import logo from "../../assets/svg/logo_app_go.svg";
import BillingTextInput from "components/input/text_input";
import PrimaryButton from "components/button/billing_button";
import BillingCheckbox from "components/input/checkbox";
import BillingPassowordInput from "components/input/password_input";
import { useAuth } from "hooks/useAuth";
import { toastInfo } from "components/toast/billing_toast";
import { ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { validarEmail } from "utils/stringUtils";

const LoginPage: React.FC = () => {
  const { login, userLoading, isAuth } = useAuth();
  const [email, setEmail] = useState<string>();
  const [isValidEmail, setValidEmail] = useState<boolean>(true);
  const [password, setPassword] = useState<string>();
  const [manterLogado, setManterLogado] = useState<boolean>(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (isAuth()) {
      navigate("/inicio");
    }
  }, []);
  return (
    <LoginContainer>
      <div className="logoTiltle">
        <img src={logo} alt="" />
        <Heading4 fontWeight={600} color={billing_theme.pallete.primary.A400}>
          Billing
        </Heading4>
      </div>
      <LoginBox>
        <LoginDiv>
          <Heading5 fontWeight={600} color={billing_theme.pallete.tertiary.A400}>
            Login
          </Heading5>
          <BillingTextInput
            change={value => {
              setEmail(value);
              setValidEmail(validarEmail(value!));
            }}
            error={!isValidEmail}
            errorMessage="O e-mail é invalido."
            placeholder="Qual seu e-mail"
          />
        </LoginDiv>
        <LoginDiv>
          <Heading5 fontWeight={600} color={billing_theme.pallete.tertiary.A400}>
            Senha
          </Heading5>
          <BillingPassowordInput
            change={value => {
              setPassword(value);
            }}
            placeholder="Qual sua senha?"
          />
        </LoginDiv>
        <BillingCheckbox
          label="Manter conectado"
          change={value => {
            setManterLogado(value!);
          }}
        />
        <PrimaryButton
          label={"Entrar"}
          disable={
            !isValidEmail ||
            email == undefined ||
            password == undefined ||
            password.length == 0 ||
            userLoading
          }
          onTap={() => login(email!, password!, manterLogado)}
        />
        <PrimaryButton
          isFilled
          label={"Recuperar senha"}
          onTap={() => toastInfo({ title: "Em breve!" })}
        />
      </LoginBox>
      <ToastContainer autoClose={5000} />
    </LoginContainer>
  );
};

export default LoginPage;
