import { SummryFieldContent } from "../style";
import { Heading6 } from "components/text/heading/style";
import { Body4 } from "components/text/body/style";
import billing_theme from "style/theme_colors";

const SummryField: React.FC<{ title: string; content?: string | number; aboveTitle?: string }> = ({
  title,
  content,
}) => {
  return (
    <SummryFieldContent>
      <Heading6 fontWeight={600} lineheight="24px" color={billing_theme.pallete.tertiary.A600}>
        {title}
      </Heading6>
      <Body4 fontWeight={400} color={billing_theme.pallete.primary.A300} lineheight="16px">
        {content ?? "--"}
      </Body4>
    </SummryFieldContent>
  );
};

export default SummryField;
