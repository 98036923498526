import React, { useState } from "react";
import { CheckboxContent } from "./style";
import billing_theme from "style/theme_colors";
import { Caution1 } from "components/text/body/style";

const BillingCheckbox: React.FC<{
  label?: string;
  change: (value?: boolean) => void;
  defaultValue?: boolean;
  disabled?: boolean | false;
}> = ({ label, change, defaultValue, disabled }) => {
  const [isChecked, setChecked] = useState(defaultValue ?? false);
  return (
    <CheckboxContent>
      <input
        defaultChecked={defaultValue}
        disabled={disabled}
        type="checkbox"
        onChange={ev => {
          setChecked(!isChecked);
          change(!isChecked);
        }}
      ></input>
      <span className={(isChecked ? "checked" : "") + " checkmark"}></span>
      <Caution1 lineheight="30px" fontWeight={600} color={billing_theme.pallete.tertiary.A600}>
        {label}
      </Caution1>
    </CheckboxContent>
  );
};

export default BillingCheckbox;
