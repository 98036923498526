import billing_theme from "style/theme_colors";
import { Heading5, Heading6 } from "components/text/heading/style";
import { Body4 } from "components/text/body/style";
import { EditContratoInputLabel } from "../../../style";
import { RootState, useAppDispatch } from "store/store";
import {
  setInstalacao,
  setDesinstalacao,
  setTransferencia,
} from "store/contrato/view_contrato_slice";
import BillingNumberInput from "components/input/number_input";
import { ValoresDataGrid, ValoresInsideContent } from "../style";
import { useSelector } from "react-redux";
import { toMoney } from "utils/numberUtils";
import BillingMoneyInput from "components/input/money_input";

const ValoresTecnicosComp: React.FC<{}> = () => {
  const edit_contrato = useSelector((state: RootState) => state.view_contrato);
  const dispatch = useAppDispatch();
  return (
    <ValoresInsideContent>
      <EditContratoInputLabel>
        <Heading5 fontWeight={600} color={billing_theme.pallete.tertiary.A600}>
          Valores técnicos
        </Heading5>
        <Body4 fontWeight={400} color={billing_theme.pallete.tertiary.A600}>
          Informe valores os negociados.
        </Body4>
      </EditContratoInputLabel>
      <ValoresDataGrid>
        <EditContratoInputLabel>
          <Heading6 fontWeight={600} color={billing_theme.pallete.tertiary.A600}>
            Instalação
          </Heading6>
          <BillingMoneyInput
            value={edit_contrato.Instalacao}
            placeholder="R$ 00,00"
            onNumberFormat={val => {
              dispatch(setInstalacao(val.floatValue));
            }}
          />
        </EditContratoInputLabel>
        <EditContratoInputLabel>
          <Heading6 fontWeight={600} color={billing_theme.pallete.tertiary.A600}>
            Desinstalação
          </Heading6>
          <BillingMoneyInput
            value={edit_contrato.Desinstalacao ?? ""}
            placeholder="R$ 00,00"
            onNumberFormat={val => {
              dispatch(setDesinstalacao(val.floatValue));
            }}
          />
        </EditContratoInputLabel>
        <EditContratoInputLabel>
          <Heading6 fontWeight={600} color={billing_theme.pallete.tertiary.A600}>
            Transferência
          </Heading6>
          <BillingMoneyInput
            value={edit_contrato.Transferencia}
            placeholder="R$ 00,00"
            onNumberFormat={val => {
              dispatch(setTransferencia(val.floatValue));
            }}
          />
        </EditContratoInputLabel>
      </ValoresDataGrid>
    </ValoresInsideContent>
  );
};

export default ValoresTecnicosComp;
