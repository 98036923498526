import billing_theme from "style/theme_colors";
import { Heading5, Heading6 } from "components/text/heading/style";
import { Body4 } from "components/text/body/style";
import { ContratoInsideContent, ContratoDataGrid } from "../style";
import { EditContratoInputLabel } from "../../../style";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "store/store";
import {
  setMensalidade,
  setValorReajuste,
  setValorNaoReajustavel,
} from "store/contrato/view_contrato_slice";
import BillingNumberInput from "components/input/number_input";
import { toMoney } from "utils/numberUtils";
import BillingMoneyInput from "components/input/money_input";

const MensalidadeEditContrato: React.FC<{}> = () => {
  const state = useSelector((state: RootState) => state);
  const edit_contrato = useSelector((state: RootState) => state.view_contrato);
  const dispatch = useAppDispatch();
  return (
    <ContratoInsideContent>
      <EditContratoInputLabel>
        <Heading5 fontWeight={600} color={billing_theme.pallete.tertiary.A600}>
          Mensalidade
        </Heading5>
        <Body4 fontWeight={400} color={billing_theme.pallete.tertiary.A600}>
          Informe os valores negociados.
        </Body4>
      </EditContratoInputLabel>

      <ContratoDataGrid>
        <EditContratoInputLabel>
          <Heading6 fontWeight={600} color={billing_theme.pallete.tertiary.A600} lineheight="24px">
            Mensalidade*
          </Heading6>

          <BillingMoneyInput
            value={edit_contrato.Mensalidade}
            placeholder="R$ 00,00"
            onNumberFormat={val => {
              dispatch(setMensalidade(val.floatValue));
            }}
          />
        </EditContratoInputLabel>

        <EditContratoInputLabel>
          <Heading6 fontWeight={600} color={billing_theme.pallete.tertiary.A600} lineheight="24px">
            Valor reajustavel*
          </Heading6>

          <BillingMoneyInput
            value={edit_contrato.ValorReajustavel}
            placeholder="R$ 00,00"
            onNumberFormat={val => {
              dispatch(setValorReajuste(val.floatValue));
              dispatch(setValorNaoReajustavel(edit_contrato.Mensalidade! - val.floatValue!));
            }}
            error={state.view_contrato.Mensalidade! < state.view_contrato.ValorReajustavel!}
            errorMesage="O valor não deve ser maior que a mensalidade!"
          />
        </EditContratoInputLabel>

        <EditContratoInputLabel>
          <Heading6 fontWeight={600} color={billing_theme.pallete.tertiary.A600} lineheight="24px">
            Não reajustavel*
          </Heading6>

          <div className="nao_reaju">
            <Heading6
              fontWeight={600}
              color={
                state.view_contrato.Mensalidade! < state.view_contrato.ValorReajustavel!
                  ? billing_theme.pallete.error.A100
                  : billing_theme.pallete.tertiary.A400
              }
            >
              {toMoney(state.view_contrato.Mensalidade! - state.view_contrato.ValorReajustavel!)}
            </Heading6>
          </div>
        </EditContratoInputLabel>
      </ContratoDataGrid>
    </ContratoInsideContent>
  );
};

export default MensalidadeEditContrato;
