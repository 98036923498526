import { ContratadaHttp } from "gateways/repo/contratada_http_client";
import {
  setContratada,
  setLoadingViewContrato,
  setErrorViewContrato,
  setModalContratada,
} from "store/contratada/view_contratada_slice";
import { useAppDispatch } from "store/store";
import useContratada from "./useContratada";
import { PostContratadaModel } from "shared/models/contratada_response.model";

const useViewContratada = () => {
  const http = new ContratadaHttp();
  const dispatch = useAppDispatch();
  const { getContratadaList } = useContratada();

  const getContratada = async (id: number) => {
    dispatch(setLoadingViewContrato(true));
    dispatch(setErrorViewContrato(false));
    try {
      const data = await http.getContratada(id);
      dispatch(setContratada(data.Objeto));
    } catch (e) {
      console.log(e);
      dispatch(setErrorViewContrato(true));
    } finally {
      dispatch(setLoadingViewContrato(false));
    }
  };
  const updateContratada = async (data: PostContratadaModel) => {
    dispatch(setLoadingViewContrato(true));
    dispatch(setErrorViewContrato(false));
    try {
      await http.updateContratada(data);
      getContratadaList();
      dispatch(setModalContratada(false));
    } catch (e) {
      console.log(e);
      dispatch(setErrorViewContrato(true));
    } finally {
      dispatch(setLoadingViewContrato(false));
    }
  };
  return {
    getContratada,
    updateContratada,
  };
};
export default useViewContratada;
