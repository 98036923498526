import { SummryTitleContent } from "../style";
import { Heading5 } from "components/text/heading/style";
import billing_theme from "style/theme_colors";

const SummryTitle: React.FC<{ title: string }> = ({ title }) => {
  return (
    <SummryTitleContent>
      <Heading5 fontWeight={600} lineheight="24px" color={billing_theme.pallete.tertiary.A600}>
        {title}
      </Heading5>
    </SummryTitleContent>
  );
};

export default SummryTitle;
