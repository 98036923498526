import { Action, ThunkDispatch, configureStore } from "@reduxjs/toolkit";
import contratoReducer from "./contrato/contrato_slice";
import contratadaReducer from "./contratada/contratada_slice";
import viewContratadaReducer from "./contratada/view_contratada_slice";
import newContratadaReducer from "./contratada/new_contratada_slice";
import contratanteReducer from "./contratante/contratante_slice";
import viewContratanteReducer from "./contratante/view_contratante_slice";
import newContratanteReducer from "./contratante/new_contratante_slice";
import kitReducer from "./kit/kit_slice";
import kitViewReducer from "./kit/view_kit_slice";
import kitNewReducer from "./kit/new_kit_slice";
import novoContratoReducer from "./contrato/new_contrato_slice";
import servicoReducer from "./servico/servico_slice";
import viewServicoReducer from "./servico/view_servico_slice";
import viewContratoReducer from "./contrato/view_contrato_slice";
import faturaReducer from "./fatura/fatura_slice";
import viewFaturaSliceReducer from "./fatura/view_fatura_slice";
import modeloReducer from "./modelo/modelo_slice";
import viewModeloReducer from "./modelo/view_modelo_slice";
import listEnumReducer from "./list_enum_slice";
import equipamentoReducer from "./equipamento/equipamento_slice";
import newEquipamentoReducer from "./equipamento/new_equipamento_slice";
import viewEquipamentoReducer from "./equipamento/view_equipamento_slice";
import veiculoReducer from "./veiculo/veiculo_slice";
import newVeiculoReducer from "./veiculo/new_veiculo_slice";
import viewVeiculoReducer from "./veiculo/view_veiculo_slice";
import instalacaoReducer from "./instalacao/instalacao_slice";
import newInstalacaoReducer from "./instalacao/new_instalacao_slice";
import viewInstalacaoReducer from "./instalacao/view_instalacao_slice";
import { useDispatch } from "react-redux";

export const store = configureStore({
  reducer: {
    contrato: contratoReducer,
    contratada: contratadaReducer,
    view_contratada: viewContratadaReducer,
    new_contratada: newContratadaReducer,
    contratante: contratanteReducer,
    view_contratante: viewContratanteReducer,
    new_contratante: newContratanteReducer,
    kit: kitReducer,
    servico: servicoReducer,
    view_servico: viewServicoReducer,
    novo_contrato: novoContratoReducer,
    view_contrato: viewContratoReducer,
    fatura: faturaReducer,
    view_fatura: viewFaturaSliceReducer,
    view_kit: kitViewReducer,
    new_kit: kitNewReducer,
    modelo: modeloReducer,
    view_modelo: viewModeloReducer,
    list_enum: listEnumReducer,
    equipamento: equipamentoReducer,
    new_equipamento: newEquipamentoReducer,
    view_equipamento: viewEquipamentoReducer,
    veiculo: veiculoReducer,
    new_veiculo: newVeiculoReducer,
    view_veiculo: viewVeiculoReducer,
    instalacao: instalacaoReducer,
    new_instalacao: newInstalacaoReducer,
    view_instalacao: viewInstalacaoReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type RootDispatch = typeof store.dispatch;
export type AppThunkDispatch = ThunkDispatch<RootState, void, Action>;
export const useAppDispatch = () => useDispatch<AppThunkDispatch>();
