import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { toastWarning } from "components/toast/billing_toast";
import { RegrasInstalacao } from "shared/models/contrato_post.model";
import {
  ContratoResponse,
  KitsComplementare,
  ServicoContrato,
} from "shared/models/contrato_response.model";

const initialState: ContratoResponse = {
  KitsComplementares: [],
  Servicos: [],
  RegrasInstalacao: [],
  pageIndex: 0,
  loading: true,
};

const view_contrato_slice = createSlice({
  name: "view_contrato",
  initialState,
  reducers: {
    resetViewContratoState: () => initialState,
    setEditContratoState(state, action: PayloadAction<ContratoResponse>) {
      state.ID = action.payload.ID;
      state.Numero = action.payload.Numero;
      state.Aditivo = action.payload.Aditivo;
      state.Contratada_ID = action.payload.Contratada_ID;
      state.Contratante_ID = action.payload.Contratante_ID;
      state.KitPrincipal_ID = action.payload.KitPrincipal_ID;
      state.Quantidade = action.payload.Quantidade;
      state.Mensalidade = action.payload.Mensalidade;
      state.Instalacao = action.payload.Instalacao;
      state.Desinstalacao = action.payload.Desinstalacao;
      state.Transferencia = action.payload.Transferencia;
      state.DuracaoMeses = action.payload.DuracaoMeses;
      state.CarenciaDias = action.payload.CarenciaDias;
      state.FidelidadeMeses = action.payload.FidelidadeMeses;
      state.PercentualDesconto = action.payload.PercentualDesconto;
      state.MesesDesconto = action.payload.MesesDesconto;
      state.Indice = action.payload.Indice;
      state.ValorReajustavel = action.payload.ValorReajustavel;
      state.ValorNaoReajustavel = action.payload.ValorNaoReajustavel;
      state.ProRataInicio = action.payload.ProRataInicio;
      state.ProRataDesinstalacao = action.payload.ProRataDesinstalacao;
      state.RegrasInstalacao = action.payload.RegrasInstalacao;
      state.KitsComplementares = action.payload.KitsComplementares;
      state.Servicos = action.payload.Servicos;
      state.Historicos = action.payload.Historicos;
      state.VigenciaInicio = action.payload.VigenciaInicio;
      state.VigenciaFim = action.payload.VigenciaFim;
      state.FaturamentoInicio = action.payload.FaturamentoFim;
    },
    setContratanteState(state, action: PayloadAction<number | undefined>) {
      state.Contratante_ID = action.payload!;
    },

    setContratadaState(state, action: PayloadAction<number | undefined>) {
      state.Contratada_ID = action.payload;
    },
    setDuracaoState(state, action: PayloadAction<number | undefined>) {
      state.DuracaoMeses = action.payload;
    },
    setCarenciaState(state, action: PayloadAction<number | undefined>) {
      state.CarenciaDias = action.payload;
    },
    setFidelidadeState(state, action: PayloadAction<number | undefined>) {
      state.FidelidadeMeses = action.payload;
    },
    setPorcentagemState(state, action: PayloadAction<number | undefined>) {
      state.PercentualDesconto = action.payload;
    },
    setMesesState(state, action: PayloadAction<number | undefined>) {
      state.MesesDesconto = action.payload;
    },
    setIndiceState(state, action: { payload: number | undefined; type: string }) {
      state.Indice = action.payload;
    },
    addKitState(state, action: PayloadAction<KitsComplementare>) {
      if (state.KitsComplementares!.find(el => el.Kit_ID == action.payload.Kit_ID)) {
        toastWarning({ title: "Você já adicionou esse kit!" });
      } else {
        state.KitsComplementares!.push(action.payload);
      }
    },
    addServicoState(state, action: PayloadAction<ServicoContrato>) {
      if (state.Servicos?.find(el => el.Servico_ID == action.payload.Servico_ID)) {
        toastWarning({ title: "Você já adicionou esse serviço!" });
      } else {
        state.Servicos?.push(action.payload);
      }
    },
    removeKitState(state, action: PayloadAction<KitsComplementare>) {
      state.KitsComplementares = state.KitsComplementares!.filter(
        el => action.payload.Kit_ID !== el.Kit_ID
      );
    },
    removeServicoState(state, action: PayloadAction<ServicoContrato>) {
      state.Servicos = state.Servicos!.filter(el => action.payload.Servico_ID! !== el.Servico_ID!);
    },
    setKitPrincipal(state, action: PayloadAction<number | undefined>) {
      state.KitPrincipal_ID = action.payload;
    },
    setQuantidadeKitPrincipal(state, action: PayloadAction<number | undefined>) {
      state.Quantidade = action.payload;
    },
    setValorReajuste(state, action: PayloadAction<number | undefined>) {
      state.ValorReajustavel = action.payload;
    },
    setValorNaoReajustavel(state, action: PayloadAction<number | undefined>) {
      state.ValorNaoReajustavel = action.payload;
    },
    setProRataInstacao(state, action: PayloadAction<boolean>) {
      state.ProRataInicio = action.payload;
    },
    setProRataDesinstacao(state, action: PayloadAction<boolean>) {
      state.ProRataDesinstalacao = action.payload;
    },
    setInstalacao(state, action: PayloadAction<number | undefined>) {
      state.Instalacao = action.payload;
    },
    setDesinstalacao(state, action: PayloadAction<number | undefined>) {
      state.Desinstalacao = action.payload;
    },
    setTransferencia(state, action: PayloadAction<number | undefined>) {
      state.Transferencia = action.payload;
    },
    setDuracaoCarencia(state, action: PayloadAction<number | undefined>) {
      state.CarenciaDias = action.payload;
    },
    setMensalidade(state, action: PayloadAction<number | undefined>) {
      state.Mensalidade = action.payload;
    },
    setActualPage(state, action: PayloadAction<number | undefined>) {
      state.pageIndex = action.payload;
    },
    setEditContratoLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },

    setPercentualDescontoViewContrato(state, action: PayloadAction<number | undefined>) {
      state.PercentualDesconto = action.payload;
    },
    setMesesDesconto(state, action: PayloadAction<number | undefined>) {
      state.MesesDesconto = action.payload;
    },
    addRegras(state, action: PayloadAction<RegrasInstalacao>) {
      state.RegrasInstalacao?.push(action.payload);
    },
    removeRegras(state, action: PayloadAction<number>) {
      state.RegrasInstalacao = state.RegrasInstalacao?.filter((_, i) => i !== action.payload)!;
    },
    editRegra(state, action: PayloadAction<{ regra: RegrasInstalacao; index: number }>) {
      state.RegrasInstalacao![action.payload.index] = action.payload.regra;
    },
    setOpenFormRegras(state, action: PayloadAction<boolean>) {
      state.openFormRegras = action.payload;
    },
  },
});

export const {
  setMensalidade,
  setTransferencia,
  setDuracaoCarencia,
  setQuantidadeKitPrincipal,
  setProRataInstacao,
  setProRataDesinstacao,
  setValorNaoReajustavel,
  setValorReajuste,
  setKitPrincipal,
  setDesinstalacao,
  setInstalacao,
  setContratanteState,
  removeServicoState,
  addKitState,
  addServicoState,
  removeKitState,
  setContratadaState,
  setCarenciaState,
  setFidelidadeState,
  setPorcentagemState,
  setMesesState,
  setIndiceState,
  setDuracaoState,
  resetViewContratoState,
  setActualPage,
  setEditContratoLoading,
  setEditContratoState,
  setPercentualDescontoViewContrato,
  setMesesDesconto,
  addRegras,
  removeRegras,
  editRegra,
  setOpenFormRegras,
} = view_contrato_slice.actions;
export default view_contrato_slice.reducer;
