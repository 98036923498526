import LineSkeleton from "components/skeleton/line_skeleton";
import {
  ContratanteListButton,
  ContratanteListContent,
  ContratanteListHeader,
  SkeletonContratanteListItensContainer,
} from "./style";
import React from "react";
const SkeletonContratanteList: React.FC<{}> = () => {
  const array = ["", "", "", ""];
  return (
    <ContratanteListContent>
      <ContratanteListHeader>
        {array.map((el, i) => (
          <LineSkeleton height={30} width={150} key={i} />
        ))}
      </ContratanteListHeader>

      <SkeletonContratanteListItensContainer>
        <LineSkeleton height={58} width={"100%"} />
      </SkeletonContratanteListItensContainer>
      <SkeletonContratanteListItensContainer>
        <LineSkeleton height={58} width={"100%"} />
      </SkeletonContratanteListItensContainer>
      <SkeletonContratanteListItensContainer>
        <LineSkeleton height={58} width={"100%"} />
      </SkeletonContratanteListItensContainer>
      <SkeletonContratanteListItensContainer>
        <LineSkeleton height={58} width={"100%"} />
      </SkeletonContratanteListItensContainer>
      <SkeletonContratanteListItensContainer>
        <LineSkeleton height={58} width={"100%"} />
      </SkeletonContratanteListItensContainer>
      <SkeletonContratanteListItensContainer>
        <LineSkeleton height={58} width={"100%"} />
      </SkeletonContratanteListItensContainer>
    </ContratanteListContent>
  );
};

const SkeletonListButton: React.FC = () => {
  return (
    <ContratanteListButton>
      <LineSkeleton height={30} width={90} />
    </ContratanteListButton>
  );
};

export default SkeletonContratanteList;
