import billing_theme from "style/theme_colors";
import { Heading5, Heading6 } from "components/text/heading/style";
import { Body4 } from "components/text/body/style";
import { ContratoInsideContent, ContratoDataGrid } from "../style";
import { NovoContratoInputLabel } from "../../../style";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "store/store";
import {
  setMensalidade,
  setValorReajuste,
  setValorNaoReajustavel,
} from "store/contrato/new_contrato_slice";
import BillingNumberInput from "components/input/number_input";
import { toMoney } from "utils/numberUtils";
import BillingMoneyInput from "components/input/money_input";

const MensalidadeNovoContrato: React.FC<{}> = () => {
  const novo_contrato = useSelector((state: RootState) => state.novo_contrato);
  const dispatch = useAppDispatch();
  return (
    <ContratoInsideContent>
      <NovoContratoInputLabel>
        <Heading5 fontWeight={600} color={billing_theme.pallete.tertiary.A600}>
          Mensalidade
        </Heading5>
        <Body4 fontWeight={400} color={billing_theme.pallete.tertiary.A600}>
          Informe os valores negociados.
        </Body4>
      </NovoContratoInputLabel>

      <ContratoDataGrid>
        <NovoContratoInputLabel>
          <Heading6 fontWeight={600} color={billing_theme.pallete.tertiary.A600} lineheight="24px">
            Mensalidade*
          </Heading6>
          <BillingMoneyInput
            value={novo_contrato.mensalidade}
            placeholder="R$ 00,00"
            onNumberFormat={val => {
              dispatch(setMensalidade(val.floatValue));
            }}
          />
        </NovoContratoInputLabel>

        <NovoContratoInputLabel>
          <Heading6 fontWeight={600} color={billing_theme.pallete.tertiary.A600} lineheight="24px">
            Valor reajustavel*
          </Heading6>

          <BillingMoneyInput
            value={novo_contrato.valorReajustavel ?? ""}
            placeholder="R$ 00,00"
            onNumberFormat={val => {
              dispatch(setValorReajuste(val.floatValue!));
              dispatch(setValorNaoReajustavel(novo_contrato.mensalidade! - val.floatValue!));
            }}
            error={novo_contrato.mensalidade! < novo_contrato.valorReajustavel!}
            errorMesage="O valor não deve ser maior que a mensalidade!"
          />
        </NovoContratoInputLabel>

        <NovoContratoInputLabel>
          <Heading6 fontWeight={600} color={billing_theme.pallete.tertiary.A600} lineheight="24px">
            Não reajustavel*
          </Heading6>

          <div className="nao_reaju">
            <Heading6
              fontWeight={600}
              color={
                novo_contrato.mensalidade! < novo_contrato.valorReajustavel!
                  ? billing_theme.pallete.error.A100
                  : billing_theme.pallete.tertiary.A400
              }
            >
              {novo_contrato.valorNaoReajustavel
                ? toMoney(novo_contrato.mensalidade! - novo_contrato.valorReajustavel!)
                : "--"}
            </Heading6>
          </div>
        </NovoContratoInputLabel>
      </ContratoDataGrid>
    </ContratoInsideContent>
  );
};

export default MensalidadeNovoContrato;
