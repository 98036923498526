import billing_theme from "style/theme_colors";
import styled from "styled-components";

export const InsideMenuWrapper = styled.div<{ top: number }>`
  display: flex;
  width: 186px;
  flex-direction: column;
  align-items: center;
  align-content: center;
  border-right: 0.5px solid ${billing_theme.pallete.tertiary?.A100};
  border-bottom: 0.5px solid ${billing_theme.pallete.tertiary?.A100};
  background: ${billing_theme.pallete.specific.T_50_25};
  height: 100vh;
  top: ${props => props.top + "px"};
  position: fixed;
`;

export const IndeMenuHeader = styled.button`
  background: transparent;
  border: none;
  display: flex;
  align-items: flex-start;
  justify-items: end;
  width: 100%;
  padding: 11px 16px;
  border-bottom: 0.5px solid ${billing_theme.pallete.primary.A300};
  flex-direction: column;
  gap: 8px;
  margin-bottom: 10px;
  .icon {
    background-color: ${billing_theme.isDark()
      ? billing_theme.light.primary?.A300
      : billing_theme.light.primary?.A400};
    padding: 3px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
