import { Body3 } from "components/text/body/style";
import React, { useEffect, useState } from "react";
import "react-material-symbols/outlined";
import billing_theme from "style/theme_colors";
import { MenuButton } from "./style";
import { useLocation, useNavigate } from "react-router-dom";
import { MaterialSymbol, SymbolCodepoints } from "react-material-symbols";
import "react-material-symbols/sharp";
const BillingMenuButton: React.FC<{
  path: string;
  name: string;
  icon: SymbolCodepoints;
}> = ({ path, name, icon }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isSelected, setSelected] = useState<boolean>(false);

  useEffect(() => {
    setSelected(path.split("/")[1] == location.pathname.split("/")[1]);
  });

  return (
    <MenuButton selected={isSelected} onClick={() => navigate(path)}>
      <MaterialSymbol
        icon={icon}
        size={24}
        fill={false}
        grade={-25}
        color={
          isSelected ? billing_theme.light.tertiary?.A25! : billing_theme.pallete.tertiary?.A400!
        }
      />
      <Body3
        fontWeight={isSelected ? 600 : 400}
        color={
          isSelected ? billing_theme.light.tertiary?.A25! : billing_theme.pallete.tertiary?.A400!
        }
      >
        {name}
      </Body3>
    </MenuButton>
  );
};

export default BillingMenuButton;
