import { EquipamentoHttp } from "gateways/repo/equipamento_http_client";
import {
  setLoadingNewEquipamento,
  setErrorNewEquipamento,
  setModalNewEquipamento,
} from "store/equipamento/new_equipamento_slice";
import { useAppDispatch } from "store/store";
import { PostEquipamentoModel } from "shared/models/equipamento_response.model";
import { toastWarning } from "components/toast/billing_toast";
import useContratante from "hooks/contratante/useContratante";
import useEquipamento from "hooks/equipamento/useEquipamento";
import useContrato from "hooks/contrato/useContrato";
import useModelo from "hooks/modelo/useModelo";
import useBillingEnum from "hooks/useBillingEnum";

const useNewEquipamento = () => {
  const http = new EquipamentoHttp();
  const dispatch = useAppDispatch();
  const { getEquipamentoList } = useEquipamento();
  const { getContratatesOptions } = useContratante();
  const { getContratoOptions } = useContrato();
  const { getModeloOptionList } = useModelo();
  const { getEnums } = useBillingEnum();

  const getNewEquipamentoInfo = async () => {
    dispatch(setLoadingNewEquipamento(true));
    dispatch(setErrorNewEquipamento(false));
    try {
      await getContratatesOptions();
      await getContratoOptions();
      await getModeloOptionList();
      await getEnums();
    } catch (e) {
      toastWarning({ title: "Erro!" });
      dispatch(setErrorNewEquipamento(true));
    } finally {
      dispatch(setLoadingNewEquipamento(false));
    }
  };
  const addEquipamento = async (data: PostEquipamentoModel) => {
    dispatch(setLoadingNewEquipamento(true));
    dispatch(setErrorNewEquipamento(false));
    try {
      await http.adEquipamento(data);
      getEquipamentoList();
      dispatch(setModalNewEquipamento(false));
    } catch (e) {
      toastWarning({ title: "Erro ao adicionar!" });
      dispatch(setErrorNewEquipamento(true));
    } finally {
      dispatch(setLoadingNewEquipamento(false));
    }
  };
  return {
    addEquipamento,
    getNewEquipamentoInfo,
  };
};
export default useNewEquipamento;
