import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export interface NewContratada {
  loading?: boolean;
  isError?: boolean;
  isOpen: boolean;
}

const initialState: NewContratada = {
  loading: false,
  isError: false,
  isOpen: false,
};

const new_contratada_slice = createSlice({
  name: "new_contratada",
  initialState,
  reducers: {
    setLoadingNewContrato(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },

    setErrorNewContrato(state, action: PayloadAction<boolean>) {
      state.isError = action.payload;
    },
    setModalNewContratada(state, action: PayloadAction<boolean>) {
      state.isOpen = action.payload;
    },
  },
});

export const { setLoadingNewContrato, setErrorNewContrato, setModalNewContratada } =
  new_contratada_slice.actions;
export default new_contratada_slice.reducer;
