import { toastError, toastInfo } from "components/toast/billing_toast";
import { VeiculoHttp } from "gateways/repo/veiculo_http_client";
import useBillingEnum from "hooks/useBillingEnum";

import { useState } from "react";
import { useSelector } from "react-redux";
import { OptionsModel } from "shared/models/contratada_response.model";
import { VeiculoListModel } from "shared/models/veiculo_response.model";
import {
  setContradaError,
  setVeiculoLastPage,
  setVeiculoList,
  setVeiculoLoading,
  setVeiculoOptions,
  setVeiculoDeleteModal,
  setLoadingVeiculo,
} from "store/veiculo/veiculo_slice";
import { RootState, useAppDispatch } from "store/store";
import { formatToOption } from "utils/enumListToOptions";

const useVeiculo = () => {
  const http = new VeiculoHttp();
  const { getEnums } = useBillingEnum();
  const dispatch = useAppDispatch();
  const veiculo = useSelector((state: RootState) => state.veiculo);
  const [veiculoOptions, setVeiculoOption] = useState<OptionsModel[]>([]);

  const getVeiculoList = async () => {
    dispatch(setVeiculoLoading(true));
    dispatch(setContradaError(false));
    try {
      const data = await http.getListVeiculo();
      await getEnums();
      dispatch(setVeiculoList(data.Lista));
      dispatch(setVeiculoLastPage(paginateVeiculo(data.Lista!).length));
    } catch (e) {
      dispatch(setContradaError(true));
      console.log(e);
    } finally {
      dispatch(setVeiculoLoading(false));
    }
  };

  const getVeiculoOption = async () => {
    dispatch(setVeiculoLoading(true));
    dispatch(setContradaError(false));
    try {
      const data = await http.getListVeiculoOptions();
      let options: OptionsModel[] = [];
      data.Lista.forEach(val => {
        options.push(formatToOption(val.ID.toString(), val.Text));
      });

      setVeiculoOption(options);
      dispatch(setVeiculoOptions(options));
    } catch (e) {
      dispatch(setContradaError(true));
      console.log(e);
    } finally {
      dispatch(setVeiculoLoading(false));
    }
  };
  const deleteVeiculo = async () => {
    dispatch(setLoadingVeiculo(true));
    try {
      await http.deleteVeiculo(veiculo.selectDeleteId!);
      toastInfo({ title: "Empresa excluida!" });
      dispatch(setVeiculoDeleteModal(false));
      getVeiculoList();
    } catch (e) {
      toastError({ title: "Ocorreu um erro!", message: "Por favor, tente novamente!" });
    } finally {
      dispatch(setLoadingVeiculo(false));
    }
  };
  const paginateVeiculo = (list: VeiculoListModel[]) => {
    const resultado: VeiculoListModel[][] = [];

    for (let i = 0; i < list.length; i += 10) {
      const chunk = list.slice(i, i + 10);
      resultado.push(chunk);
    }
    return resultado;
  };
  const setPagedVeiculo = (list: VeiculoListModel[], index: number) => {
    const resultado: VeiculoListModel[][] = [];

    for (let i = 0; i < list.length; i += 10) {
      const chunk = list.slice(i, i + 10);
      resultado.push(chunk);
    }
    return resultado[index] ?? [];
  };
  return {
    veiculoOptions,
    getVeiculoList,
    getVeiculoOption,
    deleteVeiculo,
    paginateVeiculo,
    setPagedVeiculo,
  };
};
export default useVeiculo;
