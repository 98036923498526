import billing_theme from "style/theme_colors";
import { Heading5, Heading6 } from "components/text/heading/style";
import { Body4 } from "components/text/body/style";
import { NovoContratoInputLabel } from "../../../style";
import { RootState, useAppDispatch } from "store/store";
import { AdicionarRegraContent, ValoresInsideContent } from "../style";
import BillingIconSharp from "components/billing_icon/sharp_icon";
import { setOpenFormRegras } from "store/contrato/new_contrato_slice";
import { useSelector } from "react-redux";
import { toastWarning } from "components/toast/billing_toast";

const RegrasAjustes: React.FC<{}> = () => {
  const dispatch = useAppDispatch();

  return (
    <ValoresInsideContent>
      <NovoContratoInputLabel>
        <Heading5 fontWeight={600} color={billing_theme.pallete.tertiary.A600}>
          Regras e ajustes
        </Heading5>
        <Body4 fontWeight={400} color={billing_theme.pallete.tertiary.A600}>
          Informe valores negociados.
        </Body4>
      </NovoContratoInputLabel>
      <AdicionarRegraContent
        onClick={() => {
          dispatch(setOpenFormRegras(true));
        }}
      >
        <BillingIconSharp icon={"add"} color={billing_theme.pallete.tertiary.A600} />
        <Heading6 fontWeight={600} color={billing_theme.pallete.tertiary.A600}>
          Adicionar regras
        </Heading6>
      </AdicionarRegraContent>
    </ValoresInsideContent>
  );
};

export default RegrasAjustes;
