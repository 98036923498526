import { ButtonContent, ContractViewContent, ViewContratoHeader, ViewGrid } from "./style";
import { useSelector } from "react-redux";
import { RootState } from "store/store";
import { toMoney, toPercent } from "utils/numberUtils";
import moment from "moment";
import { toDate } from "utils/stringUtils";
import ViewAboveField from "./components/view_above_field";
import ViewField from "./components/view_field";
import ViewTitle from "./components/view_title";
import { useEffect } from "react";
import useViewContrato from "hooks/contrato/useViewContrato";
import Scaffold from "components/scaffold";
import { Heading3 } from "components/text/heading/style";
import billing_theme from "style/theme_colors";
import LineSkeleton from "components/skeleton/line_skeleton";
import ContratoBackButton from "view/Contrato/components/contrato_back_button";
import PrimaryButton from "components/button/billing_button";

const ContractView: React.FC<{}> = () => {
  const { getContratoInfo } = useViewContrato();
  const state = useSelector((state: RootState) => state.view_contrato);
  const kit = useSelector((state: RootState) => state.kit);
  const servico = useSelector((state: RootState) => state.servico);
  const list_enum = useSelector((state: RootState) => state.list_enum);
  const contratante = useSelector((state: RootState) => state.contratante);
  const contratada = useSelector((state: RootState) => state.contratada);

  const getServicoName = (id: string) => {
    return servico.options.find(el => el.value == id)?.label;
  };
  const getKitName = (id: string) => {
    return kit.options.find(el => el.value == id)?.label ?? "";
  };
  const getContratanteName = (id: string) => {
    return contratante.options.find(el => el.value == id)?.label ?? "";
  };
  const getContratadaName = (id: string) => {
    return contratada.options.find(el => el.value == id)?.label ?? "";
  };

  useEffect(() => {
    getContratoInfo();
  }, []);
  return (
    <Scaffold>
      <ViewContratoHeader>
        {state.loading ? (
          <LineSkeleton width={"200px"} height={40} />
        ) : (
          <Heading3 fontWeight={600} color={billing_theme.pallete.tertiary?.A400}>
            Contrato: {state.Numero}/{state.Aditivo}
          </Heading3>
        )}
        <ContratoBackButton />
      </ViewContratoHeader>
      <ContractViewContent>
        <ViewTitle title={"Dados de contrato"} />
        <ViewGrid gridnumber={2}>
          <ViewField
            title={"Contratante"}
            content={getContratanteName(state.Contratante_ID?.toString()!)}
          />
          <ViewField
            title={"Contratado"}
            content={getContratadaName(state.Contratada_ID?.toString()!)}
          />
          <ViewField title={"Kit"} content={getKitName(state.KitPrincipal_ID?.toString()!)} />
          <ViewField title={"Quantidade"} content={state.Quantidade} />
        </ViewGrid>
        <ViewTitle title={"Valores"} />
        <ViewField title={"Mensalidade"} content={toMoney(state.Mensalidade!)} />
        <ViewGrid gridnumber={2}>
          <ViewField title={"Reajustável"} content={toMoney(state.ValorReajustavel)} />
          <ViewField title={"Não reajustável"} content={toMoney(state.ValorNaoReajustavel)} />
        </ViewGrid>
        <ViewTitle title={"Vigência"} />
        <ViewField
          title={"Duração"}
          content={moment().add(state.DuracaoMeses, "months").fromNow(true)}
        />
        <ViewGrid gridnumber={3}>
          <ViewField title={"Data ínicio"} content={toDate(state.VigenciaInicio!)} />
          <ViewField title={"Data final"} content={toDate(state.VigenciaFim!)} />
          <ViewField
            title={"Faturamento inicio"}
            content={state.FaturamentoInicio! ? toDate(state.FaturamentoInicio!) : "--"}
          />
          <ViewField
            title={"Faturamento final"}
            content={state.FaturamentoFim ? toDate(state.FaturamentoFim!) : "--"}
          />
          <ViewField
            title={"Carência"}
            content={
              state.CarenciaDias == 1 ? state.CarenciaDias + " Dia" : state.CarenciaDias + " Dias"
            }
          />
          <ViewField
            title={"Fidelidade"}
            content={
              state.FidelidadeMeses == 1
                ? state.FidelidadeMeses + " Mês"
                : state.FidelidadeMeses + " Meses"
            }
          />
        </ViewGrid>
        <ViewTitle title={"Taxas e reajustes"} />
        <ViewField
          title={"Índice de reajuste"}
          content={
            list_enum.IndiceReajusteOptions?.find(el => Number(el.value) === state.Indice)?.label
          }
        />
        <ViewTitle title={"Descontos"} />
        <ViewGrid gridnumber={2}>
          <ViewField title={"Desconto"} content={toPercent(state.PercentualDesconto!)} />
          <ViewField
            title={"Meses"}
            content={
              state.MesesDesconto == 1
                ? state.MesesDesconto + " Mês"
                : state.MesesDesconto + " Meses"
            }
          />
        </ViewGrid>
        <ViewTitle title={"Valores técnicos"} />
        <ViewGrid gridnumber={3}>
          <ViewField title={"Instalação"} content={toMoney(state.Instalacao)} />
          <ViewField title={"Desinstalação"} content={toMoney(state.Desinstalacao)} />
          <ViewField title={"Transferência"} content={toMoney(state.Transferencia)} />
        </ViewGrid>
        <ViewGrid gridnumber={2}>
          <ViewField title={"Pró rata instalação"} content={state.ProRataInicio ? "Sim" : "Não"} />
          <ViewField
            title={"Pró rata desinstalação"}
            content={state.ProRataDesinstalacao ? "Sim" : "Não"}
          />
        </ViewGrid>
        {state.RegrasInstalacao?.length != 0 && (
          <>
            <ViewTitle title={"Regras e ajustes"} />
            {state.RegrasInstalacao?.map((el, i) => (
              <>
                <ViewAboveField title={`Regra: ${i}`} />
                <ViewGrid gridnumber={2}>
                  <ViewField title={"Estado"} content={el.Estado} />
                  <ViewField title={"Cidade"} content={el.Cidade} />
                </ViewGrid>
                <ViewGrid gridnumber={3}>
                  <ViewField title={"Quant./dia útil"} content={el.InstaDia} />
                  <ViewField title={"Tec. próprios"} content={el.DescProprio + "%"} />
                  <ViewField title={"Tec. Terceiros"} content={el.DescExterna + "%"} />
                </ViewGrid>
                <ViewGrid gridnumber={3}>
                  <ViewField title={"Instalação"} content={el.Instalacao ? "Sim" : "Não"} />
                  <ViewField title={"Desinstalação"} content={el.Desinstalacao ? "Sim" : "Não"} />
                  <ViewField title={"Transferência"} content={el.Transferencia ? "Sim" : "Não"} />
                </ViewGrid>
              </>
            ))}
          </>
        )}
        {state.KitsComplementares?.length != 0 && (
          <>
            <ViewTitle title={"Kits Complementares"} />
            <ViewGrid
              gridnumber={
                state.KitsComplementares?.length! > 3 ? 4 : state.KitsComplementares?.length!
              }
            >
              {state.KitsComplementares?.map((el, i) => (
                <>
                  <ViewField title={"Kit"} content={getKitName(el.Kit_ID?.toString()!)} />
                </>
              ))}
            </ViewGrid>
          </>
        )}{" "}
        {state.Servicos?.length != 0 && (
          <>
            <ViewTitle title={"Serviços"} />
            <ViewGrid gridnumber={state.Servicos?.length! > 3 ? 4 : state.Servicos?.length!}>
              {state.Servicos?.map((el, i) => (
                <>
                  <ViewField
                    title={"Serviço"}
                    content={getServicoName(el.Servico_ID?.toString()!)}
                  />
                </>
              ))}
            </ViewGrid>
          </>
        )}
      </ContractViewContent>
    </Scaffold>
  );
};

export default ContractView;
