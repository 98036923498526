import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { toastWarning } from "components/toast/billing_toast";
import {
  InstalacaoEquipamentoModel,
  PostInstalacaoModel,
} from "shared/models/instalacao_response.model";

const initialState: PostInstalacaoModel = {
  loading: false,
  isError: false,
  isOpen: false,
  Equipamentos: [],
};

const new_instalacao_slice = createSlice({
  name: "new_instalacao",
  initialState,
  reducers: {
    resetNewInstalacaoState: () => initialState,

    setLoadingNewInstalacao(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },

    setErrorNewInstalacao(state, action: PayloadAction<boolean>) {
      state.isError = action.payload;
    },

    setContratanteNewInstalacao(state, action: PayloadAction<number | undefined>) {
      state.Contratante_ID = action.payload;
    },
    setCodigoGoNewInstalacao(state, action: PayloadAction<number | undefined>) {
      state.CodigoGO = action.payload;
    },
    setEquipamentoPrincipalNewInstalacao(state, action: PayloadAction<number | undefined>) {
      state.EquipamentoPrincipal_ID = action.payload;
    },
    setVeiculoNewInstalacao(state, action: PayloadAction<number | undefined>) {
      state.Veiculo_ID = action.payload;
    },
    setMotivoDesinstalacoNewInstalacao(state, action: PayloadAction<number | undefined>) {
      state.MotivoDesinstalacao = action.payload;
    },
    setDataDesinsitalacaoNewInstalacao(state, action: PayloadAction<string | undefined>) {
      state.DataDesinstalacao = action.payload;
    },
    setDataInstalacaoNewInstalacao(state, action: PayloadAction<string | undefined>) {
      state.DataInstalacao = action.payload;
    },
    addEquipamentoComplementarNewInstalacao(
      state,
      action: PayloadAction<InstalacaoEquipamentoModel>
    ) {
      if (
        state.Equipamentos!.filter(el => action.payload.Equipamento_ID == el.Equipamento_ID)
          .length != 0
      ) {
        toastWarning({ title: "Equipamento já adicionado!" });
      } else {
        state.Equipamentos!.push(action.payload);
      }
    },
    removeEquipamentoComplementarNewInstalacao(
      state,
      action: PayloadAction<InstalacaoEquipamentoModel>
    ) {
      state.Equipamentos = state.Equipamentos!.filter(
        el => action.payload.Equipamento_ID !== el.Equipamento_ID
      );
    },
  },
});

export const {
  setLoadingNewInstalacao,
  setErrorNewInstalacao,
  removeEquipamentoComplementarNewInstalacao,
  addEquipamentoComplementarNewInstalacao,
  setContratanteNewInstalacao,
  setEquipamentoPrincipalNewInstalacao,
  setVeiculoNewInstalacao,
  setMotivoDesinstalacoNewInstalacao,
  setDataInstalacaoNewInstalacao,
  resetNewInstalacaoState,
  setDataDesinsitalacaoNewInstalacao,
  setCodigoGoNewInstalacao,
} = new_instalacao_slice.actions;
export default new_instalacao_slice.reducer;
