import { VeiculoHttp } from "gateways/repo/veiculo_http_client";
import {
  setVeiculo,
  setLoadingViewVeiculo,
  setErrorViewVeiculo,
  setModalVeiculo,
} from "store/veiculo/view_veiculo_slice";
import { useAppDispatch } from "store/store";
import useVeiculo from "./useVeiculo";
import { PostVeiculoModel } from "shared/models/veiculo_response.model";
import useContratante from "hooks/contratante/useContratante";

const useViewVeiculo = () => {
  const http = new VeiculoHttp();
  const dispatch = useAppDispatch();
  const { getVeiculoList } = useVeiculo();
  const { getContratatesOptions } = useContratante();

  const getVeiculo = async (id: number) => {
    dispatch(setLoadingViewVeiculo(true));
    dispatch(setErrorViewVeiculo(false));
    try {
      await getContratatesOptions();

      const data = await http.getVeiculo(id);
      dispatch(setVeiculo(data.Objeto));
    } catch (e) {
      console.log(e);
      dispatch(setErrorViewVeiculo(true));
    } finally {
      dispatch(setLoadingViewVeiculo(false));
    }
  };
  const updateVeiculo = async (data: PostVeiculoModel) => {
    dispatch(setLoadingViewVeiculo(true));
    dispatch(setErrorViewVeiculo(false));
    try {
      await http.updateVeiculo(data);
      getVeiculoList();
      dispatch(setModalVeiculo(false));
    } catch (e) {
      console.log(e);
      dispatch(setErrorViewVeiculo(true));
    } finally {
      dispatch(setLoadingViewVeiculo(false));
    }
  };
  return {
    getVeiculo,
    updateVeiculo,
  };
};
export default useViewVeiculo;
