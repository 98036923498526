import { useEffect } from "react";
import useViewFatura from "hooks/fatura/useViewFatura";
import { ViewDadosGeraisContent, ViewPrincipalContent, ViewResumoContent } from "./style";
import { Body4 } from "components/text/body/style";
import { Heading5, Heading6 } from "components/text/heading/style";
import billing_theme from "style/theme_colors";
import { ViewFaturaInputLabel } from "../../style";
import BillingDatePicker from "components/input/datePicker";
import BillingTextInput from "components/input/text_input";
import { useSelector } from "react-redux";
import { RootState } from "store/store";
import moment from "moment";
import BillingMoneyInput from "components/input/money_input";
import LineSkeleton from "components/skeleton/line_skeleton";

const DadosGeraisFatura: React.FC = () => {
  const state = useSelector((state: RootState) => state.view_fatura);

  return (
    <ViewDadosGeraisContent>
      <ViewPrincipalContent>
        <ViewFaturaInputLabel>
          <Heading5 fontWeight={600} color={billing_theme.pallete.tertiary.A600}>
            Principal
          </Heading5>
          <Body4 fontWeight={400} color={billing_theme.pallete.tertiary.A600}>
            Detalhes do cliente
          </Body4>
        </ViewFaturaInputLabel>
        <div className="principal">
          <ViewFaturaInputLabel>
            <Heading6 fontWeight={600} color={billing_theme.pallete.tertiary.A600}>
              Data de referência
            </Heading6>
            {state.loading ? (
              <LineSkeleton width={"100%"} height={39} />
            ) : (
              <BillingDatePicker
                disabled
                dateFormat="MM/YYYY"
                defautValue={
                  state.Objeto?.DataRef ? moment(state.Objeto?.DataRef).toDate() : moment().toDate()
                }
              />
            )}
          </ViewFaturaInputLabel>
          <ViewFaturaInputLabel>
            <Heading6 fontWeight={600} color={billing_theme.pallete.tertiary.A600}>
              Contratante
            </Heading6>
            {state.loading ? (
              <LineSkeleton width={"100%"} height={39} />
            ) : (
              <BillingTextInput
                disabled
                value={state.Objeto?.Contratante}
                change={(value?: string) => {}}
              />
            )}
          </ViewFaturaInputLabel>
        </div>
      </ViewPrincipalContent>
      <ViewResumoContent>
        <ViewFaturaInputLabel>
          <Heading5 fontWeight={600} color={billing_theme.pallete.tertiary.A600}>
            Resumo
          </Heading5>
          <Body4 fontWeight={400} color={billing_theme.pallete.tertiary.A600}>
            Valores e itens adicionados na fatura
          </Body4>
        </ViewFaturaInputLabel>
        <div className="resumo">
          <ViewFaturaInputLabel>
            <Heading6 fontWeight={600} color={billing_theme.pallete.tertiary.A600}>
              Valor da fatura
            </Heading6>
            {state.loading ? (
              <LineSkeleton width={"100%"} height={39} />
            ) : (
              <BillingMoneyInput disabled value={state.Objeto?.ValorFatura} />
            )}
          </ViewFaturaInputLabel>
          <ViewFaturaInputLabel>
            <Heading6 fontWeight={600} color={billing_theme.pallete.tertiary.A600}>
              Valor da mensalidade
            </Heading6>
            {state.loading ? (
              <LineSkeleton width={"100%"} height={39} />
            ) : (
              <BillingMoneyInput disabled value={state.Objeto?.ValorMensalidades} />
            )}
          </ViewFaturaInputLabel>
          <ViewFaturaInputLabel>
            <Heading6 fontWeight={600} color={billing_theme.pallete.tertiary.A600}>
              Valor da mão de obra
            </Heading6>
            {state.loading ? (
              <LineSkeleton width={"100%"} height={39} />
            ) : (
              <BillingMoneyInput disabled value={state.Objeto?.ValorMaoObra} />
            )}
          </ViewFaturaInputLabel>
          <ViewFaturaInputLabel>
            <Heading6 fontWeight={600} color={billing_theme.pallete.tertiary.A600}>
              Quantidade instalada
            </Heading6>
            {state.loading ? (
              <LineSkeleton width={"100%"} height={39} />
            ) : (
              <BillingTextInput
                disabled
                value={state.Objeto?.QuantidadeInstalados}
                change={(value?: string) => {}}
              />
            )}
          </ViewFaturaInputLabel>
          <ViewFaturaInputLabel>
            <Heading6 fontWeight={600} color={billing_theme.pallete.tertiary.A600}>
              Quantidade estoque
            </Heading6>
            {state.loading ? (
              <LineSkeleton width={"100%"} height={39} />
            ) : (
              <BillingTextInput
                disabled
                value={state.Objeto?.QuantidadeEstoque}
                change={(value?: string) => {}}
              />
            )}
          </ViewFaturaInputLabel>
          <ViewFaturaInputLabel>
            <Heading6 fontWeight={600} color={billing_theme.pallete.tertiary.A600}>
              Quantidade total
            </Heading6>
            {state.loading ? (
              <LineSkeleton width={"100%"} height={39} />
            ) : (
              <BillingTextInput
                disabled
                value={state.Objeto?.QuantidadeTotal}
                change={(value?: string) => {}}
              />
            )}
          </ViewFaturaInputLabel>
        </div>
      </ViewResumoContent>
    </ViewDadosGeraisContent>
  );
};

export default DadosGeraisFatura;
