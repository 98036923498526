import { EditContratoInputLabel } from "../style";
import DescontosEditContrato from "./contract_data/components/descontos";
import { ContratoDataContainer, ContratoInsideContent } from "./contract_data/style";
import LineSkeleton from "components/skeleton/line_skeleton";

const EditContratoSkeleton: React.FC<{}> = () => {
  return (
    <ContratoDataContainer>
      <ContratoInsideContent>
        <EditContratoInputLabel>
          <LineSkeleton width={"100px"} height={24} />
          <LineSkeleton width={"100%"} height={24} />
        </EditContratoInputLabel>

        <EditContratoInputLabel>
          <LineSkeleton width={"100px"} height={24} />
          <LineSkeleton width={"100%"} height={24} />
        </EditContratoInputLabel>

        <EditContratoInputLabel>
          <LineSkeleton width={"100px"} height={24} />
          <LineSkeleton width={"100%"} height={24} />
        </EditContratoInputLabel>

        <div className="select_kit_principal">
          <EditContratoInputLabel>
            <LineSkeleton width={"100px"} height={24} />
            <LineSkeleton width={"100%"} height={24} />
          </EditContratoInputLabel>

          <EditContratoInputLabel>
            <LineSkeleton width={"50%"} height={24} />
            <LineSkeleton width={"100%"} height={24} />
          </EditContratoInputLabel>
        </div>
        <EditContratoInputLabel>
          <LineSkeleton width={"100px"} height={24} />
          <LineSkeleton width={"100%"} height={24} />
        </EditContratoInputLabel>

        <div className="select_kit_principal">
          <EditContratoInputLabel>
            <LineSkeleton width={"100px"} height={24} />
            <LineSkeleton width={"100%"} height={24} />
          </EditContratoInputLabel>

          <EditContratoInputLabel>
            <LineSkeleton width={"50%"} height={24} />
            <LineSkeleton width={"100%"} height={24} />
          </EditContratoInputLabel>
        </div>
        <div className="select_kit_principal">
          <EditContratoInputLabel>
            <LineSkeleton width={"100px"} height={24} />
            <LineSkeleton width={"100%"} height={24} />
          </EditContratoInputLabel>

          <EditContratoInputLabel>
            <LineSkeleton width={"50%"} height={24} />
            <LineSkeleton width={"100%"} height={24} />
          </EditContratoInputLabel>
        </div>
        <EditContratoInputLabel>
          <LineSkeleton width={"50%"} height={24} />
          <LineSkeleton width={"100%"} height={24} />
        </EditContratoInputLabel>
      </ContratoInsideContent>
    </ContratoDataContainer>
  );
};

export default EditContratoSkeleton;
