import { BillingWithoutTokenHttp } from "gateways/billing_http_whitout_token";

export class UserHttp {
  private http = new BillingWithoutTokenHttp();

  userLogin = async (email: string, senha: string) => {
    const reponse = await this.http.post("Usuario/Login", { data: { Email: email, Senha: senha } });

    return reponse.data;
  };

  userRegister = async (nome: string, email: string, senha: string, confirmacaoSenha: string) => {
    const reponse = await this.http.post("Usuario/Register", {
      data: {
        Nome: nome,
        Email: email,
        Senha: senha,
        SenhaConfirmacao: confirmacaoSenha,
      },
    });

    return reponse.data;
  };
}
