import { InstalacaoContainer, InstalacaoHeader } from "./style";
import { Heading3 } from "components/text/heading/style";
import { Body2 } from "components/text/body/style";
import billing_theme from "style/theme_colors";
import Scaffold from "components/scaffold";
import PrimaryButton from "components/button/billing_button";
import { useEffect } from "react";
import InsideMenu from "components/insidemenu";
import SkeletonInstalacaoList from "./components/instalacao_skeleton";
import useInstalacao from "hooks/instalacao/useInstalacao";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "store/store";
import InstalacaoErrorContent from "./components/instalacao_list_error";
import InstalacaoFilter from "./components/instalacao_filter";
import InstalacaoList from "./components/instalacao_list";
import OpecaoesInsideMenu from "components/insidemenu/components/opecaoes_menu";
import { useNavigate } from "react-router-dom";

const InstalacaoPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const { getInstalacaoList } = useInstalacao();
  const navigate = useNavigate();
  const instalacao = useSelector((state: RootState) => state.instalacao);
  useEffect(() => {
    getInstalacaoList();
  }, []);
  return (
    <Scaffold>
      <InstalacaoContainer>
        <InsideMenu title={"Instalação"} icon={"work"}>
          <OpecaoesInsideMenu />
        </InsideMenu>
        <div className="main_content">
          <InstalacaoHeader>
            <div className="">
              <Heading3 fontWeight={600} color={billing_theme.pallete.tertiary?.A400}>
                Instalação
              </Heading3>
              <Body2 color={billing_theme.pallete.tertiary?.A400}>
                Veja onde, quando e o que foi instalado nos veiculos.
              </Body2>
            </div>

            <PrimaryButton
              label={"Novo instalação"}
              leftIcon={"add"}
              isSmall
              onTap={() => navigate("/operacoes/instalacao/novo")}
            />
          </InstalacaoHeader>
          {instalacao.isError && <InstalacaoErrorContent retry={() => getInstalacaoList()} />}

          {!instalacao.isError && <InstalacaoFilter />}

          {!instalacao.isError &&
            (instalacao.loading ? <SkeletonInstalacaoList /> : <InstalacaoList />)}
        </div>
      </InstalacaoContainer>
    </Scaffold>
  );
};

export default InstalacaoPage;
