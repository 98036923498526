import { toastError } from "components/toast/billing_toast";
import { ContratoHttp } from "gateways/repo/contrato_http_client";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { ContratoResponse } from "shared/models/contrato_response.model";
import { resetViewContratoState } from "store/contrato/view_contrato_slice";
import { setEditContratoLoading, setEditContratoState } from "store/contrato/view_contrato_slice";

import { RootState, useAppDispatch } from "store/store";
import useBillingEnum from "../useBillingEnum";
import useContratante from "../contratante/useContratante";
import UseKit from "../kit/useKit";
import moment from "moment";
import useContratada from "hooks/contratada/useContratada";
import useServico from "hooks/servico/useServico";

const useEditContrato = () => {
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useAppDispatch();
  const http = new ContratoHttp();
  const { getContratadaOption } = useContratada();
  const { getContratatesOptions } = useContratante();
  const { getKitOptions } = UseKit();
  const { getServicoOptions } = useServico();
  const { getEnums } = useBillingEnum();
  const [error, setError] = useState(false);
  const state = useSelector((state: RootState) => state.view_contrato);

  const getContratoInfo = async () => {
    setError(false);
    dispatch(setEditContratoLoading(true));
    try {
      await getContratadaOption();
      await getContratatesOptions();
      await getKitOptions();
      await getServicoOptions();
      await getEnums();
      const data = await http.getContratoById(params.id!);
      dispatch(setEditContratoState(data.Objeto));
    } catch (e) {
      setError(true);
    } finally {
      dispatch(setEditContratoLoading(false));
    }
  };
  const updateContrato = async () => {
    const data: ContratoResponse = {
      ID: state.ID,
      Contratada_ID: state.Contratada_ID,
      Contratante_ID: state.Contratante_ID,
      KitPrincipal_ID: state.KitPrincipal_ID,
      Quantidade: state.Quantidade,
      Mensalidade: state.Mensalidade,
      Instalacao: state.Instalacao,
      Desinstalacao: state.Desinstalacao,
      Transferencia: state.Transferencia,
      DuracaoMeses: state.DuracaoMeses,
      CarenciaDias: state.CarenciaDias,
      FidelidadeMeses: state.FidelidadeMeses,
      PercentualDesconto: state.PercentualDesconto,
      MesesDesconto: state.MesesDesconto,
      Indice: state.Indice,
      ValorReajustavel: state.ValorReajustavel,
      ValorNaoReajustavel: state.ValorNaoReajustavel,
      ProRataInicio: state.ProRataInicio,
      ProRataDesinstalacao: state.ProRataDesinstalacao,
      RegrasInstalacao: state.RegrasInstalacao!,
      KitsComplementares: state.KitsComplementares,
      Servicos: state.Servicos,
      Historicos: state.Historicos,
      Reajustes: state.Reajustes,
      VigenciaInicio: moment().add(state.CarenciaDias, "days").format(),
      VigenciaFim: moment().add(state.DuracaoMeses, "months").format(),
      Aditivo: "0",
    };
    dispatch(setEditContratoLoading(true));
    setError(false);
    try {
      await http.updateContrato(data);
      dispatch(resetViewContratoState());
      navigate("/contrato");
    } catch (e) {
      console.log(e);
      setError(true);
      toastError({ title: "Erro", message: "Por favor, tente novamente!" });
    } finally {
      dispatch(setEditContratoLoading(false));
    }
  };

  return {
    error,
    updateContrato,
    getContratoInfo,
  };
};
export default useEditContrato;
