import billing_theme from "style/theme_colors";
import styled from "styled-components";

export const PrimaryButtonContent = styled.button<{
  $isfilled: boolean;
  $isdisable: boolean;
  $issmall: boolean;
}>`
  padding: 2px;
  position: relative;
  height: auto;
  background: transparent;
  border: none;
  div {
    cursor: pointer;
    background-color: ${props => buttonBackgroundColor(props.$isdisable, props.$isfilled)};
    border-radius: 8px;
    height: ${props => (props.$issmall ? "40px" : "48px")};
    min-width: ${props => (props.$issmall ? "112px" : "192px")};
    border: 0px;
    color: ${props => buttonColor(props.$isdisable, props.$isfilled)};
    transition: 300ms;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 8px;
    padding: ${props => (props.$issmall ? "0 8px" : "0 16px")};
    border: ${props => borderColor(props.$isdisable, props.$isfilled)};
    text-align: center;
    font-family: Montserrat;
    font-size: ${props => (props.$issmall ? "14px" : "16px")};
    font-style: normal;
    font-weight: 600;
    line-height: ${props => (props.$issmall ? "30px" : "30px")};
  }
  ${props =>
    !props.$isdisable
      ? ` div:hover {
    background-color: 
 ${props.$isfilled ? billing_theme.pallete.primary?.A100 : billing_theme.pallete.primary?.A200};
    box-shadow:  2px 4px 4px 0px ${billing_theme.pallete.box_shadow};
    border: 
    ${props.$isfilled ? ` 2px solid ${billing_theme.pallete.primary?.A400}` : ""};
    color: 
    ${buttonColor(props.$isdisable, props.$isfilled)};
  }

  div:active {
    background-color:
    ${props.$isfilled ? billing_theme.pallete.primary?.A100 : billing_theme.pallete.primary?.A600};
    box-shadow:  2px 4px 4px 0px ${billing_theme.pallete.box_shadow};
    border: 
    ${props.$isfilled ? `2px solid  ${billing_theme.pallete.primary.A200}` : ""};
    color: 
    ${buttonColor(props.$isdisable, props.$isfilled)};
  }`
      : ""}
`;

const buttonBackgroundColor = (isDisable: boolean, isFilled: boolean) => {
  if (isDisable) return billing_theme.pallete.primary?.A100;
  if (isFilled) return "transparent";
  return billing_theme.isDark()
    ? billing_theme.light.primary?.A300
    : billing_theme.light.primary?.A400;
};
const buttonColor = (isDisable: boolean, isFilled: boolean) => {
  if (isDisable) return billing_theme.pallete.tertiary?.A400;
  if (isFilled) return billing_theme.pallete.primary?.A400;
  return billing_theme.light.tertiary?.A25;
};
const borderColor = (isDisable: boolean, isFilled: boolean) => {
  if (isDisable) return "";
  if (isFilled)
    return `solid 2px ${
      billing_theme.isDark()
        ? billing_theme.pallete.primary.A300
        : billing_theme.pallete.primary.A400
    }`;
  return "";
};
