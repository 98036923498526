import PrimaryButton from "components/button/billing_button";
import TertiaryButton from "components/button/tertiaty_button";
import { Body2 } from "components/text/body/style";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "store/store";
import billing_theme from "style/theme_colors";
import {
  NewEquipamentoContent,
  NewEquipamentoDetails,
  NewEquipamentoGridTwo,
  NewContratoRazaoCnpj,
} from "./style";
import BillingTextInput from "components/input/text_input";
import { setModalNewEquipamento } from "store/equipamento/new_equipamento_slice";
import useNewEquipamento from "hooks/equipamento/useNewEquipamento";
import BillingSelectInput from "components/input/select";
import LineSkeleton from "components/skeleton/line_skeleton";
import BillingDatePicker from "components/input/datePicker";
import moment from "moment";

const ModalNewEquipamento: React.FC = () => {
  const dispatch = useAppDispatch();
  const { addEquipamento, getNewEquipamentoInfo } = useNewEquipamento();

  const state = useSelector((state: RootState) => state.new_equipamento);
  const list_enum = useSelector((state: RootState) => state.list_enum);
  const modeloState = useSelector((state: RootState) => state.modelo);

  const [numeroSerie, setNumeroSerie] = useState<string>();
  const [numeroFiscal, setNumeroFiscal] = useState<string>();
  const [contratante, setContratante] = useState<number>();
  const [contrato, setContrato] = useState<number>();
  const [dataExpedicao, setDataExpedicao] = useState<string>();
  const [status, setStatus] = useState<number>();
  const [modelo, setModelo] = useState<number>();

  useEffect(() => {
    getNewEquipamentoInfo();
  }, []);
  return (
    <NewEquipamentoContent>
      <NewEquipamentoGridTwo>
        <NewEquipamentoDetails>
          <Body2 fontWeight={600} color={billing_theme.pallete.tertiary?.A400}>
            Status:
          </Body2>

          {state.loading ? (
            <LineSkeleton width={"100%"} height={39} />
          ) : (
            <BillingSelectInput
              placeholder="Selecione um contratante"
              options={list_enum.EquipamentoStatusOptions!}
              onChange={val => {
                setStatus(Number(val.value));
              }}
            />
          )}
        </NewEquipamentoDetails>

        <NewEquipamentoDetails>
          <Body2 fontWeight={600} color={billing_theme.pallete.tertiary?.A400}>
            Modelo:
          </Body2>
          {state.loading ? (
            <LineSkeleton width={"100%"} height={39} />
          ) : (
            <BillingSelectInput
              placeholder="Selecione um contratante"
              options={modeloState.options}
              onChange={val => {
                setModelo(Number(val.value));
              }}
            />
          )}
        </NewEquipamentoDetails>
      </NewEquipamentoGridTwo>
      <EquipamentoInfo
        numeroDeSerie={value => {
          setNumeroSerie(value);
        }}
        numeroFiscal={value => {
          setNumeroFiscal(value);
        }}
        contratante={value => {
          setContratante(value);
        }}
        contrato={value => {
          setContrato(value);
        }}
      />

      <NewEquipamentoDetails>
        <Body2 fontWeight={600} color={billing_theme.pallete.tertiary?.A400}>
          Data expedição:
        </Body2>

        {state.loading ? (
          <LineSkeleton width={"100%"} height={39} />
        ) : (
          <BillingDatePicker
            placeholder="Data"
            onChange={va => {
              setDataExpedicao(moment(va).format());
            }}
          />
        )}
      </NewEquipamentoDetails>

      <div className="button">
        <TertiaryButton
          label={"Cancelar"}
          onTap={() => dispatch(setModalNewEquipamento(false))}
          isSmall
        />
        <PrimaryButton
          disable={state.loading}
          label={"Adicionar"}
          onTap={async () => {
            await addEquipamento({
              ID: 0,
              NumeroSerie: numeroSerie!,
              NumeroFiscal: numeroFiscal!,
              Contratante_ID: contratante!,
              Contrato_ID: contrato!,
              DataExpedicao: dataExpedicao!,
              Status: status!,
              Modelo_ID: modelo!,
            });
          }}
          isSmall
        />
      </div>
    </NewEquipamentoContent>
  );
};

const EquipamentoInfo: React.FC<{
  numeroDeSerie: (value: string | undefined) => void;
  numeroFiscal: (value: string | undefined) => void;
  contratante: (value: number | undefined) => void;
  contrato: (value: number | undefined) => void;
}> = ({ numeroDeSerie, numeroFiscal, contratante, contrato }) => {
  const contratoState = useSelector((state: RootState) => state.contrato);
  const contratanteState = useSelector((state: RootState) => state.contratante);
  const state = useSelector((state: RootState) => state.new_equipamento);

  return (
    <NewEquipamentoContent>
      <NewContratoRazaoCnpj>
        <NewEquipamentoDetails>
          <Body2 fontWeight={600} color={billing_theme.pallete.tertiary?.A400}>
            Contratante:
          </Body2>
          {state.loading ? (
            <LineSkeleton width={"100%"} height={39} />
          ) : (
            <BillingSelectInput
              placeholder="Selecione um contratante"
              options={contratanteState.options!}
              onChange={val => {
                contratante(Number(val.value));
              }}
            />
          )}
        </NewEquipamentoDetails>
        <NewEquipamentoDetails>
          <Body2 fontWeight={600} color={billing_theme.pallete.tertiary?.A400}>
            Contrato:
          </Body2>
          {state.loading ? (
            <LineSkeleton width={"100%"} height={39} />
          ) : (
            <BillingSelectInput
              placeholder="Selecione um contrato"
              options={contratoState.options!}
              onChange={val => {
                contrato(Number(val.value));
              }}
            />
          )}
        </NewEquipamentoDetails>
      </NewContratoRazaoCnpj>
      <NewContratoRazaoCnpj>
        <NewEquipamentoDetails>
          <Body2 fontWeight={600} color={billing_theme.pallete.tertiary?.A400}>
            Numero de serie:
          </Body2>

          {state.loading ? (
            <LineSkeleton width={"100%"} height={39} />
          ) : (
            <BillingTextInput placeholder="N de serie" change={numeroDeSerie} />
          )}
        </NewEquipamentoDetails>
        <NewEquipamentoDetails>
          <Body2 fontWeight={600} color={billing_theme.pallete.tertiary?.A400}>
            Numero fiscal:
          </Body2>
          {state.loading ? (
            <LineSkeleton width={"100%"} height={39} />
          ) : (
            <BillingTextInput placeholder="Numero fiscal" change={numeroFiscal} />
          )}
        </NewEquipamentoDetails>
      </NewContratoRazaoCnpj>
    </NewEquipamentoContent>
  );
};

export default ModalNewEquipamento;
