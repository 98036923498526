import PrimaryButton from "components/button/billing_button";
import TertiaryButton from "components/button/tertiaty_button";
import { Body2 } from "components/text/body/style";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "store/store";
import billing_theme from "style/theme_colors";
import { ViewVeiculoContent, ViewVeiculoDetails } from "./style";
import BillingTextInput from "components/input/text_input";
import useViewVeiculo from "hooks/veiculo/useEditVeiculo";
import BillingSelectInput from "components/input/select";
import LineSkeleton from "components/skeleton/line_skeleton";
import { setModalVeiculo } from "store/veiculo/view_veiculo_slice";

const ModalViewVeiculo: React.FC = () => {
  const dispatch = useAppDispatch();
  const { updateVeiculo, getVeiculo } = useViewVeiculo();

  const contratanteState = useSelector((state: RootState) => state.contratante);
  const state = useSelector((state: RootState) => state.view_veiculo);

  const [contratante, setContratante] = useState<number>();
  const [modelo, setModelo] = useState<string>();
  const [placa, setPlaca] = useState<string>();
  const [marca, setMarca] = useState<string>();

  useEffect(() => {
    getVeiculo(state.selectedId!);
  }, []);

  console.log(state.veiculo);
  return (
    <ViewVeiculoContent>
      <ViewVeiculoDetails>
        <Body2 fontWeight={600} color={billing_theme.pallete.tertiary?.A400}>
          Contratante:
        </Body2>
        {state.loading ? (
          <LineSkeleton width={"100%"} height={39} />
        ) : (
          <BillingSelectInput
            defaultInputValue={contratanteState.options.find(
              el => Number(el.value) === state.veiculo?.Contratante_ID
            )}
            placeholder="Selecione um contratante"
            options={contratanteState.options!}
            onChange={val => {
              setContratante(Number(val.value));
            }}
          />
        )}
      </ViewVeiculoDetails>
      <ViewVeiculoDetails>
        <Body2 fontWeight={600} color={billing_theme.pallete.tertiary?.A400}>
          Modelo:
        </Body2>

        {state.loading ? (
          <LineSkeleton width={"100%"} height={39} />
        ) : (
          <BillingTextInput
            placeholder="Qual o modelo?"
            change={setModelo}
            value={state.veiculo?.Modelo}
          />
        )}
      </ViewVeiculoDetails>

      <ViewVeiculoDetails>
        <Body2 fontWeight={600} color={billing_theme.pallete.tertiary?.A400}>
          Placa:
        </Body2>

        {state.loading ? (
          <LineSkeleton width={"100%"} height={39} />
        ) : (
          <BillingTextInput
            placeholder="Digite a placa"
            change={setPlaca}
            value={state.veiculo?.Placa}
          />
        )}
      </ViewVeiculoDetails>
      <ViewVeiculoDetails>
        <Body2 fontWeight={600} color={billing_theme.pallete.tertiary?.A400}>
          Marca:
        </Body2>
        {state.loading ? (
          <LineSkeleton width={"100%"} height={39} />
        ) : (
          <BillingTextInput
            placeholder="Qual a marca?"
            change={setMarca}
            value={state.veiculo?.Marca}
          />
        )}
      </ViewVeiculoDetails>

      <div className="button">
        <TertiaryButton label={"Cancelar"} onTap={() => dispatch(setModalVeiculo(false))} isSmall />
        <PrimaryButton
          disable={state.loading}
          label={"Editar"}
          onTap={async () => {
            await updateVeiculo({
              ID: state.veiculo?.ID!,
              Placa: placa! ?? state.veiculo?.Placa,
              Modelo: modelo! ?? state.veiculo?.Modelo,
              Marca: marca! ?? state.veiculo?.Marca,
              Contratante_ID: contratante! ?? state.veiculo?.Contratante_ID,
            });
          }}
          isSmall
        />
      </div>
    </ViewVeiculoContent>
  );
};

export default ModalViewVeiculo;
