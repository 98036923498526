import styled from "styled-components";
import billing_theme from "style/theme_colors";

export const ValoresDataContainer = styled.div`
  border-right: 0.5px solid ${billing_theme.pallete.tertiary?.A100};
  height: 98%;
  padding: 0 16px 16px 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  overflow-y: scroll;
`;

export const ValoresInsideContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const ValoresDataGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 8px;
`;
export const AdicionarRegraContent = styled.button`
  display: flex;
  padding: 8px 16px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 8px;
  border: 0.5px solid ${billing_theme.pallete.tertiary?.A100};
  background-color: ${billing_theme.pallete.specific.T_50_25};
  cursor: pointer;
`;
export const FormRegrasContent = styled.div`
  padding: 16px;
  border-radius: 8px;
  border: 0.5px solid ${billing_theme.pallete.tertiary?.A100};
  background-color: ${billing_theme.pallete.specific.T_50_25};
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
export const FormRegrasTwoGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 8px;
`;
export const HeaderFormRegras = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
`;
export const ButtonSection = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row;
  width: 100%;
  gap: 12px;

  button {
    width: 100%;
  }
`;
