import BillingHeader from "components/header";
import React from "react";
import { ChildWrapper, ScaffoldComponent } from "./style";
import BillingMenu from "components/menu";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { PrivateRoute } from "router/private_route";

const Scaffold: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  return (
    <PrivateRoute>
      <BillingHeader />
      <ScaffoldComponent>
        <BillingMenu />

        <div></div>
        <ChildWrapper>{children}</ChildWrapper>
      </ScaffoldComponent>
      <ToastContainer autoClose={5000} />
    </PrivateRoute>
  );
};

export default Scaffold;
