import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { OptionsModel } from "shared/models/contratada_response.model";
import { ContratoModelList } from "shared/models/contratos_response.model";

export interface ContratoSliceType {
  list: ContratoModelList[];
  filterList: ContratoModelList[];
  options?: OptionsModel[];
  loading?: boolean;
  pagination?: ContratoModelList[][];
  pageIndex: number;
  lastPage?: number;
}

const initialState: ContratoSliceType = {
  list: [],
  filterList: [],
  pageIndex: 0,
};

const contrato_slice = createSlice({
  name: "contrato",
  initialState,
  reducers: {
    setContratosList(state, action) {
      state.list = action.payload;
    },
    setFilterList(state, action) {
      state.filterList = action.payload;
    },
    setConfigList(state, action) {
      state.list = action.payload;
    },
    setOptions(state, action: PayloadAction<OptionsModel[]>) {
      state.options = action.payload;
    },
    setLoadingContrato(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    setPaginationContrato(state, action: PayloadAction<ContratoModelList[][]>) {
      state.pagination = action.payload;
    },
    setContratoPageIndex(state, action: PayloadAction<number>) {
      state.pageIndex = action.payload;
    },
    setContratoLastPage(state, action: PayloadAction<number>) {
      state.lastPage = action.payload;
    },
  },
});

export const {
  setContratosList,
  setConfigList,
  setFilterList,
  setOptions,
  setLoadingContrato,
  setPaginationContrato,
  setContratoPageIndex,
  setContratoLastPage,
} = contrato_slice.actions;
export default contrato_slice.reducer;
