import BillingTextInput from "components/input/text_input";
import { KitFilterButtons, KitFilterContent, KitFilterLabel } from "./style";
import { Heading6 } from "components/text/heading/style";
import billing_theme from "style/theme_colors";
import useKit from "hooks/kit/useKit";
import BillingSelectInput from "components/input/select";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "store/store";
import { setNameFilter, setTipoCobrancaFilter, setTipoKitFilter } from "store/kit/kit_slice";
import PrimaryButton from "components/button/billing_button";
import TertiaryButton from "components/button/tertiaty_button";

const KitFilter: React.FC = () => {
  const { setSearch, setTipoKit, setTipoCobranca, filterResultKit, clearFilterKit, search } =
    useKit();
  const kit = useSelector((state: RootState) => state.kit);
  const dispatch = useAppDispatch();
  const enumState = useSelector((state: RootState) => state.list_enum);
  return (
    <>
      <KitFilterContent>
        <KitFilterLabel>
          <Heading6 color={billing_theme.pallete.tertiary.A600} fontWeight={600}>
            Nome do contrato
          </Heading6>
          <BillingTextInput
            width={280}
            placeholder="Digite o nome do kit:"
            change={value => {
              setSearch(value!);
              dispatch(setNameFilter(value != ""));
            }}
          />
        </KitFilterLabel>
        <KitFilterLabel>
          <Heading6 color={billing_theme.pallete.tertiary.A600} fontWeight={600}>
            Tipo de cobrança
          </Heading6>
          <BillingSelectInput
            width={280}
            placeholder="Selecione um tipo de cobrança:"
            options={enumState.CobrancaKitOptions!}
            onChange={(val: any) => {
              val != null ? setTipoCobranca(val.label) : setTipoCobranca(undefined);
              dispatch(setTipoCobrancaFilter(val != null));
            }}
          />
        </KitFilterLabel>
        <KitFilterLabel>
          <Heading6 color={billing_theme.pallete.tertiary.A600} fontWeight={600}>
            Tipo de Kit
          </Heading6>
          <BillingSelectInput
            width={280}
            placeholder="Selecione um tipo de kit:"
            options={enumState.TipoKitOptions!}
            onChange={(val: any) => {
              val != null ? setTipoKit(val.label) : setTipoKit(undefined);
              dispatch(setTipoKitFilter(val != null));
            }}
          />
        </KitFilterLabel>
      </KitFilterContent>

      <KitFilterButtons>
        <PrimaryButton label={"Filtrar"} onTap={() => filterResultKit()} isSmall={true} />
        <TertiaryButton label={"Limpar"} onTap={() => clearFilterKit()} isSmall={true} />
      </KitFilterButtons>
    </>
  );
};

export default KitFilter;
