import BillingIconSharp from "components/billing_icon/sharp_icon";
import React, { useEffect, useState } from "react";
import "react-material-symbols/outlined";
import billing_theme from "style/theme_colors";
import { InsideMenuButtonComp } from "./style";
import { useNavigate } from "react-router-dom";
import { MaterialSymbol, SymbolCodepoints } from "react-material-symbols";
import { Heading6 } from "components/text/heading/style";
import "react-material-symbols/sharp";

const InsideMenuButton: React.FC<{
  path: string;
  name: string;
  icon: SymbolCodepoints;
}> = ({ path, name, icon }) => {
  const navigate = useNavigate();
  const [page, setPage] = useState("");
  useEffect(() => {
    setPage(location.pathname);
  });
  return (
    <InsideMenuButtonComp selected={page == path} onClick={() => navigate(path)}>
      <MaterialSymbol
        icon={icon}
        size={20}
        fill={false}
        grade={-25}
        color={
          page == path ? billing_theme.pallete.primary?.A400 : billing_theme.pallete.tertiary?.A400
        }
      />
      <Heading6
        color={
          page == path ? billing_theme.pallete.primary?.A400 : billing_theme.pallete.tertiary?.A400
        }
        fontWeight={page == path ? 600 : 500}
      >
        {name}
      </Heading6>
    </InsideMenuButtonComp>
  );
};

export default InsideMenuButton;
