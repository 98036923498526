import styled from "styled-components";
import billing_theme from "style/theme_colors";

export const ContractViewContent = styled.div`
  border-right: 0.5px solid #3d354a;
  display: flex;
  flex-direction: column;
`;
export const ViewFieldContent = styled.div`
  display: flex;
  padding: 4px 16px 8px 16px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 4px;
  flex: 1 0 0;
  border: 0.5px solid ${billing_theme.pallete.tertiary.A100};
  border-bottom: none;
  border-left: none;
`;
export const ViewTitleContent = styled.div`
  display: flex;
  padding: 16px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 4px;
  flex: 1 0 0;
  border: 0.5px solid ${billing_theme.pallete.tertiary.A50};
  border-bottom: none;
  border-left: none;
  background-color: ${billing_theme.pallete.specific.T_50_25};
`;
export const ViewAboveFieldContent = styled.div`
  display: flex;
  padding: 4px 16px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 4px;
  flex: 1 0 0;
  border: 0.5px solid ${billing_theme.pallete.tertiary.A50};
  background-color: ${billing_theme.pallete.specific.T_50_25};
  border: 0.5px solid ${billing_theme.pallete.tertiary.A100};
  border-bottom: none;
  border-left: none;
  border-right: none;
`;
export const ViewGrid = styled.div<{ gridnumber: number }>`
  display: grid;
  grid-template-columns: ${props => " repeat(" + props.gridnumber + ", 1fr)"};
`;

export const ViewContratoHeader = styled.div`
  display: flex;
  padding: 16px;
  align-items: center;
  gap: 8px;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: nowrap;
`;

export const ButtonContent = styled.div`
  position: absolute;
  bottom: 20px;
  right: 20px;
`;
