import { BillingHttp } from "gateways/billing_http";
import {
  EquipamentoListModelResponseModel,
  EquipamentoResponseModel,
  PostEquipamentoModel,
} from "shared/models/equipamento_response.model";
import { OptionsResponseModel } from "shared/models/default_models.model";
export class EquipamentoHttp {
  private http = new BillingHttp();

  getListEquipamento = async () => {
    const reponse = await this.http.get<EquipamentoListModelResponseModel>("Equipamento/List");

    return reponse.data;
  };

  getListEquipamentoOptions = async () => {
    const reponse = await this.http.get<OptionsResponseModel>("Equipamento/ListOptions");
    return reponse.data;
  };
  getEquipamento = async (id: number) => {
    const response = await this.http.get<EquipamentoResponseModel>("Equipamento/Get?id=" + id);
    return response.data;
  };
  adEquipamento = async (data: PostEquipamentoModel) => {
    const response = await this.http.post("Equipamento/Insert", { data: data });
    return response.data;
  };
  updateEquipamento = async (data: PostEquipamentoModel) => {
    const response = await this.http.put("Equipamento/Update", { data: data });
    return response.data;
  };
  deleteEquipamento = async (id: number | string) => {
    const response = await this.http.delete("Equipamento/Delete?id=" + id);
    return response.data;
  };
}
