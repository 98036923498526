import LineSkeleton from "components/skeleton/line_skeleton";
import {
  ContratadaListButton,
  ContratadaListContent,
  ContratadaListHeader,
  SkeletonContratadaListItensContainer,
} from "./style";
import React from "react";
const SkeletonContratadaList: React.FC<{}> = () => {
  const array = ["", "", "", ""];
  return (
    <ContratadaListContent>
      <ContratadaListHeader>
        {array.map((el, i) => (
          <LineSkeleton height={30} width={150} key={i} />
        ))}
      </ContratadaListHeader>

      <SkeletonContratadaListItensContainer>
        <LineSkeleton height={58} width={"100%"} />
      </SkeletonContratadaListItensContainer>
      <SkeletonContratadaListItensContainer>
        <LineSkeleton height={58} width={"100%"} />
      </SkeletonContratadaListItensContainer>
      <SkeletonContratadaListItensContainer>
        <LineSkeleton height={58} width={"100%"} />
      </SkeletonContratadaListItensContainer>
      <SkeletonContratadaListItensContainer>
        <LineSkeleton height={58} width={"100%"} />
      </SkeletonContratadaListItensContainer>
      <SkeletonContratadaListItensContainer>
        <LineSkeleton height={58} width={"100%"} />
      </SkeletonContratadaListItensContainer>
      <SkeletonContratadaListItensContainer>
        <LineSkeleton height={58} width={"100%"} />
      </SkeletonContratadaListItensContainer>
    </ContratadaListContent>
  );
};

const SkeletonListButton: React.FC = () => {
  return (
    <ContratadaListButton>
      <LineSkeleton height={30} width={90} />
    </ContratadaListButton>
  );
};

export default SkeletonContratadaList;
