import { ContratoHttp } from "gateways/repo/contrato_http_client";
import { useState } from "react";
import useBillingEnum from "../useBillingEnum";
import useContratante from "../contratante/useContratante";
import UseKit from "../kit/useKit";
import { useParams } from "react-router-dom";
import { setEditContratoLoading, setEditContratoState } from "store/contrato/view_contrato_slice";
import { useAppDispatch } from "store/store";
import useContratada from "hooks/contratada/useContratada";
import useServico from "hooks/servico/useServico";

const useViewContrato = () => {
  const params = useParams();
  const dispatch = useAppDispatch();
  const http = new ContratoHttp();
  const { getContratadaOption } = useContratada();
  const { getContratatesOptions } = useContratante();
  const { getKitOptions } = UseKit();
  const { getServicoOptions } = useServico();
  const { getEnums } = useBillingEnum();
  const [error, setError] = useState(false);

  const getContratoInfo = async () => {
    setError(false);
    dispatch(setEditContratoLoading(true));
    try {
      await getContratadaOption();
      await getContratatesOptions();
      await getKitOptions();
      await getServicoOptions();
      await getEnums();
      const data = await http.getContratoById(params.id!);
      dispatch(setEditContratoState(data.Objeto));
    } catch (e) {
      setError(true);
    } finally {
      dispatch(setEditContratoLoading(false));
    }
  };
  return {
    getContratoInfo,
    error,
  };
};
export default useViewContrato;
