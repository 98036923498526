import billing_theme from "style/theme_colors";
import styled from "styled-components";

export const PaginationContent = styled.div`
  border-top: solid 0.5px ${billing_theme.pallete.specific.T_50_25};
  margin-top: 16px;
  height: 72px;
  gap: 8px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0px 16px;
  button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    width: 100%;
    padding: 0px;
  }

  .atual {
    padding: 4px 24px;
    border-radius: 4px;
    background-color: ${billing_theme.pallete.specific.T_50_25};
    border-top: solid 0.5px ${billing_theme.pallete.tertiary.A100};
  }
  .text_padding {
    padding: 0 4px;
  }
`;
