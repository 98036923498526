import { SummryAboveFieldContent, SummryFieldContent } from "../style";
import { Heading6 } from "components/text/heading/style";
import billing_theme from "style/theme_colors";

const SummryAboveField: React.FC<{ title: string }> = ({ title }) => {
  return (
    <SummryAboveFieldContent>
      <Heading6 fontWeight={600} lineheight="24px" color={billing_theme.pallete.tertiary.A600}>
        {title}
      </Heading6>
    </SummryAboveFieldContent>
  );
};

export default SummryAboveField;
