import { ContratanteHttp } from "gateways/repo/contratante_http_client";
import {
  setContratante,
  setLoadingViewContrato,
  setErrorViewContrato,
  setModalContratante,
} from "store/contratante/view_contratante_slice";
import { useAppDispatch } from "store/store";
import { PostContratanteModel } from "shared/models/contratante_response.model";
import useContratante from "./useContratante";

const useViewContratante = () => {
  const http = new ContratanteHttp();
  const dispatch = useAppDispatch();
  const { getListContratantes } = useContratante();

  const getContratante = async (id: number) => {
    dispatch(setLoadingViewContrato(true));
    dispatch(setErrorViewContrato(false));
    try {
      const data = await http.getContratante(id);
      dispatch(setContratante(data.Objeto));
    } catch (e) {
      console.log(e);
      dispatch(setErrorViewContrato(true));
    } finally {
      dispatch(setLoadingViewContrato(false));
    }
  };
  const updateContratante = async (data: PostContratanteModel) => {
    dispatch(setLoadingViewContrato(true));
    dispatch(setErrorViewContrato(false));
    try {
      await http.updateNewContratante(data);
      getListContratantes();
      dispatch(setModalContratante(false));
    } catch (e) {
      console.log(e);
      dispatch(setErrorViewContrato(true));
    } finally {
      dispatch(setLoadingViewContrato(false));
    }
  };
  return {
    getContratante,
    updateContratante,
  };
};
export default useViewContratante;
