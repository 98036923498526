import React from "react";
import InsideMenuButton from "./inside_menu_button";
import InsideMenuHeader from "./inside_button_header";

const OpecaoesInsideMenu: React.FC = () => {
  return (
    <>
      <InsideMenuHeader name="Cadastros basicos" />
      <InsideMenuButton path={"/operacoes"} name={"Kit"} icon={"home_repair_service"} />
      <InsideMenuButton path={"/operacoes/servico"} name={"Serviço"} icon={"handyman"} />
      <InsideMenuButton path={"/operacoes/modelo"} name={"Modelo"} icon={"ad"} />
      <InsideMenuButton path={"/operacoes/contratante"} name={"Contratante"} icon={"domain"} />
      <InsideMenuButton path={"/operacoes/contratada"} name={"Contratada"} icon={"work"} />
      <InsideMenuHeader name="Cadastro operacional" />
      <InsideMenuButton path={"/operacoes/instalacao"} name={"Instalação"} icon={"build"} />
      <InsideMenuButton path={"/operacoes/veiculo"} name={"Veiculo"} icon={"car_rental"} />
      <InsideMenuButton
        path={"/operacoes/equipamento"}
        name={"Equipamento"}
        icon={"service_toolbox"}
      />
    </>
  );
};

export default OpecaoesInsideMenu;
