import billing_theme from "style/theme_colors";
import styled from "styled-components";

export const InstalacaoListContent = styled.div``;

export const InstalacaoListHeader = styled.div`
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  width: 100%;
  border-bottom: solid 0.5px ${billing_theme.pallete.tertiary.A100};
  margin-bottom: 12px;
  padding-bottom: 4px;
`;
export const InstalacaoListButton = styled.button`
  display: flex;
  width: 100%;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  background: transparent;
  border: none;
  padding: 0px 0px 0px 0px;
  height: 38px;
  cursor: pointer;
  gap: 10%;
  transition: 300ms;
`;

export const InstalacaoListItensContainer = styled.div`
  width: 98%;
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  align-items: center;
  padding: 16px 0px;
  box-shadow: 0px 0 0px 0.5px ${billing_theme.pallete.specific.T_300_P_300};
  border-radius: 8px;
  background-color: ${billing_theme.pallete.specific.T_50_25};
  margin: 0 auto;
  margin-bottom: 8px;
`;
export const InstalacaoListItems = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  background: transparent;
  border: none;
  padding: 0px 5px;
  height: 24px;
`;
export const InstalacaoListIcon = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  align-items: center;
  background: transparent;
  border: none;
  padding: 0px 0px;
  height: 24px;
`;
