import { useEffect, useState } from "react";
import {
  ContratanteListButton,
  ContratanteListContent,
  ContratanteListHeader,
  ContratanteListIcon,
  ContratanteListItems,
  ContratanteListItensContainer,
} from "./style";
import BillingIconRounded from "components/billing_icon/rounded_icon";
import billing_theme from "style/theme_colors";
import { Body2, Body3 } from "components/text/body/style";
import useContratante from "hooks/contratante/useContratante";
import React from "react";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "store/store";
import ModalBilling from "components/modal";
import ContratanteDeleteContent from "../contratante_delete_modal";
import { toastWarning } from "components/toast/billing_toast";
import { ContratanteListModel } from "shared/models/contratante_response.model";
import ModalViewContratante from "view/Contratante/page/ViewContratante";
import { setIdContratoView, setModalContratante } from "store/contratante/view_contratante_slice";
import { formattedTel } from "utils/stringUtils";
import {
  setContratanteDeleteID,
  setContratanteDeleteModal,
  setContratantePageIndex,
} from "store/contratante/contratante_slice";
import Pagination from "components/pagination";

const ContratanteList: React.FC<{}> = () => {
  const dispatch = useAppDispatch();
  const { deleteContratante, setPagedContratante } = useContratante();
  const contratante = useSelector((state: RootState) => state.contratante);
  const view_contratante = useSelector((state: RootState) => state.view_contratante);
  const [filter, setFilter] = useState("");
  const [data, setData] = useState<ContratanteListModel[]>([]);
  const [filterState, setFilterState] = useState("normal");

  useEffect(() => {
    contratante.filter.length == 0 ? setData(contratante.list) : setData(contratante.filter);
  });
  return (
    <ContratanteListContent>
      <ContratanteListHeader>
        <ListButton
          label="Razão Social"
          filterState={filterState}
          click={() => toastWarning({ title: "Em breve" })}
          selectedFilter={filter}
        />
        <ListButton
          label="E-mail"
          filterState={filterState}
          click={() => toastWarning({ title: "Em breve" })}
          selectedFilter={filter}
        />
        <ListButton
          label="Principal"
          filterState={filterState}
          click={() => toastWarning({ title: "Em breve" })}
          selectedFilter={filter}
        />
        <ListButton
          label="Telefone"
          filterState={filterState}
          click={() => toastWarning({ title: "Em breve" })}
          selectedFilter={filter}
        />
        <ListButton
          label="Opções"
          filterState={filterState}
          click={() => console.log("oi")}
          selectedFilter={"options"}
        />
      </ContratanteListHeader>
      {setPagedContratante(data, contratante.pageIndex).map(item => (
        <ContratanteListItensContainer key={item.ID}>
          <ListItens label={item.RazaoSocial} />
          <ListItens label={item.Email} />
          <ListItens label={item.Principal} />
          <ListItens label={formattedTel(item.Telefone)!} />

          <ContratanteListIcon>
            <BillingIconRounded
              icon={"visibility"}
              color={billing_theme.pallete.tertiary.A400}
              onTap={() => {
                dispatch(setIdContratoView(item.ID));
                dispatch(setModalContratante(true));
              }}
            />
            <BillingIconRounded
              icon={"delete"}
              color={billing_theme.pallete.error.A100}
              onTap={() => {
                dispatch(setContratanteDeleteID(item.ID));
                dispatch(setContratanteDeleteModal(true));
              }}
            />
          </ContratanteListIcon>
        </ContratanteListItensContainer>
      ))}
      <ModalBilling modalIsOpen={view_contratante.isOpen} width="800px" height="auto">
        <ModalViewContratante />
      </ModalBilling>
      <ModalBilling modalIsOpen={contratante.openDeleteModal} width="500px" height="200px">
        <ContratanteDeleteContent
          onClose={() => dispatch(setContratanteDeleteModal(false))}
          onDelete={() => deleteContratante()}
          onLoading={contratante.loadingDelete}
        />
      </ModalBilling>
      <Pagination
        index={contratante.pageIndex + 1}
        firstPage={1}
        lastPage={contratante.lastPage!}
        goToFirst={() => {
          dispatch(setContratantePageIndex(0));
        }}
        goToLast={() => {
          dispatch(setContratantePageIndex(contratante.lastPage! - 1));
        }}
        next={() => {
          dispatch(setContratantePageIndex(contratante.pageIndex + 1));
        }}
        back={() => {
          dispatch(setContratantePageIndex(contratante.pageIndex - 1));
        }}
      />
    </ContratanteListContent>
  );
};

const ListButton: React.FC<{
  label: string;
  click: () => void;
  selectedFilter: string;
  filterState: string;
}> = ({ label, click, selectedFilter, filterState }) => {
  return (
    <ContratanteListButton onClick={() => click()}>
      <Body2 fontWeight={600} color={billing_theme.pallete.tertiary.A400}>
        {label}
      </Body2>
      {selectedFilter == label && (
        <BillingIconRounded
          icon={filterState == "normal" ? "expand_less" : "expand_more"}
          color={billing_theme.pallete.tertiary.A400}
        />
      )}
    </ContratanteListButton>
  );
};

const ListItens: React.FC<{ label: string }> = ({ label }) => {
  return (
    <ContratanteListItems>
      <Body3 fontWeight={500} color={billing_theme.pallete.tertiary.A400} textalign="center">
        {label ?? "--"}
      </Body3>
    </ContratanteListItems>
  );
};
export default ContratanteList;
