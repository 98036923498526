import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { OptionsModel } from "shared/models/contratada_response.model";
import { ModeloResponseList } from "shared/models/modelo_list_response.model";

export interface ModeloListState {
  list?: ModeloResponseList[];
  filter?: ModeloResponseList[];
  pagination?: ModeloResponseList[][];
  pageIndex: number;
  lastPage?: number;
  options: OptionsModel[];
  loading?: boolean;
}

const initialState: ModeloListState = {
  list: [],
  filter: [],
  pageIndex: 0,
  options: [],
};

const modelo_slice = createSlice({
  name: "modelo",
  initialState,
  reducers: {
    setModeloList(state, action: PayloadAction<ModeloResponseList[]>) {
      state.list = action.payload;
    },
    setModeloFilter(state, action: PayloadAction<ModeloResponseList[]>) {
      state.filter = action.payload;
    },
    setLoadingModelo(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    setPaginationModelo(state, action: PayloadAction<ModeloResponseList[][]>) {
      state.pagination = action.payload;
    },
    setModeloPageIndex(state, action: PayloadAction<number>) {
      state.pageIndex = action.payload;
    },
    setModeloLastPage(state, action: PayloadAction<number>) {
      state.lastPage = action.payload;
    },

    setModeloOptions(state, action: PayloadAction<OptionsModel[]>) {
      state.options = action.payload;
    },
  },
});

export const {
  setModeloList,
  setModeloFilter,
  setLoadingModelo,
  setModeloPageIndex,
  setModeloLastPage,
  setPaginationModelo,
  setModeloOptions,
} = modelo_slice.actions;
export default modelo_slice.reducer;
