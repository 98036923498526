import axios from "axios";

export class BillingWithoutTokenHttp {
  private defaultUrl = process.env.REACT_APP_BILLING_DEFAUT_URL;

  get = async <T>(path: string, url?: string) => {
    return await axios.get<T>(`${url ?? this.defaultUrl}/${path}`, {});
  };
  post = async (path: string, options?: { data?: any }) => {
    return await axios.post(`${this.defaultUrl}/${path}`, options!.data ?? {});
  };

  delete = async (path: string) => {
    return await axios.delete(`${this.defaultUrl}/${path}`);
  };

  put = async (path: string, options?: { data?: any }) => {
    return await axios.put(`${this.defaultUrl}/${path}`, options!.data ?? {});
  };
}
