import React, { useState } from "react";
import { BillingTable, BillingTableContent, RowItem, TableContent, ViewKitListIcon } from "./style";
import { Heading3, Heading6 } from "components/text/heading/style";
import billing_theme from "style/theme_colors";
import { Body3 } from "components/text/body/style";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "store/store";
import { Acessorio } from "shared/models/kit_view_response.model";
import BillingIconRounded from "components/billing_icon/rounded_icon";
import { setKitAcessorios } from "store/kit/view_kit_slice";
import ModalBilling from "components/modal";
import ModalKitItens from "./components/modal_kit_itens";
import ModalNewAcessorio from "./components/modal_new_acessorio";
import PrimaryButton from "components/button/billing_button";

const ViewKitItens: React.FC = () => {
  const viewKit = useSelector((state: RootState) => state.view_kit);
  const [isOpenModal, setOpenModal] = useState(false);
  const [isNewAcessorioModal, setNewAcessorioModal] = useState(false);
  const [editItem, setEditItem] = useState<Acessorio>();
  const dispatch = useAppDispatch();
  const setModelo = (id: number) => {
    return viewKit.modelo.find(el => el.ID == id)?.Text ?? "Nome indisponível";
  };

  const removeItem = (id: number) => {
    let array: Acessorio[] = [];
    viewKit.kit.Acessorios.forEach((el, index) => {
      if (el.ID != id) {
        array.push(el);
      }
    });
    dispatch(setKitAcessorios(array!));
  };

  return (
    <TableContent>
      <div className="title">
        <Heading3 color={billing_theme.pallete.tertiary.A400} fontWeight={600}>
          Acessorios
        </Heading3>
        <PrimaryButton
          leftIcon="add"
          label={"Novo acessorio"}
          onTap={() => setNewAcessorioModal(true)}
          isSmall
        />
      </div>
      <BillingTableContent>
        <BillingTable>
          <thead>
            <tr>
              <td>
                <Heading6
                  color={billing_theme.pallete.tertiary.A400}
                  fontWeight={500}
                  textalign="center"
                >
                  Modelo
                </Heading6>
              </td>
              <td>
                <Heading6
                  color={billing_theme.pallete.tertiary.A400}
                  fontWeight={500}
                  textalign="center"
                >
                  Quantidade
                </Heading6>
              </td>
              <td>
                <Heading6
                  color={billing_theme.pallete.tertiary.A400}
                  fontWeight={500}
                  textalign="center"
                >
                  Ações
                </Heading6>
              </td>
            </tr>
          </thead>
          <tbody>
            {viewKit.kit.Acessorios.map((el, index) => (
              <ItemInstalacao
                key={index}
                acessorios={el}
                modelo={setModelo(el.Modelo_ID)}
                removeItem={() => removeItem(el.ID)}
                editItem={() => {
                  setEditItem(el);
                  setOpenModal(!isOpenModal);
                }}
              />
            ))}
          </tbody>
        </BillingTable>
      </BillingTableContent>
      <ModalBilling modalIsOpen={isOpenModal} width="450px" height="300px">
        <ModalKitItens
          item={editItem!}
          onClose={() => {
            setOpenModal(false);
          }}
          change={(id_modelo, quantidade) => {
            let newAcessorio: Acessorio[] = viewKit.kit.Acessorios.map(el => {
              if (el.ID === editItem?.ID) {
                return { ID: el.ID, Modelo_ID: id_modelo, Quantidade: quantidade } as Acessorio;
              }
              return el;
            });
            dispatch(setKitAcessorios(newAcessorio!));
            setOpenModal(false);
          }}
        />
      </ModalBilling>

      <ModalBilling modalIsOpen={isNewAcessorioModal} width="450px" height="300px">
        <ModalNewAcessorio
          onClose={() => {
            setNewAcessorioModal(false);
          }}
          onSave={(id_modelo, quantidade) => {
            const acessorios = viewKit.kit.Acessorios;

            let novoAcessorio: Acessorio[] = [
              ...acessorios,
              {
                ID: 0,
                Modelo_ID: id_modelo!,
                Quantidade: quantidade!,
              },
            ];
            dispatch(setKitAcessorios(novoAcessorio!));
            setNewAcessorioModal(false);
          }}
        />
      </ModalBilling>
    </TableContent>
  );
};

const ItemInstalacao: React.FC<{
  acessorios: Acessorio;
  modelo: string;
  removeItem: () => void;
  editItem: () => void;
}> = ({ acessorios, modelo, removeItem, editItem }) => {
  return (
    <RowItem>
      <td>
        <Body3 color={billing_theme.pallete.tertiary.A400} fontWeight={600}>
          {modelo}
        </Body3>
      </td>

      <td>
        <Body3 color={billing_theme.pallete.tertiary.A400} fontWeight={600}>
          {acessorios.Quantidade}
        </Body3>
      </td>
      <td>
        <ViewKitListIcon>
          <BillingIconRounded
            onTap={editItem}
            icon={"edit"}
            color={billing_theme.pallete.warning.A100}
          />
          <BillingIconRounded
            icon={"delete"}
            color={billing_theme.pallete.error.A100}
            onTap={removeItem}
          />
        </ViewKitListIcon>
      </td>
    </RowItem>
  );
};

export default ViewKitItens;
