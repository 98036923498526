import { BillingHttp } from "gateways/billing_http";
import {
  VeiculoListModelResponseModel,
  VeiculoResponseModel,
  PostVeiculoModel,
} from "shared/models/veiculo_response.model";
import { OptionsResponseModel } from "shared/models/default_models.model";
export class VeiculoHttp {
  private http = new BillingHttp();

  getListVeiculo = async () => {
    const reponse = await this.http.get<VeiculoListModelResponseModel>("Veiculo/List");

    return reponse.data;
  };

  getListVeiculoOptions = async () => {
    const reponse = await this.http.get<OptionsResponseModel>("Veiculo/ListOptions");
    return reponse.data;
  };
  getVeiculo = async (id: number) => {
    const response = await this.http.get<VeiculoResponseModel>("Veiculo/Get?id=" + id);
    return response.data;
  };
  adVeiculo = async (data: PostVeiculoModel) => {
    const response = await this.http.post("Veiculo/Insert", { data: data });
    return response.data;
  };
  updateVeiculo = async (data: PostVeiculoModel) => {
    const response = await this.http.put("Veiculo/Update", { data: data });
    return response.data;
  };
  deleteVeiculo = async (id: number | string) => {
    const response = await this.http.delete("Veiculo/Delete?id=" + id);
    return response.data;
  };
}
