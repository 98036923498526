import billing_theme from "style/theme_colors";
import styled from "styled-components";

export const EquipamentoListContent = styled.div``;

export const EquipamentoListHeader = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 25%);
  width: 100%;
  border-bottom: solid 0.5px ${billing_theme.pallete.tertiary.A100};
  margin-bottom: 12px;
  padding-bottom: 4px;
  justify-content: space-around;
  text-align: center;
`;
export const EquipamentoListButton = styled.button`
  display: flex;
  width: 100%;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  background: transparent;
  border: none;
  padding: 0px 0px 0px 0px;
  height: 38px;
  cursor: pointer;
  gap: 10%;
  transition: 300ms;

  h6 {
    line-height: 16px;
  }
`;

export const SkeletonEquipamentoListItensContainer = styled.div`
  width: 98%;
  border-radius: 8px;
  margin: 0 auto;
  margin-bottom: 8px;
`;
export const EquipamentoListItems = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  background: transparent;
  border: none;
  padding: 0px 5px;
  height: 24px;
`;
export const EquipamentoListIcon = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  align-items: center;
  background: transparent;
  border: none;
  padding: 0px 0px;
  height: 24px;
`;
