import billing_theme from "style/theme_colors";
import styled from "styled-components";

export const ViewVeiculoContent = styled.div`
  gap: 14px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  height: 100%;
  .button {
    display: flex;
    gap: 12px;
    align-items: center;
    justify-content: center;
  }
`;
export const ViewVeiculoGrid = styled.div`
  gap: 14px;
  display: grid;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  height: 100%;
  grid-template-columns: repeat(3, 1fr);
`;
export const ViewVeiculoGridTwo = styled.div`
  gap: 14px;
  display: grid;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  height: 100%;
  grid-template-columns: repeat(2, 1fr);
`;

export const ViewContratoRazaoCnpj = styled.div`
  gap: 14px;
  display: grid;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  height: 100%;
  grid-template-columns: 1fr 1fr;
`;
export const ViewContratoCep = styled.div`
  gap: 14px;
  display: grid;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  height: 100%;
  grid-template-columns: 1fr 1fr;
`;
export const ViewVeiculoDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;
