import billing_theme from "style/theme_colors";
import { Heading5, Heading6 } from "components/text/heading/style";
import { Body4 } from "components/text/body/style";
import { EditContratoInputLabel } from "../../../style";
import { RootState, useAppDispatch } from "store/store";
import {
  setFidelidadeState,
  setCarenciaState,
  setDuracaoState,
} from "store/contrato/view_contrato_slice";
import BillingNumberInput from "components/input/number_input";
import { ContratoInsideContent, ContratoDataGrid } from "../style";
import { useSelector } from "react-redux";

const RegrasDeNegocio: React.FC<{}> = () => {
  const edit_contrato = useSelector((state: RootState) => state.view_contrato);
  const dispatch = useAppDispatch();
  return (
    <ContratoInsideContent>
      <EditContratoInputLabel>
        <Heading5 fontWeight={600} color={billing_theme.pallete.tertiary.A600}>
          Regras de negócio
        </Heading5>
        <Body4 fontWeight={400} color={billing_theme.pallete.tertiary.A600}>
          Informe os detalhes do contrato.
        </Body4>
      </EditContratoInputLabel>
      <ContratoDataGrid>
        <EditContratoInputLabel>
          <Heading6 fontWeight={600} color={billing_theme.pallete.tertiary.A600}>
            Duração
          </Heading6>
          <BillingNumberInput
            alterValue={edit_contrato.DuracaoMeses}
            placeholder="Meses"
            onNumberFormat={val => {
              dispatch(setDuracaoState(val.number));
            }}
          />
        </EditContratoInputLabel>
        <EditContratoInputLabel>
          <Heading6 fontWeight={600} color={billing_theme.pallete.tertiary.A600}>
            Carência
          </Heading6>
          <BillingNumberInput
            alterValue={edit_contrato.CarenciaDias}
            placeholder="Dias"
            onNumberFormat={val => {
              dispatch(setCarenciaState(val.number));
            }}
          />
        </EditContratoInputLabel>
        <EditContratoInputLabel>
          <Heading6 fontWeight={600} color={billing_theme.pallete.tertiary.A600}>
            Fidelidade
          </Heading6>
          <BillingNumberInput
            alterValue={edit_contrato.FidelidadeMeses}
            placeholder="Meses"
            onNumberFormat={val => {
              dispatch(setFidelidadeState(val.number));
            }}
          />
        </EditContratoInputLabel>
      </ContratoDataGrid>
    </ContratoInsideContent>
  );
};

export default RegrasDeNegocio;
