import PrimaryButton from "components/button/billing_button";
import TertiaryButton from "components/button/tertiaty_button";
import { Body2 } from "components/text/body/style";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "store/store";
import billing_theme from "style/theme_colors";
import {
  ViewContratanteContent,
  ViewContratanteDetails,
  ViewContratanteGrid,
  ViewContratanteGridTwo,
  ViewContratoCep,
  ViewContratoRazaoCnpj,
} from "./style";
import BillingTextInput from "components/input/text_input";
import useViewContratante from "hooks/contratante/useEditContratante";
import LineSkeleton from "components/skeleton/line_skeleton";
import { setModalContratante } from "store/contratante/view_contratante_slice";
import { NewContratanteDetails, NewContratoThree } from "../NewContratante/style";
import BillingSelectInput from "components/input/select";

const ModalViewContratante: React.FC = () => {
  const dispatch = useAppDispatch();
  const state = useSelector((state: RootState) => state.view_contratante);
  const { getContratante, updateContratante } = useViewContratante();
  const [razao, setRazao] = useState<string>();
  const [cnpj, setCpnj] = useState<string>();
  const [telefone, setTelefone] = useState<string>();
  const [email, setEmail] = useState<string>();
  const [logradouro, setLogradouro] = useState<string>();
  const [numero, setNumero] = useState<string>();
  const [complemento, setComplemento] = useState<string>();
  const [bairro, setBairro] = useState<string>();
  const [cidade, setCidade] = useState<string>();
  const [uf, setUf] = useState<string>();
  const [cep, setCEP] = useState<string>();
  const [empresa, setEmpresa] = useState<string>();
  useEffect(() => {
    getContratante(state.selectedId!);
  }, []);

  return (
    <ViewContratanteContent>
      <ContratanteInfo
        razao={value => {
          setRazao(value);
        }}
        CNPJ={value => {
          setCpnj(value);
        }}
        email={value => {
          setEmail(value);
        }}
        telefone={value => {
          setTelefone(value);
        }}
        empresa={value => {
          setEmpresa(value);
        }}
      />

      <ViewContratoCep>
        <ViewContratanteDetails>
          <Body2 fontWeight={600} color={billing_theme.pallete.tertiary?.A400}>
            CEP:
          </Body2>

          {state.loading ? (
            <LineSkeleton width={"100%"} height={39} />
          ) : (
            <BillingTextInput
              placeholder="CEP"
              mask={"99.999-999"}
              value={state.contratante?.CEP}
              change={value => {
                setCEP(value);
              }}
            />
          )}
        </ViewContratanteDetails>

        <ViewContratanteDetails>
          <Body2 fontWeight={600} color={billing_theme.pallete.tertiary?.A400}>
            Logradouro:
          </Body2>

          {state.loading ? (
            <LineSkeleton width={"100%"} height={39} />
          ) : (
            <BillingTextInput
              placeholder="Logradouro"
              value={state.contratante?.Logradouro}
              change={value => {
                setLogradouro(value);
              }}
            />
          )}
        </ViewContratanteDetails>
      </ViewContratoCep>

      <ViewContratanteGridTwo>
        <ViewContratanteDetails>
          <Body2 fontWeight={600} color={billing_theme.pallete.tertiary?.A400}>
            Cidade:
          </Body2>

          {state.loading ? (
            <LineSkeleton width={"100%"} height={39} />
          ) : (
            <BillingTextInput
              placeholder="Cidade"
              value={state.contratante?.Cidade}
              change={value => {
                setCidade(value);
              }}
            />
          )}
        </ViewContratanteDetails>

        <ViewContratanteDetails>
          <Body2 fontWeight={600} color={billing_theme.pallete.tertiary?.A400}>
            Bairro:
          </Body2>

          {state.loading ? (
            <LineSkeleton width={"100%"} height={39} />
          ) : (
            <BillingTextInput
              placeholder="Bairro"
              value={state.contratante?.Bairro}
              change={value => {
                setBairro(value);
              }}
            />
          )}
        </ViewContratanteDetails>
      </ViewContratanteGridTwo>

      <ViewContratanteGrid>
        <ViewContratanteDetails>
          <Body2 fontWeight={600} color={billing_theme.pallete.tertiary?.A400}>
            Numero:
          </Body2>

          {state.loading ? (
            <LineSkeleton width={"100%"} height={39} />
          ) : (
            <BillingTextInput
              placeholder="Numero"
              value={state.contratante?.Numero}
              change={value => {
                setNumero(value);
              }}
            />
          )}
        </ViewContratanteDetails>

        <ViewContratanteDetails>
          <Body2 fontWeight={600} color={billing_theme.pallete.tertiary?.A400}>
            Complemento:
          </Body2>

          {state.loading ? (
            <LineSkeleton width={"100%"} height={39} />
          ) : (
            <BillingTextInput
              placeholder="Complemento"
              value={state.contratante?.Complemento}
              change={value => {
                setComplemento(value);
              }}
            />
          )}
        </ViewContratanteDetails>

        <ViewContratanteDetails>
          <Body2 fontWeight={600} color={billing_theme.pallete.tertiary?.A400}>
            UF:
          </Body2>

          {state.loading ? (
            <LineSkeleton width={"100%"} height={39} />
          ) : (
            <BillingTextInput
              placeholder="UF"
              value={state.contratante?.UF}
              change={value => {
                setUf(value);
              }}
            />
          )}
        </ViewContratanteDetails>
      </ViewContratanteGrid>

      <div className="button">
        <TertiaryButton
          label={"Cancelar"}
          onTap={() => dispatch(setModalContratante(false))}
          isSmall
        />
        <PrimaryButton
          disable={state.loading}
          label={"Editar"}
          onTap={async () => {
            await updateContratante({
              ID: state.contratante?.ID,
              EmpresaPrincipal_ID:
                state.contratante?.EmpresaPrincipal_ID || empresa
                  ? Number(empresa ?? state.contratante?.EmpresaPrincipal_ID)
                  : null,
              RazaoSocial: razao ?? state.contratante?.RazaoSocial,
              Telefone: telefone ?? state.contratante?.Telefone,
              CEP: cep ?? state.contratante?.CEP,
              CNPJ: cnpj ?? state.contratante?.CNPJ,
              Complemento: complemento ?? state.contratante?.Complemento,
              Cidade: cidade ?? state.contratante?.Cidade,
              Email: email ?? state.contratante?.Email,
              Logradouro: logradouro ?? state.contratante?.Logradouro,
              Numero: numero ?? state.contratante?.Numero,
              UF: uf ?? state.contratante?.UF,
              Bairro: bairro ?? state.contratante?.Bairro,
            });
          }}
          isSmall
        />
      </div>
    </ViewContratanteContent>
  );
};

const ContratanteInfo: React.FC<{
  razao: (value: string | undefined) => void;
  CNPJ: (value: string | undefined) => void;
  email: (value: string | undefined) => void;
  telefone: (value: string | undefined) => void;
  empresa: (value: string | undefined) => void;
}> = ({ razao, CNPJ, email, telefone, empresa }) => {
  const state = useSelector((state: RootState) => state.view_contratante);
  const contratantes = useSelector((state: RootState) => state.contratante);
  return (
    <ViewContratanteContent>
      <ViewContratoRazaoCnpj>
        <ViewContratanteDetails>
          <Body2 fontWeight={600} color={billing_theme.pallete.tertiary?.A400}>
            Razão social:
          </Body2>
          {state.loading ? (
            <LineSkeleton width={"100%"} height={39} />
          ) : (
            <BillingTextInput
              placeholder="Razão social"
              value={state.contratante?.RazaoSocial}
              change={razao}
            />
          )}
        </ViewContratanteDetails>
        <ViewContratanteDetails>
          <Body2 fontWeight={600} color={billing_theme.pallete.tertiary?.A400}>
            CNPJ:
          </Body2>
          {state.loading ? (
            <LineSkeleton width={"100%"} height={39} />
          ) : (
            <BillingTextInput
              placeholder="CNPJ"
              mask="99.999.999/9999-99"
              value={state.contratante?.CNPJ}
              change={CNPJ}
            />
          )}
        </ViewContratanteDetails>
      </ViewContratoRazaoCnpj>

      <NewContratoThree>
        <ViewContratanteDetails>
          <Body2 fontWeight={600} color={billing_theme.pallete.tertiary?.A400}>
            Email:
          </Body2>

          {state.loading ? (
            <LineSkeleton width={"100%"} height={39} />
          ) : (
            <BillingTextInput placeholder="Email" value={state.contratante?.Email} change={email} />
          )}
        </ViewContratanteDetails>
        <ViewContratanteDetails>
          <Body2 fontWeight={600} color={billing_theme.pallete.tertiary?.A400}>
            Telefone:
          </Body2>

          {state.loading ? (
            <LineSkeleton width={"100%"} height={39} />
          ) : (
            <BillingTextInput
              placeholder="Telefone"
              mask="(99) 99999-9999"
              value={state.contratante?.Telefone}
              change={telefone}
            />
          )}
        </ViewContratanteDetails>
        <ViewContratanteDetails>
          <Body2 fontWeight={600} color={billing_theme.pallete.tertiary?.A400}>
            Empresa principal:
          </Body2>
          {state.loading ? (
            <LineSkeleton width={"100%"} height={39} />
          ) : (
            <BillingSelectInput
              placeholder="Selecione um contratante"
              defaultInputValue={contratantes.options.find(
                el => el.value == state.contratante?.EmpresaPrincipal_ID
              )}
              options={contratantes.options}
              onChange={value => {
                empresa(value.value);
              }}
            />
          )}
        </ViewContratanteDetails>
      </NewContratoThree>
    </ViewContratanteContent>
  );
};

export default ModalViewContratante;
