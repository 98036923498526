import { InstalacaoHttp } from "gateways/repo/instalacao_http_client";
import {
  setLoadingViewInstalacao,
  setErrorViewInstalacao,
  setViewInstalacao,
} from "store/instalacao/view_instalacao_slice";
import { useAppDispatch } from "store/store";
import useInstalacao from "./useInstalacao";
import { PostInstalacaoModel } from "shared/models/instalacao_response.model";
import useContratante from "hooks/contratante/useContratante";
import useBillingEnum from "hooks/useBillingEnum";
import useEquipamento from "hooks/useEquipamento";
import useVeiculo from "hooks/veiculo/useVeiculo";
import { useParams } from "react-router-dom";

const useEditInstalacao = () => {
  const http = new InstalacaoHttp();
  const params = useParams();
  const dispatch = useAppDispatch();
  const { getInstalacaoList } = useInstalacao();
  const { getContratatesOptions } = useContratante();
  const { getVeiculoOption } = useVeiculo();
  const { getEquipamentoOption } = useEquipamento();
  const { getEnums } = useBillingEnum();

  const getInstalacao = async () => {
    dispatch(setLoadingViewInstalacao(true));
    dispatch(setErrorViewInstalacao(false));
    try {
      await getContratatesOptions();
      await getVeiculoOption();
      await getEquipamentoOption();
      await getEnums();
      const data = await http.getInstalacao(params.id!);
      dispatch(setViewInstalacao(data.Objeto!));
    } catch (e) {
      dispatch(setErrorViewInstalacao(true));
    } finally {
      dispatch(setLoadingViewInstalacao(false));
    }
  };
  const updateInstalacao = async (data: PostInstalacaoModel) => {
    dispatch(setLoadingViewInstalacao(true));
    dispatch(setErrorViewInstalacao(false));
    try {
      await http.updateInstalacao(data);
      getInstalacaoList();
    } catch (e) {
      dispatch(setErrorViewInstalacao(true));
    } finally {
      dispatch(setLoadingViewInstalacao(false));
    }
  };
  return {
    getInstalacao,
    updateInstalacao,
  };
};
export default useEditInstalacao;
