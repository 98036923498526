import billing_theme from "style/theme_colors";
import styled from "styled-components";

export const KitContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: flex-start;
  justify-content: space-between;

  .main_content {
    width: 100%;
  }
`;

export const KitHeader = styled.div`
  padding: 16px 32px;
  border-bottom: solid 0.5px ${billing_theme.pallete.primary?.A300};
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
`;
export const KitFilterContent = styled.div`
  padding: 16px 32px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  gap: 8px;
  justify-content: space-between;
`;
export const KitMain = styled.div`
  width: 100%;

  .date {
    display: flex;
    flex-direction: row;
    gap: 12px;
  }
`;

export const FatureFilterLabel = styled.div`
  display: flex;
  gap: 4px;
  flex-direction: column;
`;

export const KitFilterButtons = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-end;
  gap: 8px;
  padding: 8px 32px 12px 12px;
`;
