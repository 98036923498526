import billing_theme from "style/theme_colors";
import { SelectedEquipamentoButton } from "../style";
import { Body3 } from "components/text/body/style";
import BillingIconRounded from "components/billing_icon/rounded_icon";

const SelectedEquipamento: React.FC<{ label: string; onTap: () => void }> = ({ label, onTap }) => {
  return (
    <SelectedEquipamentoButton>
      <Body3 color={billing_theme.pallete.tertiary.A600} lineheight="24px">
        {label}
      </Body3>

      <BillingIconRounded onTap={onTap} icon="close" color={billing_theme.pallete.primary.A300} />
    </SelectedEquipamentoButton>
  );
};

export default SelectedEquipamento;
