import { toastError, toastInfo } from "components/toast/billing_toast";
import { ContratadaHttp } from "gateways/repo/contratada_http_client";
import { useState } from "react";
import { useSelector } from "react-redux";
import { ContratadaListModel, OptionsModel } from "shared/models/contratada_response.model";
import {
  setContradaError,
  setContratadaLastPage,
  setContratadaList,
  setContratadaLoading,
  setContratadaOptions,
  setContratadoDeleteModal,
  setLoadingContratada,
} from "store/contratada/contratada_slice";
import { RootState, useAppDispatch } from "store/store";
import { formatToOption } from "utils/enumListToOptions";

const useContratada = () => {
  const http = new ContratadaHttp();
  const dispatch = useAppDispatch();
  const contratada = useSelector((state: RootState) => state.contratada);
  const [contratadaOptions, setContratadaOption] = useState<OptionsModel[]>([]);

  const getContratadaList = async () => {
    dispatch(setContratadaLoading(true));
    dispatch(setContradaError(false));
    try {
      const data = await http.getListContratada();
      dispatch(setContratadaList(data.Lista));
      dispatch(setContratadaLastPage(paginateContratada(data.Lista!).length));
    } catch (e) {
      dispatch(setContradaError(true));
      console.log(e);
    } finally {
      dispatch(setContratadaLoading(false));
    }
  };

  const getContratadaOption = async () => {
    dispatch(setContratadaLoading(true));
    dispatch(setContradaError(false));
    try {
      const data = await http.getListContratadaOptions();
      let options: OptionsModel[] = [];
      data.Lista.forEach(val => {
        options.push(formatToOption(val.ID.toString(), val.Text));
      });

      setContratadaOption(options);
      dispatch(setContratadaOptions(options));
    } catch (e) {
      dispatch(setContradaError(true));
      console.log(e);
    } finally {
      dispatch(setContratadaLoading(false));
    }
  };
  const deleteContratada = async () => {
    dispatch(setLoadingContratada(true));
    try {
      await http.deleteContratada(contratada.selectDeleteId!);
      toastInfo({ title: "Empresa excluida!" });
      dispatch(setContratadoDeleteModal(false));
      getContratadaList();
    } catch (e) {
      toastError({ title: "Ocorreu um erro!", message: "Por favor, tente novamente!" });
    } finally {
      dispatch(setLoadingContratada(false));
    }
  };
  const paginateContratada = (list: ContratadaListModel[]) => {
    const resultado: ContratadaListModel[][] = [];

    for (let i = 0; i < list.length; i += 10) {
      const chunk = list.slice(i, i + 10);
      resultado.push(chunk);
    }
    return resultado;
  };
  const setPagedContratada = (list: ContratadaListModel[], index: number) => {
    const resultado: ContratadaListModel[][] = [];

    for (let i = 0; i < list.length; i += 10) {
      const chunk = list.slice(i, i + 10);
      resultado.push(chunk);
    }
    return resultado[index] ?? [];
  };
  return {
    contratadaOptions,
    getContratadaList,
    getContratadaOption,
    deleteContratada,
    paginateContratada,
    setPagedContratada,
  };
};
export default useContratada;
