import billing_theme from "style/theme_colors";
import { Heading5, Heading6 } from "components/text/heading/style";
import { Body4 } from "components/text/body/style";
import { ContratoInsideContent } from "../style";
import { NovoContratoInputLabel } from "../../../style";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "store/store";
import BillingSelectInput from "components/input/select";
import {
  setContratanteState,
  setContratadaState,
  setQuantidadeKitPrincipal,
  setKitPrincipal,
} from "store/contrato/new_contrato_slice";
import BillingNumberInput from "components/input/number_input";

const DadosDoContratoComp: React.FC<{}> = () => {
  const contratada = useSelector((state: RootState) => state.contratada);
  const contratante = useSelector((state: RootState) => state.contratante);
  const kit = useSelector((state: RootState) => state.kit);
  const novo_contrato = useSelector((state: RootState) => state.novo_contrato);
  const dispatch = useAppDispatch();

  return (
    <ContratoInsideContent>
      <NovoContratoInputLabel>
        <Heading5 fontWeight={600} color={billing_theme.pallete.tertiary.A600}>
          Dados do contrato
        </Heading5>
        <Body4 fontWeight={400} color={billing_theme.pallete.tertiary.A600}>
          Informe os detalhes da contratação
        </Body4>
      </NovoContratoInputLabel>

      <NovoContratoInputLabel>
        <Heading6 fontWeight={600} color={billing_theme.pallete.tertiary.A600}>
          Contratantes*
        </Heading6>
        <BillingSelectInput
          defaultInputValue={contratante.options?.find(
            el => Number(el.value) === Number(novo_contrato.contratante)
          )}
          onChange={(val: any) => {
            val != null
              ? dispatch(setContratanteState(val?.value!))
              : dispatch(setContratanteState(undefined));
          }}
          options={contratante.options}
          placeholder={"Selecione um Contratante"}
        />
      </NovoContratoInputLabel>

      <NovoContratoInputLabel>
        <Heading6 fontWeight={600} color={billing_theme.pallete.tertiary.A600}>
          Contratada*
        </Heading6>

        <BillingSelectInput
          defaultInputValue={contratada.options?.find(
            el => Number(el.value) === Number(novo_contrato.contratada)
          )}
          onChange={(val: any) => {
            val != null
              ? dispatch(setContratadaState(val?.value!))
              : dispatch(setContratadaState(undefined));
          }}
          options={contratada.options}
          placeholder={"Selecione a contratada"}
        />
      </NovoContratoInputLabel>

      <div className="select_kit_principal">
        <NovoContratoInputLabel>
          <Heading6 fontWeight={600} color={billing_theme.pallete.tertiary.A600}>
            Kit Principal*
          </Heading6>
          <BillingSelectInput
            defaultInputValue={kit.options?.find(
              el => Number(el.value) === Number(novo_contrato.kitPrincipal)
            )}
            onChange={(val: any) => {
              val != null
                ? dispatch(setKitPrincipal(val?.value!))
                : dispatch(setKitPrincipal(undefined));
            }}
            options={kit.options}
            placeholder={"Selecione um kit"}
          />
        </NovoContratoInputLabel>

        <NovoContratoInputLabel>
          <Heading6 fontWeight={600} color={billing_theme.pallete.tertiary.A600}>
            Quantidade*
          </Heading6>

          <BillingNumberInput
            value={novo_contrato.quantidade}
            placeholder="Quantidade?"
            onNumberFormat={val => {
              dispatch(setQuantidadeKitPrincipal(val.number));
            }}
          />
        </NovoContratoInputLabel>
      </div>
    </ContratoInsideContent>
  );
};

export default DadosDoContratoComp;
