import billing_theme from "style/theme_colors";
import styled from "styled-components";

export const ViewDadosGeraisContent = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 4px;
  margin: 16px;
  gap: 32px;
  height: 100%;
`;

export const ViewPrincipalContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
  border-right: 0.5px solid ${billing_theme.pallete.tertiary.A100};
  padding-right: 16px;
  .principal {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 8px;
  }
`;
export const ViewResumoContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
  .resumo {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 8px;
  }
`;
