import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { OptionsModel } from "shared/models/contratada_response.model";
import { VeiculoListModel } from "shared/models/veiculo_response.model";

export interface ContratoSliceType {
  list: VeiculoListModel[];
  filter: VeiculoListModel[];
  options: OptionsModel[];
  loading?: boolean;
  isError?: boolean;
  selectDeleteId?: number;
  openDeleteModal: boolean;
  loadingDelete: boolean;
  pagination?: VeiculoListModel[][];
  pageIndex: number;
  lastPage?: number;
}

const initialState: ContratoSliceType = {
  list: [],
  options: [],
  loading: true,
  isError: false,
  filter: [],
  openDeleteModal: false,
  loadingDelete: false,
  pageIndex: 0,
};

const veiculo_slice = createSlice({
  name: "veiculo",
  initialState,
  reducers: {
    setVeiculoList(state, action: PayloadAction<VeiculoListModel[]>) {
      state.list = action.payload;
    },

    setVeiculoOptions(state, action: PayloadAction<OptionsModel[]>) {
      state.options = action.payload;
    },
    setVeiculoLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    setContradaError(state, action: PayloadAction<boolean>) {
      state.isError = action.payload;
    },
    setVeiculoDeleteModal(state, action: PayloadAction<boolean>) {
      state.openDeleteModal = action.payload;
    },
    setCotratadaDeleteId(state, action: PayloadAction<number>) {
      state.selectDeleteId = action.payload;
    },
    setLoadingVeiculo(state, action: PayloadAction<boolean>) {
      state.loadingDelete = action.payload;
    },
    setPaginationVeiculo(state, action: PayloadAction<VeiculoListModel[][]>) {
      state.pagination = action.payload;
    },
    setVeiculoPageIndex(state, action: PayloadAction<number>) {
      state.pageIndex = action.payload;
    },
    setVeiculoLastPage(state, action: PayloadAction<number>) {
      state.lastPage = action.payload;
    },
  },
});

export const {
  setVeiculoList,
  setVeiculoOptions,
  setVeiculoLoading,
  setContradaError,
  setCotratadaDeleteId,
  setLoadingVeiculo,
  setVeiculoDeleteModal,
  setPaginationVeiculo,
  setVeiculoPageIndex,
  setVeiculoLastPage,
} = veiculo_slice.actions;
export default veiculo_slice.reducer;
