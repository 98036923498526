import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export interface NewEquipamento {
  loading?: boolean;
  isError?: boolean;
  isOpen: boolean;
}

const initialState: NewEquipamento = {
  loading: false,
  isError: false,
  isOpen: false,
};

const new_equipamento_slice = createSlice({
  name: "new_equipamento",
  initialState,
  reducers: {
    setLoadingNewEquipamento(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },

    setErrorNewEquipamento(state, action: PayloadAction<boolean>) {
      state.isError = action.payload;
    },
    setModalNewEquipamento(state, action: PayloadAction<boolean>) {
      state.isOpen = action.payload;
    },
  },
});

export const { setLoadingNewEquipamento, setErrorNewEquipamento, setModalNewEquipamento } =
  new_equipamento_slice.actions;
export default new_equipamento_slice.reducer;
