import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { OptionsModel } from "shared/models/contratada_response.model";
import { InstalacaoListModel } from "shared/models/instalacao_response.model";

export interface ContratoSliceType {
  list: InstalacaoListModel[];
  filter: InstalacaoListModel[];
  options: OptionsModel[];
  loading?: boolean;
  isError?: boolean;
  selectDeleteId?: number;
  openDeleteModal: boolean;
  loadingDelete: boolean;
  pagination?: InstalacaoListModel[][];
  pageIndex: number;
  lastPage?: number;
}

const initialState: ContratoSliceType = {
  list: [],
  options: [],
  loading: true,
  isError: false,
  filter: [],
  openDeleteModal: false,
  loadingDelete: false,
  pageIndex: 0,
};

const instalacao_slice = createSlice({
  name: "instalacao",
  initialState,
  reducers: {
    setInstalacaoList(state, action: PayloadAction<InstalacaoListModel[]>) {
      state.list = action.payload;
    },

    setInstalacaoOptions(state, action: PayloadAction<OptionsModel[]>) {
      state.options = action.payload;
    },
    setInstalacaoLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    setContradaError(state, action: PayloadAction<boolean>) {
      state.isError = action.payload;
    },
    setInstalacaoDeleteModal(state, action: PayloadAction<boolean>) {
      state.openDeleteModal = action.payload;
    },
    setCotratadaDeleteId(state, action: PayloadAction<number>) {
      state.selectDeleteId = action.payload;
    },
    setLoadingInstalacao(state, action: PayloadAction<boolean>) {
      state.loadingDelete = action.payload;
    },
    setPaginationInstalacao(state, action: PayloadAction<InstalacaoListModel[][]>) {
      state.pagination = action.payload;
    },
    setInstalacaoPageIndex(state, action: PayloadAction<number>) {
      state.pageIndex = action.payload;
    },
    setInstalacaoLastPage(state, action: PayloadAction<number>) {
      state.lastPage = action.payload;
    },
  },
});

export const {
  setInstalacaoList,
  setInstalacaoOptions,
  setInstalacaoLoading,
  setContradaError,
  setCotratadaDeleteId,
  setLoadingInstalacao,
  setInstalacaoDeleteModal,
  setPaginationInstalacao,
  setInstalacaoPageIndex,
  setInstalacaoLastPage,
} = instalacao_slice.actions;
export default instalacao_slice.reducer;
