import LineSkeleton from "components/skeleton/line_skeleton";
import {
  EquipamentoListButton,
  EquipamentoListContent,
  EquipamentoListHeader,
  SkeletonEquipamentoListItensContainer,
} from "./style";
import React from "react";
const SkeletonEquipamentoList: React.FC<{}> = () => {
  const array = ["", "", "", ""];
  return (
    <EquipamentoListContent>
      <EquipamentoListHeader>
        {array.map((el, i) => (
          <LineSkeleton height={30} width={150} key={i} />
        ))}
      </EquipamentoListHeader>

      <SkeletonEquipamentoListItensContainer>
        <LineSkeleton height={58} width={"100%"} />
      </SkeletonEquipamentoListItensContainer>
      <SkeletonEquipamentoListItensContainer>
        <LineSkeleton height={58} width={"100%"} />
      </SkeletonEquipamentoListItensContainer>
      <SkeletonEquipamentoListItensContainer>
        <LineSkeleton height={58} width={"100%"} />
      </SkeletonEquipamentoListItensContainer>
      <SkeletonEquipamentoListItensContainer>
        <LineSkeleton height={58} width={"100%"} />
      </SkeletonEquipamentoListItensContainer>
      <SkeletonEquipamentoListItensContainer>
        <LineSkeleton height={58} width={"100%"} />
      </SkeletonEquipamentoListItensContainer>
      <SkeletonEquipamentoListItensContainer>
        <LineSkeleton height={58} width={"100%"} />
      </SkeletonEquipamentoListItensContainer>
    </EquipamentoListContent>
  );
};

const SkeletonListButton: React.FC = () => {
  return (
    <EquipamentoListButton>
      <LineSkeleton height={30} width={90} />
    </EquipamentoListButton>
  );
};

export default SkeletonEquipamentoList;
