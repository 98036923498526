import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { OptionsModel } from "shared/models/contratada_response.model";
import { ContratanteListModel } from "shared/models/contratante_response.model";

export interface ContratanteSliceType {
  list: ContratanteListModel[];
  filter: ContratanteListModel[];
  options: OptionsModel[];
  loading?: boolean;
  isError?: boolean;
  openDeleteModal: boolean;
  selectDeleteId?: number;
  loadingDelete: boolean;
  pagination?: ContratanteListModel[][];
  pageIndex: number;
  lastPage?: number;
}

const initialState: ContratanteSliceType = {
  list: [],
  filter: [],
  options: [],
  openDeleteModal: false,
  loadingDelete: false,
  pageIndex: 0,
};

const contratante_slice = createSlice({
  name: "contratante",
  initialState,
  reducers: {
    setContratanteList(state, action: PayloadAction<ContratanteListModel[]>) {
      state.list = action.payload;
    },
    setContratanteFilterList(state, action: PayloadAction<ContratanteListModel[]>) {
      state.filter = action.payload;
    },
    setContratanteOptions(state, action) {
      state.options = action.payload;
    },
    setContratanteLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    setContratanteError(state, action: PayloadAction<boolean>) {
      state.isError = action.payload;
    },
    setContratanteDeleteModal(state, action: PayloadAction<boolean>) {
      state.openDeleteModal = action.payload;
    },
    setContratanteDeleteID(state, action: PayloadAction<number>) {
      state.selectDeleteId = action.payload;
    },
    setContratanteLoadingDelete(state, action: PayloadAction<boolean>) {
      state.loadingDelete = action.payload;
    },
    setPaginationContratante(state, action: PayloadAction<ContratanteListModel[][]>) {
      state.pagination = action.payload;
    },
    setContratantePageIndex(state, action: PayloadAction<number>) {
      state.pageIndex = action.payload;
    },
    setContratanteLastPage(state, action: PayloadAction<number>) {
      state.lastPage = action.payload;
    },
  },
});

export const {
  setContratanteList,
  setContratanteOptions,
  setContratanteError,
  setContratanteLoading,
  setContratanteDeleteModal,
  setContratanteDeleteID,
  setContratanteLoadingDelete,
  setPaginationContratante,
  setContratantePageIndex,
  setContratanteLastPage,
} = contratante_slice.actions;
export default contratante_slice.reducer;
