import BillingTextInput from "components/input/text_input";
import useInstalacao from "hooks/instalacao/useInstalacao";
import React from "react";
import { SearchContent, InstalacaoFilterContent, SearchInputContent } from "./style";
import { Heading6 } from "components/text/heading/style";
import billing_theme from "style/theme_colors";
import { RootState } from "store/store";
import { useSelector } from "react-redux";
import BillingDatePicker from "components/input/datePicker";
const InstalacaoFilter: React.FC<{}> = () => {
  const {} = useInstalacao();
  const list_enum = useSelector((state: RootState) => state.list_enum);
  return (
    <InstalacaoFilterContent>
      <SearchContent>
        <SearchInputContent>
          <Heading6 fontWeight={600} color={billing_theme.pallete.tertiary.A400}>
            Procurar:
          </Heading6>
          <BillingTextInput
            placeholder="Placa, Contratante, Cliente e etc."
            change={value => console.log(value!)}
          />
        </SearchInputContent>
      </SearchContent>
    </InstalacaoFilterContent>
  );
};

export default InstalacaoFilter;
