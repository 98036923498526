import { ContratanteContainer, ContratanteHeader } from "./style";
import { Heading3 } from "components/text/heading/style";
import { Body2 } from "components/text/body/style";
import billing_theme from "style/theme_colors";
import Scaffold from "components/scaffold";
import PrimaryButton from "components/button/billing_button";
import { useEffect } from "react";
import InsideMenu from "components/insidemenu";
import InsideMenuButton from "components/insidemenu/components/inside_menu_button";
import ServicoList from "./components/contratante_list";
import SkeletonServicoList from "./components/contratante_skeleton";
import ServicoErrorContent from "./components/contratante_list_error";
import ServicoFilter from "./components/contratante_filter";
import ModalBilling from "components/modal";
import useContratante from "hooks/contratante/useContratante";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "store/store";
import ModalNewContratante from "./page/NewContratante";
import { setModalNewContratante } from "store/contratante/new_contratante_slice";
import OpecaoesInsideMenu from "components/insidemenu/components/opecaoes_menu";

const ContratantePage: React.FC = () => {
  const dispatch = useAppDispatch();
  const { getListContratantes, getContratatesOptions } = useContratante();

  const contratante = useSelector((state: RootState) => state.contratante);
  const new_contratante = useSelector((state: RootState) => state.new_contratante);
  useEffect(() => {
    getListContratantes();
    getContratatesOptions();
  }, []);
  return (
    <Scaffold>
      <ContratanteContainer>
        <InsideMenu title={"Contratante"} icon={"domain"}>
          <OpecaoesInsideMenu />
        </InsideMenu>
        <div className="main_content">
          <ContratanteHeader>
            <div className="">
              <Heading3 fontWeight={600} color={billing_theme.pallete.tertiary?.A400}>
                Contratantes
              </Heading3>
              <Body2 color={billing_theme.pallete.tertiary?.A400}>
                Gestão de todos os servicos na plataforma.
              </Body2>
            </div>

            <PrimaryButton
              label={"Novo contratante"}
              leftIcon={"domain_add"}
              isSmall
              onTap={() => dispatch(setModalNewContratante(true))}
            />
          </ContratanteHeader>
          {contratante.isError && <ServicoErrorContent retry={() => getListContratantes()} />}

          {!contratante.isError && <ServicoFilter />}

          {!contratante.isError &&
            (contratante.loading ? <SkeletonServicoList /> : <ServicoList />)}
        </div>
      </ContratanteContainer>

      <ModalBilling modalIsOpen={new_contratante.isOpen} width="800px" height="auto">
        <ModalNewContratante />
      </ModalBilling>
    </Scaffold>
  );
};

export default ContratantePage;
