import styled from "styled-components";

export const ButtonIcon = styled.div<{ $cursor: boolean }>`
  border: none;
  background-color: transparent;
  width: auto;
  padding: 0px;
  cursor: ${props => (props.$cursor ? "pointer" : "auto")};
  display: flex;
  align-items: center;
  justify-content: center;
`;
