import { ContractSummaryContent, SummaryGrid } from "./style";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "store/store";
import SummryField from "./components/summary_field";
import SummryTitle from "./components/summary_title";
import { toMoney, toPercent } from "utils/numberUtils";
import SummryAboveField from "./components/summary_above_field";
import moment from "moment";
import { toDate } from "utils/stringUtils";
import PrimaryButton from "components/button/billing_button";
import { ButtonContent } from "view/Contrato/page/ViewContrato/style";
import useEditContrato from "hooks/contrato/useEditContrato";

const ContractSummary: React.FC<{}> = () => {
  const { updateContrato } = useEditContrato();
  const state = useSelector((state: RootState) => state.view_contrato);
  const kit = useSelector((state: RootState) => state.kit);
  const servico = useSelector((state: RootState) => state.servico);
  const list_enum = useSelector((state: RootState) => state.list_enum);
  const contratante = useSelector((state: RootState) => state.contratante);
  const contratada = useSelector((state: RootState) => state.contratada);
  const getServicoName = (id: string) => {
    return servico.options.find(el => el.value == id)?.label;
  };
  const getKitName = (id: string) => {
    return kit.options.find(el => el.value == id)?.label ?? "";
  };
  const getContratanteName = (id: string) => {
    return contratante.options.find(el => el.value == id)?.label ?? "";
  };
  const getContratadaName = (id: string) => {
    return contratada.options.find(el => el.value == id)?.label ?? "";
  };
  return (
    <ContractSummaryContent>
      <SummryTitle title={"Dados de contrato"} />
      <SummaryGrid gridnumber={2}>
        <SummryField
          title={"Contratante"}
          content={getContratanteName(state.Contratante_ID?.toString()!)}
        />
        <SummryField
          title={"Contratado"}
          content={getContratadaName(state.Contratada_ID?.toString()!)}
        />
        <SummryField title={"Kit"} content={getKitName(state.KitPrincipal_ID?.toString()!)} />
        <SummryField title={"Quantidade"} content={state.Quantidade} />
      </SummaryGrid>
      <SummryTitle title={"Valores"} />
      <SummryField title={"Mensalidade"} content={toMoney(state.Mensalidade!)} />
      <SummaryGrid gridnumber={2}>
        <SummryField title={"Reajustável"} content={toMoney(state.ValorReajustavel)} />
        <SummryField title={"Não reajustável"} content={toMoney(state.ValorNaoReajustavel)} />
      </SummaryGrid>

      <SummryTitle title={"Vigência"} />
      <SummaryGrid gridnumber={2}>
        <SummryField
          title={"Duração"}
          content={moment().add(state.DuracaoMeses, "months").fromNow(true)}
        />
        <SummryField title={"Data de processamento"} content={toMoney(state.Desinstalacao)} />
        <SummryField
          title={"Data ínicio"}
          content={toDate(moment().add(state.CarenciaDias, "days").format())}
        />
        <SummryField
          title={"Data final"}
          content={toDate(moment().add(state.DuracaoMeses, "months").format())}
        />
        <SummryField
          title={"Carência"}
          content={
            state.CarenciaDias == 1 ? state.CarenciaDias + " Dia" : state.CarenciaDias + " Dias"
          }
        />
        <SummryField
          title={"Fidelidade"}
          content={
            state.FidelidadeMeses == 1
              ? state.FidelidadeMeses + " Mês"
              : state.FidelidadeMeses + " Meses"
          }
        />
      </SummaryGrid>

      <SummryTitle title={"Taxas e reajustes"} />
      <SummryField
        title={"Índice de reajuste"}
        content={
          list_enum.IndiceReajusteOptions?.find(el => Number(el.value) === state.Indice)?.label
        }
      />

      <SummryTitle title={"Descontos"} />
      <SummaryGrid gridnumber={2}>
        <SummryField title={"Desconto"} content={toPercent(state.PercentualDesconto)} />
        <SummryField
          title={"Meses"}
          content={
            state.MesesDesconto == 1 ? state.MesesDesconto + " Mês" : state.MesesDesconto + " Meses"
          }
        />
      </SummaryGrid>
      <SummryTitle title={"Valores técnicos"} />
      <SummaryGrid gridnumber={3}>
        <SummryField title={"Instalação"} content={toMoney(state.Instalacao)} />
        <SummryField title={"Desinstalação"} content={toMoney(state.Desinstalacao)} />
        <SummryField title={"Transferência"} content={toMoney(state.Transferencia)} />
      </SummaryGrid>
      <SummaryGrid gridnumber={2}>
        <SummryField title={"Pró rata instalação"} content={state.ProRataInicio ? "Sim" : "Não"} />
        <SummryField
          title={"Pró rata desinstalação"}
          content={state.ProRataDesinstalacao ? "Sim" : "Não"}
        />
      </SummaryGrid>
      {state.RegrasInstalacao?.length != 0 && (
        <>
          <SummryTitle title={"Regras e ajustes"} />
          {state.RegrasInstalacao?.map((el, i) => (
            <>
              <SummryAboveField title={`Regra: ${i}`} />
              <SummaryGrid gridnumber={2}>
                <SummryField title={"Estado"} content={el.Estado} />
                <SummryField title={"Cidade"} content={el.Cidade} />
              </SummaryGrid>
              <SummaryGrid gridnumber={3}>
                <SummryField title={"Quant./dia útil"} content={el.InstaDia} />
                <SummryField title={"Tec. próprios"} content={el.DescProprio + "%"} />
                <SummryField title={"Tec. Terceiros"} content={el.DescExterna + "%"} />
              </SummaryGrid>
              <SummaryGrid gridnumber={3}>
                <SummryField title={"Instalação"} content={el.Instalacao ? "Sim" : "Não"} />
                <SummryField title={"Desinstalação"} content={el.Desinstalacao ? "Sim" : "Não"} />
                <SummryField title={"Transferência"} content={el.Transferencia ? "Sim" : "Não"} />
              </SummaryGrid>
            </>
          ))}
        </>
      )}
      <SummryTitle title={"Kits Complementares"} />

      <SummaryGrid
        gridnumber={state.KitsComplementares?.length! > 3 ? 4 : state.KitsComplementares?.length!}
      >
        {state.KitsComplementares?.map((el, i) => (
          <>
            <SummryField title={"Kit"} content={getKitName(el.Kit_ID?.toString()!)} />
          </>
        ))}
      </SummaryGrid>
      <SummryTitle title={"Serviços"} />
      <SummaryGrid gridnumber={state.Servicos?.length! > 3 ? 4 : state.Servicos?.length!}>
        {state.Servicos?.map((el, i) => (
          <>
            <SummryField title={"Serviço"} content={getServicoName(el.Servico_ID?.toString()!)} />
          </>
        ))}
      </SummaryGrid>
      <ButtonContent>
        <PrimaryButton onTap={updateContrato} label={"Atualizar contrato"} />
      </ButtonContent>
    </ContractSummaryContent>
  );
};

export default ContractSummary;
