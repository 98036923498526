import { useEffect, useState } from "react";
import {
  ContratoListButton,
  ContratoListContent,
  ContratoListHeader,
  ContratoListIcon,
  ContratoListItems,
  ContratoListItensContainer,
} from "./style";
import BillingIconRounded from "components/billing_icon/rounded_icon";
import billing_theme from "style/theme_colors";
import { Body3 } from "components/text/body/style";
import { ContratoModelList } from "shared/models/contratos_response.model";
import useContrato from "hooks/contrato/useContrato";
import React from "react";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "store/store";
import { Heading6 } from "components/text/heading/style";
import { useNavigate } from "react-router-dom";
import ContratoDeleteContent from "../contrato_delete_modal";
import ModalBilling from "components/modal";
import Pagination from "components/pagination";
import { setContratoPageIndex } from "store/contrato/contrato_slice";
import { toDate, toShortText } from "utils/stringUtils";

const ContrantoList: React.FC<{}> = () => {
  const dispatch = useAppDispatch();
  const contrato = useSelector((state: RootState) => state.contrato);
  const navigate = useNavigate();
  const {
    filterContrato,
    filterContratoReverse,
    deleteContratoModal,
    setDeleteContratoModal,
    isLoadingDelete,
    setDeleteIddeleteIdContrato,
    deleteModal,
    setPagedContrato,
  } = useContrato();
  const [filter, setFilter] = useState("");
  const [data, setData] = useState<ContratoModelList[]>([]);
  const [filterState, setFilterState] = useState("normal");
  const [deletContratoNumber, setdeletContratoNumber] = useState("");

  const selectFilter = (id: string, index: number) => {
    if (filter != id) setFilterState("normal");

    if (filterState == "normal") {
      filterContrato(index);
      setFilterState("reverse");
    } else {
      filterContratoReverse(index);
      setFilterState("normal");
    }
    setFilter(id);
    contrato.filterList.length == 0 ? setData(contrato.list) : setData(contrato.filterList);
  };

  useEffect(() => {
    contrato.filterList.length == 0 ? setData(contrato.list) : setData(contrato.filterList);
  });

  return (
    <ContratoListContent>
      <ContratoListHeader>
        <ListButton
          label="Numero"
          filterState={filterState}
          click={() => selectFilter("Numero", 2)}
          selectedFilter={filter}
        />
        <ListButton
          label="Aditivo"
          filterState={filterState}
          click={() => selectFilter("Aditivo", 3)}
          selectedFilter={filter}
        />
        <ListButton
          label="Contratante"
          filterState={filterState}
          click={() => selectFilter("Contratante", 4)}
          selectedFilter={filter}
        />
        <ListButton
          label="Contratado"
          filterState={filterState}
          click={() => selectFilter("Contratado", 5)}
          selectedFilter={filter}
        />
        <ListButton
          label="Vigencia"
          filterState={filterState}
          click={() => selectFilter("Contratante", 4)}
          selectedFilter={filter}
        />
        <ListButton
          label="Fim da vigencia"
          filterState={filterState}
          click={() => selectFilter("Contratado", 5)}
          selectedFilter={filter}
        />
        <ListButton
          label="Ações"
          filterState={filterState}
          click={() => {}}
          selectedFilter={filter}
        />
      </ContratoListHeader>
      {setPagedContrato(data, contrato.pageIndex, 15).map(item => (
        <ContratoListItensContainer key={item.ID}>
          <ListItens label={item.Numero!} />
          <ListItens label={item.Aditivo!.toString()} />
          <ListItens label={item.Contratante!} />
          <ListItens label={item.Contratada!} />
          <ListItens label={item.VigenciaInicio ? toDate(item.VigenciaInicio!) : "--"} />
          <ListItens label={item.VigenciaFim! ? toDate(item.VigenciaFim!) : "--"} />
          <ContratoListIcon>
            <BillingIconRounded
              icon={"edit"}
              color={billing_theme.pallete.warning.A100}
              onTap={() => navigate("/contrato/editar_contrato/" + item.ID)}
              size={20}
            />
            <BillingIconRounded
              icon={"visibility"}
              color={billing_theme.pallete.tertiary.A400}
              onTap={() => navigate("/contrato/view_contrato/" + item.ID)}
              size={20}
            />
            <BillingIconRounded
              icon={"delete"}
              color={billing_theme.pallete.error.A100}
              size={20}
              onTap={() => {
                setdeletContratoNumber(item.Numero!);
                setDeleteIddeleteIdContrato(item.ID!.toString());
                setDeleteContratoModal(true);
              }}
            />
          </ContratoListIcon>
        </ContratoListItensContainer>
      ))}
      <ModalBilling modalIsOpen={deleteContratoModal!} width="500px" height="200px">
        <ContratoDeleteContent
          onClose={() => setDeleteContratoModal(false)}
          onDelete={() => deleteModal()}
          onLoading={isLoadingDelete!}
          Numero={deletContratoNumber!}
        />
      </ModalBilling>
      <Pagination
        index={contrato.pageIndex + 1}
        firstPage={1}
        lastPage={contrato.lastPage!}
        goToFirst={() => {
          dispatch(setContratoPageIndex(0));
        }}
        goToLast={() => {
          dispatch(setContratoPageIndex(contrato.lastPage! - 1));
        }}
        next={() => {
          dispatch(setContratoPageIndex(contrato.pageIndex + 1));
        }}
        back={() => {
          dispatch(setContratoPageIndex(contrato.pageIndex - 1));
        }}
      />
    </ContratoListContent>
  );
};

const ListButton: React.FC<{
  label: string;
  click: () => void;
  selectedFilter: string;
  filterState: string;
}> = ({ label, click, selectedFilter, filterState }) => {
  return (
    <ContratoListButton onClick={() => click()}>
      <Heading6 fontWeight={600} color={billing_theme.pallete.tertiary.A400}>
        {label}
      </Heading6>
      {selectedFilter == label && (
        <BillingIconRounded
          icon={filterState == "normal" ? "expand_less" : "expand_more"}
          color={billing_theme.pallete.tertiary.A400}
        />
      )}
    </ContratoListButton>
  );
};

const ListItens: React.FC<{ label: string }> = ({ label }) => {
  return (
    <ContratoListItems>
      <Body3 fontWeight={600} color={billing_theme.pallete.tertiary.A400} textalign="center">
        {toShortText(label, 20)}
      </Body3>
    </ContratoListItems>
  );
};

export default ContrantoList;
