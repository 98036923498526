import LineSkeleton from "components/skeleton/line_skeleton";
import {
  InstalacaoListButton,
  InstalacaoListContent,
  InstalacaoListHeader,
  SkeletonInstalacaoListItensContainer,
} from "./style";
import React from "react";
const SkeletonInstalacaoList: React.FC<{}> = () => {
  const array = ["", "", "", "", "", "", "", ""];
  return (
    <InstalacaoListContent>
      <InstalacaoListHeader>
        {array.map((_, i) => (
          <LineSkeleton height={30} width={"100%"} key={i} />
        ))}
      </InstalacaoListHeader>

      <SkeletonInstalacaoListItensContainer>
        <LineSkeleton height={58} width={"100%"} />
      </SkeletonInstalacaoListItensContainer>
      <SkeletonInstalacaoListItensContainer>
        <LineSkeleton height={58} width={"100%"} />
      </SkeletonInstalacaoListItensContainer>
      <SkeletonInstalacaoListItensContainer>
        <LineSkeleton height={58} width={"100%"} />
      </SkeletonInstalacaoListItensContainer>
      <SkeletonInstalacaoListItensContainer>
        <LineSkeleton height={58} width={"100%"} />
      </SkeletonInstalacaoListItensContainer>
      <SkeletonInstalacaoListItensContainer>
        <LineSkeleton height={58} width={"100%"} />
      </SkeletonInstalacaoListItensContainer>
      <SkeletonInstalacaoListItensContainer>
        <LineSkeleton height={58} width={"100%"} />
      </SkeletonInstalacaoListItensContainer>
    </InstalacaoListContent>
  );
};

const SkeletonListButton: React.FC = () => {
  return (
    <InstalacaoListButton>
      <LineSkeleton height={30} width={90} />
    </InstalacaoListButton>
  );
};

export default SkeletonInstalacaoList;
