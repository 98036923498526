import billing_theme from "style/theme_colors";
import { Heading5, Heading6 } from "components/text/heading/style";
import { Body4 } from "components/text/body/style";
import { ContratoInsideContent, ContratoDataGridTwo } from "../style";
import { EditContratoInputLabel } from "../../../style";
import { RootState, useAppDispatch } from "store/store";
import {
  setProRataDesinstacao,
  setProRataInstacao,
  setMesesDesconto,
  setPercentualDescontoViewContrato,
} from "store/contrato/view_contrato_slice";
import BillingNumberInput from "components/input/number_input";
import BillingCheckbox from "components/input/checkbox";
import { useSelector } from "react-redux";
import { toPercent } from "utils/numberUtils";

const DescontosEditContrato: React.FC<{}> = () => {
  const edit_contrato = useSelector((state: RootState) => state.view_contrato);
  const dispatch = useAppDispatch();

  return (
    <ContratoInsideContent>
      <EditContratoInputLabel>
        <Heading5 fontWeight={600} color={billing_theme.pallete.tertiary.A600}>
          Descontos
        </Heading5>
        <Body4 fontWeight={400} color={billing_theme.pallete.tertiary.A600}>
          Informe valores para descontos anuais sobre fidelizado
        </Body4>
      </EditContratoInputLabel>
      <ContratoDataGridTwo>
        <EditContratoInputLabel>
          <Heading6 fontWeight={600} color={billing_theme.pallete.tertiary.A600}>
            Desconto*
          </Heading6>
          <BillingNumberInput
            format="decimal"
            placeholder="Percentual de desconto"
            value={edit_contrato.PercentualDesconto}
            maximumFractionDigits={2}
            onNumberFormat={value => {
              dispatch(setPercentualDescontoViewContrato(value.number));
            }}
          />
        </EditContratoInputLabel>

        <EditContratoInputLabel>
          <Heading6 fontWeight={600} color={billing_theme.pallete.tertiary.A600}>
            Meses*
          </Heading6>
          <BillingNumberInput
            format="decimal"
            value={edit_contrato.MesesDesconto}
            placeholder="Meses desconto"
            onNumberFormat={value => {
              dispatch(setMesesDesconto(value.number));
            }}
          />
        </EditContratoInputLabel>
      </ContratoDataGridTwo>
      <div className="prorata">
        <BillingCheckbox
          defaultValue={edit_contrato.ProRataInicio}
          label="Aplicar pro rata da instalação."
          change={value => {
            try {
              dispatch(setProRataInstacao(value!));
            } catch (e) {
              console.log(e);
            }
          }}
        />
        <BillingCheckbox
          defaultValue={edit_contrato.ProRataDesinstalacao}
          label="Aplicar pro rata na desinstalação."
          change={value => {
            try {
              dispatch(setProRataDesinstacao(value!));
            } catch (e) {
              console.log(e);
            }
          }}
        />
      </div>
    </ContratoInsideContent>
  );
};

export default DescontosEditContrato;
