import PrimaryButton from "components/button/billing_button";
import TertiaryButton from "components/button/tertiaty_button";
import { Body2 } from "components/text/body/style";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "store/store";
import billing_theme from "style/theme_colors";
import {
  ViewContratadaContent,
  ViewContratadaDetails,
  ViewContratadaGrid,
  ViewContratadaGridTwo,
  ViewContratoCep,
  ViewContratoRazaoCnpj,
} from "./style";
import BillingTextInput from "components/input/text_input";
import LineSkeleton from "components/skeleton/line_skeleton";
import { setModalContratada } from "store/contratada/view_contratada_slice";
import useViewContratada from "hooks/contratada/useEditContratada";

const ModalViewContratada: React.FC = () => {
  const dispatch = useAppDispatch();
  const state = useSelector((state: RootState) => state.view_contratada);
  const { getContratada, updateContratada } = useViewContratada();
  const [razao, setRazao] = useState<string>();
  const [cnpj, setCpnj] = useState<string>();
  const [telefone, setTelefone] = useState<string>();
  const [email, setEmail] = useState<string>();
  const [logradouro, setLogradouro] = useState<string>();
  const [numero, setNumero] = useState<string>();
  const [complemento, setComplemento] = useState<string>();
  const [bairro, setBairro] = useState<string>();
  const [cidade, setCidade] = useState<string>();
  const [uf, setUf] = useState<string>();
  const [cep, setCEP] = useState<string>();

  useEffect(() => {
    getContratada(state.selectedId!);
  }, []);

  return (
    <ViewContratadaContent>
      <ContratadaInfo
        razao={value => {
          setRazao(value);
        }}
        CNPJ={value => {
          setCpnj(value);
        }}
        email={value => {
          setEmail(value);
        }}
        telefone={value => {
          setTelefone(value);
        }}
      />

      <ViewContratoCep>
        <ViewContratadaDetails>
          <Body2 fontWeight={600} color={billing_theme.pallete.tertiary?.A400}>
            CEP:
          </Body2>

          {state.loading ? (
            <LineSkeleton width={"100%"} height={39} />
          ) : (
            <BillingTextInput
              placeholder="CEP"
              mask={"99.999-999"}
              value={state.contratada?.CEP}
              change={value => {
                setCEP(value);
              }}
            />
          )}
        </ViewContratadaDetails>

        <ViewContratadaDetails>
          <Body2 fontWeight={600} color={billing_theme.pallete.tertiary?.A400}>
            Logradouro:
          </Body2>

          {state.loading ? (
            <LineSkeleton width={"100%"} height={39} />
          ) : (
            <BillingTextInput
              placeholder="Logradouro"
              value={state.contratada?.Logradouro}
              change={value => {
                setLogradouro(value);
              }}
            />
          )}
        </ViewContratadaDetails>
      </ViewContratoCep>

      <ViewContratadaGridTwo>
        <ViewContratadaDetails>
          <Body2 fontWeight={600} color={billing_theme.pallete.tertiary?.A400}>
            Cidade:
          </Body2>

          {state.loading ? (
            <LineSkeleton width={"100%"} height={39} />
          ) : (
            <BillingTextInput
              placeholder="Cidade"
              value={state.contratada?.Cidade}
              change={value => {
                setCidade(value);
              }}
            />
          )}
        </ViewContratadaDetails>

        <ViewContratadaDetails>
          <Body2 fontWeight={600} color={billing_theme.pallete.tertiary?.A400}>
            Bairro:
          </Body2>

          {state.loading ? (
            <LineSkeleton width={"100%"} height={39} />
          ) : (
            <BillingTextInput
              placeholder="Bairro"
              value={state.contratada?.Bairro}
              change={value => {
                setBairro(value);
              }}
            />
          )}
        </ViewContratadaDetails>
      </ViewContratadaGridTwo>

      <ViewContratadaGrid>
        <ViewContratadaDetails>
          <Body2 fontWeight={600} color={billing_theme.pallete.tertiary?.A400}>
            Numero:
          </Body2>

          {state.loading ? (
            <LineSkeleton width={"100%"} height={39} />
          ) : (
            <BillingTextInput
              placeholder="Numero"
              value={state.contratada?.Numero}
              change={value => {
                setNumero(value);
              }}
            />
          )}
        </ViewContratadaDetails>

        <ViewContratadaDetails>
          <Body2 fontWeight={600} color={billing_theme.pallete.tertiary?.A400}>
            Complemento:
          </Body2>

          {state.loading ? (
            <LineSkeleton width={"100%"} height={39} />
          ) : (
            <BillingTextInput
              placeholder="Complemento"
              value={state.contratada?.Complemento}
              change={value => {
                setComplemento(value);
              }}
            />
          )}
        </ViewContratadaDetails>

        <ViewContratadaDetails>
          <Body2 fontWeight={600} color={billing_theme.pallete.tertiary?.A400}>
            UF:
          </Body2>

          {state.loading ? (
            <LineSkeleton width={"100%"} height={39} />
          ) : (
            <BillingTextInput
              placeholder="UF"
              value={state.contratada?.UF}
              change={value => {
                setUf(value);
              }}
            />
          )}
        </ViewContratadaDetails>
      </ViewContratadaGrid>

      <div className="button">
        <TertiaryButton
          label={"Cancelar"}
          onTap={() => dispatch(setModalContratada(false))}
          isSmall
        />
        <PrimaryButton
          disable={state.loading}
          label={"Editar"}
          onTap={async () => {
            await updateContratada({
              ID: state.contratada?.ID,
              EmpresaPrincipal_ID: state.contratada?.EmpresaPrincipal_ID
                ? Number(state.contratada?.EmpresaPrincipal_ID)
                : null,
              RazaoSocial: razao ?? state.contratada?.RazaoSocial,
              Telefone: telefone ?? state.contratada?.Telefone,
              CEP: cep ?? state.contratada?.CEP,
              CNPJ: cnpj ?? state.contratada?.CNPJ,
              Complemento: complemento ?? state.contratada?.Complemento,
              Cidade: cidade ?? state.contratada?.Cidade,
              Email: email ?? state.contratada?.Email,
              Logradouro: logradouro ?? state.contratada?.Logradouro,
              Numero: numero ?? state.contratada?.Numero,
              UF: uf ?? state.contratada?.UF,
              Bairro: bairro ?? state.contratada?.Bairro,
            });
          }}
          isSmall
        />
      </div>
    </ViewContratadaContent>
  );
};

const ContratadaInfo: React.FC<{
  razao: (value: string | undefined) => void;
  CNPJ: (value: string | undefined) => void;
  email: (value: string | undefined) => void;
  telefone: (value: string | undefined) => void;
}> = ({ razao, CNPJ, email, telefone }) => {
  const state = useSelector((state: RootState) => state.view_contratada);

  return (
    <ViewContratadaContent>
      <ViewContratoRazaoCnpj>
        <ViewContratadaDetails>
          <Body2 fontWeight={600} color={billing_theme.pallete.tertiary?.A400}>
            Razão social:
          </Body2>
          {state.loading ? (
            <LineSkeleton width={"100%"} height={39} />
          ) : (
            <BillingTextInput
              placeholder="Razão social"
              value={state.contratada?.RazaoSocial}
              change={razao}
            />
          )}
        </ViewContratadaDetails>
        <ViewContratadaDetails>
          <Body2 fontWeight={600} color={billing_theme.pallete.tertiary?.A400}>
            CNPJ:
          </Body2>
          {state.loading ? (
            <LineSkeleton width={"100%"} height={39} />
          ) : (
            <BillingTextInput
              placeholder="CNPJ"
              mask="99.999.999/9999-99"
              value={state.contratada?.CNPJ}
              change={CNPJ}
            />
          )}
        </ViewContratadaDetails>
      </ViewContratoRazaoCnpj>
      <ViewContratoRazaoCnpj>
        <ViewContratadaDetails>
          <Body2 fontWeight={600} color={billing_theme.pallete.tertiary?.A400}>
            Email:
          </Body2>

          {state.loading ? (
            <LineSkeleton width={"100%"} height={39} />
          ) : (
            <BillingTextInput placeholder="Email" value={state.contratada?.Email} change={email} />
          )}
        </ViewContratadaDetails>
        <ViewContratadaDetails>
          <Body2 fontWeight={600} color={billing_theme.pallete.tertiary?.A400}>
            Telefone:
          </Body2>

          {state.loading ? (
            <LineSkeleton width={"100%"} height={39} />
          ) : (
            <BillingTextInput
              placeholder="Telefone"
              mask="(99) 99999-9999"
              value={state.contratada?.Telefone}
              change={telefone}
            />
          )}
        </ViewContratadaDetails>
      </ViewContratoRazaoCnpj>
    </ViewContratadaContent>
  );
};

export default ModalViewContratada;
