import { KitContainer, KitHeader } from "./style";
import { Heading3 } from "components/text/heading/style";
import { Body2 } from "components/text/body/style";
import billing_theme from "style/theme_colors";
import Scaffold from "components/scaffold";
import PrimaryButton from "components/button/billing_button";
import { useEffect } from "react";
import InsideMenu from "components/insidemenu";
import InsideMenuButton from "components/insidemenu/components/inside_menu_button";
import KitList from "./components/kit_list";
import useKit from "hooks/kit/useKit";
import SkeletonKitList from "./components/kit_skeleton";
import KitErrorContent from "./components/kit_list_error";
import KitFilter from "./components/kit_filter";
import { useNavigate } from "react-router-dom";
import { RootState } from "store/store";
import { useSelector } from "react-redux";
import OpecaoesInsideMenu from "components/insidemenu/components/opecaoes_menu";

const KitPage: React.FC = () => {
  const navigate = useNavigate();
  const { getKit, isErrorKit } = useKit();
  const kit = useSelector((state: RootState) => state.kit);
  useEffect(() => {
    getKit();
  }, []);
  return (
    <Scaffold>
      <KitContainer>
        <InsideMenu title={"Kit"} icon={"home_repair_service"}>
          <OpecaoesInsideMenu />
        </InsideMenu>
        <div className="main_content">
          <KitHeader>
            <div className="">
              <Heading3 fontWeight={600} color={billing_theme.pallete.tertiary?.A400}>
                Kit
              </Heading3>
              <Body2 color={billing_theme.pallete.tertiary?.A400}>
                Listagem de kits cadastrados na plataforma.
              </Body2>
            </div>

            <PrimaryButton
              label={"Novo kit"}
              leftIcon={"add"}
              isSmall
              onTap={() => navigate("/operacoes/kit/novo")}
            />
          </KitHeader>

          {isErrorKit && <KitErrorContent retry={() => getKit()} />}
          {!isErrorKit && <KitFilter />}
          {!isErrorKit && (kit.loading ? <SkeletonKitList /> : <KitList />)}
        </div>
      </KitContainer>
    </Scaffold>
  );
};

export default KitPage;
