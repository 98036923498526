import BillingTextInput from "components/input/text_input";
import React from "react";
import { SearchContent, ContratadaFilterContent, SearchInputContent } from "./style";
import { Heading6 } from "components/text/heading/style";
import billing_theme from "style/theme_colors";
import useContratada from "hooks/contratada/useContratada";
const ContratadaFilter: React.FC<{}> = () => {
  const {} = useContratada();
  return (
    <ContratadaFilterContent>
      <SearchContent>
        <SearchInputContent>
          <Heading6 fontWeight={600} color={billing_theme.pallete.tertiary.A400}>
            Busque pelo nome:
          </Heading6>
          <BillingTextInput
            width={280}
            placeholder="Digite a razão social:"
            change={value => console.log(value!)}
          />
        </SearchInputContent>
      </SearchContent>
    </ContratadaFilterContent>
  );
};

export default ContratadaFilter;
