import { useAuth } from "hooks/useAuth";
import { useEffect } from "react";
import { Navigate, useLocation } from "react-router-dom";
import HomePage from "view/Home";

export const PrivateRoute: React.FC<{ children: any }> = ({ children }) => {
  const { isAuth, userLoading, verifyReload } = useAuth();

  let location = useLocation();

  useEffect(() => {
    verifyReload();
  }, [location.pathname]);

  return isAuth() ? children : <Navigate to="/" />;
};
