import billing_theme from "style/theme_colors";
import { Heading5, Heading6 } from "components/text/heading/style";
import { Body4 } from "components/text/body/style";
import { ContratoInsideContent, ContratoDataGridTwo } from "../style";
import { NovoContratoInputLabel } from "../../../style";
import { RootState, useAppDispatch } from "store/store";
import {
  setProRataDesinstacao,
  setProRataInstacao,
  setMesesDesconto,
  setPercentualDescontoNewContrato,
} from "store/contrato/new_contrato_slice";
import BillingNumberInput from "components/input/number_input";
import BillingCheckbox from "components/input/checkbox";
import { useSelector } from "react-redux";
import { toPercent } from "utils/numberUtils";

const DescontosNovoContrato: React.FC<{}> = () => {
  const novo_contrato = useSelector((state: RootState) => state.novo_contrato);
  const dispatch = useAppDispatch();

  return (
    <ContratoInsideContent>
      <NovoContratoInputLabel>
        <Heading5 fontWeight={600} color={billing_theme.pallete.tertiary.A600}>
          Descontos
        </Heading5>
        <Body4 fontWeight={400} color={billing_theme.pallete.tertiary.A600}>
          Informe valores para descontos anuais sobre fidelizado
        </Body4>
      </NovoContratoInputLabel>
      <ContratoDataGridTwo>
        <NovoContratoInputLabel>
          <Heading6 fontWeight={600} color={billing_theme.pallete.tertiary.A600}>
            Desconto*
          </Heading6>
          <BillingNumberInput
            value={novo_contrato.percentualDesconto}
            format="decimal"
            placeholder="Percentual de desconto"
            maximumFractionDigits={2}
            onNumberFormat={value => {
              dispatch(setPercentualDescontoNewContrato(value.number));
            }}
          />
        </NovoContratoInputLabel>

        <NovoContratoInputLabel>
          <Heading6 fontWeight={600} color={billing_theme.pallete.tertiary.A600}>
            Meses*
          </Heading6>
          <BillingNumberInput
            value={novo_contrato.mesesDesconto}
            format="decimal"
            placeholder="Meses desconto"
            onNumberFormat={value => {
              dispatch(setMesesDesconto(value.number));
            }}
          />
        </NovoContratoInputLabel>
      </ContratoDataGridTwo>
      <div className="prorata">
        <BillingCheckbox
          defaultValue={novo_contrato.proRataInicio}
          label="Aplicar pro rata da instalação."
          change={value => {
            try {
              dispatch(setProRataInstacao(value!));
            } catch (e) {
              console.log(e);
            }
          }}
        />
        <BillingCheckbox
          defaultValue={novo_contrato.proRataDesinstalacao}
          label="Aplicar pro rata na desinstalação."
          change={value => {
            try {
              dispatch(setProRataDesinstacao(value!));
            } catch (e) {
              console.log(e);
            }
          }}
        />
      </div>
    </ContratoInsideContent>
  );
};

export default DescontosNovoContrato;
