import LineSkeleton from "components/skeleton/line_skeleton";
import {
  VeiculoListButton,
  VeiculoListContent,
  VeiculoListHeader,
  SkeletonVeiculoListItensContainer,
} from "./style";
import React from "react";
const SkeletonVeiculoList: React.FC<{}> = () => {
  const array = ["", "", "", ""];
  return (
    <VeiculoListContent>
      <VeiculoListHeader>
        {array.map((el, i) => (
          <LineSkeleton height={30} width={150} key={i} />
        ))}
      </VeiculoListHeader>

      <SkeletonVeiculoListItensContainer>
        <LineSkeleton height={58} width={"100%"} />
      </SkeletonVeiculoListItensContainer>
      <SkeletonVeiculoListItensContainer>
        <LineSkeleton height={58} width={"100%"} />
      </SkeletonVeiculoListItensContainer>
      <SkeletonVeiculoListItensContainer>
        <LineSkeleton height={58} width={"100%"} />
      </SkeletonVeiculoListItensContainer>
      <SkeletonVeiculoListItensContainer>
        <LineSkeleton height={58} width={"100%"} />
      </SkeletonVeiculoListItensContainer>
      <SkeletonVeiculoListItensContainer>
        <LineSkeleton height={58} width={"100%"} />
      </SkeletonVeiculoListItensContainer>
      <SkeletonVeiculoListItensContainer>
        <LineSkeleton height={58} width={"100%"} />
      </SkeletonVeiculoListItensContainer>
    </VeiculoListContent>
  );
};

const SkeletonListButton: React.FC = () => {
  return (
    <VeiculoListButton>
      <LineSkeleton height={30} width={90} />
    </VeiculoListButton>
  );
};

export default SkeletonVeiculoList;
