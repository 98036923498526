import DadosDoContratoComp from "../contract_data/components/dados_do_contrato";
import DescontosNovoContrato from "../contract_data/components/descontos";
import MensalidadeNovoContrato from "../contract_data/components/mensalidade";
import RegrasDeNegocio from "../contract_data/components/regras_de_negocio";
import TaxasEReajustes from "../contract_data/components/taxas_e_juros";
import { ContratoDataContainer } from "./style";

const DadosDoContrato: React.FC<{}> = () => {
  return (
    <ContratoDataContainer>
      <DadosDoContratoComp />
      <MensalidadeNovoContrato />
      <RegrasDeNegocio />
      <TaxasEReajustes />
      <DescontosNovoContrato />
    </ContratoDataContainer>
  );
};

export default DadosDoContrato;
