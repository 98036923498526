import { Heading5, Heading6 } from "components/text/heading/style";
import {
  DeleteVeiculoButton,
  DeleteVeiculoContent,
  DeleteVeiculoHeader,
  VeiculoListErrorContent,
} from "./style";
import React from "react";
import billing_theme from "style/theme_colors";
import PrimaryButton from "components/button/billing_button";
import BillingIconSharp from "components/billing_icon/sharp_icon";
import { Body3 } from "components/text/body/style";

const VeiculoDeleteContent: React.FC<{
  onDelete: () => void;
  onClose: () => void;
  onLoading: boolean;
}> = ({ onDelete, onClose, onLoading }) => {
  const close = () => {
    onClose();
  };
  return (
    <VeiculoListErrorContent>
      <DeleteVeiculoHeader>
        <BillingIconSharp icon={"delete"} color={billing_theme.pallete.error.A100} size={32} />
        <BillingIconSharp
          icon={"close"}
          color={billing_theme.pallete.primary.A400}
          size={32}
          onTap={close}
        />
      </DeleteVeiculoHeader>
      <DeleteVeiculoContent>
        <Heading5 color={billing_theme.pallete.tertiary.A600} fontWeight={600}>
          Deseja excluir o empresa?
        </Heading5>
        <Body3 color={billing_theme.pallete.tertiary.A600} fontWeight={500}>
          Ao excluir essa empresa, os dados serão movidos para a lixeira e posteriormente poderá ser
          recuperado.
        </Body3>
      </DeleteVeiculoContent>
      <DeleteVeiculoButton>
        <PrimaryButton label={"Cancelar"} onTap={() => onClose()} isSmall disable={onLoading} />
        <PrimaryButton
          label={"Deletar mesmo assim"}
          onTap={() => onDelete()}
          isSmall
          disable={onLoading}
        />
      </DeleteVeiculoButton>
    </VeiculoListErrorContent>
  );
};

export default VeiculoDeleteContent;
