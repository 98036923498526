import { VeiculoHttp } from "gateways/repo/veiculo_http_client";
import {
  setLoadingNewVeiculo,
  setErrorNewVeiculo,
  setModalNewVeiculo,
} from "store/veiculo/new_veiculo_slice";
import { useAppDispatch } from "store/store";
import { PostVeiculoModel } from "shared/models/veiculo_response.model";
import { toastWarning } from "components/toast/billing_toast";
import useContratante from "hooks/contratante/useContratante";
import useVeiculo from "hooks/veiculo/useVeiculo";

const useNewVeiculo = () => {
  const http = new VeiculoHttp();
  const dispatch = useAppDispatch();
  const { getVeiculoList } = useVeiculo();
  const { getContratatesOptions } = useContratante();

  const getNewVeiculoInfo = async () => {
    dispatch(setLoadingNewVeiculo(true));
    dispatch(setErrorNewVeiculo(false));
    try {
      await getContratatesOptions();
    } catch (e) {
      toastWarning({ title: "Erro!" });
      dispatch(setErrorNewVeiculo(true));
    } finally {
      dispatch(setLoadingNewVeiculo(false));
    }
  };
  const addVeiculo = async (data: PostVeiculoModel) => {
    dispatch(setLoadingNewVeiculo(true));
    dispatch(setErrorNewVeiculo(false));
    try {
      await http.adVeiculo(data);
      getVeiculoList();
      dispatch(setModalNewVeiculo(false));
    } catch (e) {
      toastWarning({ title: "Erro ao adicionar!" });
      dispatch(setErrorNewVeiculo(true));
    } finally {
      dispatch(setLoadingNewVeiculo(false));
    }
  };
  return {
    addVeiculo,
    getNewVeiculoInfo,
  };
};
export default useNewVeiculo;
