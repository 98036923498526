import billing_theme from "style/theme_colors";
import styled from "styled-components";
import "../../../../../../../node_modules/@syncfusion/ej2-base/styles/material.css";
import "../../../../../../../node_modules/@syncfusion/ej2-buttons/styles/material.css";
import "../../../../../../../node_modules/@syncfusion/ej2-calendars/styles/material.css";
import "../../../../../../../node_modules/@syncfusion/ej2-dropdowns/styles/material.css";
import "../../../../../../../node_modules/@syncfusion/ej2-inputs/styles/material.css";
import "../../../../../../../node_modules/@syncfusion/ej2-navigations/styles/material.css";
import "../../../../../../../node_modules/@syncfusion/ej2-popups/styles/material.css";
import "../../../../../../../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css";
import "../../../../../../../node_modules/@syncfusion/ej2-notifications/styles/material.css";
import "../../../../../../../node_modules/@syncfusion/ej2-react-grids/styles/material.css";
export const EquipamentoFaturaContainer = styled.div`
  padding: 16px;
  .e-content {
    overflow-y: hidden !important;
    background: transparent;
  }

  td.e-rowcell.waringField {
    background: ${billing_theme.pallete.warning.A100};
    color: ${billing_theme.light.tertiary.A600}!important;
    font-weight: 600;
  }
  td.e-rowcell.waringField:hover {
    background: ${billing_theme.pallete.warning.A50};
  }
  td.e-rowcell.newItem {
    background: ${billing_theme.pallete.success.A100};
    color: ${billing_theme.light.tertiary.A25}!important;
    font-weight: 600;
  }
  td.e-rowcell.newItem:hover {
    background: ${billing_theme.pallete.success.A50};
  }
  .e-grid .e-groupcaption,
  .e-grid .e-indentcell,
  .e-grid .e-recordplusexpand,
  a.e-icons.e-gdiagonaldown.e-icon-gdownarrow {
    background: ${billing_theme.pallete.specific.T_25_50};
    color: ${billing_theme.pallete.tertiary.A600};
  }
  td.e-recordplusexpand,
  td.e-indentcell {
    border: none;
  }
  td.e-rowcell.removedItem {
    background: ${billing_theme.pallete.error.A100};
    color: ${billing_theme.light.tertiary.A25}!important;
    font-weight: 600;
  }
  td.e-rowcell.removedItem:hover {
    background: ${billing_theme.pallete.error.A50};
  }

  .e-grid {
    border: 0.5px solid ${billing_theme.pallete.tertiary.A100};
    background: transparent;
  }
  .e-grid .e-gridheader {
    background: transparent;
  }
  .e-gridheader.e-lib.e-draggable.e-droppable {
    background: transparent;
  }
  .e-headercontent {
    border-right-width: 1px;
    background: transparent;
  }
  .e-grid .e-headercontent {
    border-color: transparent;
  }
  .e-grid .e-table {
    background-color: transparent;
  }
  th.e-headercell.e-fltr-icon.e-mousepointer.e-focus {
    background: ${billing_theme.pallete.primary?.A200};
  }
  .e-grid .e-headercell,
  .e-grid .e-detailheadercell {
    background-color: ${billing_theme.pallete.specific?.T_25_50};
    border-color: ${billing_theme.pallete.tertiary.A100};
  }
  .td.e-rowcell.e-hide {
    background-color: ${billing_theme.pallete.specific?.T_25_50};
  }
  .e-grid .e-rowcell {
    border-style: solid;
    border-color: ${billing_theme.pallete.tertiary?.A100};
    color: ${billing_theme.pallete.tertiary?.A600} !important;
  }
  span.e-headertext {
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px;
    color: ${billing_theme.pallete.tertiary?.A600};
  }
  th.e-headercell.e-fltr-icon.e-mousepointer {
    height: 40px;
  }
  .e-grid .e-rowcell {
    border-style: solid;
    border-color: ${billing_theme.pallete.tertiary?.A100};
    border-width: 1px;
    border-left: none;
    border-top: none;
  }
  td.e-rowcell {
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;

    color: ${billing_theme.pallete.tertiary?.A600};
  }

  tr.e-row {
    height: 40px;
    background-color: ${billing_theme.pallete.specific?.T_50_25};
  }

  .e-gridheader.e-lib.e-draggable.e-droppable {
    margin: 0px 0px;
    border: none;
  }
  .e-input-group.e-search,
  input#Grid_searchbar {
    background-color: ${billing_theme.pallete.specific?.T_50_25}!important;
  }
  input#Grid_searchbar,
  span#Grid_searchbutton {
    color: ${billing_theme.pallete.tertiary?.A600};
  }
  input#Grid_searchbar::placeholder {
    color: ${billing_theme.pallete.tertiary?.A600};
  }
  .e-grid .e-grouptopleftcell {
    background-color: transparent;
  }
  .e-grid .e-groupdroparea {
    background-color: ${billing_theme.pallete.specific?.T_25_50};
  }
  div#Grid_toolbarItems {
    background-color: ${billing_theme.pallete.specific?.T_25_50};
    border-bottom: 0.5px solid ${billing_theme.pallete.tertiary?.A100};
    border-top: 0.5px solid ${billing_theme.pallete.tertiary?.A100};
  }
  .e-input-group.e-search {
    background: #fff;
    border-radius: 8px;
    padding: 0px 12px;
  }

  .e-input-group.e-search::before,
  .e-input-group.e-search::after {
    background: ${billing_theme.pallete.primary?.A400};
  }

  input#Grid_SearchBox::before,
  input#Grid_SearchBox::after {
  }
  input.e-ccsearch.e-cc.e-input::before input.e-ccsearch.e-cc.e-input::after {
    background: ${billing_theme.pallete.primary?.A400};
  }
  button#Grid_excelexport {
    width: 146px;
    border: 0.5px solid ${billing_theme.pallete.tertiary.A100};
    border-radius: 4px;
    background-color: ${billing_theme.pallete.specific.T_50_25};
  }
  button#Grid_columnchooser {
    border: 0.5px solid ${billing_theme.pallete.tertiary.A100};
    border-radius: 4px;
    background-color: ${billing_theme.pallete.specific.T_50_25};
  }
  .e-grid .e-headercelldiv {
    display: block;
    font-size: 14px;
    font-weight: 400;
    height: 40px;
    line-height: 30px;
    overflow: hidden;
    text-transform: none;
    user-select: none;
    text-align: center;
  }
  .e-grid .e-gridheader .e-sortfilter .e-headercelldiv {
    padding: 0;
  }
  .e-grid .e-gridheader .e-sortfilter .e-fltr-icon .e-headercelldiv {
    margin: -4px 15px -15px -7px;
  }
  th.e-headercell.e-sort-icon.e-fltr-icon.e-mousepointer {
    border-bottom-width: 1px;
  }
  .e-grid th.e-headercell[aria-sort="ascending"] .e-headertext {
    color: ${billing_theme.pallete.tertiary.A600};
  }
  .e-grid.e-resize-lines .e-headercell .e-rhandler {
    border-color: ${billing_theme.pallete.tertiary.A100};
  }
  span.e-tbar-btn-text,
  .e-toolbar .e-toolbar-item .e-tbar-btn .e-icons {
    color: ${billing_theme.pallete.tertiary.A600}!important;
    font-weight: 500;
  }
  .e-grid .e-groupdroparea {
    font-size: 14px;
    opacity: 1;
    padding: 0px 0px 0px 16px;
    text-align: left;
    text-indent: 1pt;
    -webkit-user-select: none;
    user-select: none;
    width: 100%;
    border-radius: 0px;
    border: none;
    font-weight: 600;
    display: flex;
    align-items: center;
    margin-bottom: 0px;
    border-bottom: 0.5px solid ${billing_theme.pallete.tertiary.A100};
    color: ${billing_theme.pallete.tertiary.A600};
  }
  .e-grid .e-icon-descending::before,
  .e-grid .e-icon-ascending::before {
    color: ${billing_theme.pallete.tertiary.A600};
  }
  .e-grid .e-icon-filter::before,
  .e-grid .e-icon-filter.e-filtered::before,
  .e-grid-menu .e-icon-filter::before,
  .e-grid-menu .e-icon-filter.e-filtered::before {
    color: ${billing_theme.pallete.tertiary.A600};
  }
  .e-groupdroparea.e-lib.e-droppable.e-draggable.e-grouped {
    border-bottom: 0.5px solid ${billing_theme.pallete.primary?.A200};
  }
  .e-grid .e-gridcontent {
    background-color: ${billing_theme.pallete.specific.T_50_25};
  }
  .e-gridheader.e-lib.e-draggable.e-droppable {
    padding-right: 0px !important;
  }
  a.e-link.e-numericitem.e-spacing.e-pager-default {
    color: ${billing_theme.pallete.tertiary.A600};
    height: 24px;
    width: 24px;
    background: transparent;
  }
  .e-pager div.e-parentmsgbar {
    color: ${billing_theme.pallete.tertiary.A600};
  }
  .e-pager .e-next.e-icons.e-icon-next.e-nextpagedisabled.e-disable,
  .e-pager .e-next.e-icons.e-icon-next.e-nextpage.e-pager-default,
  .e-last.e-icons.e-icon-last.e-lastpage.e-pager-default,
  .e-prev.e-icons.e-icon-prev.e-prevpagedisabled.e-disable,
  .e-first.e-icons.e-icon-first.e-firstpagedisabled.e-disable,
  .e-first.e-icons.e-icon-first.e-firstpage.e-pager-default,
  .e-prev.e-icons.e-icon-prev.e-prevpage.e-pager-default,
  .e-last.e-icons.e-icon-last.e-lastpagedisabled.e-disable {
    background: transparent;
    color: ${billing_theme.pallete.tertiary.A600};
  }
  .e-gridpager.e-control.e-pager.e-lib {
    background: transparent;
  }
  a.e-link.e-numericitem.e-spacing.e-currentitem.e-active {
    background: ${billing_theme.light.primary.A400};
  }
  .e-pager .e-pagercontainer {
    background-color: transparent;
    border-color: #e0e0e0;
  }

  td.e-rowcell.e-selectionbackground.e-active {
    background: ${billing_theme.pallete.specific.T_25_50};
  }
  td.e-rowcell:hover {
    background: ${billing_theme.pallete.specific.T_25_50};
  }
  .e-checkbox-wrapper .e-frame.e-check,
  .e-css.e-checkbox-wrapper .e-frame.e-check {
    background: ${billing_theme.pallete.specific.T_25_50};
  }
  .e-grid .e-toolbar-items .e-toolbar-item.e-search-wrapper {
    padding-bottom: 0px;
    padding-top: 0px;
    padding-right: 1px;
    padding-left: 12px;
  }
  .e-grid .e-toolbar-item.e-cc.e-ccdiv.e-cc-toolbar {
    margin-top: 0;
    padding: 0px 0 0;
  }
  td.e-lastrowcell {
    color: #fff;
  }
`;
