import PrimaryButton from "components/button/billing_button";
import TertiaryButton from "components/button/tertiaty_button";
import { Body2 } from "components/text/body/style";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "store/store";
import billing_theme from "style/theme_colors";
import { NewVeiculoContent, NewVeiculoDetails } from "./style";
import BillingTextInput from "components/input/text_input";
import { setModalNewVeiculo } from "store/veiculo/new_veiculo_slice";
import useNewVeiculo from "hooks/veiculo/useNewVeiculo";
import BillingSelectInput from "components/input/select";
import LineSkeleton from "components/skeleton/line_skeleton";

const ModalNewVeiculo: React.FC = () => {
  const dispatch = useAppDispatch();
  const { addVeiculo, getNewVeiculoInfo } = useNewVeiculo();

  const contratanteState = useSelector((state: RootState) => state.contratante);
  const state = useSelector((state: RootState) => state.new_veiculo);

  const [contratante, setContratante] = useState<number>();
  const [modelo, setModelo] = useState<string>();
  const [placa, setPlaca] = useState<string>();
  const [marca, setMarca] = useState<string>();

  useEffect(() => {
    getNewVeiculoInfo();
  }, []);
  return (
    <NewVeiculoContent>
      <NewVeiculoDetails>
        <Body2 fontWeight={600} color={billing_theme.pallete.tertiary?.A400}>
          Contratante:
        </Body2>
        {state.loading ? (
          <LineSkeleton width={"100%"} height={39} />
        ) : (
          <BillingSelectInput
            placeholder="Selecione um contratante"
            options={contratanteState.options!}
            onChange={val => {
              setContratante(Number(val.value));
            }}
          />
        )}
      </NewVeiculoDetails>
      <NewVeiculoDetails>
        <Body2 fontWeight={600} color={billing_theme.pallete.tertiary?.A400}>
          Modelo:
        </Body2>

        {state.loading ? (
          <LineSkeleton width={"100%"} height={39} />
        ) : (
          <BillingTextInput placeholder="Qual o modelo?" change={setModelo} />
        )}
      </NewVeiculoDetails>

      <NewVeiculoDetails>
        <Body2 fontWeight={600} color={billing_theme.pallete.tertiary?.A400}>
          Placa:
        </Body2>

        {state.loading ? (
          <LineSkeleton width={"100%"} height={39} />
        ) : (
          <BillingTextInput placeholder="Digite a placa" change={setPlaca} />
        )}
      </NewVeiculoDetails>
      <NewVeiculoDetails>
        <Body2 fontWeight={600} color={billing_theme.pallete.tertiary?.A400}>
          Marca:
        </Body2>
        {state.loading ? (
          <LineSkeleton width={"100%"} height={39} />
        ) : (
          <BillingTextInput placeholder="Qual a marca?" change={setMarca} />
        )}
      </NewVeiculoDetails>

      <div className="button">
        <TertiaryButton
          label={"Cancelar"}
          onTap={() => dispatch(setModalNewVeiculo(false))}
          isSmall
        />
        <PrimaryButton
          disable={state.loading}
          label={"Adicionar"}
          onTap={async () => {
            await addVeiculo({
              ID: 0,
              Placa: placa!,
              Modelo: modelo!,
              Marca: marca!,
              Contratante_ID: contratante!,
            });
          }}
          isSmall
        />
      </div>
    </NewVeiculoContent>
  );
};

export default ModalNewVeiculo;
