import { Route, Routes, createBrowserRouter } from "react-router-dom";
import ContratoPage from "view/Contrato/index";
import KitPage from "view/Kit/index";
import ServicoPage from "view/Servico/index";
import HomePage from "view/Home/index";
import FuturamentoPage from "view/Faturamento";
import FaturaPage from "view/Fatura";
import PagamentoPage from "view/Pagamento";
import ViewFaturaPage from "view/Fatura/pages/ViewFatura";
import ViewKitPage from "view/Kit/pages/ViewKIt";
import NewKitPage from "view/Kit/pages/NewKit";
import ModeloPage from "view/Modelo";
import NovoContratoPage from "view/Contrato/page/NewContrato";
import ViewContratoPage from "view/Contrato/page/EditContrato";
import LoginPage from "view/Login";
import ContratantePage from "view/Contratante";
import ContratadaPage from "view/Contrada";
import ContractView from "view/Contrato/page/ViewContrato";
import EditContratoPage from "view/Contrato/page/EditContrato";
import EquipamentoPage from "view/Equipamento";
import VeiculoPage from "view/Veiculo";
import InstalacaoPage from "view/Instalacao";
import NewInstalacao from "view/Instalacao/page/NewInstalacao";
import EditInstalacao from "view/Instalacao/page/EditInstalacao";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <HomePage />,
  },
  {
    path: "/contrato",
    children: [
      {
        element: <ContratoPage />,
        index: true,
      },
      {
        path: "/contrato/novo_contrato",
        element: <NovoContratoPage />,
      },
      {
        path: "/contrato/editar_contrato/:id",
        element: <ViewContratoPage />,
      },
    ],
  },
  {
    path: "/operacoes",
    children: [
      {
        index: true,
      },
      {
        path: "/operacoes",
        children: [
          {
            element: <KitPage />,
            index: true,
          },
          { path: "/operacoes/kit/:id", element: <ViewKitPage />, index: true },
          { path: "/operacoes/kit/novo", element: <NewKitPage />, index: true },
        ],
      },
      {
        path: "/operacoes/servico",
        element: <ServicoPage />,
      },
      {
        path: "/operacoes/modelo",
        children: [
          {
            element: <ModeloPage />,
            index: true,
          },
        ],
      },
    ],
  },
  {
    path: "/faturamento",
    children: [
      {
        element: <FuturamentoPage />,
        index: true,
      },
      {
        path: "/faturamento/fatura",
        children: [
          {
            element: <FaturaPage />,
            index: true,
          },
          { path: "/faturamento/fatura/:id", element: <ViewFaturaPage /> },
        ],
      },
      {
        path: "/faturamento/pagamento",
        element: <PagamentoPage />,
      },
    ],
  },
]);

export const BillingRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<LoginPage />} />
      <Route path="/inicio" element={<HomePage />} />
      <Route path="/contrato" element={<ContratoPage />} />
      <Route path="/contrato/novo_contrato" element={<NovoContratoPage />} />
      <Route path="/contrato/editar_contrato/:id" element={<EditContratoPage />} />
      <Route path="/contrato/view_contrato/:id" element={<ContractView />} />
      <Route path="/operacoes" element={<KitPage />} />
      <Route path="/operacoes/kit/:id" element={<ViewKitPage />} />
      <Route path="/operacoes/kit/novo" element={<NewKitPage />} />
      <Route path="/operacoes/servico" element={<ServicoPage />} />
      <Route path="/operacoes/modelo" element={<ModeloPage />} />
      <Route path="/operacoes/contratante" element={<ContratantePage />} />
      <Route path="/operacoes/contratada" element={<ContratadaPage />} />
      <Route path="/operacoes/equipamento" element={<EquipamentoPage />} />
      <Route path="/operacoes/veiculo" element={<VeiculoPage />} />
      <Route path="/operacoes/instalacao" element={<InstalacaoPage />} />
      <Route path="/operacoes/instalacao/novo" element={<NewInstalacao />} />
      <Route path="/operacoes/instalacao/editar_instalacao/:id" element={<EditInstalacao />} />
      <Route path="/faturamento" element={<FaturaPage />} />
      <Route path="/faturamento/fatura/:id" element={<ViewFaturaPage />} />
      <Route path="/faturamento/pagamento" element={<PagamentoPage />} />
      <Route path="*" element={<LoginPage />} />
    </Routes>
  );
};
