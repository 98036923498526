import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { VeiculoModel } from "shared/models/veiculo_response.model";

export interface ViewVeiculo {
  veiculo?: VeiculoModel;
  loading?: boolean;
  isError?: boolean;
  isOpen: boolean;
  selectedId?: number;
}

const initialState: ViewVeiculo = {
  loading: true,
  isError: false,
  isOpen: false,
};

const view_veiculo_slice = createSlice({
  name: "view_veiculo",
  initialState,
  reducers: {
    setVeiculo(state, action: PayloadAction<VeiculoModel>) {
      state.veiculo = action.payload;
    },
    setLoadingViewVeiculo(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },

    setErrorViewVeiculo(state, action: PayloadAction<boolean>) {
      state.isError = action.payload;
    },
    setModalVeiculo(state, action: PayloadAction<boolean>) {
      state.isOpen = action.payload;
    },
    setIdVeiculoView(state, action: PayloadAction<number>) {
      state.selectedId = action.payload;
    },
  },
});

export const {
  setVeiculo,
  setLoadingViewVeiculo,
  setErrorViewVeiculo,
  setModalVeiculo,
  setIdVeiculoView,
} = view_veiculo_slice.actions;
export default view_veiculo_slice.reducer;
