import billing_theme from "style/theme_colors";
import styled from "styled-components";

export const VeiculoListErrorContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  gap: 28px;
`;

export const DeleteVeiculoHeader = styled.div`
  height: 32px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;

  button {
    background-color: transparent;
    border: none;
  }
`;

export const DeleteVeiculoContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
  flex-direction: column;
`;
export const DeleteVeiculoButton = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  align-self: stretch;
  gap: 12px;
`;
