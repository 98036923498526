import PrimaryButton from "components/button/billing_button";
import TertiaryButton from "components/button/tertiaty_button";
import { Body2 } from "components/text/body/style";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "store/store";
import billing_theme from "style/theme_colors";
import { ViewServicoContent, ViewServicoDetails } from "./style";
import BillingTextInput from "components/input/text_input";
import BillingTextArea from "components/input/text_area";
import LineSkeleton from "components/skeleton/line_skeleton";
import { setDescricaoModelo, setNameModelo } from "store/servico/view_servico_slice";
import useViewServico from "hooks/servico/useViewServico";

const ModalViewServico: React.FC<{
  onClose: () => void;
  id: string;
}> = ({ onClose, id }) => {
  const dispatch = useAppDispatch();
  const state = useSelector((state: RootState) => state.view_servico);
  const { getServico, isLoadingServico, updateServico } = useViewServico();

  useEffect(() => {
    getServico(id);
  }, []);

  return (
    <ViewServicoContent>
      <ViewServicoDetails>
        <Body2 fontWeight={600} color={billing_theme.pallete.tertiary?.A400}>
          Nome:
        </Body2>
        {isLoadingServico ? (
          <LineSkeleton width={"100%"} height={40} />
        ) : (
          <BillingTextInput
            placeholder="Qual o nome do produto?"
            value={state.Nome}
            change={value => {
              dispatch(setNameModelo(value));
            }}
          />
        )}
      </ViewServicoDetails>
      <ViewServicoDetails>
        <Body2 fontWeight={600} color={billing_theme.pallete.tertiary?.A400}>
          Quantidade:
        </Body2>

        {isLoadingServico ? (
          <LineSkeleton width={"100%"} height={100} />
        ) : (
          <BillingTextArea
            placeholder="De uma descrição ao serviço!"
            change={value => {
              dispatch(setDescricaoModelo(value));
            }}
            defaultValue={state.Descricao}
            lines={3}
          />
        )}
      </ViewServicoDetails>

      <div className="button">
        <TertiaryButton label={"Cancelar"} onTap={onClose} isSmall />
        <PrimaryButton
          disable={isLoadingServico}
          label={"Editar"}
          onTap={async () => {
            await updateServico(state.ID!, state.Nome!, state.Descricao);
            onClose();
          }}
          isSmall
        />
      </div>
    </ViewServicoContent>
  );
};

export default ModalViewServico;
