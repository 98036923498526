import { BillingHeaderContent, GoContent, HeaderLogos } from "./style";
import React from "react";
import { Heading3, Heading6 } from "components/text/heading/style";
import billing_theme from "style/theme_colors";
import BillingIconSharp from "components/billing_icon/sharp_icon";
import BillingTextInput from "components/input/text_input";
import InfoHeaderComp from "./components/info_context";
import logo from "../../assets/svg/logo_app_go.svg";

const BillingHeader: React.FC = () => {
  return (
    <BillingHeaderContent>
      <div className="apps">
        <div className="menu-icon">
          <BillingIconSharp icon={"apps"} color={billing_theme.light.tertiary.A25!} size={25} />
        </div>
        <HeaderLogos>
          <img className="billing_logo" src={logo} alt="Logo" />

          <GoContent>
            <Heading3 color={billing_theme.light.tertiary?.A25} fontWeight={500}>
              GO
            </Heading3>
            <Heading6 color={billing_theme.light.tertiary?.A25}>Billing</Heading6>
          </GoContent>
        </HeaderLogos>
      </div>

      <div className="inputField">
        <BillingTextInput
          width={372}
          placeholder="Procurar por contrato, equipamento..."
          icon="search"
          change={value => {}}
        />
      </div>
      <InfoHeaderComp />
    </BillingHeaderContent>
  );
};

export default BillingHeader;
