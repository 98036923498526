import { toastError } from "components/toast/billing_toast";
import { ContratoHttp } from "gateways/repo/contrato_http_client";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { NovoContrato } from "shared/models/contrato_post.model";
import { resetNovoContratoState, setLoadingNovoContrato } from "store/contrato/new_contrato_slice";

import { RootState, useAppDispatch } from "store/store";
import useBillingEnum from "../useBillingEnum";
import useContratante from "../contratante/useContratante";
import UseKit from "../kit/useKit";

import moment from "moment";
import useContratada from "hooks/contratada/useContratada";
import useServico from "hooks/servico/useServico";

const useNovoContrato = () => {
  const http = new ContratoHttp();
  const { getContratadaOption } = useContratada();
  const { getContratatesOptions } = useContratante();
  const { getKitOptions } = UseKit();
  const { getServicoOptions } = useServico();
  const { getEnums } = useBillingEnum();
  const [error, setListError] = useState(false);
  const state = useSelector((state: RootState) => state.novo_contrato);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const loadContatoData = async () => {
    setListError(false);
    dispatch(setLoadingNovoContrato(true));
    try {
      await getContratadaOption();
      await getContratatesOptions();
      await getKitOptions();
      await getServicoOptions();
      await getEnums();
    } catch (e) {
      setListError(true);
      toastError({ title: "Erro", message: "Por favor, tente novamente!" });
    } finally {
      dispatch(setLoadingNovoContrato(false));
    }
  };

  const addNewContrato = async () => {
    const data: NovoContrato = {
      ID: 0,
      Contratada_ID: Number(state.contratada),
      Contratante_ID: Number(state.contratante),
      KitPrincipal_ID: Number(state.kitPrincipal),
      Quantidade: state.quantidade,
      Mensalidade: state.mensalidade,
      Instalacao: state.instalacao,
      Desinstalacao: state.desinstalacao,
      Transferencia: state.transferencia,
      DuracaoMeses: state.duracaoMeses,
      CarenciaDias: state.carenciaDias,
      FidelidadeMeses: state.fidelidadeMeses,
      PercentualDesconto: state.percentualDesconto,
      MesesDesconto: state.mesesDesconto,
      Indice: state.indice,
      ValorReajustavel: state.valorReajustavel,
      ValorNaoReajustavel: state.valorNaoReajustavel,
      ProRataInicio: state.proRataInicio,
      ProRataDesinstalacao: state.proRataDesinstalacao,
      RegrasInstalacao: state.regrasInstalacao!,
      KitsComplementares: state.kitsComplementares,
      Servicos: state.servico,
      Historicos: state.historicos,
      Reajustes: state.reajustes,
      VigenciaInicio: moment().add(state.carenciaDias, "days").format(),
      VigenciaFim: moment().add(state.duracaoMeses, "months").format(),
      Aditivo: "0",
    };
    console.log(data);
    dispatch(setLoadingNovoContrato(true));
    setListError(false);
    try {
      await http.addNewContrato(data);
      dispatch(resetNovoContratoState());
      navigate("/contrato");
    } catch (e) {
      console.log(e);
      setListError(true);
      toastError({ title: "Erro", message: "Por favor, tente novamente!" });
    } finally {
      dispatch(setLoadingNovoContrato(false));
    }
  };

  return {
    error,
    addNewContrato,
    loadContatoData,
  };
};
export default useNovoContrato;
