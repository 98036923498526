import billing_theme from "style/theme_colors";
import styled from "styled-components";

export const KitContainer = styled.div`
  display: grid;
  width: 100%;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: flex-start;
  justify-content: space-between;
  grid-template-columns: 187px 1fr;
  .main_content {
    width: 100%;
  }
`;

export const KitHeader = styled.div`
  padding: 16px 32px;
  border-bottom: solid 0.5px ${billing_theme.pallete.primary?.A300};
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
`;

export const KitFilterContent = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-top: 24px;
`;

export const SearchContent = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
`;
export const DataContent = styled.div`
  padding: 16px;
  display: flex;
  gap: 12px;
`;
