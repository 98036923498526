import { Body4 } from "components/text/body/style";
import { Heading5 } from "components/text/heading/style";
import billing_theme from "style/theme_colors";
import { NovoContratoInputLabel } from "../../style";
import { SelectedServicoContent, ServicoAdicionalContainer } from "./style";
import BillingSelectInput from "components/input/select";
import { useSelector } from "react-redux";
import { SingleValue } from "react-select";
import { OptionsModel } from "shared/models/contratada_response.model";
import { RootState, useAppDispatch } from "store/store";
import { addServicoState, removeServicoState } from "store/contrato/new_contrato_slice";
import SelectedServico from "./components/selected_item_servico";
const ServicoAdicional: React.FC<{}> = () => {
  const state = useSelector((state: RootState) => state.novo_contrato);
  const servico = useSelector((state: RootState) => state.servico);
  const dispatch = useAppDispatch();
  const getServicoName = (id: string) => {
    return servico.options.find(el => el.value == id)?.label;
  };
  return (
    <ServicoAdicionalContainer>
      <NovoContratoInputLabel>
        <Heading5 fontWeight={600} color={billing_theme.pallete.tertiary.A600}>
          Serviços
        </Heading5>
        <Body4 fontWeight={400} color={billing_theme.pallete.tertiary.A600}>
          Selecione um serviço a parte
        </Body4>
      </NovoContratoInputLabel>
      <BillingSelectInput
        onChange={(val: SingleValue<OptionsModel>) => {
          if (val != null)
            dispatch(
              addServicoState({
                ID: 0,
                Servico_ID: Number(val?.value),
              })
            );
        }}
        options={servico.options}
        placeholder={"Selecione um serviço"}
      />
      <Heading5 fontWeight={600} color={billing_theme.pallete.tertiary.A600}>
        Adicionados
      </Heading5>
      <SelectedServicoContent>
        {state.servico?.map(el => (
          <SelectedServico
            key={el.Servico_ID}
            label={getServicoName(el.Servico_ID!.toString())!}
            onTap={() => {
              dispatch(removeServicoState(el));
            }}
          />
        ))}
      </SelectedServicoContent>
    </ServicoAdicionalContainer>
  );
};

export default ServicoAdicional;
