import { toastError, toastInfo } from "components/toast/billing_toast";
import { ModeloHttp } from "gateways/repo/modelo_http_client";
import { useState } from "react";
import { useSelector } from "react-redux";
import { OptionsModel } from "shared/models/contratada_response.model";
import { ModeloResponseList } from "shared/models/modelo_list_response.model";
import {
  setModeloLastPage,
  setLoadingModelo,
  setModeloFilter,
  setModeloList,
  setPaginationModelo,
  setModeloOptions,
} from "store/modelo/modelo_slice";
import { RootState, useAppDispatch } from "store/store";
import { formatToOption } from "utils/enumListToOptions";

const useModelo = () => {
  const http = new ModeloHttp();
  const dispatch = useAppDispatch();
  const [isModeloError, setisModeloError] = useState(false);
  const [openDeleteModalModelo, setDeleteModalModelo] = useState<boolean>();
  const [deleteIdModelo, setDeleteIdModelo] = useState<string | number>();
  const [loadModal, setLoadingModal] = useState(false);
  const modelo = useSelector((state: RootState) => state.modelo);

  const getListModelos = async () => {
    setisModeloError(false);
    dispatch(setLoadingModelo(true));
    try {
      const data = await http.getListModelos();
      dispatch(setModeloList(data.Lista));
      dispatch(setModeloLastPage(paginateModelo(data.Lista!).length));
    } catch (e) {
      setisModeloError(true);
      toastError({ title: "Erro ao trazer Resultados" });
    } finally {
      dispatch(setLoadingModelo(false));
    }
  };

  const getModeloOptionList = async () => {
    setisModeloError(false);
    dispatch(setLoadingModelo(true));
    try {
      const data = await http.getModelosOptions();
      let options: OptionsModel[] = [];
      data.Lista.forEach(val => {
        options.push(formatToOption(val.ID.toString(), val.Text));
      });
      dispatch(setModeloOptions(options));
    } catch (e) {
      setisModeloError(true);
      toastError({ title: "Erro ao trazer Resultados" });
    } finally {
      dispatch(setLoadingModelo(false));
    }
  };
  const filterModelo = (key: number) => {
    const filter: ModeloResponseList[] = [];

    modelo.list!.forEach(el => filter.push(el));
    switch (key) {
      case 1:
        filter.sort((a, b) => a.Nome.toLowerCase().localeCompare(b.Nome.toLowerCase()));

        break;

      default:
        filter.sort((a, b) => a.Tipo.toLowerCase().localeCompare(b.Tipo.toLowerCase()));
    }

    dispatch(setModeloFilter(filter));
  };

  const filterModeloReverse = (key: number) => {
    const filter: ModeloResponseList[] = [];

    modelo.list!.forEach(el => filter.push(el));
    switch (key) {
      case 1:
        filter.sort((a, b) => b.Nome.toLowerCase().localeCompare(a.Nome.toLowerCase()));
        break;

      default:
        filter.sort((a, b) => b.Tipo.toLowerCase().localeCompare(a.Tipo.toLowerCase()));
    }

    dispatch(setModeloFilter(filter));
  };

  const filterByName = (name: string) => {
    const filter: ModeloResponseList[] = [];

    dispatch(setModeloFilter([]));
    modelo.list!.forEach(el => {
      if (el.Nome.toLowerCase().includes(name.toLowerCase())) {
        filter.push(el);
      }
    });

    dispatch(setModeloFilter(filter));
  };

  const deleteModelo = async () => {
    setLoadingModal(true);
    try {
      await http.deleteModelo(deleteIdModelo!);

      toastInfo({ title: "Modelo excluido!" });
      setDeleteModalModelo(false);
      getListModelos();
    } catch (e) {
      toastError({ title: "Ocorreu um erro!", message: "Por favor, tente novamente!" });
    } finally {
      setLoadingModal(false);
    }
  };

  const paginateModelo = (list: ModeloResponseList[]) => {
    const resultado: ModeloResponseList[][] = [];

    for (let i = 0; i < list.length; i += 10) {
      const chunk = list.slice(i, i + 10);
      resultado.push(chunk);
    }
    return resultado;
  };

  const setPagedModelo = (list: ModeloResponseList[], index: number) => {
    const resultado: ModeloResponseList[][] = [];

    for (let i = 0; i < list.length; i += 10) {
      const chunk = list.slice(i, i + 10);
      resultado.push(chunk);
    }
    return resultado[index] ?? [];
  };
  return {
    getListModelos,
    filterByName,
    filterModelo,
    filterModeloReverse,
    isModeloError,
    openDeleteModalModelo,
    setDeleteModalModelo,
    setDeleteIdModelo,
    deleteModelo,
    loadModal,
    paginateModelo,
    setPagedModelo,
    getModeloOptionList,
  };
};
export default useModelo;
