import React from "react";
import { ContratoBackButtonContent } from "../style";
import BillingIconSharp from "components/billing_icon/sharp_icon";
import billing_theme from "style/theme_colors";
import { Heading6 } from "components/text/heading/style";
import { useNavigate } from "react-router-dom";

const ContratoBackButton: React.FC = () => {
  const navigate = useNavigate();
  return (
    <ContratoBackButtonContent onClick={() => navigate("/contrato")}>
      <BillingIconSharp icon={"undo"} color={billing_theme.pallete.error.A50} />
      <Heading6 color={billing_theme.pallete.tertiary.A600} fontWeight={500}>
        Voltar a lista
      </Heading6>
    </ContratoBackButtonContent>
  );
};

export default ContratoBackButton;
