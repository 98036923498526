import styled from "styled-components";
import billing_theme from "style/theme_colors";

export const ServicoAdicionalContainer = styled.div`
  border-right: 0.5px solid ${billing_theme.pallete.tertiary?.A100};
  height: 93%;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  overflow-y: scroll;
`;

export const SelectedServicoContent = styled.div`
  display: flex;
  gap: 8px;
  flex-direction: row;
  flex-wrap: wrap;
`;
export const SelectedServicoButton = styled.div`
  display: flex;
  gap: 8px;
  border: solid 1px ${billing_theme.pallete.primary?.A300};

  border-radius: 8px;
  align-items: center;
  justify-content: space-between;
  padding: 0 8px;
  width: auto;
`;
