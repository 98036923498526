import { useEffect, useState } from "react";
import {
  KitListButton,
  KitListContent,
  KitListHeader,
  KitListIcon,
  KitListItems,
  KitListItensContainer,
} from "./style";
import BillingIconRounded from "components/billing_icon/rounded_icon";
import billing_theme from "style/theme_colors";
import { Body2, Body3 } from "components/text/body/style";
import React from "react";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "store/store";
import { KitModel } from "shared/models/kit_response.model";
import UseKit from "hooks/kit/useKit";
import KitEmptyContent from "../kit_empty_list";
import { useNavigate } from "react-router-dom";
import ModalBilling from "components/modal";
import KitDeleteContent from "../kit_delete_modal";
import Pagination from "components/pagination";
import { setKitPageIndex } from "store/kit/kit_slice";

const KitList: React.FC<{}> = () => {
  const dispatch = useAppDispatch();
  const kit = useSelector((state: RootState) => state.kit);
  const navigate = useNavigate();
  const {
    filterKit,
    filterKitReverse,
    clearFilterKit,
    deleteKit,
    openDeleteModalKit,
    setDeleteModalKit,
    setDeleteIdKit,
    loadModal,
    setPagedKit,
  } = UseKit();
  const [filter, setFilter] = useState("");
  const [data, setData] = useState<KitModel[]>([]);
  const [filterState, setFilterState] = useState("normal");
  const [deletKitNome, setDeleteKitNome] = useState<string>();

  const selectFilter = (id: string, index: number) => {
    if (filter != id) setFilterState("normal");

    if (filterState == "normal") {
      filterKit(index);
      setFilterState("reverse");
    } else {
      filterKitReverse(index);
      setFilterState("normal");
    }
    setFilter(id);
  };

  useEffect(() => {
    kit.filterList.length == 0 ? setData(kit.list) : setData(kit.filterList);
  });

  return (
    <>
      {data.length == 0 && kit.enable_filter && <KitEmptyContent clear={clearFilterKit} />}
      {data.length > 0 && (
        <KitListContent>
          <KitListHeader>
            <ListButton
              label="Nome"
              filterState={filterState}
              click={() => selectFilter("Nome", 1)}
              selectedFilter={filter}
            />
            <ListButton
              label="Tipo de Cobrança"
              filterState={filterState}
              click={() => selectFilter("Tipo de Cobrança", 2)}
              selectedFilter={filter}
            />
            <ListButton
              label="Tipo de kit"
              filterState={filterState}
              click={() => selectFilter("Tipo de kit", 3)}
              selectedFilter={filter}
            />

            <ListButton
              label="Opções"
              filterState={filterState}
              click={() => console.log("oi")}
              selectedFilter={"options"}
            />
          </KitListHeader>
          {setPagedKit(data, kit.pageIndex).map(item => (
            <KitListItensContainer key={item.ID}>
              <ListItens label={item.Nome} />
              <ListItens label={item.TipoCobrancaNome} />
              <ListItens label={item.TipoKitNome} />

              <KitListIcon>
                <BillingIconRounded
                  onTap={() => navigate("/operacoes/kit/" + item.ID)}
                  icon={"visibility"}
                  color={billing_theme.pallete.tertiary.A400}
                />
                <BillingIconRounded
                  icon={"delete"}
                  color={billing_theme.pallete.error.A100}
                  onTap={() => {
                    setDeleteModalKit(true);
                    setDeleteIdKit(item.ID);
                    setDeleteKitNome(item.Nome);
                  }}
                />
              </KitListIcon>
            </KitListItensContainer>
          ))}
          <ModalBilling modalIsOpen={openDeleteModalKit!} width="500px" height="200px">
            <KitDeleteContent
              onClose={() => setDeleteModalKit(false)}
              onDelete={() => deleteKit()}
              onLoading={loadModal!}
              kit={deletKitNome!}
            />
          </ModalBilling>
          <Pagination
            index={kit.pageIndex + 1}
            firstPage={1}
            lastPage={kit.lastPage!}
            goToFirst={() => {
              dispatch(setKitPageIndex(0));
            }}
            goToLast={() => {
              dispatch(setKitPageIndex(kit.lastPage! - 1));
            }}
            next={() => {
              dispatch(setKitPageIndex(kit.pageIndex + 1));
            }}
            back={() => {
              dispatch(setKitPageIndex(kit.pageIndex - 1));
            }}
          />
        </KitListContent>
      )}
    </>
  );
};

const ListButton: React.FC<{
  label: string;
  click: () => void;
  selectedFilter: string;
  filterState: string;
}> = ({ label, click, selectedFilter, filterState }) => {
  return (
    <KitListButton onClick={() => click()}>
      <Body2 fontWeight={600} color={billing_theme.pallete.tertiary.A400}>
        {label}
      </Body2>
      {selectedFilter == label && (
        <BillingIconRounded
          icon={filterState == "normal" ? "expand_less" : "expand_more"}
          color={billing_theme.pallete.tertiary.A400}
        />
      )}
    </KitListButton>
  );
};

const ListItens: React.FC<{ label: string }> = ({ label }) => {
  return (
    <KitListItems>
      <Body3 fontWeight={400} color={billing_theme.pallete.tertiary.A400} textalign="center">
        {label}
      </Body3>
    </KitListItems>
  );
};
export default KitList;
