import { useEffect, useState } from "react";
import {
  EquipamentoListButton,
  EquipamentoListContent,
  EquipamentoListHeader,
  EquipamentoListIcon,
  EquipamentoListItems,
  EquipamentoListItensContainer,
} from "./style";
import BillingIconRounded from "components/billing_icon/rounded_icon";
import billing_theme from "style/theme_colors";
import { Body2, Body3 } from "components/text/body/style";
import useEquipamento from "hooks/useEquipamento";
import React from "react";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "store/store";
import ModalBilling from "components/modal";
import EquipamentoDeleteContent from "../equipamento_delete_modal";
import { toastWarning } from "components/toast/billing_toast";
import { EquipamentoListModel } from "shared/models/equipamento_response.model";
import {
  setIdEquipamentoView,
  setModalEquipamento,
} from "store/equipamento/view_equipamento_slice";

import { toDate, toShortText } from "utils/stringUtils";
import {
  setEquipamentoPageIndex,
  setEquipamentoDeleteModal,
  setCotratadaDeleteId,
} from "store/equipamento/equipamento_slice";
import Pagination from "components/pagination";
import ModalViewEquipamento from "view/Equipamento/page/ViewEquipamento";

const EquipamentoList: React.FC<{}> = () => {
  const dispatch = useAppDispatch();
  const { deleteEquipamento, setPagedEquipamento } = useEquipamento();
  const equipamento = useSelector((state: RootState) => state.equipamento);
  const view_equipamento = useSelector((state: RootState) => state.view_equipamento);
  const [filter, setFilter] = useState("");
  const [data, setData] = useState<EquipamentoListModel[]>([]);
  const [filterState, setFilterState] = useState("normal");

  useEffect(() => {
    equipamento.filter.length == 0 ? setData(equipamento.list) : setData(equipamento.filter);
  });

  return (
    <EquipamentoListContent>
      <EquipamentoListHeader>
        <ListButton
          label="Cliente"
          filterState={filterState}
          click={() => toastWarning({ title: "Em breve" })}
          selectedFilter={filter}
        />

        <ListButton
          label="Status"
          filterState={filterState}
          click={() => toastWarning({ title: "Em breve" })}
          selectedFilter={filter}
        />
        <ListButton
          label="Modelo"
          filterState={filterState}
          click={() => toastWarning({ title: "Em breve" })}
          selectedFilter={filter}
        />
        <ListButton
          label="N de Serie"
          filterState={filterState}
          click={() => toastWarning({ title: "Em breve" })}
          selectedFilter={filter}
        />

        <ListButton
          label="Data Exp"
          filterState={filterState}
          click={() => toastWarning({ title: "Em breve" })}
          selectedFilter={filter}
        />

        <ListButton
          label="Opções"
          filterState={filterState}
          click={() => console.log("oi")}
          selectedFilter={"options"}
        />
      </EquipamentoListHeader>
      {setPagedEquipamento(data, equipamento.pageIndex).map(item => (
        <EquipamentoListItensContainer key={item.ID}>
          <ListItens label={item.ClienteTemp!} />
          <ListItens label={item.StatusNome} />
          <ListItens label={item.Modelo} />
          <ListItens label={item.NumeroSerie} />
          <ListItens label={toDate(item.DataExpedicao)} />

          <EquipamentoListIcon>
            <BillingIconRounded
              icon={"visibility"}
              color={billing_theme.pallete.tertiary.A400}
              onTap={() => {
                dispatch(setIdEquipamentoView(item.ID));
                dispatch(setModalEquipamento(true));
              }}
            />
            <BillingIconRounded
              icon={"delete"}
              color={billing_theme.pallete.error.A100}
              onTap={() => {
                dispatch(setCotratadaDeleteId(item.ID));
                dispatch(setEquipamentoDeleteModal(true));
              }}
            />
          </EquipamentoListIcon>
        </EquipamentoListItensContainer>
      ))}
      <ModalBilling modalIsOpen={view_equipamento.isOpen} width="600px" height="auto">
        <ModalViewEquipamento />
      </ModalBilling>
      <ModalBilling modalIsOpen={equipamento.openDeleteModal} width="500px" height="200px">
        <EquipamentoDeleteContent
          onClose={() => dispatch(setEquipamentoDeleteModal(false))}
          onDelete={() => deleteEquipamento()}
          onLoading={equipamento.loadingDelete}
        />
      </ModalBilling>
      <Pagination
        index={equipamento.pageIndex + 1}
        firstPage={1}
        lastPage={equipamento.lastPage!}
        goToFirst={() => {
          dispatch(setEquipamentoPageIndex(0));
        }}
        goToLast={() => {
          dispatch(setEquipamentoPageIndex(equipamento.lastPage! - 1));
        }}
        next={() => {
          dispatch(setEquipamentoPageIndex(equipamento.pageIndex + 1));
        }}
        back={() => {
          dispatch(setEquipamentoPageIndex(equipamento.pageIndex - 1));
        }}
      />
    </EquipamentoListContent>
  );
};

const ListButton: React.FC<{
  label: string;
  click: () => void;
  selectedFilter: string;
  filterState: string;
}> = ({ label, click, selectedFilter, filterState }) => {
  return (
    <EquipamentoListButton onClick={() => click()}>
      <Body2 fontWeight={600} color={billing_theme.pallete.tertiary.A400}>
        {label}
      </Body2>
      {selectedFilter == label && (
        <BillingIconRounded
          icon={filterState == "normal" ? "expand_less" : "expand_more"}
          color={billing_theme.pallete.tertiary.A400}
        />
      )}
    </EquipamentoListButton>
  );
};

const ListItens: React.FC<{ label: string }> = ({ label }) => {
  return (
    <EquipamentoListItems>
      <Body3 fontWeight={500} color={billing_theme.pallete.tertiary.A400} textalign="center">
        {label ? toShortText(label, 20) : "--"}
      </Body3>
    </EquipamentoListItems>
  );
};
export default EquipamentoList;
