import { useSelector } from "react-redux";
import { RootState } from "store/store";
import FormRegras from "./components/form_regras";
import RegrasAjustes from "./components/regras_e_ajustes";
import ValoresTecnicosComp from "./components/valores_tecnicos";
import { ValoresDataContainer } from "./style";
import RegrasCard from "./components/regras_card";

const ValoresTecnicos: React.FC<{}> = () => {
  const edit_contrato = useSelector((state: RootState) => state.view_contrato);
  return (
    <ValoresDataContainer>
      <ValoresTecnicosComp />
      <RegrasAjustes />
      {edit_contrato.openFormRegras && <FormRegras />}

      {edit_contrato.RegrasInstalacao?.map((e, i) => <RegrasCard regras={e} index={i} />)}
    </ValoresDataContainer>
  );
};

export default ValoresTecnicos;
