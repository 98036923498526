import { toastError, toastSuccess } from "components/toast/billing_toast";
import { FaturaHttp } from "gateways/repo/fatura_http_client";
import { useState } from "react";
import { useSelector } from "react-redux";
import { FaturaItemModel } from "shared/models/fatura_list_response.model";
import {
  setErrorFatura,
  setFaturaFilterList,
  setFaturaLastPage,
  setFaturaList,
  setFilterByDate,
  setFilterByValorMaior,
  setFilterByValorMenor,
  setFilterEnable,
  setFilterbyName,
  setLoadingFatura,
} from "store/fatura/fatura_slice";
import { RootState, useAppDispatch } from "store/store";
import { toDate } from "utils/stringUtils";

const useFatura = () => {
  const http = new FaturaHttp();
  const dispatch = useAppDispatch();
  const fatura = useSelector((state: RootState) => state.fatura);

  const [search, setSearch] = useState<string>();
  const [date, setDate] = useState<Date>();
  const [numeroMaior, setNumeroMaior] = useState<number>();
  const [numeroMenor, setNumeroMenor] = useState<number>();
  const [modalDeleteId, setModalDeleteId] = useState<number>();
  const [openDeleteModals, setOpenDeleteModals] = useState<boolean>(false);
  const [isLoadingDelete, setLoadingDelete] = useState<boolean>(false);
  const [idNameDelete, setIdNameDelete] = useState<string>();

  const getFatura = async () => {
    dispatch(setErrorFatura(false));
    dispatch(setLoadingFatura(true));
    try {
      const data = await http.getFaturaList();
      dispatch(setFaturaList(data.Lista));
      dispatch(setFaturaLastPage(paginateFatura(data.Lista!).length));
    } catch (e) {
      toastError({ title: "Algo deu errado!", message: "Por favor, tente novamente!" });
      dispatch(setErrorFatura(true));
    } finally {
      dispatch(setLoadingFatura(false));
    }
  };

  const filterFatura = (key: number) => {
    const filter: FaturaItemModel[] = [];

    fatura.list.forEach(el => filter.push(el));
    switch (key) {
      case 1:
        filter.sort((a, b) => a.ID - b.ID);
        break;
      case 2:
        filter.sort((a, b) =>
          a.Contratante.toLowerCase().localeCompare(b.Contratante.toLowerCase())
        );
        break;
      case 3:
        filter.sort((a, b) => a.ValorFatura - b.ValorFatura);
        break;
      default:
        filter.sort((a, b) =>
          toDate(a.DataRef, "MM/YYYY")
            .toLowerCase()
            .localeCompare(toDate(b.DataRef, "MM/YYYY").toLowerCase())
        );
    }

    dispatch(setFaturaFilterList(filter));
  };

  const filterFaturaReverse = (key: number) => {
    const filter: FaturaItemModel[] = [];

    fatura.list.forEach(el => filter.push(el));
    switch (key) {
      case 1:
        filter.sort((a, b) => b.ID - a.ID);
        break;
      case 2:
        filter.sort((a, b) =>
          b.Contratante.toLowerCase().localeCompare(a.Contratante.toLowerCase())
        );
        break;
      case 3:
        filter.sort((a, b) => b.ValorFatura - a.ValorFatura);
        break;
      default:
        filter.sort((a, b) =>
          toDate(b.DataRef, "MM/YYYY")
            .toLowerCase()
            .localeCompare(toDate(a.DataRef, "MM/YYYY").toLowerCase())
        );
    }

    dispatch(setFaturaFilterList(filter));
  };

  const filterByName = (name: string) => {
    setSearch(name);
    dispatch(setFilterbyName(name != ""));
  };

  const filterByDate = (date?: Date) => {
    setDate(date);
    dispatch(setFilterByDate(date != undefined));
  };

  const filterValorMaior = (number?: number) => {
    setNumeroMaior(number);
    dispatch(setFilterByValorMaior(number != undefined));
  };

  const filterValorMenor = (number?: number) => {
    setNumeroMenor(number);
    dispatch(setFilterByValorMenor(number != undefined));
  };

  const filterResultFatura = () => {
    let faturaLista: FaturaItemModel[] = fatura.list;
    if (fatura.search_fatura_filter) {
      faturaLista = faturaLista.filter(el =>
        el.Contratante.toLowerCase().includes(search!.toLowerCase())
      );
    }
    if (fatura.date_filter) {
      faturaLista = faturaLista.filter(
        el => toDate(el.DataRef, "MM/YYYY") === toDate(date!, "MM/YYYY")
      );
    }

    if (fatura.valor_maior_fatura_filter) {
      faturaLista = faturaLista.filter(el => el.ValorFatura >= numeroMaior!);
    }
    if (fatura.valor_menor_fatura_filter) {
      faturaLista = faturaLista.filter(el => el.ValorFatura <= numeroMenor!);
    }
    dispatch(
      setFilterEnable(
        fatura.date_filter ||
          fatura.search_fatura_filter ||
          fatura.redorder_fatura_filter ||
          fatura.valor_maior_fatura_filter ||
          fatura.valor_menor_fatura_filter
      )
    );
    dispatch(setFaturaFilterList(faturaLista));
  };

  const clearFilterFatura = () => {
    filterByName("");
    filterByDate();
    filterValorMaior();
    filterValorMenor();
    dispatch(setFilterEnable(false));
    dispatch(setFaturaFilterList([]));
  };

  const openModalDelete = (number: number, name: string) => {
    setModalDeleteId(number);
    setOpenDeleteModals(!openDeleteModals);
    setIdNameDelete(name);
  };

  const deleteFatura = async () => {
    try {
      setLoadingDelete(true);
      await http.deleteFatura(modalDeleteId!);
      setOpenDeleteModals(false);
      toastSuccess({ title: "Fatura excluida!" });
      getFatura();
    } catch (e) {
      console.log(e);
      toastError({ title: "Erro ao excluir fatura", message: "Por favor tente novamente!" });
    } finally {
      setLoadingDelete(false);
    }
  };
  const paginateFatura = (list: FaturaItemModel[]) => {
    const resultado: FaturaItemModel[][] = [];

    for (let i = 0; i < list.length; i += 10) {
      const chunk = list.slice(i, i + 10);
      resultado.push(chunk);
    }
    return resultado;
  };

  const setPagedFatura = (list: FaturaItemModel[], index: number) => {
    const resultado: FaturaItemModel[][] = [];

    for (let i = 0; i < list.length; i += 10) {
      const chunk = list.slice(i, i + 10);
      resultado.push(chunk);
    }
    return resultado[index] ?? [];
  };
  return {
    getFatura,
    filterByName,
    filterFatura,
    filterFaturaReverse,
    filterByDate,
    filterValorMaior,
    filterValorMenor,
    filterResultFatura,
    clearFilterFatura,
    openModalDelete,
    deleteFatura,
    openDeleteModals,
    setOpenDeleteModals,
    idNameDelete,
    isLoadingDelete,
    paginateFatura,
    setPagedFatura,
  };
};
export default useFatura;
