import React from "react";
import { ViewFaturaButtonSelected } from "../style";
import billing_theme from "style/theme_colors";
import { Heading6 } from "components/text/heading/style";
import BillingIconRounded from "components/billing_icon/rounded_icon";
import { SymbolCodepoints } from "react-material-symbols";

const ViewFaturaTabButton: React.FC<{
  is_selected: boolean;
  label: string;
  onTap: () => void;
  icon: SymbolCodepoints;
}> = ({ is_selected, label, onTap, icon }) => {
  return (
    <ViewFaturaButtonSelected onClick={onTap} $is_selected={is_selected}>
      <BillingIconRounded
        icon={icon}
        color={
          is_selected ? billing_theme.pallete.tertiary.A50 : billing_theme.pallete.tertiary.A600
        }
        size={20}
      />
      <Heading6
        fontWeight={600}
        color={
          is_selected ? billing_theme.pallete.tertiary.A50 : billing_theme.pallete.tertiary.A600
        }
        lineheight="normal"
      >
        {label}
      </Heading6>
    </ViewFaturaButtonSelected>
  );
};

export default ViewFaturaTabButton;
