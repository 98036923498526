import { toastInfo, toastError } from "components/toast/billing_toast";
import { ContratanteHttp } from "gateways/repo/contratante_http_client";
import { useSelector } from "react-redux";
import { OptionsModel } from "shared/models/contratada_response.model";
import { ContratanteListModel } from "shared/models/contratante_response.model";
import {
  setContratanteDeleteModal,
  setContratanteError,
  setContratanteLastPage,
  setContratanteList,
  setContratanteLoading,
  setContratanteLoadingDelete,
  setContratanteOptions,
} from "store/contratante/contratante_slice";
import { RootState, useAppDispatch } from "store/store";
import { formatToOption } from "utils/enumListToOptions";

const useContratante = () => {
  const http = new ContratanteHttp();
  const dispatch = useAppDispatch();
  const contratante = useSelector((state: RootState) => state.contratante);

  const getListContratantes = async () => {
    dispatch(setContratanteError(false));
    dispatch(setContratanteLoading(true));
    try {
      const data = await http.getListContratante();
      dispatch(setContratanteList(data.Lista));
      dispatch(setContratanteLastPage(paginateContratante(data.Lista!).length));
    } catch (e) {
      dispatch(setContratanteError(true));
      console.log(e);
    } finally {
      dispatch(setContratanteLoading(false));
    }
  };

  const getContratatesOptions = async () => {
    dispatch(setContratanteError(false));
    dispatch(setContratanteLoading(true));
    try {
      const data = await http.getListContratanteOptions();
      let options: OptionsModel[] = [];
      data.Lista.forEach(val => {
        options.push(formatToOption(val.ID.toString(), val.Text));
      });

      dispatch(setContratanteOptions(options));
    } catch (e) {
      dispatch(setContratanteError(true));
      console.log(e);
    } finally {
      dispatch(setContratanteLoading(false));
    }
  };
  const deleteContratante = async () => {
    dispatch(setContratanteLoadingDelete(true));
    try {
      await http.deleteContratante(contratante.selectDeleteId!);
      toastInfo({ title: "Empresa excluida!" });
      dispatch(setContratanteDeleteModal(false));
      getListContratantes();
    } catch (e) {
      toastError({ title: "Ocorreu um erro!", message: "Por favor, tente novamente!" });
    } finally {
      dispatch(setContratanteLoadingDelete(false));
    }
  };
  const paginateContratante = (list: ContratanteListModel[]) => {
    const resultado: ContratanteListModel[][] = [];

    for (let i = 0; i < list.length; i += 10) {
      const chunk = list.slice(i, i + 10);
      resultado.push(chunk);
    }
    return resultado;
  };
  const setPagedContratante = (list: ContratanteListModel[], index: number) => {
    const resultado: ContratanteListModel[][] = [];

    for (let i = 0; i < list.length; i += 10) {
      const chunk = list.slice(i, i + 10);
      resultado.push(chunk);
    }
    return resultado[index] ?? [];
  };
  return {
    getContratatesOptions,
    getListContratantes,
    deleteContratante,
    paginateContratante,
    setPagedContratante,
  };
};
export default useContratante;
