import styled from "styled-components";
import bgLogin from "../../assets/png/background_login.png";
import bgLoginDark from "../../assets/png/background_login_dark.png";
import billing_theme from "style/theme_colors";

export const LoginContainer = styled.div`
  width: 100%;
  height: 100vh;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url(${billing_theme.isDark() ? bgLoginDark : bgLogin});
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .logoTiltle {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding-bottom: 4px;
  }

  button > div {
    border: none;
  }
  button > div:hover {
    border: none;
  }
`;

export const LoginBox = styled.div`
  display: flex;
  padding: 32px 16px;
  flex-direction: column;
  align-items: stretch;
  gap: 16px;
  justify-content: center;
  align-self: stretch;
  border-radius: 8px;
  border: 0.5px solid ${billing_theme.pallete.tertiary.A100};
  background: ${billing_theme.pallete.tertiary.A25};
  min-height: 348px;
  width: 400px;
  margin: 0 auto;
`;

export const LoginDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 4px;
  align-self: stretch;
`;
