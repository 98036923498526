import { BillingHttp } from "gateways/billing_http";
import {
  ContratanteListResponseModel,
  ContratanteRespondeModel,
  PostContratanteModel,
} from "shared/models/contratante_response.model";
import { OptionsResponseModel } from "shared/models/default_models.model";

export class ContratanteHttp {
  private http = new BillingHttp();

  getListContratante = async () => {
    const reponse = await this.http.get<ContratanteListResponseModel>("Contratante/List");
    return reponse.data;
  };

  getListContratanteOptions = async () => {
    const reponse = await this.http.get<OptionsResponseModel>("Contratante/ListOptions");
    return reponse.data;
  };

  getContratante = async (id: number) => {
    const response = await this.http.get<ContratanteRespondeModel>("Contratante/Get?id=" + id);
    return response.data;
  };
  addNewContratante = async (data: PostContratanteModel) => {
    const response = await this.http.post("Contratante/Insert", { data: data });
    return response.data;
  };
  updateNewContratante = async (data: PostContratanteModel) => {
    const response = await this.http.put("Contratante/Update", { data: data });
    return response.data;
  };
  deleteContratante = async (id: number | string) => {
    const response = await this.http.delete("Contratante/Delete?id=" + id);
    return response.data;
  };
}
