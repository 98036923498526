import { EquipamentoContainer, EquipamentoHeader } from "./style";
import { Heading3 } from "components/text/heading/style";
import { Body2 } from "components/text/body/style";
import billing_theme from "style/theme_colors";
import Scaffold from "components/scaffold";
import PrimaryButton from "components/button/billing_button";
import { useEffect } from "react";
import InsideMenu from "components/insidemenu";
import InsideMenuButton from "components/insidemenu/components/inside_menu_button";
import SkeletonEquipamentoList from "./components/equipamento_skeleton";
import ModalBilling from "components/modal";
import useEquipamento from "hooks/useEquipamento";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "store/store";
import ModalNewEquipamento from "./page/NewEquipamento";
import { setModalNewEquipamento } from "store/equipamento/new_equipamento_slice";
import EquipamentoErrorContent from "./components/equipamento_list_error";
import EquipamentoFilter from "./components/equipamento_filter";
import EquipamentoList from "./components/equipamento_list";
import InsideMenuHeader from "components/insidemenu/components/inside_button_header";
import OpecaoesInsideMenu from "components/insidemenu/components/opecaoes_menu";

const EquipamentoPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const { getEquipamentoList } = useEquipamento();

  const equipamento = useSelector((state: RootState) => state.equipamento);
  const new_equipamento = useSelector((state: RootState) => state.new_equipamento);
  useEffect(() => {
    getEquipamentoList();
  }, []);
  return (
    <Scaffold>
      <EquipamentoContainer>
        <InsideMenu title={"Equipamento"} icon={"work"}>
          <OpecaoesInsideMenu />
        </InsideMenu>
        <div className="main_content">
          <EquipamentoHeader>
            <div className="">
              <Heading3 fontWeight={600} color={billing_theme.pallete.tertiary?.A400}>
                Equipamento
              </Heading3>
              <Body2 color={billing_theme.pallete.tertiary?.A400}>
                Veja os equipamentos alocados.
              </Body2>
            </div>

            <PrimaryButton
              label={"Novo equipamento"}
              leftIcon={"add"}
              isSmall
              onTap={() => dispatch(setModalNewEquipamento(true))}
            />
          </EquipamentoHeader>
          {equipamento.isError && <EquipamentoErrorContent retry={() => getEquipamentoList()} />}

          {!equipamento.isError && <EquipamentoFilter />}

          {!equipamento.isError &&
            (equipamento.loading ? <SkeletonEquipamentoList /> : <EquipamentoList />)}
        </div>
      </EquipamentoContainer>

      <ModalBilling modalIsOpen={new_equipamento.isOpen} width="600px" height="auto">
        <ModalNewEquipamento />
      </ModalBilling>
    </Scaffold>
  );
};

export default EquipamentoPage;
