import billing_theme from "style/theme_colors";
import { Heading5, Heading6 } from "components/text/heading/style";
import { Body4 } from "components/text/body/style";
import { NovoContratoInputLabel } from "../../../style";
import { useAppDispatch } from "store/store";

import {
  FormRegrasContent,
  FormRegrasTwoGrid,
  ButtonSection,
  HeaderFormRegras,
  ValoresDataGrid,
} from "../style";
import BillingIconSharp from "components/billing_icon/sharp_icon";
import BillingNumberInput from "components/input/number_input";
import BillingCheckbox from "components/input/checkbox";
import TertiaryButton from "components/button/tertiaty_button";
import PrimaryButton from "components/button/billing_button";
import { useState } from "react";
import BillingTextInput from "components/input/text_input";
import { addRegras, setOpenFormRegras } from "store/contrato/new_contrato_slice";

const FormRegras: React.FC<{}> = () => {
  const dispatch = useAppDispatch();
  const [estado, setEstado] = useState<string>();
  const [cidade, setCidade] = useState<string>();
  const [dia, setDia] = useState<number>();
  const [proprios, setProprios] = useState<number>();
  const [terceiro, setTerceiro] = useState<number>();
  const [instalacao, setInstalacao] = useState<boolean>(false);
  const [desinstalacao, setDesinstalacao] = useState<boolean>(false);
  const [transferencia, setTransferencia] = useState<boolean>(false);
  return (
    <FormRegrasContent>
      <HeaderFormRegras>
        <NovoContratoInputLabel>
          <Heading5 fontWeight={600} color={billing_theme.pallete.tertiary.A600}>
            Regras e ajustes
          </Heading5>
          <Body4 fontWeight={400} color={billing_theme.pallete.tertiary.A600}>
            Informe valores negociados.
          </Body4>
        </NovoContratoInputLabel>
        <BillingIconSharp
          icon={"close"}
          color={billing_theme.pallete.tertiary.A600}
          onTap={() => dispatch(setOpenFormRegras(false))}
        />
      </HeaderFormRegras>
      <FormRegrasTwoGrid>
        <NovoContratoInputLabel>
          <Heading6 fontWeight={600} color={billing_theme.pallete.tertiary.A600}>
            Estado*
          </Heading6>
          <BillingTextInput
            placeholder="Estado"
            change={value => {
              setEstado(value!);
            }}
          />
        </NovoContratoInputLabel>

        <NovoContratoInputLabel>
          <Heading6 fontWeight={600} color={billing_theme.pallete.tertiary.A600}>
            Cidade*
          </Heading6>
          <BillingTextInput
            placeholder="Cidade"
            change={value => {
              setCidade(value);
            }}
          />
        </NovoContratoInputLabel>
      </FormRegrasTwoGrid>

      <ValoresDataGrid>
        <NovoContratoInputLabel>
          <Heading6 fontWeight={600} color={billing_theme.pallete.tertiary.A600}>
            Quant./dia útil
          </Heading6>
          <BillingNumberInput
            placeholder="00"
            onNumberFormat={val => {
              setDia(val.number);
            }}
          />
        </NovoContratoInputLabel>
        <NovoContratoInputLabel>
          <Heading6 fontWeight={600} color={billing_theme.pallete.tertiary.A600}>
            Tec. próprios
          </Heading6>
          <BillingNumberInput
            placeholder="%"
            onNumberFormat={val => {
              setProprios(val.number);
            }}
          />
        </NovoContratoInputLabel>
        <NovoContratoInputLabel>
          <Heading6 fontWeight={600} color={billing_theme.pallete.tertiary.A600}>
            Tec. Terceiros
          </Heading6>
          <BillingNumberInput
            placeholder="%"
            onNumberFormat={val => {
              setTerceiro(val.number);
            }}
          />
        </NovoContratoInputLabel>
      </ValoresDataGrid>
      <BillingCheckbox
        label="Instalação"
        change={value => {
          setInstalacao(value!);
        }}
      />
      <BillingCheckbox
        label="Desinstalação"
        change={value => {
          setDesinstalacao(value!);
        }}
      />
      <BillingCheckbox
        label="Transferência"
        change={value => {
          setTransferencia(value!);
        }}
      />
      <ButtonSection>
        <TertiaryButton label={"Limpar"} onTap={() => {}} isSmall isFilled />
        <PrimaryButton
          disable={
            estado == undefined ||
            cidade == undefined ||
            dia == undefined ||
            terceiro == undefined ||
            proprios == undefined
          }
          label={"Adicionar"}
          onTap={() => {
            dispatch(
              addRegras({
                ID: 0,
                Estado: estado,
                Cidade: cidade,
                InstaDia: dia,
                DescProprio: proprios,
                Transferencia: transferencia,
                Instalacao: instalacao,
                Desinstalacao: desinstalacao,
                DescExterna: terceiro,
              })
            );
            dispatch(setOpenFormRegras(false));
          }}
          isSmall
        />
      </ButtonSection>
    </FormRegrasContent>
  );
};

export default FormRegras;
