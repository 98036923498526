import { ViewAboveFieldContent, ViewFieldContent } from "../style";
import { Heading6 } from "components/text/heading/style";
import billing_theme from "style/theme_colors";

const ViewAboveField: React.FC<{ title: string }> = ({ title }) => {
  return (
    <ViewAboveFieldContent>
      <Heading6 fontWeight={600} lineheight="24px" color={billing_theme.pallete.tertiary.A600}>
        {title}
      </Heading6>
    </ViewAboveFieldContent>
  );
};

export default ViewAboveField;
