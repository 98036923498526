import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { toastWarning } from "components/toast/billing_toast";
import {
  Historico,
  KitsComplementare,
  Reajuste,
  RegrasInstalacao,
  ServicoNovoContrato,
} from "shared/models/contrato_post.model";

export interface NovoContratoSlice {
  id?: number;
  numero?: string;
  aditivo?: string;
  contratada?: number;
  contratante?: number;
  contratoPai?: number;
  kitPrincipal?: number;
  quantidade?: number;
  mensalidade?: number;
  valorReajustavel?: number;
  valorNaoReajustavel?: number;
  duracaoMeses?: number;
  carenciaDias?: number;
  fidelidadeMeses?: number;
  percentualDesconto?: number;
  mesesDesconto?: number;
  indice?: number;
  vigenciaInicio?: Date;
  vigenciaFim?: Date;
  faturamentoInicio?: Date;
  faturamentoFim?: Date;
  primeiraExpedicao?: Date;
  status?: number;
  instalacao?: number;
  desinstalacao?: number;
  transferencia?: number;
  proRataInicio?: boolean;
  proRataDesinstalacao?: boolean;
  regrasInstalacao?: RegrasInstalacao[];
  kitsComplementares?: KitsComplementare[];
  servico?: ServicoNovoContrato[];
  historicos?: Historico[];
  reajustes?: Reajuste[];
  pageIndex?: number;
  openFormRegras: boolean;
  loading?: boolean;
}

const initialState: NovoContratoSlice = {
  id: 0,
  kitsComplementares: [],
  servico: [],
  historicos: [],
  regrasInstalacao: [],
  reajustes: [],
  pageIndex: 0,
  openFormRegras: false,
};

const new_contrato_slice = createSlice({
  name: "new_contrato",
  initialState,
  reducers: {
    resetNovoContratoState: () => initialState,
    setLoadingNovoContrato(state, action: PayloadAction<boolean | undefined>) {
      state.loading = action.payload;
    },
    setContratanteState(state, action: PayloadAction<number | undefined>) {
      state.contratante = action.payload;
    },

    setContratadaState(state, action: PayloadAction<number | undefined>) {
      state.contratada = action.payload;
    },
    setDuracaoState(state, action: PayloadAction<number | undefined>) {
      state.duracaoMeses = action.payload;
    },
    setCarenciaState(state, action: PayloadAction<number | undefined>) {
      state.carenciaDias = action.payload;
    },
    setFidelidadeState(state, action: PayloadAction<number | undefined>) {
      state.fidelidadeMeses = action.payload;
    },
    setPorcentagemState(state, action: PayloadAction<number | undefined>) {
      state.percentualDesconto = action.payload;
    },
    setMesesState(state, action: PayloadAction<number | undefined>) {
      state.duracaoMeses = action.payload;
    },
    setIndiceState(state, action: PayloadAction<number | undefined>) {
      state.indice = action.payload;
    },
    addKitState(state, action: PayloadAction<KitsComplementare>) {
      if (state.kitsComplementares!.filter(el => action.payload.Kit_ID == el.Kit_ID).length != 0) {
        toastWarning({ title: "Kit já adicionado!" });
      } else {
        state.kitsComplementares!.push(action.payload);
      }
    },
    addServicoState(state, action: PayloadAction<ServicoNovoContrato>) {
      if (state.servico!.filter(el => action.payload.Servico_ID == el.Servico_ID).length != 0) {
        toastWarning({ title: "Serviço já adiciona!" });
      } else {
        state.servico!.push(action.payload);
      }
    },
    removeKitState(state, action: PayloadAction<KitsComplementare>) {
      state.kitsComplementares = state.kitsComplementares!.filter(
        el => action.payload.Kit_ID !== el.Kit_ID
      );
    },
    removeServicoState(state, action: PayloadAction<ServicoNovoContrato>) {
      state.servico = state.servico!.filter(el => action.payload.Servico_ID !== el.Servico_ID);
    },
    setKitPrincipal(state, action: PayloadAction<number | undefined>) {
      state.kitPrincipal = action.payload;
    },
    setQuantidadeKitPrincipal(state, action: PayloadAction<number | undefined>) {
      state.quantidade = action.payload;
    },
    setValorReajuste(state, action: PayloadAction<number>) {
      state.valorReajustavel = action.payload;
    },
    setValorNaoReajustavel(state, action: PayloadAction<number | undefined>) {
      state.valorNaoReajustavel = action.payload;
    },
    setProRataInstacao(state, action: PayloadAction<boolean | undefined>) {
      state.proRataInicio = action.payload;
    },
    setProRataDesinstacao(state, action: PayloadAction<boolean | undefined>) {
      state.proRataDesinstalacao = action.payload;
    },
    setInstalacao(state, action: PayloadAction<number | undefined>) {
      state.instalacao = action.payload;
    },
    setDesinstalacao(state, action: PayloadAction<number | undefined>) {
      state.desinstalacao = action.payload;
    },
    setTransferencia(state, action: PayloadAction<number | undefined>) {
      state.transferencia = action.payload;
    },

    setMensalidade(state, action: PayloadAction<number | undefined>) {
      state.mensalidade = action.payload;
    },
    setActualPage(state, action: PayloadAction<number | undefined>) {
      state.pageIndex = action.payload;
    },
    setPercentualDescontoNewContrato(state, action: PayloadAction<number | undefined>) {
      state.percentualDesconto = action.payload;
    },
    setMesesDesconto(state, action: PayloadAction<number | undefined>) {
      state.mesesDesconto = action.payload;
    },
    addRegras(state, action: PayloadAction<RegrasInstalacao>) {
      state.regrasInstalacao?.push(action.payload);
    },
    removeRegras(state, action: PayloadAction<number>) {
      state.regrasInstalacao = state.regrasInstalacao?.filter((_, i) => i !== action.payload)!;
    },
    editRegra(state, action: PayloadAction<{ regra: RegrasInstalacao; index: number }>) {
      state.regrasInstalacao![action.payload.index] = action.payload.regra;
    },
    setOpenFormRegras(state, action: PayloadAction<boolean>) {
      state.openFormRegras = action.payload;
    },
  },
});

export const {
  setLoadingNovoContrato,
  setMensalidade,
  setTransferencia,
  setQuantidadeKitPrincipal,
  setProRataInstacao,
  setProRataDesinstacao,
  setValorNaoReajustavel,
  setValorReajuste,
  setKitPrincipal,
  setDesinstalacao,
  setInstalacao,
  setContratanteState,
  removeServicoState,
  addKitState,
  addServicoState,
  removeKitState,
  setContratadaState,
  setCarenciaState,
  setFidelidadeState,
  setPorcentagemState,
  setMesesState,
  setIndiceState,
  setDuracaoState,
  resetNovoContratoState,
  setActualPage,
  setPercentualDescontoNewContrato,
  setMesesDesconto,
  addRegras,
  removeRegras,
  editRegra,
  setOpenFormRegras,
} = new_contrato_slice.actions;
export default new_contrato_slice.reducer;
