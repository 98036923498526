import billing_theme from "style/theme_colors";
import styled from "styled-components";

export const EquipamentoListErrorContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 40px;
  background: ${billing_theme.pallete.specific.T_50_25};
  width: 98%;
  margin: 0 auto;
  padding: 50px 0px;
  border-radius: 12px;
  -webservico-box-shadow: 2px 4px 4px 0px ${billing_theme.pallete.box_shadow};
  -moz-box-shadow: 2px 4px 4px 0px ${billing_theme.pallete.box_shadow};
  box-shadow: 2px 4px 4px 0px ${billing_theme.pallete.box_shadow};
  height: 60vh;
  margin-top: 16px;
  margin-bottom: 16px;
  .text {
    text-align: center;
    padding: 0px 22%;
  }

  .text h5 {
    line-height: 22px;
  }
`;
