import React, { useEffect, useState } from "react";
import { NovoInstalacaosForm } from "../style";
import billing_theme from "style/theme_colors";
import { Heading5, Heading6 } from "components/text/heading/style";
import BillingSelectInput from "components/input/select";
import { NovoInstalacaoInputLabel } from "view/Instalacao/page/NewInstalacao/style";
import BillingDatePicker from "components/input/datePicker";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "store/store";
import LineSkeleton from "components/skeleton/line_skeleton";
import {
  setContratanteNewInstalacao,
  setDataInstalacaoNewInstalacao,
  setEquipamentoPrincipalNewInstalacao,
  setMotivoDesinstalacoNewInstalacao,
  setVeiculoNewInstalacao,
  setDataDesinsitalacaoNewInstalacao,
  setCodigoGoNewInstalacao,
} from "store/instalacao/new_instalacao_slice";
import PrimaryButton from "components/button/billing_button";
import useNewInstalacao from "hooks/instalacao/useNewInstalacao";
import BillingNumberInput from "components/input/number_input";
import { OptionsModel } from "shared/models/contratada_response.model";
import moment from "moment";

const NovaInstalacaoForm: React.FC = () => {
  const dispatch = useAppDispatch();
  const state = useSelector((state: RootState) => state.new_instalacao);
  const contratante = useSelector((state: RootState) => state.contratante);
  const veiculo = useSelector((state: RootState) => state.veiculo);
  const enum_list = useSelector((state: RootState) => state.list_enum);
  const { addInstalacao } = useNewInstalacao();

  const [selectedContratante, setSelectedContratante] = useState<OptionsModel>();
  const [selectedVeiculo, setselectedVeiculo] = useState<OptionsModel>();
  const [selectedMotivo, setselectedMotivo] = useState<OptionsModel>();
  useEffect(() => {
    setSelectedContratante(
      contratante.options.find(el => Number(el.value) == state.Contratante_ID)
    );
    setselectedVeiculo(veiculo.options.find(el => Number(el.value) == state.Veiculo_ID));
    setselectedMotivo(
      enum_list.MotivoDesinstalacaoOptions?.find(
        el => Number(el.value) == state.MotivoDesinstalacao
      )
    );
  });
  return (
    <NovoInstalacaosForm>
      <Heading5 fontWeight={600} color={billing_theme.pallete.tertiary.A600}>
        Dados da instalação
      </Heading5>
      <NovoInstalacaoInputLabel>
        <Heading6 fontWeight={600} color={billing_theme.pallete.tertiary.A600}>
          Código GO
        </Heading6>
        {state.loading ? (
          <LineSkeleton width={"100%"} height={40} />
        ) : (
          <BillingNumberInput
            value={state.CodigoGO}
            onNumberFormat={val => {
              dispatch(setCodigoGoNewInstalacao(val.number));
            }}
            placeholder={"Selecione um equipamento principal"}
          />
        )}
      </NovoInstalacaoInputLabel>
      <NovoInstalacaoInputLabel>
        <Heading6 fontWeight={600} color={billing_theme.pallete.tertiary.A600}>
          Contratante
        </Heading6>
        {state.loading ? (
          <LineSkeleton width={"100%"} height={40} />
        ) : (
          <BillingSelectInput
            defaultInputValue={selectedContratante}
            onChange={(val: any) => {
              val != null
                ? dispatch(setContratanteNewInstalacao(Number(val.value)))
                : dispatch(setContratanteNewInstalacao(undefined));
            }}
            options={contratante.options}
            placeholder={"Selecione o contratante"}
          />
        )}
      </NovoInstalacaoInputLabel>
      <NovoInstalacaoInputLabel>
        <Heading6 fontWeight={600} color={billing_theme.pallete.tertiary.A600}>
          Veiculo
        </Heading6>
        {state.loading ? (
          <LineSkeleton width={"100%"} height={40} />
        ) : (
          <BillingSelectInput
            defaultInputValue={selectedVeiculo}
            onChange={(val: any) => {
              val != null
                ? dispatch(setVeiculoNewInstalacao(Number(val.value)))
                : dispatch(setVeiculoNewInstalacao(undefined));
            }}
            options={veiculo.options}
            placeholder={"Selecione o veiculo"}
          />
        )}
      </NovoInstalacaoInputLabel>

      <NovoInstalacaoInputLabel>
        <Heading6 fontWeight={600} color={billing_theme.pallete.tertiary.A600}>
          Data da instalação
        </Heading6>
        {state.loading ? (
          <LineSkeleton width={"100%"} height={40} />
        ) : (
          <BillingDatePicker
            defautValue={state.DataInstalacao ? moment(state.DataInstalacao).toDate() : undefined}
            onChange={(val: Date) => {
              dispatch(setDataInstalacaoNewInstalacao(val.toISOString()));
            }}
            placeholder={"Selecione a data da instalação"}
            dateFormat={"dd/MM/yyyy"}
          />
        )}
      </NovoInstalacaoInputLabel>
      <NovoInstalacaoInputLabel>
        <Heading6 fontWeight={600} color={billing_theme.pallete.tertiary.A600}>
          Data da desinstalação
        </Heading6>
        {state.loading ? (
          <LineSkeleton width={"100%"} height={40} />
        ) : (
          <BillingDatePicker
            defautValue={
              state.DataDesinstalacao ? moment(state.DataDesinstalacao).toDate() : undefined
            }
            onChange={(val: Date) => {
              dispatch(setDataDesinsitalacaoNewInstalacao(val.toISOString()));
            }}
            placeholder={"Selecione a data da desinstalação"}
            dateFormat={"dd/MM/yyyy"}
          />
        )}
      </NovoInstalacaoInputLabel>
      <NovoInstalacaoInputLabel>
        <Heading6 fontWeight={600} color={billing_theme.pallete.tertiary.A600}>
          Motivo da desinstalação
        </Heading6>
        {state.loading ? (
          <LineSkeleton width={"100%"} height={40} />
        ) : (
          <BillingSelectInput
            defaultInputValue={selectedMotivo}
            onChange={(val: any) => {
              val != null
                ? dispatch(setMotivoDesinstalacoNewInstalacao(Number(val.value)))
                : dispatch(setMotivoDesinstalacoNewInstalacao(undefined));
            }}
            options={enum_list.MotivoDesinstalacaoOptions!}
            placeholder={"Qual o motivo da desinstalação"}
          />
        )}
      </NovoInstalacaoInputLabel>
      <div className="button">
        <PrimaryButton label={"Nova instalação"} onTap={addInstalacao} />
      </div>
    </NovoInstalacaosForm>
  );
};

export default NovaInstalacaoForm;
