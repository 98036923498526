import styled from "styled-components";

export const SearchContent = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
`;
export const ContratanteFilterContent = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-top: 24px;
`;

export const SearchInputContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
