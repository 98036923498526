import PrimaryButton from "components/button/billing_button";
import TertiaryButton from "components/button/tertiaty_button";
import { Body2 } from "components/text/body/style";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "store/store";
import billing_theme from "style/theme_colors";
import {
  ViewEquipamentoContent,
  ViewEquipamentoDetails,
  ViewEquipamentoGridTwo,
  ViewContratoRazaoCnpj,
} from "./style";
import BillingTextInput from "components/input/text_input";
import useViewEquipamento from "hooks/equipamento/useEditEquipamento";
import BillingSelectInput from "components/input/select";
import LineSkeleton from "components/skeleton/line_skeleton";
import BillingDatePicker from "components/input/datePicker";
import moment from "moment";
import { setModalEquipamento } from "store/equipamento/view_equipamento_slice";

const ModalViewEquipamento: React.FC = () => {
  const dispatch = useAppDispatch();
  const { updateEquipamento, getEquipamento } = useViewEquipamento();

  const state = useSelector((state: RootState) => state.view_equipamento);
  const list_enum = useSelector((state: RootState) => state.list_enum);
  const modeloState = useSelector((state: RootState) => state.modelo);

  const [numeroSerie, setNumeroSerie] = useState<string>();
  const [numeroFiscal, setNumeroFiscal] = useState<string>();
  const [contratante, setContratante] = useState<number>();
  const [contrato, setContrato] = useState<number>();
  const [dataExpedicao, setDataExpedicao] = useState<string>();
  const [status, setStatus] = useState<number>();
  const [modelo, setModelo] = useState<number>();

  const selectedModelo = () =>
    modeloState.options?.find(el => Number(el.value) === state.equipamento?.Modelo_ID);
  const selectStatus = () =>
    list_enum.EquipamentoStatusOptions?.find(el => Number(el.value) === state.equipamento?.Status);

  useEffect(() => {
    getEquipamento(state.selectedId!);
  }, []);

  return (
    <ViewEquipamentoContent>
      <ViewEquipamentoGridTwo>
        <ViewEquipamentoDetails>
          <Body2 fontWeight={600} color={billing_theme.pallete.tertiary?.A400}>
            Status:
          </Body2>

          {state.loading ? (
            <LineSkeleton width={"100%"} height={39} />
          ) : (
            <BillingSelectInput
              defaultInputValue={selectStatus()}
              placeholder="Selecione um contratante"
              options={list_enum.EquipamentoStatusOptions!}
              onChange={val => {
                setStatus(Number(val.value));
              }}
            />
          )}
        </ViewEquipamentoDetails>

        <ViewEquipamentoDetails>
          <Body2 fontWeight={600} color={billing_theme.pallete.tertiary?.A400}>
            Modelo:
          </Body2>
          {state.loading ? (
            <LineSkeleton width={"100%"} height={39} />
          ) : (
            <BillingSelectInput
              defaultInputValue={selectedModelo()}
              placeholder="Selecione um contratante"
              options={modeloState.options}
              onChange={val => {
                setModelo(Number(val.value));
              }}
            />
          )}
        </ViewEquipamentoDetails>
      </ViewEquipamentoGridTwo>
      <EquipamentoInfo
        numeroDeSerie={value => {
          setNumeroSerie(value);
        }}
        numeroFiscal={value => {
          setNumeroFiscal(value);
        }}
        contratante={value => {
          setContratante(value);
        }}
        contrato={value => {
          setContrato(value);
        }}
      />

      <ViewEquipamentoDetails>
        <Body2 fontWeight={600} color={billing_theme.pallete.tertiary?.A400}>
          Data expedição:
        </Body2>

        {state.loading ? (
          <LineSkeleton width={"100%"} height={39} />
        ) : (
          <BillingDatePicker
            placeholder="Data"
            defautValue={moment(state.equipamento?.DataExpedicao).toDate()}
            onChange={va => {
              setDataExpedicao(moment(va).format());
            }}
            dateFormat="dd/MM/yyyy"
          />
        )}
      </ViewEquipamentoDetails>

      <div className="button">
        <TertiaryButton
          label={"Cancelar"}
          onTap={() => dispatch(setModalEquipamento(false))}
          isSmall
        />
        <PrimaryButton
          disable={state.loading}
          label={"Editar"}
          onTap={async () => {
            await updateEquipamento({
              ID: state.equipamento?.ID!,
              NumeroSerie: numeroSerie! ?? state.equipamento?.NumeroSerie,
              NumeroFiscal: numeroFiscal! ?? state.equipamento?.NumeroFiscal,
              Contratante_ID: contratante! ?? state.equipamento?.Contratante_ID,
              Contrato_ID: contrato! ?? state.equipamento?.Contrato_ID,
              DataExpedicao: dataExpedicao! ?? state.equipamento?.DataExpedicao,
              Status: status! ?? state.equipamento?.Status,
              Modelo_ID: modelo! ?? state.equipamento?.Modelo_ID,
            });
          }}
          isSmall
        />
      </div>
    </ViewEquipamentoContent>
  );
};

const EquipamentoInfo: React.FC<{
  numeroDeSerie: (value: string | undefined) => void;
  numeroFiscal: (value: string | undefined) => void;
  contratante: (value: number | undefined) => void;
  contrato: (value: number | undefined) => void;
}> = ({ numeroDeSerie, numeroFiscal, contratante, contrato }) => {
  const contratoState = useSelector((state: RootState) => state.contrato);
  const contratanteState = useSelector((state: RootState) => state.contratante);
  const state = useSelector((state: RootState) => state.view_equipamento);

  const selectedContrato = () =>
    contratoState.options?.find(el => Number(el.value) === state.equipamento?.Contrato_ID);
  const selectedContratante = () =>
    contratanteState.options?.find(el => Number(el.value) === state.equipamento?.Contratante_ID);

  return (
    <ViewEquipamentoContent>
      <ViewContratoRazaoCnpj>
        <ViewEquipamentoDetails>
          <Body2 fontWeight={600} color={billing_theme.pallete.tertiary?.A400}>
            Contratante:
          </Body2>
          {state.loading ? (
            <LineSkeleton width={"100%"} height={39} />
          ) : (
            <BillingSelectInput
              defaultInputValue={selectedContratante()}
              placeholder="Selecione um contratante"
              options={contratanteState.options!}
              onChange={val => {
                contratante(Number(val.value));
              }}
            />
          )}
        </ViewEquipamentoDetails>
        <ViewEquipamentoDetails>
          <Body2 fontWeight={600} color={billing_theme.pallete.tertiary?.A400}>
            Contrato:
          </Body2>
          {state.loading ? (
            <LineSkeleton width={"100%"} height={39} />
          ) : (
            <BillingSelectInput
              defaultInputValue={selectedContrato()}
              placeholder="Selecione um contrato"
              options={contratoState.options!}
              onChange={val => {
                contrato(Number(val.value));
              }}
            />
          )}
        </ViewEquipamentoDetails>
      </ViewContratoRazaoCnpj>
      <ViewContratoRazaoCnpj>
        <ViewEquipamentoDetails>
          <Body2 fontWeight={600} color={billing_theme.pallete.tertiary?.A400}>
            Numero de serie:
          </Body2>

          {state.loading ? (
            <LineSkeleton width={"100%"} height={39} />
          ) : (
            <BillingTextInput
              placeholder="N de serie"
              value={state.equipamento?.NumeroSerie}
              change={numeroDeSerie}
            />
          )}
        </ViewEquipamentoDetails>
        <ViewEquipamentoDetails>
          <Body2 fontWeight={600} color={billing_theme.pallete.tertiary?.A400}>
            Numero fiscal:
          </Body2>
          {state.loading ? (
            <LineSkeleton width={"100%"} height={39} />
          ) : (
            <BillingTextInput
              value={state.equipamento?.NumeroFiscal}
              placeholder="Numero fiscal"
              change={numeroFiscal}
            />
          )}
        </ViewEquipamentoDetails>
      </ViewContratoRazaoCnpj>
    </ViewEquipamentoContent>
  );
};

export default ModalViewEquipamento;
