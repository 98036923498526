import { BillingHttp } from "gateways/billing_http";
import {
  InstalacaoListResponseModel,
  InstalacaoResponseModel,
  PostInstalacaoModel,
} from "shared/models/instalacao_response.model";
import { OptionsResponseModel } from "shared/models/default_models.model";
export class InstalacaoHttp {
  private http = new BillingHttp();

  getListInstalacao = async () => {
    const reponse = await this.http.get<InstalacaoListResponseModel>("Instalacao/List");

    return reponse.data;
  };

  getListInstalacaoOptions = async () => {
    const reponse = await this.http.get<OptionsResponseModel>("Instalacao/ListOptions");
    return reponse.data;
  };
  getInstalacao = async (id: string) => {
    const response = await this.http.get<InstalacaoResponseModel>("Instalacao/Get?id=" + id);
    return response.data;
  };
  adInstalacao = async (data: PostInstalacaoModel) => {
    const response = await this.http.post("Instalacao/Insert", { data: data });
    return response.data;
  };
  updateInstalacao = async (data: PostInstalacaoModel) => {
    const response = await this.http.put("Instalacao/Update", { data: data });
    return response.data;
  };
  deleteInstalacao = async (id: number | string) => {
    const response = await this.http.delete("Instalacao/Delete?id=" + id);
    return response.data;
  };
}
