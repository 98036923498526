import React, { useState } from "react";
import { DatePickerContent } from "./style";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import icon from "../../../assets/icon/calendar_clock.svg";

type DatePickerProps = {
  error?: boolean;
  errorMessage?: string;
  placeholder?: string;
  onChange?(date: Date): void;
  width?: string;
  disabled?: boolean | false;
  dateFormat?: string;
  showMonthYearPicker?: boolean;
  defautValue?: Date;
};

const BillingDatePicker: React.FC<DatePickerProps> = ({
  placeholder,
  onChange,
  width,
  disabled,
  dateFormat,
  showMonthYearPicker = false,
  defautValue,
}) => {
  const [date, setDate] = useState<Date>(defautValue!);

  return (
    <DatePickerContent width={width}>
      <DatePicker
        selected={date}
        disabled={disabled}
        onChange={(date: Date) => {
          setDate(date!);
          if (onChange) onChange(date);
        }}
        dropdownMode="select"
        popperPlacement="bottom"
        placeholderText={placeholder}
        showIcon
        toggleCalendarOnIconClick
        showMonthYearPicker={showMonthYearPicker}
        isClearable
        icon={icon}
        dateFormat={dateFormat ?? "MM/yyyy"}
      />
    </DatePickerContent>
  );
};

export default BillingDatePicker;
