import { toastError } from "components/toast/billing_toast";
import { FaturaHttp } from "gateways/repo/fatura_http_client";
import useContratante from "hooks/contratante/useContratante";
import { useState } from "react";
import { useParams } from "react-router-dom";
import {
  setViewFatura,
  setViewFaturaError,
  setViewFaturaLoading,
} from "store/fatura/view_fatura_slice";
import { useAppDispatch } from "store/store";

const useViewFatura = () => {
  const params = useParams();
  const http = new FaturaHttp();
  const dispatch = useAppDispatch();
  const { getListContratantes } = useContratante();

  const getFaturaById = async () => {
    dispatch(setViewFaturaError(false));
    dispatch(setViewFaturaLoading(true));
    try {
      await getListContratantes();
      const data = await http.getFaturaById(params.id!);
      dispatch(setViewFatura(data));
    } catch (e) {
      toastError({ title: "Algo deu errado!", message: "Por favor, tente novamente!" });
      dispatch(setViewFaturaError(true));
    } finally {
      dispatch(setViewFaturaLoading(false));
    }
  };

  return {
    getFaturaById,
  };
};
export default useViewFatura;
