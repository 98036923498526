import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Inject,
  Page,
  Sort,
  Filter,
  Group,
  Resize,
  ExcelExport,
  Toolbar,
  Reorder,
  SelectionSettingsModel,
  ToolbarItems,
  ExcelExportProperties,
  ColumnChooser,
} from "@syncfusion/ej2-react-grids";
import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "store/store";
import { EquipamentoFaturaContainer } from "./style";
import { toDate } from "utils/stringUtils";
import { toMoney } from "utils/numberUtils";

const EquipamentoFatura: React.FC<{}> = ({}) => {
  const state = useSelector((state: RootState) => state.view_fatura);
  let grid: GridComponent | null;
  const pageSettings: object = { pageSize: 20 };

  const filterSettings: object = { type: "Excel" };

  const searchOptions = {
    fields: [],
    ignoreCase: true,
    operator: "contains",
  };

  const selectionOptions: SelectionSettingsModel = {
    type: "Multiple",
    allowColumnSelection: true,
  };

  const toolbarOptions: ToolbarItems[] = ["ColumnChooser", "ExcelExport", "Search"];

  const toolbarClick = (args: any) => {
    if (grid && args.item.id === "Grid_excelexport") {
      const selectedRecords = grid.getSelectedRecords();
      const exportProperties: ExcelExportProperties = {
        dataSource: selectedRecords.length == 0 ? state.Objeto?.Equipamentos : selectedRecords,
        enableFilter: true,
      };
      grid.excelExport(exportProperties);
    }
  };
  const dataGridFormater = (field: any, data: any, column: any) => {
    return toDate(data[field]);
  };
  const moneyGrudFormatter = (field: any, data: any, column: any) => {
    return toMoney(data[field]);
  };
  const customizeCell = (args: any) => {
    if (args.data.ItemNovo == true) {
      args.cell.classList.add("newItem");
    }
    if (args.data.ItemFaltante == true) {
      args.cell.classList.add("removedItem");
    }
    if (args.column.field === "Mensalidade") {
      if (args.data.MarcarMensalidade == true) return args.cell.classList.add("waringField");
    }
    if (args.column.field === "NumeroSerie") {
      if (args.data.MarcarNumeroSerie == true) return args.cell.classList.add("waringField");
    }
    if (args.column.field === "Placa") {
      if (args.data.MarcarPlaca == true) return args.cell.classList.add("waringField");
    }
  };

  return (
    <EquipamentoFaturaContainer>
      <GridComponent
        id="Grid"
        queryCellInfo={customizeCell}
        dataSource={state.Objeto?.Equipamentos}
        toolbar={toolbarOptions}
        searchSettings={searchOptions}
        allowGrouping={true}
        allowSorting={true}
        allowFiltering={true}
        allowPaging={true}
        pageSettings={pageSettings}
        filterSettings={filterSettings}
        height={"100%"}
        width={"100%"}
        enableHover={false}
        showColumnChooser={true}
        allowExcelExport={true}
        ref={g => (grid = g)}
        allowReordering={true}
        allowResizing={true}
        selectionSettings={selectionOptions}
        toolbarClick={toolbarClick}
      >
        <ColumnsDirective>
          <ColumnDirective textAlign="Center" width={100} field="ID" headerText="ID" />
          <ColumnDirective
            textAlign="Center"
            width={250}
            field="NumeroContratoBilling"
            headerText="N Contrato"
          />
          <ColumnDirective textAlign="Center" width={250} field="Placa" headerText="Placa" />
          <ColumnDirective
            textAlign="Center"
            width={250}
            field="NumeroSerie"
            headerText="Numero de serie"
          />

          <ColumnDirective
            textAlign="Center"
            width={250}
            field="ResumoKits"
            headerText="ResumoKits"
          />
          <ColumnDirective
            textAlign="Center"
            width={250}
            field="ResumoServicos"
            headerText="ResumoServicos"
          />
          <ColumnDirective
            textAlign="Center"
            width={250}
            field="Equipamento"
            headerText="Equipamento"
          />
          <ColumnDirective
            textAlign="Center"
            width={250}
            field="Mensalidade"
            headerText="Mensalidade"
            valueAccessor={moneyGrudFormatter}
          />
          <ColumnDirective
            textAlign="Center"
            width={250}
            field="Descontos"
            headerText="Descontos"
            valueAccessor={moneyGrudFormatter}
          />
          <ColumnDirective
            textAlign="Center"
            width={250}
            field="ContratoGO"
            headerText="Contrato GO"
          />
          <ColumnDirective
            textAlign="Center"
            width={250}
            field="DataInstalacao"
            headerText="Data instalação"
            valueAccessor={dataGridFormater}
          />
          <ColumnDirective
            textAlign="Center"
            width={250}
            field="NotaFiscal"
            headerText="Nota finscal"
          />
          <ColumnDirective
            textAlign="Center"
            width={250}
            field="DataExpedicao"
            headerText="Data expedição"
            valueAccessor={dataGridFormater}
          />
          <ColumnDirective
            textAlign="Center"
            width={250}
            field="TipoAlteracao"
            headerText="Tipo alteração"
          />

          <ColumnDirective
            textAlign="Center"
            width={250}
            field="Fatura_ID"
            headerText="id fatura"
          />
        </ColumnsDirective>

        <Inject
          services={[
            Page,
            Sort,
            Filter,
            Group,
            Resize,
            Toolbar,
            ExcelExport,
            Reorder,
            ColumnChooser,
          ]}
        />
      </GridComponent>
    </EquipamentoFaturaContainer>
  );
};

export default EquipamentoFatura;
