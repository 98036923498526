import styled from "styled-components";

export const NewVeiculoContent = styled.div`
  gap: 14px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  height: 100%;
  .button {
    display: flex;
    gap: 12px;
    align-items: center;
    justify-content: center;
  }
`;
export const NewVeiculoGrid = styled.div`
  gap: 14px;
  display: grid;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  height: 100%;
  grid-template-columns: repeat(3, 1fr);
`;

export const NewContratoCep = styled.div`
  gap: 14px;
  display: grid;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  height: 100%;
  grid-template-columns: 1fr 2fr;
`;
export const NewVeiculoDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;
