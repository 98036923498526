import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { OptionsModel } from "shared/models/contratada_response.model";
import { EnumModel, EnumNames, ListEnumModel } from "shared/models/list_enum.model";
import { formatOptionList } from "utils/enumListToOptions";

export interface EnumListState {
  CobrancaKit?: EnumModel[];
  EquipamentoStatus?: EnumModel[];
  IndiceReajuste?: EnumModel[];
  MotivoDesinstalacao?: EnumModel[];
  TipoEquipamento?: EnumModel[];
  TipoKit?: EnumModel[];
  UsuarioStatus?: EnumModel[];
  CobrancaKitOptions?: OptionsModel[];
  EquipamentoStatusOptions?: OptionsModel[];
  IndiceReajusteOptions?: OptionsModel[];
  MotivoDesinstalacaoOptions?: OptionsModel[];
  TipoEquipamentoOptions?: OptionsModel[];
  TipoKitOptions?: OptionsModel[];
  UsuarioStatusOptions?: OptionsModel[];
}

const initialState: EnumListState = {};

const list_enum_slice = createSlice({
  name: "list_enum",
  initialState,
  reducers: {
    setEnumBilling(state, action: PayloadAction<ListEnumModel[]>) {
      action.payload.forEach(el => {
        switch (el.Name) {
          case EnumNames.CobrancaKit:
            state.CobrancaKit = el.List;
            state.CobrancaKitOptions = formatOptionList(el.List);
            break;
          case EnumNames.EquipamentoStatus:
            state.EquipamentoStatus = el.List;
            state.EquipamentoStatusOptions = formatOptionList(el.List);
            break;
          case EnumNames.IndiceReajuste:
            state.IndiceReajuste = el.List;
            state.IndiceReajusteOptions = formatOptionList(el.List);
            break;
          case EnumNames.MotivoDesinstalacao:
            state.MotivoDesinstalacao = el.List;
            state.MotivoDesinstalacaoOptions = formatOptionList(el.List);
            break;
          case EnumNames.TipoEquipamento:
            state.TipoEquipamento = el.List;
            state.TipoEquipamentoOptions = formatOptionList(el.List);
            break;
          case EnumNames.TipoKit:
            state.TipoKit = el.List;
            state.TipoKitOptions = formatOptionList(el.List);
            break;
          case EnumNames.UsuarioStatus:
            state.UsuarioStatus = el.List;
            state.UsuarioStatusOptions = formatOptionList(el.List);
            break;
        }
      });
    },
  },
});

export const { setEnumBilling } = list_enum_slice.actions;
export default list_enum_slice.reducer;
