import PrimaryButton from "components/button/billing_button";
import TertiaryButton from "components/button/tertiaty_button";
import { Body2 } from "components/text/body/style";
import { useState } from "react";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "store/store";
import billing_theme from "style/theme_colors";
import {
  NewContratanteContent,
  NewContratanteDetails,
  NewContratanteGrid,
  NewContratanteGridTwo,
  NewContratoCep,
  NewContratoRazaoCnpj,
  NewContratoThree,
} from "./style";
import BillingTextInput from "components/input/text_input";
import useNewContratante from "hooks/contratante/useNewContratante";
import { setModalNewContratante } from "store/contratante/new_contratante_slice";
import BillingSelectInput from "components/input/select";

const ModalNewContratante: React.FC = () => {
  const dispatch = useAppDispatch();
  const { addContratante } = useNewContratante();

  const state = useSelector((state: RootState) => state.new_contratante);

  const [razao, setRazao] = useState<string>();
  const [cnpj, setCpnj] = useState<string>();
  const [telefone, setTelefone] = useState<string>();
  const [email, setEmail] = useState<string>();
  const [logradouro, setLogradouro] = useState<string>();
  const [numero, setNumero] = useState<string>();
  const [complemento, setComplemento] = useState<string>();
  const [bairro, setBairro] = useState<string>();
  const [cidade, setCidade] = useState<string>();
  const [uf, setUf] = useState<string>();
  const [cep, setCEP] = useState<string>();
  const [empresa, setEmpresa] = useState<string>();
  return (
    <NewContratanteContent>
      <ContratanteInfo
        razao={value => {
          setRazao(value);
        }}
        CNPJ={value => {
          setCpnj(value);
        }}
        email={value => {
          setEmail(value);
        }}
        telefone={value => {
          setTelefone(value);
        }}
        empresa={value => {
          setEmpresa(value);
        }}
      />

      <NewContratoCep>
        <NewContratanteDetails>
          <Body2 fontWeight={600} color={billing_theme.pallete.tertiary?.A400}>
            CEP:
          </Body2>

          <BillingTextInput
            placeholder="CEP"
            mask={"99.999-999"}
            change={value => {
              setCEP(value);
            }}
          />
        </NewContratanteDetails>

        <NewContratanteDetails>
          <Body2 fontWeight={600} color={billing_theme.pallete.tertiary?.A400}>
            Logradouro:
          </Body2>

          <BillingTextInput
            placeholder="Logradouro"
            change={value => {
              setLogradouro(value);
            }}
          />
        </NewContratanteDetails>
      </NewContratoCep>

      <NewContratanteGridTwo>
        <NewContratanteDetails>
          <Body2 fontWeight={600} color={billing_theme.pallete.tertiary?.A400}>
            Cidade:
          </Body2>

          <BillingTextInput
            placeholder="Cidade"
            change={value => {
              setCidade(value);
            }}
          />
        </NewContratanteDetails>

        <NewContratanteDetails>
          <Body2 fontWeight={600} color={billing_theme.pallete.tertiary?.A400}>
            Bairro:
          </Body2>

          <BillingTextInput
            placeholder="Bairro"
            change={value => {
              setBairro(value);
            }}
          />
        </NewContratanteDetails>
      </NewContratanteGridTwo>

      <NewContratanteGrid>
        <NewContratanteDetails>
          <Body2 fontWeight={600} color={billing_theme.pallete.tertiary?.A400}>
            Numero:
          </Body2>

          <BillingTextInput
            placeholder="Numero"
            change={value => {
              setNumero(value);
            }}
          />
        </NewContratanteDetails>

        <NewContratanteDetails>
          <Body2 fontWeight={600} color={billing_theme.pallete.tertiary?.A400}>
            Complemento:
          </Body2>

          <BillingTextInput
            placeholder="Complemento"
            change={value => {
              setComplemento(value);
            }}
          />
        </NewContratanteDetails>

        <NewContratanteDetails>
          <Body2 fontWeight={600} color={billing_theme.pallete.tertiary?.A400}>
            UF:
          </Body2>

          <BillingTextInput
            placeholder="UF"
            change={value => {
              setUf(value);
            }}
          />
        </NewContratanteDetails>
      </NewContratanteGrid>

      <div className="button">
        <TertiaryButton
          label={"Cancelar"}
          onTap={() => dispatch(setModalNewContratante(false))}
          isSmall
        />
        <PrimaryButton
          disable={state.loading}
          label={"Adicionar contratante"}
          onTap={async () => {
            await addContratante({
              ID: 0,
              EmpresaPrincipal_ID: empresa ? Number(empresa) : null,
              RazaoSocial: razao,
              Telefone: telefone,
              CEP: cep,
              CNPJ: cnpj,
              Complemento: complemento,
              Cidade: cidade,
              Email: email,
              Logradouro: logradouro,
              Numero: numero,
              UF: uf,
              Bairro: bairro,
            });
          }}
          isSmall
        />
      </div>
    </NewContratanteContent>
  );
};

const ContratanteInfo: React.FC<{
  razao: (value: string | undefined) => void;
  CNPJ: (value: string | undefined) => void;
  email: (value: string | undefined) => void;
  telefone: (value: string | undefined) => void;
  empresa: (value: string | undefined) => void;
}> = ({ razao, CNPJ, email, telefone, empresa }) => {
  const contratantes = useSelector((state: RootState) => state.contratante);

  return (
    <NewContratanteContent>
      <NewContratoRazaoCnpj>
        <NewContratanteDetails>
          <Body2 fontWeight={600} color={billing_theme.pallete.tertiary?.A400}>
            Razão social:
          </Body2>

          <BillingTextInput placeholder="Razão social" change={razao} />
        </NewContratanteDetails>
        <NewContratanteDetails>
          <Body2 fontWeight={600} color={billing_theme.pallete.tertiary?.A400}>
            CNPJ:
          </Body2>

          <BillingTextInput placeholder="CNPJ" mask="99.999.999/9999-99" change={CNPJ} />
        </NewContratanteDetails>
      </NewContratoRazaoCnpj>

      <NewContratoThree>
        <NewContratanteDetails>
          <Body2 fontWeight={600} color={billing_theme.pallete.tertiary?.A400}>
            Email:
          </Body2>

          <BillingTextInput placeholder="Email" change={email} />
        </NewContratanteDetails>
        <NewContratanteDetails>
          <Body2 fontWeight={600} color={billing_theme.pallete.tertiary?.A400}>
            Telefone:
          </Body2>

          <BillingTextInput placeholder="Telefone" mask="(99) 99999-9999" change={telefone} />
        </NewContratanteDetails>
        <NewContratanteDetails>
          <Body2 fontWeight={600} color={billing_theme.pallete.tertiary?.A400}>
            Empresa principal:
          </Body2>

          <BillingSelectInput
            placeholder="Selecione um contratante"
            options={contratantes.options}
            onChange={value => {
              empresa(value.value);
            }}
          />
        </NewContratanteDetails>
      </NewContratoThree>
    </NewContratanteContent>
  );
};

export default ModalNewContratante;
