import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { EquipamentoModel } from "shared/models/equipamento_response.model";

export interface ViewEquipamento {
  equipamento?: EquipamentoModel;
  loading?: boolean;
  isError?: boolean;
  isOpen: boolean;
  selectedId?: number;
}

const initialState: ViewEquipamento = {
  loading: true,
  isError: false,
  isOpen: false,
};

const view_equipamento_slice = createSlice({
  name: "view_equipamento",
  initialState,
  reducers: {
    setEquipamento(state, action: PayloadAction<EquipamentoModel>) {
      state.equipamento = action.payload;
    },
    setLoadingViewEquipamento(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },

    setErrorViewEquipamento(state, action: PayloadAction<boolean>) {
      state.isError = action.payload;
    },
    setModalEquipamento(state, action: PayloadAction<boolean>) {
      state.isOpen = action.payload;
    },
    setIdEquipamentoView(state, action: PayloadAction<number>) {
      state.selectedId = action.payload;
    },
  },
});

export const {
  setEquipamento,
  setLoadingViewEquipamento,
  setErrorViewEquipamento,
  setModalEquipamento,
  setIdEquipamentoView,
} = view_equipamento_slice.actions;
export default view_equipamento_slice.reducer;
