import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ContratadaListModel, OptionsModel } from "shared/models/contratada_response.model";
import { Configuracao } from "shared/models/default_models.model";

export interface ContratoSliceType {
  list: ContratadaListModel[];
  filter: ContratadaListModel[];
  options: OptionsModel[];
  loading?: boolean;
  isError?: boolean;
  selectDeleteId?: number;
  openDeleteModal: boolean;
  loadingDelete: boolean;
  pagination?: ContratadaListModel[][];
  pageIndex: number;
  lastPage?: number;
}

const initialState: ContratoSliceType = {
  list: [],
  options: [],
  loading: true,
  isError: false,
  filter: [],
  openDeleteModal: false,
  loadingDelete: false,
  pageIndex: 0,
};

const contratada_slice = createSlice({
  name: "contratada",
  initialState,
  reducers: {
    setContratadaList(state, action: PayloadAction<ContratadaListModel[]>) {
      state.list = action.payload;
    },

    setContratadaOptions(state, action: PayloadAction<OptionsModel[]>) {
      state.options = action.payload;
    },
    setContratadaLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    setContradaError(state, action: PayloadAction<boolean>) {
      state.isError = action.payload;
    },
    setContratadoDeleteModal(state, action: PayloadAction<boolean>) {
      state.openDeleteModal = action.payload;
    },
    setCotratadaDeleteId(state, action: PayloadAction<number>) {
      state.selectDeleteId = action.payload;
    },
    setLoadingContratada(state, action: PayloadAction<boolean>) {
      state.loadingDelete = action.payload;
    },
    setPaginationContratada(state, action: PayloadAction<ContratadaListModel[][]>) {
      state.pagination = action.payload;
    },
    setContratadaPageIndex(state, action: PayloadAction<number>) {
      state.pageIndex = action.payload;
    },
    setContratadaLastPage(state, action: PayloadAction<number>) {
      state.lastPage = action.payload;
    },
  },
});

export const {
  setContratadaList,
  setContratadaOptions,
  setContratadaLoading,
  setContradaError,
  setCotratadaDeleteId,
  setLoadingContratada,
  setContratadoDeleteModal,
  setPaginationContratada,
  setContratadaPageIndex,
  setContratadaLastPage,
} = contratada_slice.actions;
export default contratada_slice.reducer;
