import { FaturaContainer, FaturaHeader, FaturaMain } from "./style";
import Scaffold from "components/scaffold";
import { useEffect } from "react";
import InsideMenu from "components/insidemenu";
import InsideMenuButton from "components/insidemenu/components/inside_menu_button";
import useFatura from "hooks/fatura/useFatura";
import PrimaryButton from "components/button/billing_button";
import { Body2 } from "components/text/body/style";
import { Heading3 } from "components/text/heading/style";
import billing_theme from "style/theme_colors";
import SkeletonFaturaList from "./components/fatura_list_skeleton";
import FaturaList from "./components/fatura_list";
import FaturaFilter from "./components/fatura_filter";
import ModalBilling from "components/modal";
import NovaFatura from "view/Fatura/pages/NovaFatura";
import FaturaErrorContent from "./components/fatura_list_error";
import { setNovaFaturalModalState } from "store/fatura/fatura_slice";
import { RootState, useAppDispatch } from "store/store";
import { useSelector } from "react-redux";
import InsideMenuHeader from "components/insidemenu/components/inside_button_header";

const FaturaPage: React.FC = () => {
  const { getFatura } = useFatura();
  const fatura = useSelector((state: RootState) => state.fatura);
  const dispatch = useAppDispatch();

  useEffect(() => {
    getFatura();
  }, []);

  return (
    <Scaffold>
      <FaturaContainer>
        <InsideMenu title={"Fatura"} icon={"universal_currency_alt"}>
          <InsideMenuHeader name="Faturamento" />
          <InsideMenuButton path={"/faturamento"} name={"Fatura"} icon={"universal_currency_alt"} />
        </InsideMenu>
        <FaturaMain>
          <FaturaHeader>
            <div className="">
              <Heading3 fontWeight={600} color={billing_theme.pallete.tertiary?.A400}>
                Fatura
              </Heading3>
              <Body2 color={billing_theme.pallete.tertiary?.A400}>
                Listagem de faturas na plataforma.
              </Body2>
            </div>

            <PrimaryButton
              label={"Nova fatura"}
              leftIcon={"add"}
              isSmall={true}
              disable={fatura.loading}
              onTap={() => dispatch(setNovaFaturalModalState(true))}
            />
          </FaturaHeader>
          {fatura.isError && <FaturaErrorContent retry={getFatura} />}
          {!fatura.isError && (fatura.loading ? <></> : <FaturaFilter />)}
          {!fatura.isError && (fatura.loading ? <SkeletonFaturaList /> : <FaturaList />)}
          <ModalBilling modalIsOpen={fatura.openNovaFaturalModal} width="450px" height="380px">
            <NovaFatura />
          </ModalBilling>
        </FaturaMain>
      </FaturaContainer>
    </Scaffold>
  );
};

export default FaturaPage;
