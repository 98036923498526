import BillingTextInput from "components/input/text_input";
import useVeiculo from "hooks/veiculo/useVeiculo";
import React from "react";
import { SearchContent, VeiculoFilterContent, SearchInputContent } from "./style";
import { Heading6 } from "components/text/heading/style";
import billing_theme from "style/theme_colors";
import { RootState } from "store/store";
import { useSelector } from "react-redux";
import BillingDatePicker from "components/input/datePicker";
const VeiculoFilter: React.FC<{}> = () => {
  const {} = useVeiculo();
  const list_enum = useSelector((state: RootState) => state.list_enum);
  return (
    <VeiculoFilterContent>
      <SearchContent>
        <SearchInputContent>
          <Heading6 fontWeight={600} color={billing_theme.pallete.tertiary.A400}>
            Procurar:
          </Heading6>
          <BillingTextInput
            placeholder="Placa, Contratante, Cliente e etc."
            change={value => console.log(value!)}
          />
        </SearchInputContent>
      </SearchContent>
    </VeiculoFilterContent>
  );
};

export default VeiculoFilter;
