import { VeiculoContainer, VeiculoHeader } from "./style";
import { Heading3 } from "components/text/heading/style";
import { Body2 } from "components/text/body/style";
import billing_theme from "style/theme_colors";
import Scaffold from "components/scaffold";
import PrimaryButton from "components/button/billing_button";
import { useEffect } from "react";
import InsideMenu from "components/insidemenu";
import InsideMenuButton from "components/insidemenu/components/inside_menu_button";
import SkeletonVeiculoList from "./components/veiculo_skeleton";
import ModalBilling from "components/modal";
import useVeiculo from "hooks/veiculo/useVeiculo";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "store/store";
import ModalNewVeiculo from "./page/NewVeiculo";
import { setModalNewVeiculo } from "store/veiculo/new_veiculo_slice";
import VeiculoErrorContent from "./components/veiculo_list_error";
import VeiculoFilter from "./components/veiculo_filter";
import VeiculoList from "./components/veiculo_list";
import OpecaoesInsideMenu from "components/insidemenu/components/opecaoes_menu";

const VeiculoPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const { getVeiculoList } = useVeiculo();

  const veiculo = useSelector((state: RootState) => state.veiculo);
  const new_veiculo = useSelector((state: RootState) => state.new_veiculo);
  useEffect(() => {
    getVeiculoList();
  }, []);
  return (
    <Scaffold>
      <VeiculoContainer>
        <InsideMenu title={"Veiculo"} icon={"work"}>
          <OpecaoesInsideMenu />
        </InsideMenu>
        <div className="main_content">
          <VeiculoHeader>
            <div className="">
              <Heading3 fontWeight={600} color={billing_theme.pallete.tertiary?.A400}>
                Veiculo
              </Heading3>
              <Body2 color={billing_theme.pallete.tertiary?.A400}>Veja os veiculos alocados.</Body2>
            </div>

            <PrimaryButton
              label={"Novo veiculo"}
              leftIcon={"add"}
              isSmall
              onTap={() => dispatch(setModalNewVeiculo(true))}
            />
          </VeiculoHeader>
          {veiculo.isError && <VeiculoErrorContent retry={() => getVeiculoList()} />}

          {!veiculo.isError && <VeiculoFilter />}

          {!veiculo.isError && (veiculo.loading ? <SkeletonVeiculoList /> : <VeiculoList />)}
        </div>
      </VeiculoContainer>

      <ModalBilling modalIsOpen={new_veiculo.isOpen} width="400px" height="auto">
        <ModalNewVeiculo />
      </ModalBilling>
    </Scaffold>
  );
};

export default VeiculoPage;
