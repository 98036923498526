import styled from "styled-components";
import billing_theme from "style/theme_colors";

export const ContractSummaryContent = styled.div`
  border-right: 0.5px solid #3d354a;

  display: flex;
  flex-direction: column;
  overflow-y: scroll;
`;
export const SummryFieldContent = styled.div`
  display: flex;
  padding: 0px 16px 8px 16px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 4px;
  flex: 1 0 0;
  border: 0.5px solid ${billing_theme.pallete.tertiary.A100};
  border-bottom: none;
  border-left: none;
`;
export const SummryTitleContent = styled.div`
  display: flex;
  padding: 16px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 4px;
  flex: 1 0 0;
  border: 0.5px solid ${billing_theme.pallete.tertiary.A50};
  border-bottom: none;
  border-left: none;
  background-color: ${billing_theme.pallete.specific.T_50_25};
`;
export const SummryAboveFieldContent = styled.div`
  display: flex;
  padding: 4px 16px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 4px;
  flex: 1 0 0;
  border: 0.5px solid ${billing_theme.pallete.tertiary.A50};
  background-color: ${billing_theme.pallete.specific.T_50_25};
  border: 0.5px solid ${billing_theme.pallete.tertiary.A100};
  border-bottom: none;
  border-left: none;
  border-right: none;
`;
export const SummaryGrid = styled.div<{ gridnumber: number }>`
  display: grid;
  grid-template-columns: ${props => " repeat(" + props.gridnumber + ", 1fr)"};
`;
