import LineSkeleton from "components/skeleton/line_skeleton";
import {
  ServicoListButton,
  ServicoListContent,
  ServicoListHeader,
  SkeletonServicoListItensContainer,
} from "./style";
import React from "react";
const SkeletonServicoList: React.FC<{}> = () => {
  const array = ["", "", "", ""];
  return (
    <ServicoListContent>
      <ServicoListHeader>
        {array.map((_, i) => (
          <LineSkeleton height={30} width={150} key={i} />
        ))}
      </ServicoListHeader>

      <SkeletonServicoListItensContainer>
        <LineSkeleton height={58} width={"100%"} />
      </SkeletonServicoListItensContainer>
      <SkeletonServicoListItensContainer>
        <LineSkeleton height={58} width={"100%"} />
      </SkeletonServicoListItensContainer>
      <SkeletonServicoListItensContainer>
        <LineSkeleton height={58} width={"100%"} />
      </SkeletonServicoListItensContainer>
      <SkeletonServicoListItensContainer>
        <LineSkeleton height={58} width={"100%"} />
      </SkeletonServicoListItensContainer>
      <SkeletonServicoListItensContainer>
        <LineSkeleton height={58} width={"100%"} />
      </SkeletonServicoListItensContainer>
      <SkeletonServicoListItensContainer>
        <LineSkeleton height={58} width={"100%"} />
      </SkeletonServicoListItensContainer>
    </ServicoListContent>
  );
};

const SkeletonListButton: React.FC = () => {
  return (
    <ServicoListButton>
      <LineSkeleton height={30} width={90} />
    </ServicoListButton>
  );
};

export default SkeletonServicoList;
