import PrimaryButton from "components/button/billing_button";
import TertiaryButton from "components/button/tertiaty_button";
import { Body2 } from "components/text/body/style";
import { useState } from "react";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "store/store";
import billing_theme from "style/theme_colors";
import {
  NewContratadaContent,
  NewContratadaDetails,
  NewContratadaGrid,
  NewContratadaGridTwo,
  NewContratoCep,
  NewContratoRazaoCnpj,
} from "./style";
import BillingTextInput from "components/input/text_input";
import { setModalNewContratada } from "store/contratada/new_contratada_slice";
import useNewContratada from "hooks/contratada/useNewContratada";

const ModalNewContratada: React.FC = () => {
  const dispatch = useAppDispatch();
  const { addContratada } = useNewContratada();

  const state = useSelector((state: RootState) => state.new_contratada);

  const [razao, setRazao] = useState<string>();
  const [cnpj, setCpnj] = useState<string>();
  const [telefone, setTelefone] = useState<string>();
  const [email, setEmail] = useState<string>();
  const [logradouro, setLogradouro] = useState<string>();
  const [numero, setNumero] = useState<string>();
  const [complemento, setComplemento] = useState<string>();
  const [bairro, setBairro] = useState<string>();
  const [cidade, setCidade] = useState<string>();
  const [uf, setUf] = useState<string>();
  const [cep, setCEP] = useState<string>();
  return (
    <NewContratadaContent>
      <ContratadaInfo
        razao={value => {
          setRazao(value);
        }}
        CNPJ={value => {
          setCpnj(value);
        }}
        email={value => {
          setEmail(value);
        }}
        telefone={value => {
          setTelefone(value);
        }}
      />

      <NewContratoCep>
        <NewContratadaDetails>
          <Body2 fontWeight={600} color={billing_theme.pallete.tertiary?.A400}>
            CEP:
          </Body2>

          <BillingTextInput
            placeholder="CEP"
            mask={"99.999-999"}
            change={value => {
              setCEP(value);
            }}
          />
        </NewContratadaDetails>

        <NewContratadaDetails>
          <Body2 fontWeight={600} color={billing_theme.pallete.tertiary?.A400}>
            Logradouro:
          </Body2>

          <BillingTextInput
            placeholder="Logradouro"
            change={value => {
              setLogradouro(value);
            }}
          />
        </NewContratadaDetails>
      </NewContratoCep>

      <NewContratadaGridTwo>
        <NewContratadaDetails>
          <Body2 fontWeight={600} color={billing_theme.pallete.tertiary?.A400}>
            Cidade:
          </Body2>

          <BillingTextInput
            placeholder="Cidade"
            change={value => {
              setCidade(value);
            }}
          />
        </NewContratadaDetails>

        <NewContratadaDetails>
          <Body2 fontWeight={600} color={billing_theme.pallete.tertiary?.A400}>
            Bairro:
          </Body2>

          <BillingTextInput
            placeholder="Bairro"
            change={value => {
              setBairro(value);
            }}
          />
        </NewContratadaDetails>
      </NewContratadaGridTwo>

      <NewContratadaGrid>
        <NewContratadaDetails>
          <Body2 fontWeight={600} color={billing_theme.pallete.tertiary?.A400}>
            Numero:
          </Body2>

          <BillingTextInput
            placeholder="Numero"
            change={value => {
              setNumero(value);
            }}
          />
        </NewContratadaDetails>

        <NewContratadaDetails>
          <Body2 fontWeight={600} color={billing_theme.pallete.tertiary?.A400}>
            Complemento:
          </Body2>

          <BillingTextInput
            placeholder="Complemento"
            change={value => {
              setComplemento(value);
            }}
          />
        </NewContratadaDetails>

        <NewContratadaDetails>
          <Body2 fontWeight={600} color={billing_theme.pallete.tertiary?.A400}>
            UF:
          </Body2>

          <BillingTextInput
            placeholder="UF"
            change={value => {
              setUf(value);
            }}
          />
        </NewContratadaDetails>
      </NewContratadaGrid>

      <div className="button">
        <TertiaryButton
          label={"Cancelar"}
          onTap={() => dispatch(setModalNewContratada(false))}
          isSmall
        />
        <PrimaryButton
          disable={state.loading}
          label={"Adicionar"}
          onTap={async () => {
            await addContratada({
              ID: 0,
              RazaoSocial: razao,
              Telefone: telefone,
              CEP: cep,
              CNPJ: cnpj,
              Complemento: complemento,
              Cidade: cidade,
              Email: email,
              Logradouro: logradouro,
              Numero: numero,
              UF: uf,
              Bairro: bairro,
            });
          }}
          isSmall
        />
      </div>
    </NewContratadaContent>
  );
};

const ContratadaInfo: React.FC<{
  razao: (value: string | undefined) => void;
  CNPJ: (value: string | undefined) => void;
  email: (value: string | undefined) => void;
  telefone: (value: string | undefined) => void;
}> = ({ razao, CNPJ, email, telefone }) => {
  return (
    <NewContratadaContent>
      <NewContratoRazaoCnpj>
        <NewContratadaDetails>
          <Body2 fontWeight={600} color={billing_theme.pallete.tertiary?.A400}>
            Razão social:
          </Body2>

          <BillingTextInput placeholder="Razão social" change={razao} />
        </NewContratadaDetails>
        <NewContratadaDetails>
          <Body2 fontWeight={600} color={billing_theme.pallete.tertiary?.A400}>
            CNPJ:
          </Body2>

          <BillingTextInput placeholder="CNPJ" mask="99.999.999/9999-99" change={CNPJ} />
        </NewContratadaDetails>
      </NewContratoRazaoCnpj>
      <NewContratoRazaoCnpj>
        <NewContratadaDetails>
          <Body2 fontWeight={600} color={billing_theme.pallete.tertiary?.A400}>
            Email:
          </Body2>

          <BillingTextInput placeholder="Email" change={email} />
        </NewContratadaDetails>
        <NewContratadaDetails>
          <Body2 fontWeight={600} color={billing_theme.pallete.tertiary?.A400}>
            Telefone:
          </Body2>

          <BillingTextInput placeholder="Telefone" mask="(99) 99999-9999" change={telefone} />
        </NewContratadaDetails>
      </NewContratoRazaoCnpj>
    </NewContratadaContent>
  );
};

export default ModalNewContratada;
