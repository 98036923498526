import styled from "styled-components";
import billing_theme from "style/theme_colors";

export const ContratoDataContainer = styled.div`
  border-right: 0.5px solid ${billing_theme.pallete.tertiary?.A100};
  height: 98%;
  padding: 0 16px 16px 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  overflow-y: scroll;

  .select_kit_principal {
    display: grid;
    grid-template-columns: 65% 1fr;
    gap: 8px;
  }
  .nao_reaju {
    border-radius: 4px;
    border: 0.5px solid ${billing_theme.pallete.tertiary?.A200};
    font-size: 14px;
    line-height: 27px;
    margin: 0px;
    padding: 4px 0px 3px 12px;
    transition: 300ms;

    font-weight: 500;
    width: calc(100% - 12px);
    background-color: ${billing_theme.pallete.specific.T_50_25};
  }
  .prorata {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
`;
export const ContratoInsideContent = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const ContratoDataGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 8px;
`;
export const ContratoDataGridTwo = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 8px;
`;
