import { EditContratoContent, EditContratoGrid, EditContratoHeader, StepDiv } from "./style";
import React, { useEffect } from "react";
import Scaffold from "components/scaffold";
import { Body2 } from "components/text/body/style";
import { Heading3 } from "components/text/heading/style";
import billing_theme from "style/theme_colors";
import { RootState, useAppDispatch } from "store/store";
import useEditContrato from "hooks/contrato/useEditContrato";
import { useSelector } from "react-redux";
import ContratoStepIndicator from "./components/contrato_step_indicator";
import { setActualPage } from "store/contrato/view_contrato_slice";
import ValoresTecnicos from "./components/valores_tecnicos";
import DadosDoContrato from "./components/contract_data";
import KitAdicional from "./components/kit_adicional";
import ServicoAdicional from "./components/servico_adicional";
import ContractSummary from "./components/contract_summary";
import { toastWarning } from "components/toast/billing_toast";
import ContratoBackButton from "view/Contrato/components/contrato_back_button";
import EditContratoSkeleton from "./components/contrato_skeleton";

const EditContratoPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const { getContratoInfo } = useEditContrato();

  const edit_contrato = useSelector((state: RootState) => state.view_contrato);
  useEffect(() => {
    getContratoInfo();
  }, []);

  return (
    <>
      <Scaffold>
        <EditContratoContent>
          <EditContratoHeader>
            <div>
              <Heading3 fontWeight={600} color={billing_theme.pallete.tertiary?.A600}>
                Contrato: {edit_contrato.Numero}
              </Heading3>
              <Body2 color={billing_theme.pallete.tertiary?.A600}>Edição de contrato.</Body2>
            </div>

            <ContratoBackButton />
          </EditContratoHeader>
          <EditContratoGrid $isResume={!edit_contrato.loading && edit_contrato.pageIndex == 2}>
            <StepDiv>
              <ContratoStepIndicator
                onClick={() => {
                  dispatch(setActualPage(0));
                }}
                title={"Dados de contrato"}
                text="Informações do contratante; contratados e kit de instalações"
                icon={"info"}
                isSelected={edit_contrato.pageIndex == 0}
              />
              <ContratoStepIndicator
                onClick={() => {
                  if (
                    edit_contrato.Contratante_ID != undefined &&
                    edit_contrato.Contratada_ID != undefined &&
                    edit_contrato.KitPrincipal_ID != undefined &&
                    edit_contrato.Quantidade != undefined &&
                    edit_contrato.Mensalidade != undefined &&
                    edit_contrato.ValorReajustavel != undefined &&
                    edit_contrato.DuracaoMeses != undefined &&
                    edit_contrato.CarenciaDias != undefined &&
                    edit_contrato.FidelidadeMeses != undefined &&
                    edit_contrato.Indice != undefined &&
                    edit_contrato.PercentualDesconto != undefined &&
                    edit_contrato.MesesDesconto != undefined
                  ) {
                    dispatch(setActualPage(1));
                  } else {
                    toastWarning({ title: "Verifique todos os campos!" });
                  }
                }}
                title={"Complementares"}
                text="Kits e serviços adicionais"
                icon={"info"}
                isSelected={edit_contrato.pageIndex == 1}
              />
              <ContratoStepIndicator
                onClick={() => {
                  if (
                    edit_contrato.Contratante_ID != undefined &&
                    edit_contrato.Contratada_ID != undefined &&
                    edit_contrato.KitPrincipal_ID != undefined &&
                    edit_contrato.Quantidade != undefined &&
                    edit_contrato.Mensalidade != undefined &&
                    edit_contrato.ValorReajustavel != undefined &&
                    edit_contrato.DuracaoMeses != undefined &&
                    edit_contrato.CarenciaDias != undefined &&
                    edit_contrato.FidelidadeMeses != undefined &&
                    edit_contrato.Indice != undefined &&
                    edit_contrato.PercentualDesconto != undefined &&
                    edit_contrato.MesesDesconto != undefined
                  ) {
                    dispatch(setActualPage(2));
                  } else {
                    toastWarning({ title: "Verifique todos os campos!" });
                  }
                }}
                title={"Resumo do contrato"}
                text="Revisão geral e envio para aprovação"
                icon={"info"}
                isSelected={edit_contrato.pageIndex == 2}
              />
            </StepDiv>

            {edit_contrato.loading && <EditContratoSkeleton />}
            {edit_contrato.loading && <EditContratoSkeleton />}
            {!edit_contrato.loading && edit_contrato.pageIndex == 0 && <DadosDoContrato />}
            {!edit_contrato.loading && edit_contrato.pageIndex == 0 && <ValoresTecnicos />}
            {!edit_contrato.loading && edit_contrato.pageIndex == 1 && <KitAdicional />}
            {!edit_contrato.loading && edit_contrato.pageIndex == 1 && <ServicoAdicional />}
            {!edit_contrato.loading && edit_contrato.pageIndex == 2 && <ContractSummary />}
          </EditContratoGrid>
        </EditContratoContent>
      </Scaffold>
    </>
  );
};

export default EditContratoPage;
