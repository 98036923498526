import { SymbolCodepoints } from "react-material-symbols";
import { Bounce, toast } from "react-toastify";
import billing_theme from "style/theme_colors";
import InfoToastComp from "./info";
import WarningToastComp from "./warning";
import ErrorToastComp from "./error";
import SuccessToastComp from "./sucess";

export interface toastprops {
  title: string;
  icon?: SymbolCodepoints;
  message?: string;
  time?: number;
}

export const toastSuccess = ({ title, message, icon, time }: toastprops) =>
  toast(SuccessToastComp({ title, message, icon }), {
    position: "top-right",
    autoClose: time ?? 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    theme: "colored",
    transition: Bounce,
    bodyClassName: "BillingToast",
    className: "BillingToastGeral",
    progressStyle: {
      background: billing_theme.pallete.success.A100,
    },
  });

export const toastError = ({ title, message, icon, time }: toastprops) =>
  toast(ErrorToastComp({ title, message, icon }), {
    position: "top-right",
    autoClose: time ?? 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    theme: "colored",
    transition: Bounce,
    bodyClassName: "BillingToast",
    className: "BillingToastGeral",
    progressStyle: {
      background: billing_theme.pallete.error.A100,
    },
  });

export const toastWarning = ({ title, message, icon, time }: toastprops) =>
  toast(WarningToastComp({ title, message, icon }), {
    position: "top-right",
    autoClose: time ?? 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    theme: "colored",
    transition: Bounce,
    bodyClassName: "BillingToast",
    className: "BillingToastGeral",
    progressStyle: {
      background: billing_theme.pallete.warning.A100,
    },
  });

export const toastInfo = ({ title, message, icon, time }: toastprops) =>
  toast(InfoToastComp({ title, message, icon }), {
    position: "top-right",
    autoClose: time ?? 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    theme: "colored",
    transition: Bounce,
    bodyClassName: "BillingToast",
    className: "BillingToastGeral",
    progressStyle: {
      background: billing_theme.pallete.primary.A400,
    },
  });
