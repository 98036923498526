import billing_theme from "style/theme_colors";
import styled from "styled-components";

export const NovoContratoContent = styled.div`
  width: 100%;

  .contain {
    padding: 16px 16px;
  }
  .css-13cymwt-control {
    border-radius: 8px;
    border: solid 0.5px ${billing_theme.pallete.tertiary?.A200};
  }

  .css-13cymwt-control:hover {
    border-radius: 8px;
    border: solid 0.5px ${billing_theme.pallete.primary?.A200};
  }
  .css-t3ipsp-control:hover {
    border: solid 0.5px ${billing_theme.pallete.primary?.A200};
    border-color: ${billing_theme.pallete.primary?.A200};
  }

  .css-13cymwt-control:focus {
    border: solid 0.5px ${billing_theme.pallete.primary?.A200};
    border-color: ${billing_theme.pallete.primary?.A200};
  }
  .css-1jqq78o-placeholder {
    color: ${billing_theme.pallete.tertiary?.A300};
    font-weight: 600;
    font-size: 13px;
  }

  .css-1dimb5e-singleValue {
    color: ${billing_theme.pallete.tertiary?.A400};
    font-size: 13px;
    font-weight: 500;
  }

  .content {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
`;
export const NovoContratoHeader = styled.div`
  padding: 16px 32px;
  border-bottom: solid 0.5px ${billing_theme.pallete.tertiary?.A100};
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: flex-start;
  justify-content: space-between;
`;
export const StepDiv = styled.div`
  height: 100%;
  border-right: 0.5px solid ${billing_theme.pallete.tertiary?.A100};
`;
export const NovoContratoInputLabel = styled.div`
  display: flex;
  gap: 4px;
  flex-direction: column;
`;

export const NovoContratosForm = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;

  .contrantes_contratoInicial {
    display: grid;
    grid-template-columns: 65% 1fr;
    gap: 16px;
  }
`;
export const NovoContratoGrid = styled.div<{ $isResume: boolean }>`
  display: grid;
  grid-template-columns: ${props => (props.$isResume ? "370px 1fr" : "370px 1fr 1fr")};
  height: ${window.innerHeight - (64 + 93) + "px"};
`;
