import React from "react";
import { TertiaryButtonContent } from "./style";
import billing_theme from "style/theme_colors";
import { MaterialSymbol, SymbolCodepoints } from "react-material-symbols";
import "react-material-symbols/sharp";

type ButtonProps = {
  leftIcon?: SymbolCodepoints;
  rigthIcon?: SymbolCodepoints;
  disable?: boolean;
  label: string;
  isFilled?: boolean;
  isSmall?: boolean;
  onTap?: () => void;
};
const TertiaryButton: React.FC<ButtonProps> = ({
  label,
  leftIcon,
  rigthIcon,
  disable = false,
  isFilled = false,
  isSmall = false,
  onTap,
}) => {
  const buttonColor = (isDisable: boolean, isFilled: boolean): string => {
    if (isDisable) return billing_theme.pallete.tertiary?.A400;
    if (isFilled) return billing_theme.pallete.tertiary?.A400;
    return billing_theme.pallete.tertiary?.A50;
  };

  const onAction = () => {
    if (!disable) onTap!();
  };
  return (
    <TertiaryButtonContent
      $isdisable={disable}
      $isfilled={isFilled}
      $issmall={isSmall}
      disabled={disable}
      onClick={() => onAction()}
    >
      <div>
        {leftIcon && (
          <MaterialSymbol
            icon={leftIcon}
            size={20}
            fill={false}
            grade={-25}
            color={buttonColor(disable, isFilled)}
          />
        )}
        {label}
        {rigthIcon && (
          <MaterialSymbol
            icon={rigthIcon}
            size={20}
            fill={false}
            grade={-25}
            color={buttonColor(disable, isFilled)}
          />
        )}
      </div>
    </TertiaryButtonContent>
  );
};

export default TertiaryButton;
