export enum LocalStorageEnums {
  token = "token",
  user = "user",
  senha = "senha",
  horarioLogin = "horarioLogin",
}
export const useLocalStorage = () => {
  const setLocalStorage = (name: LocalStorageEnums, value: any) => {
    window.localStorage.setItem(name, JSON.stringify(value));
  };
  const setLocalStorageAsString = (name: LocalStorageEnums, value: any) => {
    window.localStorage.setItem(name, value);
  };
  const getLocalStorage = (name: LocalStorageEnums): any => {
    const value = window.localStorage.getItem(name);
    if (value) {
      return JSON.parse(value);
    }
    return "";
  };
  const getLocalStorageAsString = (name: LocalStorageEnums): string => {
    const value = window.localStorage.getItem(name);
    if (value) {
      return value;
    }
    return "";
  };
  const deleteLocalStorage = (name: LocalStorageEnums): any => {
    window.localStorage.removeItem(name);
  };
  return {
    setLocalStorage,
    getLocalStorage,
    deleteLocalStorage,
    getLocalStorageAsString,
    setLocalStorageAsString,
  };
};
