import billing_theme from "style/theme_colors";
import { Heading5, Heading6 } from "components/text/heading/style";
import { Body4 } from "components/text/body/style";
import { NovoContratoInputLabel } from "../../../style";
import { RootState, useAppDispatch } from "store/store";
import {
  FormRegrasContent,
  FormRegrasTwoGrid,
  ButtonSection,
  HeaderFormRegras,
  ValoresDataGrid,
} from "../style";
import BillingIconSharp from "components/billing_icon/sharp_icon";
import BillingNumberInput from "components/input/number_input";
import BillingCheckbox from "components/input/checkbox";
import TertiaryButton from "components/button/tertiaty_button";
import PrimaryButton from "components/button/billing_button";
import { useState } from "react";
import BillingTextInput from "components/input/text_input";
import { editRegra, removeRegras } from "store/contrato/new_contrato_slice";
import { RegrasInstalacao } from "shared/models/contrato_post.model";
import { toMoney, toPercent } from "utils/numberUtils";
import { useSelector } from "react-redux";

const RegrasCard: React.FC<{ regras: RegrasInstalacao; index: number }> = ({ regras, index }) => {
  const [edit, setEdit] = useState<boolean>(false);
  return edit ? (
    <FormRegras
      regras={regras}
      index={index}
      changeEdit={() => {
        setEdit(!edit);
      }}
    />
  ) : (
    <ViewRegrasCard
      regras={regras}
      index={index}
      changeEdit={() => {
        setEdit(!edit);
      }}
    />
  );
};

const ViewRegrasCard: React.FC<{
  regras?: RegrasInstalacao;
  index: number;
  changeEdit: () => void;
}> = ({ regras, index, changeEdit }) => {
  const dispatch = useAppDispatch();

  const calcPercent = (valor: number, porcentagem: number): number => {
    return (valor * porcentagem) / 100;
  };
  const state = useSelector((state: RootState) => state.novo_contrato);
  return (
    <FormRegrasContent>
      <HeaderFormRegras>
        <NovoContratoInputLabel>
          <Heading5 fontWeight={600} color={billing_theme.pallete.tertiary.A600}>
            Regras {index}
          </Heading5>
          <Body4 fontWeight={400} color={billing_theme.pallete.tertiary.A400}>
            {regras?.Cidade}-{regras?.Estado}
          </Body4>
        </NovoContratoInputLabel>
        <div style={{ display: "flex", gap: "12px", flexDirection: "row" }}>
          <BillingIconSharp
            icon={"edit"}
            color={billing_theme.pallete.tertiary.A600}
            onTap={() => changeEdit()}
          />
          <BillingIconSharp
            icon={"delete"}
            color={billing_theme.pallete.error.A100}
            onTap={() => dispatch(removeRegras(index))}
          />
        </div>
      </HeaderFormRegras>
      <ValoresDataGrid>
        <NovoContratoInputLabel>
          <Heading6 fontWeight={600} color={billing_theme.pallete.tertiary.A600} lineheight="24px">
            Instalação
          </Heading6>
          <Body4 lineheight="16px" color={billing_theme.pallete.specific.P_300_400}>
            {toPercent(regras?.DescProprio)}
          </Body4>
          <Heading6 fontWeight={600} color={billing_theme.pallete.success.A100} lineheight="16px">
            {toMoney(calcPercent(state?.instalacao!, regras?.DescProprio!))}
          </Heading6>
        </NovoContratoInputLabel>

        <NovoContratoInputLabel>
          <Heading6 fontWeight={600} color={billing_theme.pallete.tertiary.A600} lineheight="24px">
            Desinstalação
          </Heading6>
          <Body4 lineheight="16px" color={billing_theme.pallete.specific.P_300_400}>
            {toPercent(regras?.DescProprio)}
          </Body4>
          <Heading6 fontWeight={600} color={billing_theme.pallete.success.A100} lineheight="16px">
            {toMoney(calcPercent(state?.desinstalacao!, regras?.DescProprio!))}
          </Heading6>
        </NovoContratoInputLabel>

        <NovoContratoInputLabel>
          <Heading6 fontWeight={600} color={billing_theme.pallete.tertiary.A600} lineheight="24px">
            Transferência
          </Heading6>
          <Body4 lineheight="16px" color={billing_theme.pallete.specific.P_300_400}>
            {toPercent(regras?.DescProprio)}
          </Body4>
          <Heading6 fontWeight={600} color={billing_theme.pallete.success.A100} lineheight="16px">
            {toMoney(calcPercent(state?.transferencia!, regras?.DescProprio!))}
          </Heading6>
        </NovoContratoInputLabel>
      </ValoresDataGrid>

      <Body4 fontWeight={400} color={billing_theme.pallete.specific.P_300_400}>
        {regras?.InstaDia} visita(s) técnica(s)/dia
      </Body4>

      <ValoresDataGrid>
        <BillingCheckbox
          defaultValue={regras?.Instalacao}
          label="Instalação"
          change={value => {}}
          disabled={true}
        />

        <BillingCheckbox
          defaultValue={regras?.Desinstalacao}
          label="Desinstalação"
          change={value => {}}
          disabled={true}
        />

        <BillingCheckbox
          defaultValue={regras?.Transferencia}
          label="Transferência"
          change={value => {}}
          disabled={true}
        />
      </ValoresDataGrid>
    </FormRegrasContent>
  );
};

const FormRegras: React.FC<{
  regras?: RegrasInstalacao;
  index: number;
  changeEdit: () => void;
}> = ({ regras, index, changeEdit }) => {
  const dispatch = useAppDispatch();

  const [estado, setEstado] = useState<string>(regras?.Estado!);
  const [cidade, setCidade] = useState<string>(regras?.Cidade!);
  const [dia, setDia] = useState<number>(regras?.InstaDia!);
  const [proprios, setProprios] = useState<number>(regras?.DescProprio!);
  const [terceiro, setTerceiro] = useState<number>(regras?.DescExterna!);
  const [instalacao, setInstalacao] = useState<boolean>(regras?.Instalacao!);
  const [desinstalacao, setDesinstalacao] = useState<boolean>(regras?.Desinstalacao!);
  const [transferencia, setTransferencia] = useState<boolean>(regras?.Transferencia!);
  return (
    <FormRegrasContent>
      <NovoContratoInputLabel>
        <Heading5 fontWeight={600} color={billing_theme.pallete.tertiary.A600}>
          Regras e ajustes
        </Heading5>
        <Body4 fontWeight={400} color={billing_theme.pallete.tertiary.A600}>
          Informe valores negociados.
        </Body4>
      </NovoContratoInputLabel>

      <FormRegrasTwoGrid>
        <NovoContratoInputLabel>
          <Heading6 fontWeight={600} color={billing_theme.pallete.tertiary.A600}>
            Estado*
          </Heading6>
          <BillingTextInput
            placeholder="Estado"
            value={estado}
            change={value => {
              setEstado(value!);
            }}
          />
        </NovoContratoInputLabel>

        <NovoContratoInputLabel>
          <Heading6 fontWeight={600} color={billing_theme.pallete.tertiary.A600}>
            Cidade*
          </Heading6>
          <BillingTextInput
            value={cidade}
            placeholder="Cidade"
            change={value => {
              setCidade(value!);
            }}
          />
        </NovoContratoInputLabel>
      </FormRegrasTwoGrid>

      <ValoresDataGrid>
        <NovoContratoInputLabel>
          <Heading6 fontWeight={600} color={billing_theme.pallete.tertiary.A600}>
            Quant./dia útil
          </Heading6>
          <BillingNumberInput
            alterValue={dia}
            placeholder="00"
            onNumberFormat={val => {
              setDia(val.number);
            }}
          />
        </NovoContratoInputLabel>
        <NovoContratoInputLabel>
          <Heading6 fontWeight={600} color={billing_theme.pallete.tertiary.A600}>
            Tec. próprios
          </Heading6>
          <BillingNumberInput
            alterValue={proprios}
            placeholder="%"
            onNumberFormat={val => {
              setProprios(val.number);
            }}
          />
        </NovoContratoInputLabel>
        <NovoContratoInputLabel>
          <Heading6 fontWeight={600} color={billing_theme.pallete.tertiary.A600}>
            Tec. Terceiros
          </Heading6>
          <BillingNumberInput
            alterValue={terceiro}
            placeholder="%"
            onNumberFormat={val => {
              setTerceiro(val.number);
            }}
          />
        </NovoContratoInputLabel>
      </ValoresDataGrid>
      <BillingCheckbox
        defaultValue={instalacao}
        label="Instalação"
        change={value => {
          setInstalacao(value!);
        }}
      />
      <BillingCheckbox
        defaultValue={desinstalacao}
        label="Desinstalação"
        change={value => {
          setDesinstalacao(value!);
        }}
      />
      <BillingCheckbox
        defaultValue={transferencia}
        label="Transferência"
        change={value => {
          setTransferencia(value!);
        }}
      />
      <ButtonSection>
        <TertiaryButton
          label={"Voltar"}
          onTap={() => {
            changeEdit();
          }}
          isSmall
          isFilled
        />
        <PrimaryButton
          disable={
            estado == undefined ||
            cidade == undefined ||
            dia == undefined ||
            terceiro == undefined ||
            proprios == undefined
          }
          label={"Editar"}
          onTap={() => {
            dispatch(
              editRegra({
                regra: {
                  ID: regras?.ID,
                  Estado: estado,
                  Cidade: cidade,
                  InstaDia: dia,
                  DescProprio: proprios,
                  Transferencia: transferencia,
                  Instalacao: instalacao,
                  Desinstalacao: desinstalacao,
                  DescExterna: terceiro,
                },
                index: index,
              })
            );
            changeEdit();
          }}
          isSmall
        />
      </ButtonSection>
    </FormRegrasContent>
  );
};

export default RegrasCard;
