import { useEffect, useState } from "react";
import {
  InstalacaoListButton,
  InstalacaoListContent,
  InstalacaoListHeader,
  InstalacaoListIcon,
  InstalacaoListItems,
  InstalacaoListItensContainer,
} from "./style";
import BillingIconRounded from "components/billing_icon/rounded_icon";
import billing_theme from "style/theme_colors";
import { Body2, Body3 } from "components/text/body/style";
import useInstalacao from "hooks/instalacao/useInstalacao";
import React from "react";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "store/store";
import ModalBilling from "components/modal";
import InstalacaoDeleteContent from "../instalacao_delete_modal";
import { toastWarning } from "components/toast/billing_toast";
import { InstalacaoListModel } from "shared/models/instalacao_response.model";

import { toDate, toShortText } from "utils/stringUtils";
import {
  setInstalacaoPageIndex,
  setInstalacaoDeleteModal,
  setCotratadaDeleteId,
} from "store/instalacao/instalacao_slice";
import Pagination from "components/pagination";
import { useNavigate } from "react-router-dom";

const InstalacaoList: React.FC<{}> = () => {
  const dispatch = useAppDispatch();
  const { deleteInstalacao, setPagedInstalacao } = useInstalacao();
  const instalacao = useSelector((state: RootState) => state.instalacao);
  const navagate = useNavigate();
  const [filter, setFilter] = useState("");
  const [data, setData] = useState<InstalacaoListModel[]>([]);
  const [filterState, setFilterState] = useState("normal");

  useEffect(() => {
    instalacao.filter.length == 0 ? setData(instalacao.list) : setData(instalacao.filter);
  });

  return (
    <InstalacaoListContent>
      <InstalacaoListHeader>
        <ListButton
          label="Codigo GO"
          filterState={filterState}
          click={() => toastWarning({ title: "Em breve" })}
          selectedFilter={filter}
        />

        <ListButton
          label="Contratante"
          filterState={filterState}
          click={() => toastWarning({ title: "Em breve" })}
          selectedFilter={filter}
        />
        <ListButton
          label="Veiculo"
          filterState={filterState}
          click={() => toastWarning({ title: "Em breve" })}
          selectedFilter={filter}
        />
        <ListButton
          label="N Serie"
          filterState={filterState}
          click={() => toastWarning({ title: "Em breve" })}
          selectedFilter={filter}
        />

        <ListButton
          label="Modelo"
          filterState={filterState}
          click={() => toastWarning({ title: "Em breve" })}
          selectedFilter={filter}
        />
        <ListButton
          label="Instalação"
          filterState={filterState}
          click={() => toastWarning({ title: "Em breve" })}
          selectedFilter={filter}
        />
        <ListButton
          label="Desinstalação"
          filterState={filterState}
          click={() => toastWarning({ title: "Em breve" })}
          selectedFilter={filter}
        />
        <ListButton
          label="Opções"
          filterState={filterState}
          click={() => console.log("oi")}
          selectedFilter={"options"}
        />
      </InstalacaoListHeader>
      {setPagedInstalacao(data, instalacao.pageIndex).map(item => (
        <InstalacaoListItensContainer key={item.ID}>
          <ListItens label={item.CodigoGO?.toString()!} />
          <ListItens label={item.Contratante!} />
          <ListItens label={item.Veiculo!} />
          <ListItens label={item.NumeroSerie?.toString()!} />
          <ListItens label={item.Modelo!} />
          <ListItens label={toDate(item.DataInstalacao!)} />
          <ListItens label={toDate(item.DataDesinstalacao!)} />
          <InstalacaoListIcon>
            <BillingIconRounded
              icon={"visibility"}
              color={billing_theme.pallete.tertiary.A400}
              onTap={() => {
                navagate(`/operacoes/instalacao/editar_instalacao/${item.ID}`);
              }}
            />
            <BillingIconRounded
              icon={"delete"}
              color={billing_theme.pallete.error.A100}
              onTap={() => {
                dispatch(setCotratadaDeleteId(item.ID!));
                dispatch(setInstalacaoDeleteModal(true));
              }}
            />
          </InstalacaoListIcon>
        </InstalacaoListItensContainer>
      ))}

      <ModalBilling modalIsOpen={instalacao.openDeleteModal} width="500px" height="200px">
        <InstalacaoDeleteContent
          onClose={() => dispatch(setInstalacaoDeleteModal(false))}
          onDelete={() => deleteInstalacao()}
          onLoading={instalacao.loadingDelete}
        />
      </ModalBilling>
      <Pagination
        index={instalacao.pageIndex + 1}
        firstPage={1}
        lastPage={instalacao.lastPage!}
        goToFirst={() => {
          dispatch(setInstalacaoPageIndex(0));
        }}
        goToLast={() => {
          dispatch(setInstalacaoPageIndex(instalacao.lastPage! - 1));
        }}
        next={() => {
          dispatch(setInstalacaoPageIndex(instalacao.pageIndex + 1));
        }}
        back={() => {
          dispatch(setInstalacaoPageIndex(instalacao.pageIndex - 1));
        }}
      />
    </InstalacaoListContent>
  );
};

const ListButton: React.FC<{
  label: string;
  click: () => void;
  selectedFilter: string;
  filterState: string;
}> = ({ label, click, selectedFilter, filterState }) => {
  return (
    <InstalacaoListButton onClick={() => click()}>
      <Body2 fontWeight={600} color={billing_theme.pallete.tertiary.A400}>
        {label}
      </Body2>
      {selectedFilter == label && (
        <BillingIconRounded
          icon={filterState == "normal" ? "expand_less" : "expand_more"}
          color={billing_theme.pallete.tertiary.A400}
        />
      )}
    </InstalacaoListButton>
  );
};

const ListItens: React.FC<{ label: string }> = ({ label }) => {
  return (
    <InstalacaoListItems>
      <Body3 fontWeight={500} color={billing_theme.pallete.tertiary.A400} textalign="center">
        {label ? toShortText(label, 20) : "--"}
      </Body3>
    </InstalacaoListItems>
  );
};
export default InstalacaoList;
