import { ContratanteHttp } from "gateways/repo/contratante_http_client";
import {
  setLoadingNewContrato,
  setErrorNewContrato,
  setModalNewContratante,
} from "store/contratante/new_contratante_slice";
import { useAppDispatch } from "store/store";
import useContratante from "./useContratante";
import { PostContratanteModel } from "shared/models/contratante_response.model";
import { toastWarning } from "components/toast/billing_toast";

const useNewContratante = () => {
  const http = new ContratanteHttp();
  const dispatch = useAppDispatch();
  const { getListContratantes } = useContratante();

  const addContratante = async (data: PostContratanteModel) => {
    dispatch(setLoadingNewContrato(true));
    dispatch(setErrorNewContrato(false));
    try {
      await http.addNewContratante(data);
      getListContratantes();
      dispatch(setModalNewContratante(false));
    } catch (e) {
      toastWarning({ title: "Erro ao adicionar!" });
      dispatch(setErrorNewContrato(true));
    } finally {
      dispatch(setLoadingNewContrato(false));
    }
  };
  return {
    addContratante,
  };
};
export default useNewContratante;
