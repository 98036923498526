import { toastError, toastInfo } from "components/toast/billing_toast";
import { EquipamentoHttp } from "gateways/repo/equipamento_http_client";

import { useState } from "react";
import { useSelector } from "react-redux";
import { OptionsModel } from "shared/models/contratada_response.model";
import { EquipamentoListModel } from "shared/models/equipamento_response.model";
import {
  setContradaError,
  setEquipamentoLastPage,
  setEquipamentoList,
  setEquipamentoLoading,
  setEquipamentoOptions,
  setEquipamentoDeleteModal,
  setLoadingEquipamento,
} from "store/equipamento/equipamento_slice";
import { RootState, useAppDispatch } from "store/store";
import { formatToOption } from "utils/enumListToOptions";

const useEquipamento = () => {
  const http = new EquipamentoHttp();
  const dispatch = useAppDispatch();
  const equipamento = useSelector((state: RootState) => state.equipamento);
  const [equipamentoOptions, setEquipamentoOption] = useState<OptionsModel[]>([]);

  const getEquipamentoList = async () => {
    dispatch(setEquipamentoLoading(true));
    dispatch(setContradaError(false));
    try {
      const data = await http.getListEquipamento();
      dispatch(setEquipamentoList(data.Lista));
      dispatch(setEquipamentoLastPage(paginateEquipamento(data.Lista!).length));
    } catch (e) {
      dispatch(setContradaError(true));
      console.log(e);
    } finally {
      dispatch(setEquipamentoLoading(false));
    }
  };

  const getEquipamentoOption = async () => {
    dispatch(setEquipamentoLoading(true));
    dispatch(setContradaError(false));
    try {
      const data = await http.getListEquipamentoOptions();
      let options: OptionsModel[] = [];
      data.Lista.forEach(val => {
        options.push(formatToOption(val.ID.toString(), val.Text));
      });

      setEquipamentoOption(options);
      dispatch(setEquipamentoOptions(options));
    } catch (e) {
      dispatch(setContradaError(true));
      console.log(e);
    } finally {
      dispatch(setEquipamentoLoading(false));
    }
  };
  const deleteEquipamento = async () => {
    dispatch(setLoadingEquipamento(true));
    try {
      await http.deleteEquipamento(equipamento.selectDeleteId!);
      toastInfo({ title: "Empresa excluida!" });
      dispatch(setEquipamentoDeleteModal(false));
      getEquipamentoList();
    } catch (e) {
      toastError({ title: "Ocorreu um erro!", message: "Por favor, tente novamente!" });
    } finally {
      dispatch(setLoadingEquipamento(false));
    }
  };
  const paginateEquipamento = (list: EquipamentoListModel[]) => {
    const resultado: EquipamentoListModel[][] = [];

    for (let i = 0; i < list.length; i += 10) {
      const chunk = list.slice(i, i + 10);
      resultado.push(chunk);
    }
    return resultado;
  };
  const setPagedEquipamento = (list: EquipamentoListModel[], index: number) => {
    const resultado: EquipamentoListModel[][] = [];

    for (let i = 0; i < list.length; i += 10) {
      const chunk = list.slice(i, i + 10);
      resultado.push(chunk);
    }
    return resultado[index] ?? [];
  };
  return {
    equipamentoOptions,
    getEquipamentoList,
    getEquipamentoOption,
    deleteEquipamento,
    paginateEquipamento,
    setPagedEquipamento,
  };
};
export default useEquipamento;
