import styled from "styled-components";

export const SearchContent = styled.div`
  gap: 16px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
  width: 100%;
`;
export const VeiculoFilterContent = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-top: 24px;
`;

export const SearchInputContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
