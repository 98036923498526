import PrimaryButton from "components/button/billing_button";
import { Body2 } from "components/text/body/style";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "store/store";
import billing_theme from "style/theme_colors";
import { EditInstalacaoContent, EditInstalacaoGrid, EditInstalacaoHeader } from "./style";

import { Heading3 } from "components/text/heading/style";
import InstalacaoBackButton from "./components/instalacao_back_button";
import Scaffold from "components/scaffold";
import NovaInstalacaoForm from "./components/editar_instalacao_form";
import EquipamentoAdicional from "./components/equipamentos_adicionais";
import useEditInstalacao from "hooks/instalacao/useEditIntalacao";

const EditInstalacao: React.FC = () => {
  const { getInstalacao } = useEditInstalacao();

  useEffect(() => {
    getInstalacao();
  }, []);

  return (
    <Scaffold>
      <EditInstalacaoContent>
        <EditInstalacaoHeader>
          <div>
            <Heading3 fontWeight={600} color={billing_theme.pallete.tertiary?.A600}>
              Nova instalação
            </Heading3>
            <Body2 color={billing_theme.pallete.tertiary?.A600}>
              Cadastrar uma nova instalação.
            </Body2>
          </div>

          <InstalacaoBackButton />
        </EditInstalacaoHeader>
        <EditInstalacaoGrid>
          <NovaInstalacaoForm />
          <EquipamentoAdicional />
        </EditInstalacaoGrid>
      </EditInstalacaoContent>
    </Scaffold>
  );
};

export default EditInstalacao;
