import styled from "styled-components";
import billing_theme from "style/theme_colors";

export const StepContainer = styled.div<{ $isSelected: boolean }>`
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 16px 8px;
  box-shadow: 0px 0 0px 0.5px ${billing_theme.pallete.tertiary.A100};
  background-color: ${props =>
    props.$isSelected
      ? billing_theme.isDark()
        ? billing_theme.light.primary?.A300
        : billing_theme.light.primary?.A400
      : billing_theme.pallete.specific.T_50_25};
  width: calc(100% - 16px);
  justify-content: space-between;
  cursor: pointer;
`;

export const StepTextContent = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 12px;
`;
