import PrimaryButton from "components/button/billing_button";
import TertiaryButton from "components/button/tertiaty_button";
import { Body2 } from "components/text/body/style";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "store/store";
import billing_theme from "style/theme_colors";
import { ViewModeloContent, ViewModeloDetails } from "./style";
import BillingTextInput from "components/input/text_input";
import BillingTextArea from "components/input/text_area";
import useViewModelo from "hooks/modelo/useViewModelo";
import LineSkeleton from "components/skeleton/line_skeleton";
import BillingSelectInput from "components/input/select";
import { NewModeloDetails } from "../NewModelo/style";
import useBillingEnum from "hooks/useBillingEnum";

const ModalViewModelo: React.FC<{
  onClose: () => void;
  id: number;
}> = ({ onClose, id }) => {
  const state = useSelector((state: RootState) => state.view_modelo);
  const [nome, setNome] = useState<string>();
  const [idEquipamento, setIdEquipamento] = useState<string>();
  const [descricao, setDescricao] = useState<string>();
  const { getModelo, isLoadingModelo, updateModelo } = useViewModelo();
  const { getEnums, isLoadingEnum } = useBillingEnum();
  const enumList = useSelector((state: RootState) => state.list_enum);

  useEffect(() => {
    getModelo(id);
    getEnums();
    setNome(state.Nome);
    setDescricao(state.Descricao);
  }, []);
  const getValueOption = () => {
    return enumList.TipoEquipamentoOptions!.find(
      el => state.TipoEquipamento?.toString() == el.value
    );
  };
  return (
    <ViewModeloContent>
      <ViewModeloDetails>
        <Body2 fontWeight={600} color={billing_theme.pallete.tertiary?.A400}>
          Nome:
        </Body2>
        {isLoadingModelo ? (
          <LineSkeleton width={"100%"} height={40} />
        ) : (
          <BillingTextInput
            placeholder="Qual o nome do Modelo?"
            value={state.Nome}
            change={value => {
              setNome(value);
            }}
          />
        )}
      </ViewModeloDetails>
      <NewModeloDetails>
        <Body2 fontWeight={600} color={billing_theme.pallete.tertiary?.A400}>
          Tipo de equipamento:
        </Body2>
        {isLoadingEnum || isLoadingModelo ? (
          <LineSkeleton width={"100%"} height={40} />
        ) : (
          <BillingSelectInput
            onChange={(val: any) => {
              val != null ? setIdEquipamento(val?.value!) : setIdEquipamento("");
            }}
            defaultInputValue={enumList.TipoEquipamentoOptions!.find(
              el => state.TipoEquipamento?.toString() == el.value
            )}
            options={enumList.TipoEquipamentoOptions!}
            placeholder={"Qual o tipo de equipamento?"}
          />
        )}
      </NewModeloDetails>
      <ViewModeloDetails>
        <Body2 fontWeight={600} color={billing_theme.pallete.tertiary?.A400}>
          Descrição:
        </Body2>

        {isLoadingModelo ? (
          <LineSkeleton width={"100%"} height={100} />
        ) : (
          <BillingTextArea
            placeholder="De uma descrição ao modelo!"
            change={value => {
              setDescricao(value);
            }}
            defaultValue={state.Descricao}
            lines={3}
          />
        )}
      </ViewModeloDetails>

      <div className="button">
        <TertiaryButton label={"Cancelar"} onTap={onClose} isSmall />
        <PrimaryButton
          disable={isLoadingModelo}
          label={"Editar"}
          onTap={async () => {
            await updateModelo({
              Nome: nome ? state.Nome! : nome!,
              ID: state.ID!,
              TipoEquipamento:
                idEquipamento == undefined
                  ? Number(getValueOption()!.value)
                  : Number(idEquipamento),
              Descricao: descricao,
            });
            onClose();
          }}
          isSmall
        />
      </div>
    </ViewModeloContent>
  );
};

export default ModalViewModelo;
