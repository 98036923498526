import billing_theme from "style/theme_colors";
import styled from "styled-components";

export const VeiculoContainer = styled.div`
  display: grid;
  width: 100%;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: flex-start;
  justify-content: space-between;
  grid-template-columns: 187px 1fr;

  .main_content {
    width: 100%;
  }
`;

export const VeiculoHeader = styled.div`
  padding: 16px 32px;
  border-bottom: solid 0.5px ${billing_theme.pallete.primary?.A300};
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
`;
