import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { FaturaItemModel } from "shared/models/fatura_list_response.model";
import { Configuracao } from "shared/models/default_models.model";

export interface FaturaSliceType {
  list: FaturaItemModel[];
  filterList: FaturaItemModel[];
  search_fatura_filter: boolean;
  date_filter: boolean;
  valor_menor_fatura_filter: boolean;
  valor_maior_fatura_filter: boolean;
  redorder_fatura_filter: boolean;
  enable_filter: boolean;
  openNovaFaturalModal: boolean;
  loading?: boolean;
  isError?: boolean;
  pagination?: FaturaItemModel[][];
  pageIndex: number;
  lastPage?: number;
}

const initialState: FaturaSliceType = {
  list: [],
  filterList: [],
  pageIndex: 0,
  search_fatura_filter: false,
  date_filter: false,
  valor_menor_fatura_filter: false,
  valor_maior_fatura_filter: false,
  redorder_fatura_filter: false,
  enable_filter: false,
  openNovaFaturalModal: false,
  loading: false,
  isError: false,
};

const fatura_slice = createSlice({
  name: "fatura",
  initialState,
  reducers: {
    setFaturaList(state, action: PayloadAction<FaturaItemModel[]>) {
      state.list = action.payload;
    },
    setFaturaFilterList(state, action: PayloadAction<FaturaItemModel[]>) {
      state.filterList = action.payload;
    },

    setFilterbyName(state, action: PayloadAction<boolean>) {
      state.search_fatura_filter = action.payload;
    },
    setFilterByDate(state, action: PayloadAction<boolean>) {
      state.date_filter = action.payload;
    },

    setFilterByValorMenor(state, action: PayloadAction<boolean>) {
      state.valor_menor_fatura_filter = action.payload;
    },
    setFilterByValorMaior(state, action: PayloadAction<boolean>) {
      state.valor_maior_fatura_filter = action.payload;
    },
    setFilterByReorder(state, action: PayloadAction<boolean>) {
      state.redorder_fatura_filter = action.payload;
    },
    setFilterEnable(state, action: PayloadAction<boolean>) {
      state.enable_filter = action.payload;
    },
    setNovaFaturalModalState(state, action: PayloadAction<boolean>) {
      state.openNovaFaturalModal = action.payload;
    },
    setLoadingFatura(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    setErrorFatura(state, action: PayloadAction<boolean>) {
      state.isError = action.payload;
    },
    setPaginationFatura(state, action: PayloadAction<FaturaItemModel[][]>) {
      state.pagination = action.payload;
    },
    setFaturaPageIndex(state, action: PayloadAction<number>) {
      state.pageIndex = action.payload;
    },
    setFaturaLastPage(state, action: PayloadAction<number>) {
      state.lastPage = action.payload;
    },
  },
});

export const {
  setFaturaList,
  setFaturaFilterList,
  setFilterByReorder,
  setFilterByValorMaior,
  setFilterByValorMenor,
  setFilterbyName,
  setFilterByDate,
  setFilterEnable,
  setNovaFaturalModalState,
  setLoadingFatura,
  setErrorFatura,
  setPaginationFatura,
  setFaturaPageIndex,
  setFaturaLastPage,
} = fatura_slice.actions;
export default fatura_slice.reducer;
