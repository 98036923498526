import { ContextContent, InfoButton, InfoContent } from "./style";
import React from "react";
import { Heading6 } from "components/text/heading/style";
import billing_theme from "style/theme_colors";
import BillingIconSharp from "components/billing_icon/sharp_icon";
import BillingAvatar from "components/avatar";
import { Caution1 } from "components/text/body/style";
import logo from "../../../../assets/png/logo_maxtrack.png";
import { toastWarning } from "components/toast/billing_toast";
import { useAuth } from "hooks/useAuth";

const InfoHeaderComp: React.FC = () => {
  const { logout } = useAuth();
  return (
    <InfoContent>
      <InfoButton>
        <BillingIconSharp
          icon={"contrast"}
          color={billing_theme.light.tertiary.A25!}
          size={25}
          onTap={() => billing_theme.changeTheme()}
        />
      </InfoButton>

      <InfoButton>
        <BillingIconSharp
          icon={"notifications"}
          color={billing_theme.light.tertiary.A25!}
          size={25}
          onTap={() => toastWarning({ title: "Em breve" })}
        />
      </InfoButton>
      <InfoButton>
        <ContextContent>
          <div className="image">
            <img src={logo} alt="Logo" />
          </div>

          <div className="context">
            <Heading6 color={billing_theme.light.tertiary?.A25} fontWeight={600}>
              Maxtrack Tecnologia
            </Heading6>

            <Caution1 color={billing_theme.light.tertiary?.A25}>Empresa contexto</Caution1>
          </div>
          <BillingIconSharp
            icon={"expand_more"}
            color={billing_theme.light.tertiary.A25!}
            size={25}
          />
        </ContextContent>
      </InfoButton>
      <InfoButton>
        <BillingAvatar size={"small"} color={"white"} name={"Caio Reis"} />
      </InfoButton>
      <InfoButton>
        <BillingIconSharp
          icon={"exit_to_app"}
          color={billing_theme.light.tertiary.A25!}
          size={25}
          onTap={() => logout()}
        />
      </InfoButton>
    </InfoContent>
  );
};

export default InfoHeaderComp;
