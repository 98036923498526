import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { OptionsModel } from "shared/models/contratada_response.model";
import { EquipamentoListModel } from "shared/models/equipamento_response.model";

export interface ContratoSliceType {
  list: EquipamentoListModel[];
  filter: EquipamentoListModel[];
  options: OptionsModel[];
  loading?: boolean;
  isError?: boolean;
  selectDeleteId?: number;
  openDeleteModal: boolean;
  loadingDelete: boolean;
  pagination?: EquipamentoListModel[][];
  pageIndex: number;
  lastPage?: number;
}

const initialState: ContratoSliceType = {
  list: [],
  options: [],
  loading: true,
  isError: false,
  filter: [],
  openDeleteModal: false,
  loadingDelete: false,
  pageIndex: 0,
};

const equipamento_slice = createSlice({
  name: "equipamento",
  initialState,
  reducers: {
    setEquipamentoList(state, action: PayloadAction<EquipamentoListModel[]>) {
      state.list = action.payload;
    },

    setEquipamentoOptions(state, action: PayloadAction<OptionsModel[]>) {
      state.options = action.payload;
    },
    setEquipamentoLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    setContradaError(state, action: PayloadAction<boolean>) {
      state.isError = action.payload;
    },
    setEquipamentoDeleteModal(state, action: PayloadAction<boolean>) {
      state.openDeleteModal = action.payload;
    },
    setCotratadaDeleteId(state, action: PayloadAction<number>) {
      state.selectDeleteId = action.payload;
    },
    setLoadingEquipamento(state, action: PayloadAction<boolean>) {
      state.loadingDelete = action.payload;
    },
    setPaginationEquipamento(state, action: PayloadAction<EquipamentoListModel[][]>) {
      state.pagination = action.payload;
    },
    setEquipamentoPageIndex(state, action: PayloadAction<number>) {
      state.pageIndex = action.payload;
    },
    setEquipamentoLastPage(state, action: PayloadAction<number>) {
      state.lastPage = action.payload;
    },
  },
});

export const {
  setEquipamentoList,
  setEquipamentoOptions,
  setEquipamentoLoading,
  setContradaError,
  setCotratadaDeleteId,
  setLoadingEquipamento,
  setEquipamentoDeleteModal,
  setPaginationEquipamento,
  setEquipamentoPageIndex,
  setEquipamentoLastPage,
} = equipamento_slice.actions;
export default equipamento_slice.reducer;
