import BillingIconRounded from "components/billing_icon/rounded_icon";
import { PaginationContent } from "./style";
import React from "react";
import "react-material-symbols/sharp";
import billing_theme from "style/theme_colors";
import { Heading5, Heading6 } from "components/text/heading/style";

const Pagination: React.FC<{
  firstPage: number;
  lastPage: number;
  index: number;
  goToFirst: () => void;
  goToLast: () => void;
  next: () => void;
  back: () => void;
}> = ({ firstPage, lastPage, index, goToFirst, goToLast, next, back }) => {
  return (
    <PaginationContent>
      {firstPage != index && lastPage != 0 && (
        <>
          <div className="text_padding">
            <BillingIconRounded
              size={22}
              icon={"keyboard_double_arrow_left"}
              color={billing_theme.pallete.tertiary.A400}
              onTap={goToFirst}
            />
          </div>
          <div className="text_padding">
            <BillingIconRounded
              size={22}
              icon={"keyboard_arrow_left"}
              color={billing_theme.pallete.tertiary.A400}
              onTap={back}
            />
          </div>
          <div className="text_padding">
            <Heading6 fontWeight={600} color={billing_theme.pallete.tertiary.A400}>
              ...
            </Heading6>
          </div>
          <div className="text_padding">
            <Heading6 fontWeight={600} color={billing_theme.pallete.tertiary.A400}>
              {index - 1}
            </Heading6>
          </div>
        </>
      )}
      {firstPage != lastPage && lastPage != 0 && (
        <div className="atual">
          <Heading5 fontWeight={600} color={billing_theme.pallete.tertiary.A400}>
            {index}
          </Heading5>
        </div>
      )}

      {lastPage != index && lastPage != 0 && (
        <>
          <div className="text_padding">
            <Heading6 fontWeight={600} color={billing_theme.pallete.tertiary.A400}>
              {index + 1}
            </Heading6>
          </div>
          <div className="text_padding">
            <Heading6 fontWeight={600} color={billing_theme.pallete.tertiary.A400}>
              ...
            </Heading6>
          </div>
          <div className="text_padding">
            <BillingIconRounded
              size={22}
              icon={"keyboard_arrow_right"}
              color={billing_theme.pallete.tertiary.A400}
              onTap={next}
            />
          </div>
          <div className="text_padding">
            <BillingIconRounded
              size={22}
              icon={"keyboard_double_arrow_right"}
              color={billing_theme.pallete.tertiary.A400}
              onTap={goToLast}
            />
          </div>
        </>
      )}
    </PaginationContent>
  );
};

export default Pagination;
