import { useEffect, useState } from "react";
import {
  ContratadaListButton,
  ContratadaListContent,
  ContratadaListHeader,
  ContratadaListIcon,
  ContratadaListItems,
  ContratadaListItensContainer,
} from "./style";
import BillingIconRounded from "components/billing_icon/rounded_icon";
import billing_theme from "style/theme_colors";
import { Body2, Body3 } from "components/text/body/style";
import React from "react";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "store/store";
import ModalBilling from "components/modal";
import ContratadaDeleteContent from "../contratada_delete_modal";
import { toastWarning } from "components/toast/billing_toast";
import { ContratadaListModel } from "shared/models/contratada_response.model";
import { setIdContratoView, setModalContratada } from "store/contratada/view_contratada_slice";
import ModalViewContratada from "view/Contrada/page/ViewContratada";
import { formattedTel } from "utils/stringUtils";
import {
  setContratadaPageIndex,
  setContratadoDeleteModal,
  setCotratadaDeleteId,
} from "store/contratada/contratada_slice";
import Pagination from "components/pagination";
import useContratada from "hooks/contratada/useContratada";

const ContratadaList: React.FC<{}> = () => {
  const dispatch = useAppDispatch();
  const { deleteContratada, setPagedContratada } = useContratada();
  const contratada = useSelector((state: RootState) => state.contratada);
  const view_contratada = useSelector((state: RootState) => state.view_contratada);
  const [filter, setFilter] = useState("");
  const [data, setData] = useState<ContratadaListModel[]>([]);
  const [filterState, setFilterState] = useState("normal");

  useEffect(() => {
    contratada.filter.length == 0 ? setData(contratada.list) : setData(contratada.filter);
  });

  return (
    <ContratadaListContent>
      <ContratadaListHeader>
        <ListButton
          label="Razão Social"
          filterState={filterState}
          click={() => toastWarning({ title: "Em breve" })}
          selectedFilter={filter}
        />
        <ListButton
          label="E-mail"
          filterState={filterState}
          click={() => toastWarning({ title: "Em breve" })}
          selectedFilter={filter}
        />

        <ListButton
          label="Telefone"
          filterState={filterState}
          click={() => toastWarning({ title: "Em breve" })}
          selectedFilter={filter}
        />
        <ListButton
          label="Opções"
          filterState={filterState}
          click={() => console.log("oi")}
          selectedFilter={"options"}
        />
      </ContratadaListHeader>
      {setPagedContratada(data, contratada.pageIndex).map(item => (
        <ContratadaListItensContainer key={item.ID}>
          <ListItens label={item.RazaoSocial} />
          <ListItens label={item.Email} />
          <ListItens label={formattedTel(item.Telefone)!} />

          <ContratadaListIcon>
            <BillingIconRounded
              icon={"visibility"}
              color={billing_theme.pallete.tertiary.A400}
              onTap={() => {
                dispatch(setIdContratoView(item.ID));
                dispatch(setModalContratada(true));
              }}
            />
            <BillingIconRounded
              icon={"delete"}
              color={billing_theme.pallete.error.A100}
              onTap={() => {
                dispatch(setCotratadaDeleteId(item.ID));
                dispatch(setContratadoDeleteModal(true));
              }}
            />
          </ContratadaListIcon>
        </ContratadaListItensContainer>
      ))}
      <ModalBilling modalIsOpen={view_contratada.isOpen} width="600px" height="auto">
        <ModalViewContratada />
      </ModalBilling>
      <ModalBilling modalIsOpen={contratada.openDeleteModal} width="500px" height="200px">
        <ContratadaDeleteContent
          onClose={() => dispatch(setContratadoDeleteModal(false))}
          onDelete={() => deleteContratada()}
          onLoading={contratada.loadingDelete}
        />
      </ModalBilling>
      <Pagination
        index={contratada.pageIndex + 1}
        firstPage={1}
        lastPage={contratada.lastPage!}
        goToFirst={() => {
          dispatch(setContratadaPageIndex(0));
        }}
        goToLast={() => {
          dispatch(setContratadaPageIndex(contratada.lastPage! - 1));
        }}
        next={() => {
          dispatch(setContratadaPageIndex(contratada.pageIndex + 1));
        }}
        back={() => {
          dispatch(setContratadaPageIndex(contratada.pageIndex - 1));
        }}
      />
    </ContratadaListContent>
  );
};

const ListButton: React.FC<{
  label: string;
  click: () => void;
  selectedFilter: string;
  filterState: string;
}> = ({ label, click, selectedFilter, filterState }) => {
  return (
    <ContratadaListButton onClick={() => click()}>
      <Body2 fontWeight={600} color={billing_theme.pallete.tertiary.A400}>
        {label}
      </Body2>
      {selectedFilter == label && (
        <BillingIconRounded
          icon={filterState == "normal" ? "expand_less" : "expand_more"}
          color={billing_theme.pallete.tertiary.A400}
        />
      )}
    </ContratadaListButton>
  );
};

const ListItens: React.FC<{ label: string }> = ({ label }) => {
  return (
    <ContratadaListItems>
      <Body3 fontWeight={500} color={billing_theme.pallete.tertiary.A400} textalign="center">
        {label ?? "--"}
      </Body3>
    </ContratadaListItems>
  );
};
export default ContratadaList;
