import { toastError, toastInfo } from "components/toast/billing_toast";
import { InstalacaoHttp } from "gateways/repo/instalacao_http_client";
import useBillingEnum from "hooks/useBillingEnum";

import { useState } from "react";
import { useSelector } from "react-redux";
import { OptionsModel } from "shared/models/contratada_response.model";
import { InstalacaoListModel } from "shared/models/instalacao_response.model";
import {
  setContradaError,
  setInstalacaoLastPage,
  setInstalacaoList,
  setInstalacaoLoading,
  setInstalacaoOptions,
  setInstalacaoDeleteModal,
  setLoadingInstalacao,
} from "store/instalacao/instalacao_slice";
import { RootState, useAppDispatch } from "store/store";
import { formatToOption } from "utils/enumListToOptions";

const useInstalacao = () => {
  const http = new InstalacaoHttp();
  const { getEnums } = useBillingEnum();
  const dispatch = useAppDispatch();
  const instalacao = useSelector((state: RootState) => state.instalacao);
  const [instalacaoOptions, setInstalacaoOption] = useState<OptionsModel[]>([]);

  const getInstalacaoList = async () => {
    dispatch(setInstalacaoLoading(true));
    dispatch(setContradaError(false));
    try {
      const data = await http.getListInstalacao();
      await getEnums();
      dispatch(setInstalacaoList(data.Lista!));
      dispatch(setInstalacaoLastPage(paginateInstalacao(data.Lista!).length));
    } catch (e) {
      dispatch(setContradaError(true));
      console.log(e);
    } finally {
      dispatch(setInstalacaoLoading(false));
    }
  };

  const getInstalacaoOption = async () => {
    dispatch(setInstalacaoLoading(true));
    dispatch(setContradaError(false));
    try {
      const data = await http.getListInstalacaoOptions();
      let options: OptionsModel[] = [];
      data.Lista.forEach(val => {
        options.push(formatToOption(val.ID.toString(), val.Text));
      });

      setInstalacaoOption(options);
      dispatch(setInstalacaoOptions(options));
    } catch (e) {
      dispatch(setContradaError(true));
      console.log(e);
    } finally {
      dispatch(setInstalacaoLoading(false));
    }
  };
  const deleteInstalacao = async () => {
    dispatch(setLoadingInstalacao(true));
    try {
      await http.deleteInstalacao(instalacao.selectDeleteId!);
      toastInfo({ title: "Empresa excluida!" });
      dispatch(setInstalacaoDeleteModal(false));
      getInstalacaoList();
    } catch (e) {
      toastError({ title: "Ocorreu um erro!", message: "Por favor, tente novamente!" });
    } finally {
      dispatch(setLoadingInstalacao(false));
    }
  };
  const paginateInstalacao = (list: InstalacaoListModel[]) => {
    const resultado: InstalacaoListModel[][] = [];

    for (let i = 0; i < list.length; i += 10) {
      const chunk = list.slice(i, i + 10);
      resultado.push(chunk);
    }
    return resultado;
  };
  const setPagedInstalacao = (list: InstalacaoListModel[], index: number) => {
    const resultado: InstalacaoListModel[][] = [];

    for (let i = 0; i < list.length; i += 10) {
      const chunk = list.slice(i, i + 10);
      resultado.push(chunk);
    }
    return resultado[index] ?? [];
  };
  return {
    instalacaoOptions,
    getInstalacaoList,
    getInstalacaoOption,
    deleteInstalacao,
    paginateInstalacao,
    setPagedInstalacao,
  };
};
export default useInstalacao;
