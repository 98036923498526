import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ContratanteResponse } from "shared/models/contratante_response.model";

export interface ViewContratante {
  contratante?: ContratanteResponse;
  loading?: boolean;
  isError?: boolean;
  isOpen: boolean;
  selectedId?: number;
}

const initialState: ViewContratante = {
  loading: true,
  isError: false,
  isOpen: false,
};

const view_contratante_slice = createSlice({
  name: "view_contratante",
  initialState,
  reducers: {
    setContratante(state, action: PayloadAction<ContratanteResponse>) {
      state.contratante = action.payload;
    },
    setLoadingViewContrato(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },

    setErrorViewContrato(state, action: PayloadAction<boolean>) {
      state.isError = action.payload;
    },
    setModalContratante(state, action: PayloadAction<boolean>) {
      state.isOpen = action.payload;
    },
    setIdContratoView(state, action: PayloadAction<number>) {
      state.selectedId = action.payload;
    },
  },
});

export const {
  setContratante,
  setLoadingViewContrato,
  setErrorViewContrato,
  setModalContratante,
  setIdContratoView,
} = view_contratante_slice.actions;
export default view_contratante_slice.reducer;
