import billing_theme from "style/theme_colors";
import styled from "styled-components";

export const InsideMenuHeaderComp = styled.div`
  display: flex;
  height: 35px;
  padding: 0px 0px 0px 8px;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;
  flex-shrink: 0;
  flex-shrink: 0;
  align-self: center;
  color: ${billing_theme.pallete.primary?.A400};
  border: none;
  background: transparent;
  width: 96%;
  border-bottom: solid 0.5px ${billing_theme.pallete.tertiary.A100};
  -webkit-background-clip: padding-box; /* for Safari */
  background-clip: padding-box;
`;
