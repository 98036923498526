import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ContratadaResponse } from "shared/models/contratada_response.model";

export interface ViewContratada {
  contratada?: ContratadaResponse;
  loading?: boolean;
  isError?: boolean;
  isOpen: boolean;
  selectedId?: number;
}

const initialState: ViewContratada = {
  loading: true,
  isError: false,
  isOpen: false,
};

const view_contratada_slice = createSlice({
  name: "view_contratada",
  initialState,
  reducers: {
    setContratada(state, action: PayloadAction<ContratadaResponse>) {
      state.contratada = action.payload;
    },
    setLoadingViewContrato(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },

    setErrorViewContrato(state, action: PayloadAction<boolean>) {
      state.isError = action.payload;
    },
    setModalContratada(state, action: PayloadAction<boolean>) {
      state.isOpen = action.payload;
    },
    setIdContratoView(state, action: PayloadAction<number>) {
      state.selectedId = action.payload;
    },
  },
});

export const {
  setContratada,
  setLoadingViewContrato,
  setErrorViewContrato,
  setModalContratada,
  setIdContratoView,
} = view_contratada_slice.actions;
export default view_contratada_slice.reducer;
