import React, { StrictMode } from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { BillingRoutes } from "router/routes";
import { store } from "store/store";
import GlobalStyle from "style";
import { registerLicense } from "@syncfusion/ej2-base";
import { L10n, setCulture } from "@syncfusion/ej2-base";
import { syncfusion_translate } from "assets/json/translate_syncfusion";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import { ptBR } from "date-fns/locale/pt-BR";
import "./index.scss";

const App: React.FC = () => {
  registerLocale("pt-BR", ptBR);
  registerLicense(process.env.REACT_APP_REGISTER_LICENSE_SYNC!);
  L10n.load(syncfusion_translate);
  setCulture("pt-BR");
  setDefaultLocale("pt-BR");
  return (
    <Provider store={store}>
      <GlobalStyle />

      <BrowserRouter basename="/">
        <StrictMode>
          <BillingRoutes />
        </StrictMode>
      </BrowserRouter>
    </Provider>
  );
};

export default App;
