import { NovoContratoInputLabel } from "../style";
import DescontosNovoContrato from "./contract_data/components/descontos";
import { ContratoDataContainer, ContratoInsideContent } from "./contract_data/style";
import LineSkeleton from "components/skeleton/line_skeleton";

const NovoContratoSkeleton: React.FC<{}> = () => {
  return (
    <ContratoDataContainer>
      <ContratoInsideContent>
        <NovoContratoInputLabel>
          <LineSkeleton width={"100px"} height={24} />
          <LineSkeleton width={"95%"} height={24} />
        </NovoContratoInputLabel>

        <NovoContratoInputLabel>
          <LineSkeleton width={"100px"} height={24} />
          <LineSkeleton width={"95%"} height={24} />
        </NovoContratoInputLabel>

        <NovoContratoInputLabel>
          <LineSkeleton width={"100px"} height={24} />
          <LineSkeleton width={"100%"} height={24} />
        </NovoContratoInputLabel>

        <div className="select_kit_principal">
          <NovoContratoInputLabel>
            <LineSkeleton width={"100px"} height={24} />
            <LineSkeleton width={"100%"} height={24} />
          </NovoContratoInputLabel>

          <NovoContratoInputLabel>
            <LineSkeleton width={"50%"} height={24} />
            <LineSkeleton width={"95%"} height={24} />
          </NovoContratoInputLabel>
        </div>
        <NovoContratoInputLabel>
          <LineSkeleton width={"100px"} height={24} />
          <LineSkeleton width={"100%"} height={24} />
        </NovoContratoInputLabel>

        <div className="select_kit_principal">
          <NovoContratoInputLabel>
            <LineSkeleton width={"100px"} height={24} />
            <LineSkeleton width={"100%"} height={24} />
          </NovoContratoInputLabel>

          <NovoContratoInputLabel>
            <LineSkeleton width={"50%"} height={24} />
            <LineSkeleton width={"95%"} height={24} />
          </NovoContratoInputLabel>
        </div>
        <div className="select_kit_principal">
          <NovoContratoInputLabel>
            <LineSkeleton width={"100px"} height={24} />
            <LineSkeleton width={"100%"} height={24} />
          </NovoContratoInputLabel>

          <NovoContratoInputLabel>
            <LineSkeleton width={"50%"} height={24} />
            <LineSkeleton width={"95%"} height={24} />
          </NovoContratoInputLabel>
        </div>
        <NovoContratoInputLabel>
          <LineSkeleton width={"50%"} height={24} />
          <LineSkeleton width={"95%"} height={24} />
        </NovoContratoInputLabel>
      </ContratoInsideContent>
    </ContratoDataContainer>
  );
};

export default NovoContratoSkeleton;
