import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { toastWarning } from "components/toast/billing_toast";
import {
  InstalacaoEquipamentoModel,
  InstalacaoModel,
} from "shared/models/instalacao_response.model";

const initialState: InstalacaoModel = {
  loading: true,
  isError: false,
  isOpen: false,
};

const view_instalacao_slice = createSlice({
  name: "view_instalacao",
  initialState,
  reducers: {
    resetViewInstalacaoState: () => initialState,
    setViewInstalacao(state, action: PayloadAction<InstalacaoModel>) {
      state.CodigoGO = action.payload.CodigoGO;
      state.Contratante_ID = action.payload.Contratante_ID;
      state.Veiculo_ID = action.payload.Veiculo_ID;
      state.DataInstalacao = action.payload.DataInstalacao;
      state.DataDesinstalacao = action.payload.DataDesinstalacao;
      state.MotivoDesinstalacao = action.payload.MotivoDesinstalacao;
      state.EquipamentoPrincipal_ID = action.payload.EquipamentoPrincipal_ID;
      state.Equipamentos = action.payload.Equipamentos;
    },

    setLoadingViewInstalacao(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },

    setErrorViewInstalacao(state, action: PayloadAction<boolean>) {
      state.isError = action.payload;
    },

    setContratanteViewInstalacao(state, action: PayloadAction<number | undefined>) {
      state.Contratante_ID = action.payload;
    },
    setCodigoGoViewInstalacao(state, action: PayloadAction<number | undefined>) {
      state.CodigoGO = action.payload;
    },
    setEquipamentoPrincipalViewInstalacao(state, action: PayloadAction<number | undefined>) {
      state.EquipamentoPrincipal_ID = action.payload;
    },
    setVeiculoViewInstalacao(state, action: PayloadAction<number | undefined>) {
      state.Veiculo_ID = action.payload;
    },
    setMotivoDesinstalacoViewInstalacao(state, action: PayloadAction<number | undefined>) {
      state.MotivoDesinstalacao = action.payload;
    },
    setDataDesinsitalacaoViewInstalacao(state, action: PayloadAction<string | undefined>) {
      state.DataDesinstalacao = action.payload;
    },
    setDataInstalacaoViewInstalacao(state, action: PayloadAction<string | undefined>) {
      state.DataInstalacao = action.payload;
    },
    addEquipamentoComplementarViewInstalacao(
      state,
      action: PayloadAction<InstalacaoEquipamentoModel>
    ) {
      if (
        state.Equipamentos!.filter(el => action.payload.Equipamento_ID == el.Equipamento_ID)
          .length != 0
      ) {
        toastWarning({ title: "Equipamento já adicionado!" });
      } else {
        state.Equipamentos!.push(action.payload);
      }
    },
    removeEquipamentoComplementarViewInstalacao(
      state,
      action: PayloadAction<InstalacaoEquipamentoModel>
    ) {
      state.Equipamentos = state.Equipamentos!.filter(
        el => action.payload.Equipamento_ID !== el.Equipamento_ID
      );
    },
  },
});

export const {
  setLoadingViewInstalacao,
  setErrorViewInstalacao,
  removeEquipamentoComplementarViewInstalacao,
  addEquipamentoComplementarViewInstalacao,
  setContratanteViewInstalacao,
  setEquipamentoPrincipalViewInstalacao,
  setVeiculoViewInstalacao,
  setMotivoDesinstalacoViewInstalacao,
  setDataInstalacaoViewInstalacao,
  resetViewInstalacaoState,
  setDataDesinsitalacaoViewInstalacao,
  setCodigoGoViewInstalacao,
  setViewInstalacao,
} = view_instalacao_slice.actions;
export default view_instalacao_slice.reducer;
