import { EquipamentoHttp } from "gateways/repo/equipamento_http_client";
import {
  setEquipamento,
  setLoadingViewEquipamento,
  setErrorViewEquipamento,
  setModalEquipamento,
} from "store/equipamento/view_equipamento_slice";
import { useAppDispatch } from "store/store";
import useEquipamento from "./useEquipamento";
import { PostEquipamentoModel } from "shared/models/equipamento_response.model";
import useContratante from "hooks/contratante/useContratante";
import useContrato from "hooks/contrato/useContrato";
import useModelo from "hooks/modelo/useModelo";
import useBillingEnum from "hooks/useBillingEnum";

const useViewEquipamento = () => {
  const http = new EquipamentoHttp();
  const dispatch = useAppDispatch();
  const { getEquipamentoList } = useEquipamento();
  const { getContratatesOptions } = useContratante();
  const { getContratoOptions } = useContrato();
  const { getModeloOptionList } = useModelo();
  const { getEnums } = useBillingEnum();

  const getEquipamento = async (id: number) => {
    dispatch(setLoadingViewEquipamento(true));
    dispatch(setErrorViewEquipamento(false));
    try {
      await getContratatesOptions();
      await getContratoOptions();
      await getModeloOptionList();
      await getEnums();
      const data = await http.getEquipamento(id);
      dispatch(setEquipamento(data.Objeto));
    } catch (e) {
      console.log(e);
      dispatch(setErrorViewEquipamento(true));
    } finally {
      dispatch(setLoadingViewEquipamento(false));
    }
  };
  const updateEquipamento = async (data: PostEquipamentoModel) => {
    dispatch(setLoadingViewEquipamento(true));
    dispatch(setErrorViewEquipamento(false));
    try {
      await http.updateEquipamento(data);
      getEquipamentoList();
      dispatch(setModalEquipamento(false));
    } catch (e) {
      console.log(e);
      dispatch(setErrorViewEquipamento(true));
    } finally {
      dispatch(setLoadingViewEquipamento(false));
    }
  };
  return {
    getEquipamento,
    updateEquipamento,
  };
};
export default useViewEquipamento;
