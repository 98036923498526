import { NovoContratoContent, NovoContratoGrid, NovoContratoHeader, StepDiv } from "./style";
import React, { useEffect, useState } from "react";
import Scaffold from "components/scaffold";
import { Body2 } from "components/text/body/style";
import { Heading3 } from "components/text/heading/style";
import billing_theme from "style/theme_colors";
import { RootState, useAppDispatch } from "store/store";
import useNovoContrato from "hooks/contrato/useNovoContrato";
import { useSelector } from "react-redux";
import ContratoStepIndicator from "./components/contrato_step_indicator";
import { setActualPage } from "store/contrato/new_contrato_slice";
import ValoresTecnicos from "./components/valores_tecnicos";
import DadosDoContrato from "./components/contract_data";
import KitAdicional from "./components/kit_adicional";
import ServicoAdicional from "./components/servico_adicional";
import ContractSummary from "./components/contract_summary";
import { toastWarning } from "components/toast/billing_toast";
import { title } from "process";
import ContratoBackButton from "view/Contrato/components/contrato_back_button";
import NovoContratoSkeleton from "./components/contrato_skeleton";

const NovoContratoPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const { loadContatoData } = useNovoContrato();

  const novo_contrato = useSelector((state: RootState) => state.novo_contrato);
  useEffect(() => {
    loadContatoData();
  }, []);

  return (
    <Scaffold>
      <NovoContratoContent>
        <NovoContratoHeader>
          <div>
            <Heading3 fontWeight={600} color={billing_theme.pallete.tertiary?.A600}>
              Novo contrato
            </Heading3>
            <Body2 color={billing_theme.pallete.tertiary?.A600}>Cadastrar um novo contrato.</Body2>
          </div>

          <ContratoBackButton />
        </NovoContratoHeader>
        <NovoContratoGrid $isResume={novo_contrato.pageIndex == 2}>
          <StepDiv>
            <ContratoStepIndicator
              onClick={() => {
                dispatch(setActualPage(0));
              }}
              title={"Dados de contrato"}
              text="Informações do contratante; contratados e kit de instalações"
              icon={"info"}
              isSelected={novo_contrato.pageIndex == 0}
            />
            <ContratoStepIndicator
              onClick={() => {
                if (
                  novo_contrato.contratante != undefined &&
                  novo_contrato.contratada != undefined &&
                  novo_contrato.kitPrincipal != undefined &&
                  novo_contrato.quantidade != undefined &&
                  novo_contrato.contratada != undefined &&
                  novo_contrato.mensalidade != undefined &&
                  novo_contrato.valorReajustavel != undefined &&
                  novo_contrato.duracaoMeses != undefined &&
                  novo_contrato.carenciaDias != undefined &&
                  novo_contrato.fidelidadeMeses != undefined &&
                  novo_contrato.indice != undefined &&
                  novo_contrato.percentualDesconto != undefined &&
                  novo_contrato.mesesDesconto != undefined
                ) {
                  dispatch(setActualPage(1));
                } else {
                  toastWarning({ title: "Atenção!", message: "Verifique todos os campos!" });
                }
              }}
              title={"Complementares"}
              text="Kits e serviços adicionais"
              icon={"info"}
              isSelected={novo_contrato.pageIndex == 1}
            />
            <ContratoStepIndicator
              onClick={() => {
                if (
                  novo_contrato.contratante != undefined &&
                  novo_contrato.contratada != undefined &&
                  novo_contrato.kitPrincipal != undefined &&
                  novo_contrato.quantidade != undefined &&
                  novo_contrato.contratada != undefined &&
                  novo_contrato.mensalidade != undefined &&
                  novo_contrato.valorReajustavel != undefined &&
                  novo_contrato.duracaoMeses != undefined &&
                  novo_contrato.carenciaDias != undefined &&
                  novo_contrato.fidelidadeMeses != undefined &&
                  novo_contrato.indice != undefined &&
                  novo_contrato.percentualDesconto != undefined &&
                  novo_contrato.mesesDesconto != undefined
                ) {
                  dispatch(setActualPage(2));
                } else {
                  toastWarning({ title: "Atenção!", message: "Verifique todos os campos!" });
                }
              }}
              title={"Resumo do contrato"}
              text="Revisão geral e envio para aprovação"
              icon={"info"}
              isSelected={novo_contrato.pageIndex == 2}
            />
          </StepDiv>
          {novo_contrato.loading && <NovoContratoSkeleton />}
          {novo_contrato.loading && <NovoContratoSkeleton />}
          {!novo_contrato.loading && novo_contrato.pageIndex == 0 && <DadosDoContrato />}

          {!novo_contrato.loading && novo_contrato.pageIndex == 0 && <ValoresTecnicos />}
          {!novo_contrato.loading && novo_contrato.pageIndex == 1 && <KitAdicional />}
          {!novo_contrato.loading && novo_contrato.pageIndex == 1 && <ServicoAdicional />}
          {!novo_contrato.loading && novo_contrato.pageIndex == 2 && <ContractSummary />}
        </NovoContratoGrid>
      </NovoContratoContent>
    </Scaffold>
  );
};

export default NovoContratoPage;
