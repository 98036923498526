import LineSkeleton from "components/skeleton/line_skeleton";

import React from "react";
import {
  NewKitButton,
  NewKitContainer,
  NewKitDetails,
  NewKitTwoInputs,
} from "../new_kit_main/style";
import { ViewKitDetails } from "view/Kit/pages/ViewKIt/components/kit_view_main/style";
import { SkeletonContainer } from "./style";

const SkeletonKitList: React.FC<{}> = () => {
  return (
    <SkeletonContainer>
      <LineSkeleton height={30} width={150} />

      <NewKitTwoInputs>
        <NewKitDetails>
          <LineSkeleton height={30} width={150} />
          <LineSkeleton height={30} width={"100%"} />
        </NewKitDetails>

        <NewKitDetails>
          <LineSkeleton height={30} width={150} />
          <LineSkeleton height={30} width={"100%"} />
        </NewKitDetails>
      </NewKitTwoInputs>

      <NewKitTwoInputs>
        <NewKitDetails>
          <LineSkeleton height={30} width={150} />
          <LineSkeleton height={30} width={"100%"} />
        </NewKitDetails>

        <NewKitDetails>
          <LineSkeleton height={30} width={150} />
          <LineSkeleton height={30} width={"100%"} />
        </NewKitDetails>
      </NewKitTwoInputs>
      <div className="descri">
        <ViewKitDetails>
          <LineSkeleton height={30} width={150} />
          <LineSkeleton height={200} width={"100%"} />
        </ViewKitDetails>
      </div>

      <NewKitButton>
        <LineSkeleton height={30} width={"100%"} />
        <LineSkeleton height={30} width={"100%"} />
      </NewKitButton>
    </SkeletonContainer>
  );
};

export default SkeletonKitList;
