import React from "react";
import InputMask from "react-input-mask";
import { InputIcon, TextInputContent } from "./style";
import BillingIconSharp from "components/billing_icon/sharp_icon";
import billing_theme from "style/theme_colors";
import { SymbolCodepoints } from "react-material-symbols";
import { Caution1 } from "components/text/body/style";
import { removeSpecialCharacters } from "utils/stringUtils";
const BillingTextInput: React.FC<{
  placeholder?: string;
  width?: number;
  mask?: string | (string | RegExp)[];
  maskChar?: string;
  icon?: SymbolCodepoints;
  change: (value?: string) => void;
  value?: string | number | readonly string[] | undefined;
  disabled?: boolean | false;
  error?: boolean;
  errorMessage?: string;
}> = ({
  width,
  placeholder,
  mask,
  maskChar,
  change,
  icon,
  value,
  disabled,
  error,
  errorMessage,
}) => {
  return (
    <TextInputContent $width={width} $hasicon={icon != undefined} $error={error}>
      <InputMask
        mask={mask!}
        maskChar={maskChar}
        placeholder={placeholder}
        disabled={disabled}
        onChange={ev => {
          if (maskChar || mask) {
            change(removeSpecialCharacters(ev!.target!.value!));
          } else {
            change(ev!.target!.value!);
          }
        }}
        defaultValue={value}
      />
      {icon && (
        <InputIcon>
          <BillingIconSharp icon={icon} color={billing_theme.pallete.primary?.A400!} />
        </InputIcon>
      )}
      {error && <Caution1 color={billing_theme.pallete.error.A100}>{errorMessage}</Caution1>}
    </TextInputContent>
  );
};

export default BillingTextInput;
