import { ContratadaHttp } from "gateways/repo/contratada_http_client";
import {
  setLoadingNewContrato,
  setErrorNewContrato,
  setModalNewContratada,
} from "store/contratada/new_contratada_slice";
import { useAppDispatch } from "store/store";
import useContratada from "./useContratada";
import { PostContratadaModel } from "shared/models/contratada_response.model";
import { toastWarning } from "components/toast/billing_toast";

const useNewContratada = () => {
  const http = new ContratadaHttp();
  const dispatch = useAppDispatch();
  const { getContratadaList } = useContratada();

  const addContratada = async (data: PostContratadaModel) => {
    dispatch(setLoadingNewContrato(true));
    dispatch(setErrorNewContrato(false));
    try {
      await http.adContratada(data);
      getContratadaList();
      dispatch(setModalNewContratada(false));
    } catch (e) {
      toastWarning({ title: "Erro ao adicionar!" });
      dispatch(setErrorNewContrato(true));
    } finally {
      dispatch(setLoadingNewContrato(false));
    }
  };
  return {
    addContratada,
  };
};
export default useNewContratada;
