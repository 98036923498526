import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { OptionsModel } from "shared/models/contratada_response.model";
import { Configuracao } from "shared/models/default_models.model";
import { ServicoModel } from "shared/models/servico_response.model";

export interface ServicoSliceType {
  list: ServicoModel[];
  filterList: ServicoModel[];
  options: OptionsModel[];
  loading?: boolean;
  isError?: boolean;
  pagination?: ServicoModel[][];
  pageIndex: number;
  lastPage?: number;
}

const initialState: ServicoSliceType = {
  list: [],
  filterList: [],
  options: [],
  loading: false,
  isError: false,
  pageIndex: 0,
};

const servico_slice = createSlice({
  name: "servico",
  initialState,
  reducers: {
    setServicoList(state, action: PayloadAction<ServicoModel[]>) {
      state.list = action.payload;
    },
    setServicoFilterList(state, action: PayloadAction<ServicoModel[]>) {
      state.filterList = action.payload;
    },
    setServicoOptions(state, action: PayloadAction<OptionsModel[]>) {
      state.options = action.payload;
    },
    setLoadingServicos(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    setErrorServicos(state, action: PayloadAction<boolean>) {
      state.isError = action.payload;
    },
    setPaginationServico(state, action: PayloadAction<ServicoModel[][]>) {
      state.pagination = action.payload;
    },
    setServicoPageIndex(state, action: PayloadAction<number>) {
      state.pageIndex = action.payload;
    },
    setServicoLastPage(state, action: PayloadAction<number>) {
      state.lastPage = action.payload;
    },
  },
});

export const {
  setServicoList,
  setServicoOptions,
  setServicoFilterList,
  setLoadingServicos,
  setErrorServicos,
  setPaginationServico,
  setServicoPageIndex,
  setServicoLastPage,
} = servico_slice.actions;
export default servico_slice.reducer;
