import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export interface NewContratante {
  loading?: boolean;
  isError?: boolean;
  isOpen: boolean;
}

const initialState: NewContratante = {
  loading: false,
  isError: false,
  isOpen: false,
};

const new_contratante_slice = createSlice({
  name: "new_contratante",
  initialState,
  reducers: {
    setLoadingNewContrato(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },

    setErrorNewContrato(state, action: PayloadAction<boolean>) {
      state.isError = action.payload;
    },
    setModalNewContratante(state, action: PayloadAction<boolean>) {
      state.isOpen = action.payload;
    },
  },
});

export const { setLoadingNewContrato, setErrorNewContrato, setModalNewContratante } =
  new_contratante_slice.actions;
export default new_contratante_slice.reducer;
