import BillingTextInput from "components/input/text_input";
import useEquipamento from "hooks/useEquipamento";
import React from "react";
import { SearchContent, EquipamentoFilterContent, SearchInputContent } from "./style";
import { Heading6 } from "components/text/heading/style";
import billing_theme from "style/theme_colors";
import BillingSelectInput from "components/input/select";
import { RootState } from "store/store";
import { useSelector } from "react-redux";
import BillingDatePicker from "components/input/datePicker";
const EquipamentoFilter: React.FC<{}> = () => {
  const {} = useEquipamento();
  const list_enum = useSelector((state: RootState) => state.list_enum);
  return (
    <EquipamentoFilterContent>
      <SearchContent>
        <SearchInputContent>
          <Heading6 fontWeight={600} color={billing_theme.pallete.tertiary.A400}>
            Procurar:
          </Heading6>
          <BillingTextInput
            placeholder="Cliente, modelo, n de serie e etc..."
            change={value => console.log(value!)}
          />
        </SearchInputContent>
        <SearchInputContent>
          <Heading6 fontWeight={600} color={billing_theme.pallete.tertiary.A400}>
            Status:
          </Heading6>
          <BillingSelectInput
            onChange={val => {
              console.log(val);
            }}
            options={list_enum.EquipamentoStatusOptions!}
            placeholder={"Status equipamento"}
          />
        </SearchInputContent>
        <SearchInputContent>
          <Heading6 fontWeight={600} color={billing_theme.pallete.tertiary.A400}>
            Data expiração:
          </Heading6>
          <BillingDatePicker
            onChange={val => {
              console.log(val);
            }}
            placeholder={"Busque pela data"}
          />
        </SearchInputContent>
      </SearchContent>
    </EquipamentoFilterContent>
  );
};

export default EquipamentoFilter;
