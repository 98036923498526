import billing_theme from "style/theme_colors";
import styled from "styled-components";

export const ViewFaturaContent = styled.div`
  width: 100%;

  .contain {
    padding: 16px 16px;
  }
  .css-13cymwt-control {
    border-radius: 8px;
    border: solid 0.5px ${billing_theme.pallete.tertiary?.A200};
  }

  .css-13cymwt-control:hover {
    border-radius: 8px;
    border: solid 0.5px ${billing_theme.pallete.primary?.A200};
  }
  .css-t3ipsp-control:hover {
    border: solid 0.5px ${billing_theme.pallete.primary?.A200};
    border-color: ${billing_theme.pallete.primary?.A200};
  }

  .css-13cymwt-control:focus {
    border: solid 0.5px ${billing_theme.pallete.primary?.A200};
    border-color: ${billing_theme.pallete.primary?.A200};
  }
  .css-1jqq78o-placeholder {
    color: ${billing_theme.pallete.tertiary?.A300};
    font-weight: 600;
    font-size: 13px;
  }

  .css-1dimb5e-singleValue {
    color: ${billing_theme.pallete.tertiary?.A400};
    font-size: 13px;
    font-weight: 500;
  }

  .content {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
`;
export const ViewFaturaHeader = styled.div`
  padding: 16px 32px;
  border-bottom: solid 0.5px ${billing_theme.pallete.tertiary?.A100};
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: flex-start;
  justify-content: space-between;
`;
export const StepDiv = styled.div`
  height: 100%;
  border-right: 0.5px solid ${billing_theme.pallete.tertiary?.A100};
`;
export const ViewFaturaInputLabel = styled.div`
  display: flex;
  gap: 4px;
  flex-direction: column;
`;

export const ViewFaturasForm = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;

  .contrantes_faturaInicial {
    display: grid;
    grid-template-columns: 65% 1fr;
    gap: 16px;
  }
`;
export const ViewFaturaGrid = styled.div<{ $isResume: boolean }>`
  display: grid;
  grid-template-columns: ${props => (props.$isResume ? "370px 1fr" : "370px 1fr 1fr")};
  height: ${window.innerHeight - (64 + 93) + "px"};
`;
export const FaturaBackButtonContent = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  padding: 10px;
  align-items: center;
  flex-wrap: nowrap;
  cursor: pointer;
`;
export const NoContentTable = styled.div`
  width: 100%;
  height: 200px;
  border: solid 0.5px ${billing_theme.pallete.tertiary.A100};
  background: ${billing_theme.pallete.specific.T_25_50};
  display: flex;
  align-items: center;
  align-self: center;
  justify-content: center;
  margin-top: 18px;
`;

export const ViewFaturaButtonSelected = styled.div<{ $is_selected: boolean }>`
  display: flex;
  height: 32px;
  padding: 0px 8px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  gap: 8px;
  flex: 1 0 0;
  border-radius: 4px;
  border: solid 0.5px ${billing_theme.pallete.tertiary.A100};
  background-color: ${props =>
    props.$is_selected
      ? billing_theme.pallete.tertiary.A600
      : billing_theme.pallete.specific.T_50_25};
`;

export const ViewFaturaTabContent = styled.div`
  display: flex;
  width: 654px;
  padding: 4px;
  align-items: flex-start;
  align-content: flex-start;
  gap: 4px;
  align-self: stretch;
  flex-wrap: wrap;
  border: solid 0.5px ${billing_theme.pallete.tertiary.A100};
  border-radius: 8px;
  margin: 16px;
`;
