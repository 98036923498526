import BillingIconSharp from "components/billing_icon/sharp_icon";
import React, { useEffect, useState } from "react";
import "react-material-symbols/outlined";
import billing_theme from "style/theme_colors";
import { InsideMenuHeaderComp } from "./style";
import { useNavigate } from "react-router-dom";
import { MaterialSymbol, SymbolCodepoints } from "react-material-symbols";
import { Heading6 } from "components/text/heading/style";
import "react-material-symbols/sharp";

const InsideMenuHeader: React.FC<{
  name: string;
}> = ({ name }) => {
  return (
    <InsideMenuHeaderComp>
      <Heading6 color={billing_theme.pallete.primary?.A400} fontWeight={600}>
        {name}
      </Heading6>
    </InsideMenuHeaderComp>
  );
};

export default InsideMenuHeader;
