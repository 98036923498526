import Scaffold from "components/scaffold";
import { useEffect, useState } from "react";
import useViewFatura from "hooks/fatura/useViewFatura";

import { Body2 } from "components/text/body/style";
import { Heading3 } from "components/text/heading/style";
import billing_theme from "style/theme_colors";
import { ViewFaturaContent, ViewFaturaHeader, ViewFaturaTabContent } from "./style";
import FaturaBackButton from "./components/fatura_back_button";
import DadosGeraisFatura from "./components/dados_gerais_fatura";
import ViewFaturaTabButton from "./components/fatura_tab_area";
import EquipamentoFatura from "./components/equipamento_fatura";
import MaoDeObra from "./components/mao_de_obra";
import ReajustesFatura from "./components/reajustes";
import LineSkeleton from "components/skeleton/line_skeleton";
import { RootState } from "store/store";
import { useSelector } from "react-redux";

const ViewFaturaPage: React.FC = () => {
  const { getFaturaById } = useViewFatura();
  const [pageIndex, setPageIndex] = useState(0);
  const state = useSelector((state: RootState) => state.view_fatura);
  useEffect(() => {
    getFaturaById();
  }, []);
  return (
    <Scaffold>
      <ViewFaturaContent>
        <ViewFaturaHeader>
          <div>
            <Heading3 fontWeight={600} color={billing_theme.pallete.tertiary?.A600}>
              Fatura
            </Heading3>
            <Body2 color={billing_theme.pallete.tertiary?.A600}>
              Veja as informações da fatura.
            </Body2>
          </div>

          <FaturaBackButton />
        </ViewFaturaHeader>
        <ViewFaturaTabContent>
          {state.loading ? (
            <LineSkeleton width={"160px"} height={32} />
          ) : (
            <ViewFaturaTabButton
              icon="info"
              is_selected={pageIndex === 0}
              label={"Dados Gerais"}
              onTap={() => {
                setPageIndex(0);
              }}
            />
          )}
          {state.loading ? (
            <LineSkeleton width={"160px"} height={32} />
          ) : (
            <ViewFaturaTabButton
              icon="data_check"
              is_selected={pageIndex === 1}
              label={"Assinaturas"}
              onTap={() => {
                setPageIndex(1);
              }}
            />
          )}
          {state.loading ? (
            <LineSkeleton width={"160px"} height={32} />
          ) : (
            <ViewFaturaTabButton
              icon="build"
              is_selected={pageIndex === 2}
              label={"Mão de obra"}
              onTap={() => {
                setPageIndex(2);
              }}
            />
          )}
          {state.loading ? (
            <LineSkeleton width={"160px"} height={32} />
          ) : (
            <ViewFaturaTabButton
              icon="tune"
              is_selected={pageIndex === 3}
              label={"Reajustes"}
              onTap={() => {
                setPageIndex(3);
              }}
            />
          )}
        </ViewFaturaTabContent>
        {pageIndex == 0 && <DadosGeraisFatura />}
        {pageIndex == 1 && <EquipamentoFatura />}
        {pageIndex == 2 && <MaoDeObra />}
        {pageIndex == 3 && <ReajustesFatura />}
      </ViewFaturaContent>
    </Scaffold>
  );
};

export default ViewFaturaPage;
