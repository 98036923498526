import { ViewFieldContent } from "../style";
import { Heading6 } from "components/text/heading/style";
import { Body4 } from "components/text/body/style";
import billing_theme from "style/theme_colors";
import Skeleton from "react-loading-skeleton";
import { useSelector } from "react-redux";
import { RootState } from "store/store";
import LineSkeleton from "components/skeleton/line_skeleton";

const ViewField: React.FC<{ title: string; content?: string | number; aboveTitle?: string }> = ({
  title,
  content,
}) => {
  const state = useSelector((state: RootState) => state.view_contrato);
  return (
    <ViewFieldContent>
      <Heading6 fontWeight={600} lineheight="24px" color={billing_theme.pallete.tertiary.A600}>
        {title}
      </Heading6>
      {state.loading ? (
        <LineSkeleton width={"200px"} height={16} />
      ) : (
        <Body4 fontWeight={400} color={billing_theme.pallete.primary.A300} lineheight="16px">
          {content ?? "--"}
        </Body4>
      )}
    </ViewFieldContent>
  );
};

export default ViewField;
