import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { OptionsModel } from "shared/models/contratada_response.model";
import { KitModel } from "shared/models/kit_response.model";
import { Configuracao } from "shared/models/default_models.model";

export interface KitSliceType {
  list: KitModel[];
  filterList: KitModel[];
  options: OptionsModel[];
  type_kit_filter: boolean;
  type_cobranca_filter: boolean;
  type_name_filter: boolean;
  enable_filter: boolean;
  loading?: boolean;
  pagination?: KitModel[][];
  pageIndex: number;
  lastPage?: number;
}

const initialState: KitSliceType = {
  list: [],
  filterList: [],
  options: [],
  loading: false,
  type_kit_filter: false,
  type_cobranca_filter: false,
  type_name_filter: false,
  enable_filter: false,
  pageIndex: 0,
};

const kit_slice = createSlice({
  name: "kit",
  initialState,
  reducers: {
    setkitList(state, action) {
      state.list = action.payload;
    },

    setConfigList(state, action) {
      state.list = action.payload;
    },
    setFilterKitList(state, action) {
      state.filterList = action.payload;
    },

    setkitOptions(state, action) {
      state.options = action.payload;
    },
    setTipoKitFilter(state, action: PayloadAction<boolean>) {
      state.type_kit_filter = action.payload;
    },
    setTipoCobrancaFilter(state, action: PayloadAction<boolean>) {
      state.type_cobranca_filter = action.payload;
    },
    setNameFilter(state, action: PayloadAction<boolean>) {
      state.type_name_filter = action.payload;
    },
    setFilterEnable(state, action: PayloadAction<boolean>) {
      state.enable_filter = action.payload;
    },
    setLoadingKit(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    setPaginationKit(state, action: PayloadAction<KitModel[][]>) {
      state.pagination = action.payload;
    },
    setKitPageIndex(state, action: PayloadAction<number>) {
      state.pageIndex = action.payload;
    },
    setKitLastPage(state, action: PayloadAction<number>) {
      state.lastPage = action.payload;
    },
  },
});

export const {
  setkitList,
  setConfigList,
  setkitOptions,
  setFilterKitList,
  setFilterEnable,
  setNameFilter,
  setTipoCobrancaFilter,
  setTipoKitFilter,
  setLoadingKit,
  setPaginationKit,
  setKitPageIndex,
  setKitLastPage,
} = kit_slice.actions;
export default kit_slice.reducer;
