import { toastError } from "components/toast/billing_toast";
import { KitHttp, UpdateAndPostKitModel } from "gateways/repo/kit_http_cliente";
import { ModeloHttp } from "gateways/repo/modelo_http_client";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { resetKitNew, setKitLoading, setKitModelos } from "store/kit/new_kit_slice";
import { useAppDispatch } from "store/store";

const useNewKit = () => {
  const http = new KitHttp();
  const httpModelo = new ModeloHttp();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [isErrorKit, setErrorKit] = useState(false);

  const getModelosOptions = async () => {
    setErrorKit(false);
    dispatch(setKitLoading(true));
    try {
      const options = await httpModelo.getModelosOptions();
      dispatch(setKitModelos(options.Lista));
    } catch (e) {
      setErrorKit(true);
      console.log(e);
    } finally {
      dispatch(setKitLoading(false));
    }
  };
  const addNewkit = async (data: UpdateAndPostKitModel) => {
    dispatch(setKitLoading(true));

    try {
      await http.addNewkit(data);
      navigate("/operacoes");
      dispatch(resetKitNew());
    } catch (error) {
      toastError({
        title: "Algo deu errado!",
        message: "Erro ao editar, por favor tente novamente.",
      });
    } finally {
      dispatch(setKitLoading(false));
    }
  };
  return {
    isErrorKit,
    getModelosOptions,
    addNewkit,
  };
};
export default useNewKit;
