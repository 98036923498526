import billing_theme from "style/theme_colors";
import styled from "styled-components";
export const NovoInstalacaoContent = styled.div`
  width: 100%;

  position: relative;
`;
export const NovoInstalacaoHeader = styled.div`
  padding: 16px 32px;
  border-bottom: solid 0.5px ${billing_theme.pallete.tertiary?.A100};
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: flex-start;
  justify-content: space-between;
`;
export const StepDiv = styled.div`
  height: 100%;
  border-right: 0.5px solid ${billing_theme.pallete.tertiary?.A100};
`;
export const NovoInstalacaoInputLabel = styled.div`
  display: flex;
  gap: 4px;
  flex-direction: column;
`;

export const NovoInstalacaosForm = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  border-right: solid 0.5px ${billing_theme.pallete.tertiary?.A100};
  padding-right: 16px;
  padding-top: 16px;
  padding-bottom: 16px;

  .button {
    padding-top: 24px;
    width: 100%;
    text-align: center;
  }
`;
export const NovoInstalacaoGrid = styled.div`
  display: grid;
  grid-template-columns: 60% 1fr;
  padding: 31px;
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 0;
`;
export const InstalacaoBackButtonContent = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  padding: 10px;
  align-items: center;
  flex-wrap: nowrap;
  cursor: pointer;
`;
