import { BillingMenuAside } from "./style";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import "react-material-symbols/sharp";
import BillingMenuButton from "./components/menu_button";

const BillingMenu: React.FC = () => {
  const location = useLocation();
  const [topScroll, setTopScroll] = useState(64);

  window.addEventListener("scroll", function () {
    if (window.scrollY <= 64) {
      setTopScroll(64 - document.documentElement.scrollTop);
    } else {
      setTopScroll(0);
    }
  });
  return (
    <BillingMenuAside top={topScroll}>
      <BillingMenuButton icon="home" name="Home" path="/inicio" />

      <BillingMenuButton icon="description" name="Contrato" path="/contrato" />
      <BillingMenuButton icon="note_add" name="Operações" path="/operacoes" />
      <BillingMenuButton icon="payments" name="Faturamento" path="/faturamento" />
    </BillingMenuAside>
  );
};

export default BillingMenu;
