import { Body4 } from "components/text/body/style";
import { Heading5, Heading6 } from "components/text/heading/style";
import billing_theme from "style/theme_colors";
import { NovoInstalacaoInputLabel } from "../../style";
import { EquipamentoAdicionalContainer, SelectedEquipamentoContent } from "./style";
import { useSelector } from "react-redux";
import { OptionsModel } from "shared/models/contratada_response.model";
import { RootState, useAppDispatch } from "store/store";
import { SingleValue } from "react-select";
import BillingSelectInput from "components/input/select";
import SelectedEquipamento from "./components/selected_item_equipamento";
import {
  addEquipamentoComplementarNewInstalacao,
  removeEquipamentoComplementarNewInstalacao,
  setEquipamentoPrincipalNewInstalacao,
} from "store/instalacao/new_instalacao_slice";
import LineSkeleton from "components/skeleton/line_skeleton";
import { useState, useEffect } from "react";

const EquipamentoAdicional: React.FC<{}> = () => {
  const equipamento = useSelector((state: RootState) => state.equipamento);
  const state = useSelector((state: RootState) => state.new_instalacao);
  const dispatch = useAppDispatch();

  const getEquipamentoName = (id: string) => {
    return equipamento.options.find(el => el.value == id)?.label ?? "";
  };

  const [selectedEquipamentoPrincipal, setSelectedEquipamentoPrincipal] = useState<OptionsModel>();
  useEffect(() => {
    setSelectedEquipamentoPrincipal(
      equipamento.options.find(el => Number(el.value) == state.Veiculo_ID)
    );
  });
  return (
    <EquipamentoAdicionalContainer>
      <NovoInstalacaoInputLabel>
        <Heading5 fontWeight={600} color={billing_theme.pallete.tertiary.A600}>
          Equipamentos
        </Heading5>
        <Body4 fontWeight={400} color={billing_theme.pallete.tertiary.A600}>
          Informe o equipamento adicional e o principal.
        </Body4>
      </NovoInstalacaoInputLabel>
      <NovoInstalacaoInputLabel>
        <Heading6 fontWeight={600} color={billing_theme.pallete.tertiary.A600}>
          Equipamento principal
        </Heading6>
        {state.loading ? (
          <LineSkeleton width={"100%"} height={40} />
        ) : (
          <BillingSelectInput
            defaultInputValue={selectedEquipamentoPrincipal}
            onChange={(val: any) => {
              val != null
                ? dispatch(setEquipamentoPrincipalNewInstalacao(Number(val.value)))
                : dispatch(setEquipamentoPrincipalNewInstalacao(undefined));
            }}
            options={equipamento.options}
            placeholder={"Selecione um equipamento principal"}
          />
        )}
      </NovoInstalacaoInputLabel>
      <NovoInstalacaoInputLabel>
        <Heading6 fontWeight={600} color={billing_theme.pallete.tertiary.A600}>
          Equipamento adicional
        </Heading6>
        {state.loading ? (
          <LineSkeleton width={"100%"} height={40} />
        ) : (
          <BillingSelectInput
            onChange={(val: SingleValue<OptionsModel>) => {
              if (val != null) {
                dispatch(
                  addEquipamentoComplementarNewInstalacao({
                    ID: 0,
                    Equipamento_ID: Number(val.value),
                    Instalacao_ID: 0,
                  })
                );
              }
            }}
            options={equipamento.options}
            placeholder={"Selecione um  Equipamento"}
          />
        )}
      </NovoInstalacaoInputLabel>
      <Heading5 fontWeight={600} color={billing_theme.pallete.tertiary.A600}>
        Adicionados
      </Heading5>
      <SelectedEquipamentoContent>
        {state?.Equipamentos?.map(el => (
          <SelectedEquipamento
            key={el.Equipamento_ID}
            label={getEquipamentoName(el.Equipamento_ID!.toString())}
            onTap={() => {
              dispatch(
                removeEquipamentoComplementarNewInstalacao({
                  Equipamento_ID: el.Equipamento_ID,
                  Instalacao_ID: 0,
                })
              );
            }}
          />
        ))}
      </SelectedEquipamentoContent>
    </EquipamentoAdicionalContainer>
  );
};

export default EquipamentoAdicional;
