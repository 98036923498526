import React from "react";

import { InputContent } from "./style";
import { Caution1 } from "components/text/body/style";
import billing_theme from "style/theme_colors";
import { NumberFormatValues, NumericFormat } from "react-number-format";

const BillingMoneyInput: React.FC<{
  placeholder?: string;
  width?: number;
  disabled?: boolean | false;
  value?: string | number | null | undefined;
  error?: boolean;
  errorMesage?: string;
  onNumberFormat?: (value: NumberFormatValues) => void;
}> = ({ width, placeholder, value, disabled, error, errorMesage, onNumberFormat }) => {
  return (
    <InputContent $width={width} $error={error}>
      <NumericFormat
        disabled={disabled}
        placeholder={placeholder}
        onValueChange={(val: NumberFormatValues) => {
          onNumberFormat ? onNumberFormat(val) : () => {};
        }}
        defaultValue={value}
        thousandSeparator="."
        decimalSeparator=","
        prefix={"R$ "}
        decimalScale={2}
      />
      {error && <Caution1 color={billing_theme.pallete.error.A100}>{errorMesage}</Caution1>}
    </InputContent>
  );
};

export default BillingMoneyInput;
