import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Inject,
  Page,
  Sort,
  Filter,
  Group,
  Resize,
  ExcelExport,
  Toolbar,
  Reorder,
  SelectionSettingsModel,
  ToolbarItems,
  ExcelExportProperties,
  ColumnChooser,
} from "@syncfusion/ej2-react-grids";
import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "store/store";
import { MaoDeObraFaturaContainer } from "./style";
import { toMoney } from "utils/numberUtils";
import { toDate } from "utils/stringUtils";

const MaoDeObra: React.FC<{}> = ({}) => {
  const state = useSelector((state: RootState) => state.view_fatura);
  let grid: GridComponent | null;
  const pageSettings: object = { pageSize: 20 };

  const filterSettings: object = { type: "Excel" };

  const searchOptions = {
    fields: [],
    ignoreCase: true,
    operator: "contains",
  };

  const selectionOptions: SelectionSettingsModel = {
    type: "Multiple",
    allowColumnSelection: true,
  };

  const toolbarOptions: ToolbarItems[] = ["ColumnChooser", "ExcelExport", "Search"];

  const toolbarClick = (args: any) => {
    if (grid && args.item.id === "Grid_excelexport") {
      const selectedRecords = grid.getSelectedRecords();
      const exportProperties: ExcelExportProperties = {
        dataSource: selectedRecords.length == 0 ? state.Objeto?.MaoObras : selectedRecords,
        enableFilter: true,
      };
      grid.excelExport(exportProperties);
    }
  };
  const dataGridFormater = (field: any, data: any, column: any) => {
    return toDate(data[field]);
  };
  const moneyGrudFormatter = (field: any, data: any, column: any) => {
    return toMoney(data[field]);
  };

  const customizeCell = (args: any) => {
    if (args.data.ItemNovo == true) {
      args.cell.classList.add("newItem");
    }
    if (args.data.ItemFaltante == true) {
      args.cell.classList.add("removedItem");
    }
    if (args.column.field === "Mensalidade") {
      if (args.data.MarcarMensalidade == true) return args.cell.classList.add("waringField");
    }
    if (args.column.field === "NumeroSerie") {
      if (args.data.MarcarNumeroSerie == true) return args.cell.classList.add("waringField");
    }
    if (args.column.field === "Placa") {
      if (args.data.MarcarPlaca == true) return args.cell.classList.add("waringField");
    }
  };
  return (
    <MaoDeObraFaturaContainer>
      <GridComponent
        id="Grid"
        dataSource={state.Objeto?.MaoObras}
        toolbar={toolbarOptions}
        searchSettings={searchOptions}
        allowGrouping={true}
        allowSorting={true}
        allowFiltering={true}
        allowPaging={true}
        pageSettings={pageSettings}
        filterSettings={filterSettings}
        height={"100%"}
        width={"100%"}
        enableHover={false}
        showColumnChooser={true}
        allowExcelExport={true}
        ref={g => (grid = g)}
        allowReordering={true}
        allowResizing={true}
        selectionSettings={selectionOptions}
        toolbarClick={toolbarClick}
      >
        <ColumnsDirective>
          <ColumnDirective textAlign="Center" width={100} field="ID" headerText="ID" />
          <ColumnDirective textAlign="Center" width={250} field="Placa" headerText="Placa" />
          <ColumnDirective
            textAlign="Center"
            width={250}
            field="NumeroContratoBilling"
            headerText="N de Contrato"
          />
          <ColumnDirective
            textAlign="Center"
            width={250}
            field="NumeroSerie"
            headerText="Numero de serie"
          />
          <ColumnDirective textAlign="Center" width={250} field="Tipo" headerText="Tipo" />
          <ColumnDirective
            textAlign="Center"
            width={250}
            field="Data"
            headerText="Data"
            valueAccessor={dataGridFormater}
          />
          <ColumnDirective
            textAlign="Center"
            width={250}
            field="Observacoes"
            headerText="Observações"
          />
          <ColumnDirective
            textAlign="Center"
            width={250}
            field="Instalador"
            headerText="Instalador"
          />
          <ColumnDirective
            textAlign="Center"
            width={250}
            field="Valor"
            headerText="Valor"
            valueAccessor={moneyGrudFormatter}
          />
          <ColumnDirective
            textAlign="Center"
            width={250}
            field="Fatura_ID"
            headerText="Id da fatura"
          />
        </ColumnsDirective>

        <Inject
          services={[
            Page,
            Sort,
            Filter,
            Group,
            Resize,
            Toolbar,
            ExcelExport,
            Reorder,
            ColumnChooser,
          ]}
        />
      </GridComponent>
    </MaoDeObraFaturaContainer>
  );
};

export default MaoDeObra;
