import useFatura from "hooks/fatura/useFatura";
import { Heading6 } from "components/text/heading/style";
import billing_theme from "style/theme_colors";
import BillingTextInput from "components/input/text_input";
import BillingDatePicker from "components/input/datePicker";
import BillingNumberInput from "components/input/number_input";
import { FaturaFilterButtons, FaturaFilterContent, FatureFilterLabel } from "view/Fatura/style";
import PrimaryButton from "components/button/billing_button";
import TertiaryButton from "components/button/tertiaty_button";

const FaturaFilter: React.FC = () => {
  const {
    filterByName,
    filterByDate,
    filterValorMaior,
    filterValorMenor,
    filterResultFatura,
    clearFilterFatura,
  } = useFatura();

  return (
    <>
      <FaturaFilterContent>
        <FatureFilterLabel>
          <Heading6 color={billing_theme.pallete.tertiary.A600} fontWeight={600}>
            Busque por contratantes:
          </Heading6>
          <BillingTextInput
            placeholder="EX: Empresa x"
            change={value => {
              filterByName(value!);
            }}
          />
        </FatureFilterLabel>

        <FatureFilterLabel>
          <Heading6 color={billing_theme.pallete.tertiary.A600} fontWeight={600}>
            Mês da fatura:
          </Heading6>
          <BillingDatePicker
            placeholder="Mês"
            onChange={date => filterByDate(date)}
            dateFormat="MM/yyyy"
          />
        </FatureFilterLabel>

        <FatureFilterLabel>
          <Heading6 color={billing_theme.pallete.tertiary.A600} fontWeight={600}>
            Valor igual ou maior:
          </Heading6>
          <BillingNumberInput
            currencyDisplay="symbol"
            format="currency"
            placeholder="Ex: R$ 1.000"
            onNumberFormat={e => filterValorMaior(e.number)}
          />
        </FatureFilterLabel>
        <FatureFilterLabel>
          <Heading6 color={billing_theme.pallete.tertiary.A600} fontWeight={600}>
            Valor igual ou menor:
          </Heading6>
          <BillingNumberInput
            currencyDisplay="symbol"
            format="currency"
            placeholder="Ex: R$ 20.0000"
            onNumberFormat={e => filterValorMenor(e.number)}
          />
        </FatureFilterLabel>
      </FaturaFilterContent>
      <FaturaFilterButtons>
        <PrimaryButton label={"Filtrar"} onTap={() => filterResultFatura()} isSmall={true} />
        <TertiaryButton label={"Limpar"} onTap={() => clearFilterFatura()} isSmall={true} />
      </FaturaFilterButtons>
    </>
  );
};

export default FaturaFilter;
