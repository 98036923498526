import { ContratadaContainer, ContratadaHeader } from "./style";
import { Heading3 } from "components/text/heading/style";
import { Body2 } from "components/text/body/style";
import billing_theme from "style/theme_colors";
import Scaffold from "components/scaffold";
import PrimaryButton from "components/button/billing_button";
import { useEffect } from "react";
import InsideMenu from "components/insidemenu";
import InsideMenuButton from "components/insidemenu/components/inside_menu_button";
import ContratadaList from "./components/contratada_list";
import SkeletonContratadaList from "./components/contrada_skeleton";
import ContratadaErrorContent from "./components/contratada_list_error";
import ContratadaFilter from "./components/contratada_filter";
import ModalBilling from "components/modal";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "store/store";
import ModalNewContratada from "./page/NewContratada";
import { setModalNewContratada } from "store/contratada/new_contratada_slice";
import useContratada from "hooks/contratada/useContratada";
import OpecaoesInsideMenu from "components/insidemenu/components/opecaoes_menu";

const ContratadaPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const { getContratadaList, getContratadaOption } = useContratada();

  const contratada = useSelector((state: RootState) => state.contratada);
  const new_contratada = useSelector((state: RootState) => state.new_contratada);
  useEffect(() => {
    getContratadaList();
    getContratadaOption();
  }, []);
  return (
    <Scaffold>
      <ContratadaContainer>
        <InsideMenu title={"Contratada"} icon={"work"}>
          <OpecaoesInsideMenu />
        </InsideMenu>
        <div className="main_content">
          <ContratadaHeader>
            <div className="">
              <Heading3 fontWeight={600} color={billing_theme.pallete.tertiary?.A400}>
                Contratada
              </Heading3>
              <Body2 color={billing_theme.pallete.tertiary?.A400}>Empresas prestadoras.</Body2>
            </div>

            <PrimaryButton
              label={"Novo contratador"}
              leftIcon={"add_business"}
              isSmall
              onTap={() => dispatch(setModalNewContratada(true))}
            />
          </ContratadaHeader>
          {contratada.isError && <ContratadaErrorContent retry={() => getContratadaList()} />}

          {!contratada.isError && <ContratadaFilter />}

          {!contratada.isError &&
            (contratada.loading ? <SkeletonContratadaList /> : <ContratadaList />)}
        </div>
      </ContratadaContainer>

      <ModalBilling modalIsOpen={new_contratada.isOpen} width="600px" height="auto">
        <ModalNewContratada />
      </ModalBilling>
    </Scaffold>
  );
};

export default ContratadaPage;
