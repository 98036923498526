import { InstalacaoHttp } from "gateways/repo/instalacao_http_client";
import {
  setLoadingNewInstalacao,
  setErrorNewInstalacao,
} from "store/instalacao/new_instalacao_slice";
import { RootState, useAppDispatch } from "store/store";
import { PostInstalacaoModel } from "shared/models/instalacao_response.model";
import { toastWarning } from "components/toast/billing_toast";
import useContratante from "hooks/contratante/useContratante";
import useInstalacao from "hooks/instalacao/useInstalacao";
import useVeiculo from "hooks/veiculo/useVeiculo";
import useEquipamento from "hooks/useEquipamento";
import useBillingEnum from "hooks/useBillingEnum";
import { useSelector } from "react-redux";

const useNewInstalacao = () => {
  const state = useSelector((state: RootState) => state.new_instalacao);
  const http = new InstalacaoHttp();
  const dispatch = useAppDispatch();
  const { getInstalacaoList } = useInstalacao();
  const { getContratatesOptions } = useContratante();
  const { getVeiculoOption } = useVeiculo();
  const { getEquipamentoOption } = useEquipamento();
  const { getEnums } = useBillingEnum();

  const getNewInstalacaoInfo = async () => {
    dispatch(setLoadingNewInstalacao(true));
    dispatch(setErrorNewInstalacao(false));
    try {
      await getContratatesOptions();
      await getVeiculoOption();
      await getEquipamentoOption();
      await getEnums();
    } catch (e) {
      toastWarning({ title: "Erro!" });
      dispatch(setErrorNewInstalacao(true));
    } finally {
      dispatch(setLoadingNewInstalacao(false));
    }
  };
  const addInstalacao = async () => {
    dispatch(setLoadingNewInstalacao(true));
    dispatch(setErrorNewInstalacao(false));
    try {
      await http.adInstalacao({
        ID: 0,
        CodigoGO: state.CodigoGO,
        Contratante_ID: state.Contratante_ID,
        Veiculo_ID: state.Veiculo_ID,
        EquipamentoPrincipal_ID: state.EquipamentoPrincipal_ID,
        DataInstalacao: state.DataInstalacao,
        DataDesinstalacao: state.DataDesinstalacao,
        MotivoDesinstalacao: state.MotivoDesinstalacao,
        Equipamentos: state.Equipamentos,
      });
      getInstalacaoList();
    } catch (e) {
      toastWarning({ title: "Erro ao adicionar!" });
      dispatch(setErrorNewInstalacao(true));
    } finally {
      dispatch(setLoadingNewInstalacao(false));
    }
  };
  return {
    addInstalacao,
    getNewInstalacaoInfo,
  };
};
export default useNewInstalacao;
