import { SymbolCodepoints } from "react-material-symbols";
import { StepContainer, StepTextContent } from "./style";
import BillingIconSharp from "components/billing_icon/sharp_icon";
import billing_theme from "style/theme_colors";
import { Heading6 } from "components/text/heading/style";
import { Body4 } from "components/text/body/style";

const ContratoStepIndicator: React.FC<{
  onClick: () => void;
  title: string;
  text: string;
  icon: SymbolCodepoints;
  isSelected: boolean;
}> = ({ onClick, title, text, icon, isSelected }) => {
  return (
    <StepContainer onClick={onClick} $isSelected={isSelected}>
      <StepTextContent>
        <BillingIconSharp
          icon={icon}
          color={
            isSelected ? billing_theme.light.tertiary.A25! : billing_theme.pallete.tertiary.A600
          }
        />
        <div>
          <Heading6
            fontWeight={600}
            color={
              isSelected ? billing_theme.light.tertiary.A25! : billing_theme.pallete.tertiary.A600
            }
          >
            {title}
          </Heading6>
          <Body4
            color={
              isSelected ? billing_theme.light.tertiary.A25! : billing_theme.pallete.tertiary.A600
            }
          >
            {text}
          </Body4>
        </div>
      </StepTextContent>
      <BillingIconSharp
        icon={"chevron_right"}
        color={isSelected ? billing_theme.light.tertiary.A25! : billing_theme.pallete.tertiary.A600}
      />
    </StepContainer>
  );
};

export default ContratoStepIndicator;
