import { useNavigate } from "react-router-dom";
import { useLocalStorage, LocalStorageEnums } from "./useLocalStorage";
import { UserHttp } from "gateways/repo/user_http";
import { toastError } from "components/toast/billing_toast";
import { useState } from "react";
import { jwtDecode } from "jwt-decode";
import { TokenModel } from "shared/models/token.model";
import moment from "moment";

export const useAuth = () => {
  const http = new UserHttp();
  const navigate = useNavigate();
  const {
    setLocalStorage,
    deleteLocalStorage,
    getLocalStorage,
    getLocalStorageAsString,
    setLocalStorageAsString,
  } = useLocalStorage();

  const [userLoading, setUserLoading] = useState<boolean>(false);

  const login = async (email: string, senha: string, manter_logado: boolean) => {
    setUserLoading(true);

    try {
      const data = await http.userLogin(email, senha);
      const jwtData: TokenModel = jwtDecode(data.Token);

      setLocalStorageAsString(LocalStorageEnums.horarioLogin, Date.now());
      setLocalStorage(LocalStorageEnums.token, { token: data.Token });
      if (manter_logado) {
        setLocalStorage(LocalStorageEnums.user, { email: email, senha: senha });
      }
      navigate("/inicio");
    } catch (error) {
      toastError({ title: "Algo deu errado!" });
    } finally {
      setUserLoading(false);
    }
  };

  const logout = () => {
    deleteLocalStorage(LocalStorageEnums.horarioLogin);
    deleteLocalStorage(LocalStorageEnums.token);
    deleteLocalStorage(LocalStorageEnums.user);
    navigate("/", { replace: true });
  };

  const reload = async () => {
    const email = getLocalStorage(LocalStorageEnums.user).email;
    const senha = getLocalStorage(LocalStorageEnums.user).senha;

    if (email != undefined && senha != undefined) await login(email, senha, true);
  };
  const isAuth = () => getLocalStorage(LocalStorageEnums.token).token != undefined;

  const userToken = () => getLocalStorage(LocalStorageEnums.token).token;

  const verifyReload = async () => {
    let tempoSalvo: any = getLocalStorageAsString(LocalStorageEnums.horarioLogin);

    if (tempoSalvo) {
      tempoSalvo = Number(tempoSalvo);
      let tempoAtual = Date.now();
      let diferenca = tempoAtual - tempoSalvo;
      let diferencaHoras = diferenca / (1000 * 60 * 60);
      if (diferencaHoras >= 12) {
        logout();
      }
    } else {
      logout();
    }
  };

  return { userToken, login, isAuth, logout, reload, userLoading, verifyReload };
};
