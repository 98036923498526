import billing_theme from "style/theme_colors";
import { Heading5, Heading6 } from "components/text/heading/style";
import { Body4 } from "components/text/body/style";
import { NovoContratoInputLabel } from "../../../style";
import { RootState, useAppDispatch } from "store/store";
import {
  setInstalacao,
  setDesinstalacao,
  setTransferencia,
} from "store/contrato/new_contrato_slice";
import BillingNumberInput from "components/input/number_input";
import { ValoresDataGrid, ValoresInsideContent } from "../style";
import { useSelector } from "react-redux";
import { toMoney } from "utils/numberUtils";
import BillingMoneyInput from "components/input/money_input";

const ValoresTecnicosComp: React.FC<{}> = () => {
  const novo_contrato = useSelector((state: RootState) => state.novo_contrato);
  const dispatch = useAppDispatch();
  return (
    <ValoresInsideContent>
      <NovoContratoInputLabel>
        <Heading5 fontWeight={600} color={billing_theme.pallete.tertiary.A600}>
          Valores técnicos
        </Heading5>
        <Body4 fontWeight={400} color={billing_theme.pallete.tertiary.A600}>
          Informe valores os negociados.
        </Body4>
      </NovoContratoInputLabel>
      <ValoresDataGrid>
        <NovoContratoInputLabel>
          <Heading6 fontWeight={600} color={billing_theme.pallete.tertiary.A600}>
            Instalação
          </Heading6>
          <BillingMoneyInput
            value={novo_contrato.instalacao}
            placeholder="R$ 00,00"
            onNumberFormat={val => {
              dispatch(setInstalacao(val.floatValue));
            }}
          />
        </NovoContratoInputLabel>
        <NovoContratoInputLabel>
          <Heading6 fontWeight={600} color={billing_theme.pallete.tertiary.A600}>
            Desinstalação
          </Heading6>
          <BillingMoneyInput
            value={novo_contrato.desinstalacao ?? ""}
            placeholder="R$ 00,00"
            onNumberFormat={val => {
              dispatch(setDesinstalacao(val.floatValue));
            }}
          />
        </NovoContratoInputLabel>
        <NovoContratoInputLabel>
          <Heading6 fontWeight={600} color={billing_theme.pallete.tertiary.A600}>
            Transferência
          </Heading6>
          <BillingMoneyInput
            value={novo_contrato.transferencia}
            placeholder="R$ 00,00"
            onNumberFormat={val => {
              dispatch(setTransferencia(val.floatValue));
            }}
          />
        </NovoContratoInputLabel>
      </ValoresDataGrid>
    </ValoresInsideContent>
  );
};

export default ValoresTecnicosComp;
