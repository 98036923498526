import axios from "axios";

export class BillingHttp {
  private defaultUrl = process.env.REACT_APP_BILLING_DEFAUT_URL;
  private tokenBearer = JSON.parse(window.localStorage.getItem("token")!);

  get = async <T>(path: string, url?: string) => {
    return await axios.get<T>(`${url ?? this.defaultUrl}/${path}`, {
      headers: {
        Authorization: `Bearer ${this.tokenBearer.token}`,
      },
    });
  };
  post = async (path: string, options?: { data?: any }) => {
    return await axios.post(`${this.defaultUrl}/${path}`, options!.data ?? {}, {
      headers: {
        Authorization: `Bearer ${this.tokenBearer.token}`,
      },
    });
  };

  delete = async (path: string) => {
    return await axios.delete(`${this.defaultUrl}/${path}`, {
      headers: {
        Authorization: `Bearer ${this.tokenBearer.token}`,
      },
    });
  };

  put = async (path: string, options?: { data?: any }) => {
    return await axios.put(`${this.defaultUrl}/${path}`, options!.data ?? {}, {
      headers: {
        Authorization: `Bearer ${this.tokenBearer.token}`,
      },
    });
  };
}
